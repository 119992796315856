import React from 'react';

function RadioGroup({ id, name, register, validationSchema, items }) {
  return (
    <div className="space-y-2">
      {items.map((item, index) => {
        return (
          <div key={index} className="space-x-1">
            <input id={`${id}_${item}`} name={name} value={item} type="radio" {...register(name, validationSchema)} />
            <label htmlFor={`${id}_${item}`} className={`${index % 2 === 0 ? 'text-blue-500' : 'text-blue-700'}`}>
              {item}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default RadioGroup;
