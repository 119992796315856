import React, { useRef, useState } from 'react';
import { PortalWithState } from 'react-portal';
import StudentSelect from '../StudentSelect/StudentSelect';
import UserDispoService from '../../oldService/userDispoService';
import { useEffect } from 'react';
import BigAnimateButton from '../../components/BigAnimateButton';

function PeopleListAddModal({ excludedUsers, firstStep, dispoId, onAdded, newList, AdminShortcut }) {
  const [student, setStudent] = useState(null);
  const closePortalRef = useRef(null);
  const onSubmit = async () => {
    await UserDispoService.PostUserDispo(dispoId, student, firstStep?.step_id);
    if (onAdded instanceof Function) {
      onAdded();
    }
  };
  const handleDisplay = () => {
    if (closePortalRef.current) {
      closePortalRef.current();
      document.body.classList.remove('ModalOpenNoScrollBody');
    }
  };
  const handleOpen = (open) => {
    open();
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleDisplay();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  return (
    <PortalWithState closeOnEsc>
      {({ openPortal, closePortal, portal }) => {
        closePortalRef.current = closePortal;
        return (
          <>
            {AdminShortcut && (
              <BigAnimateButton handleOnClick={() => handleOpen(openPortal)} btnText="Ajouter un participant">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <line x1="20" y1="8" x2="20" y2="14"></line>
                  <line x1="23" y1="11" x2="17" y2="11"></line>
                </svg>
              </BigAnimateButton>
            )}
            {newList && (
              <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-1 min-w-0">
                    <p className="text-base font-bold text-gray-900 truncate">Ajouter un nouveau participant</p>
                  </div>
                  <div className="flex gap-8">
                    <button
                      className="text-newViolet-300 hover:text-newViolet-200"
                      onClick={() => {
                        openPortal();
                        document.body.classList.add('ModalOpenNoScrollBody');
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </button>
                  </div>
                </div>
              </li>
            )}
            {!AdminShortcut && !newList && (
              <button
                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                type="button"
                onClick={() => {
                  openPortal();
                  document.body.classList.add('ModalOpenNoScrollBody');
                }}>
                Ajouter un participant
              </button>
            )}

            {portal(
              <>
                <div
                  className="py-12 fixed bg-gray-700 transition duration-150 ease-in-out z-50 top-0 right-0 bottom-0 left-0 flex backdrop-blur-sm bg-white/30 backgoundModalColor"
                  id="modal">
                  <div
                    id="authentication-modal"
                    tabIndex="-1"
                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
                    aria-modal="true"
                    role="dialog">
                    <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
                      <div className="relative bg-white rounded shadow">
                        <button
                          type="button"
                          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                          onClick={() => {
                            closePortal();
                            document.body.classList.remove('ModalOpenNoScrollBody');
                          }}>
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                        <div className="py-6 px-6 lg:px-8">
                          <h3 className="mb-4 text-xl font-medium text-gray-900">Ajout d'un participant</h3>
                          <div className="space-y-6">
                            <StudentSelect
                              excludedUsers={excludedUsers}
                              onSelect={(data) => {
                                setStudent(data);
                              }}
                            />
                            <div className="flex justify-around items-center pt-2">
                              <div>
                                <button
                                  className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                                  onClick={async () => {
                                    await onSubmit();
                                    closePortal();
                                  }}>
                                  Ajouter
                                </button>
                              </div>
                              <div>
                                <button
                                  className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-red-500 hover:text-white flex space-x-1 mb-6"
                                  onClick={closePortal}>
                                  Fermer
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        );
      }}
    </PortalWithState>
  );
}

export default PeopleListAddModal;
