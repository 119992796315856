import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif_step';

const PostDispoStep = (dispoId, stepId, order) => {
  return axios.post(
    API_URL,
    {
      dispositif_id: dispoId,
      step_id: stepId,
      order: order
    },
    { headers: authHeader() }
  );
};

const GetAllDispoStepDispo = (dispoId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      dispoId +
      '&fields=data_created,date_updated,dispositif_id,id,order,step_id',
    { headers: authHeader() }
  );
};
const GetAllDispoStepDispoWithOrder = (dispoId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      dispoId +
      '&fields=data_created,date_updated,dispositif_id,id,order,step_id.order,step_id.id,step_id.name',
    { headers: authHeader() }
  );
};

const FormatGetAllDispoStepDispo = (stepList, setStepList, dispoId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = (await GetAllDispoStepDispo(dispoId)).data.data;

      data.map((item) => {
        list.push(item);
        return item;
      });
      setStepList(list);
    };
    MakeList();
  }, []);
  return stepList;
};

const DispoStepService = {
  PostDispoStep,
  GetAllDispoStepDispo,
  FormatGetAllDispoStepDispo,
  GetAllDispoStepDispoWithOrder
};

export default DispoStepService;
