import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginError from '../../oldComponents/LoginError/LoginError';
import { AuthContext } from '../../AuthProvider';
import './Login.css';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import AuthService from '../../oldService/authService';
export const client = process.env.REACT_APP_CLIENT_NAME;

const Login = () => {
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const navigate = useNavigate();
  const login = AuthService.login;
  const { user, setUser, setIsLogged } = useContext(AuthContext);
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeNewPassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
  };
  useEffect(() => {
    if (user !== null) {
      navigate('/home');
    }
  }, [user]);
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    login(email, password, setLoading, setErrorLogin, setUser, setIsLogged);
    setPassword('');
  };

  return (
    <div
      className="Login absolute w-screen h-screen"
      style={{
        background: `url('../assets/images/${client}_loginbg.png') no-repeat center fixed`,
        backgroundSize: 'cover'
      }}>
      <form onSubmit={handleLogin}>
        <div className="absolute bg-white border shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col w-2/3 text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:px-16 xl:px-20 md:w-1/2 lg:w-1/3">
          <img src={`/assets/images/${client}_loginlogo.png`} alt={'logo'} className="pb-6" />
          {page === 0 && (
            <div>
              <input
                className="shadow appearance-none border rounded py-2 px-3 mb-1 mx-auto text-gray-700 leading-tight focus:outline-none w-full"
                type="text"
                name="email"
                value={email}
                onChange={onChangeEmail}
                placeholder="Adresse e-mail"
              />
              <input
                className="shadow appearance-none border rounded py-2 px-3 mt-1 mx-auto text-gray-700 leading-tight focus:outline-none w-full"
                type="password"
                name="password"
                value={password}
                onChange={onChangePassword}
                placeholder="Mot de passe"
              />
              {errorLogin && (
                <div className="mt-3 -mb-3">
                  <LoginError />
                </div>
              )}
              <div className="flex justify-between items-center pt-6 flex-col-reverse xl:flex-row">
                <a
                  href="https://d-prod.responsiblenatives.fr/admin/reset-password"
                  // onClick={HandleResetPass}
                  className="font-medium text-end pt-5 xl:pt-0 hover:underline">
                  Mot de passe oublié ?
                </a>
                <LoadingButton
                  handleSubmit={handleLogin}
                  loading={loading}
                  btnText="Connexion"
                  btnColor="bg-blue-500"
                  btnTextColor="text-white"
                  hoverProp="hover:bg-blue-600 hover:text-white"
                />
              </div>
            </div>
          )}
          {page === 1 && (
            <div>
              <input
                className="shadow appearance-none border rounded py-2 px-3 mb-1 mx-auto text-gray-700 leading-tight focus:outline-none w-full"
                type="text"
                name="email"
                value={email}
                onChange={onChangeEmail}
                placeholder="Adresse e-mail"
              />
              <input
                className="shadow appearance-none border rounded py-2 px-3 mb-1 mx-auto text-gray-700 leading-tight focus:outline-none w-full"
                type="password"
                name="newPass"
                value={newPassword}
                onChange={onChangeNewPassword}
                placeholder="Nouveau mot de passe"
              />
              <input
                className="shadow appearance-none border rounded py-2 px-3 mb-1 mx-auto text-gray-700 leading-tight focus:outline-none w-full"
                type="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                placeholder="Confirmation du mot de passe"
              />
              <div className="pt-6">
                <button
                  className="bg-blue-500 hover:bg-blue-700/90 text-white font py-2 px-4 rounded-sm"
                  onClick={() => {
                    setPage(0);
                  }}>
                  Modifier
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
