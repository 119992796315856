import React, { useEffect, useState } from 'react';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';
import UserDocList from '../UserDocList/UserDocList';
import { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';

const SignatureContactContent = ({ blur, setBlur }) => {
  const { user } = useContext(AuthContext);
  const [punctuals, setPunctuals] = useState([]);
  PunctualFilesServices.FormatGetAllPunctualFilesContact(punctuals, setPunctuals, user.id);

  const [userDispos, setUserDispos] = useState([]);
  UserDispoService.FormatGetAllUserDispoAll(userDispos, setUserDispos);

  const [docList, setDocList] = useState([]);

  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    const list = [];
    punctuals.map((item) => {
      if (item.organization_id && item.convention_doc.signed === false) {
        list.push({
          name: item.user.last_name + ' ' + item.user.first_name + ' Convention',
          link: item.convention_doc.contact_signature_link,
          signed: item.convention_doc.signed,
          docId: item.convention_doc.id
        });
      }
      return item;
    });
    setDocList(list);
  }, [punctuals]);
  return (
    <div>
      {docList.length === 0 ? (
        <div className="flex flex-col gap-2">
          <span className="text-2xl">Bienvenue sur la plateforme Responsible Natives.</span>
          <span className="text-2xl">Vous n'avez actuellement aucune signature en attente.</span>
        </div>
      ) : (
        <UserDocList
          user={user}
          userDocList={docList}
          setBlur={setBlur}
          setDisplayModal={setDisplayModal}
          displayModal={displayModal}
          blur={blur}
          userDispos={userDispos}
        />
      )}
    </div>
  );
};

export default SignatureContactContent;
