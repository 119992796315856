import React, { useState } from 'react';
import DocumentIcon from '../DocumentIcon/DocumentIcon';
import RemoveIcon from '../RemoveIcon/RemoveIcon';
import DownloadAllIcon from '../DownloadAllIcon/DownloadAllIcon';
import AddIcon from '../AddIcon/AddIcon';
import DispDirFileService from '../../oldService/dispDirFile';
import FileService from '../../oldService/fileService';
import DownloadOnceIcon from '../DownloadOnceIcon/DonwloadOnceIcon';
import { API } from '../../oldService/authHeader';

function DocListModif(props) {
  const [loading, setLoading] = useState(false);
  const handleDisplay = () => {
    props.setBlur(true);
    props.setDisplayAddDoc(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  const handleDownloadAll = () => {
    const downloadPromises = props.data.map((item) => {
      const url = API + 'assets/' + item.directus_files_id.id + '?download';
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = item.directus_files_id.title;
          a.click();
          URL.revokeObjectURL(url);
        });
    });

    Promise.all(downloadPromises);
  };

  const handleRemove = async (item) => {
    setLoading(true);
    DispDirFileService.DeleteOneDispFile(item.id).then(() => {
      FileService.deleteFile(item.directus_files_id.id).then(() => {
        // window.location.reload();
        setLoading(false);
        props.setReload((prev) => prev + 1);
      });
    });
  };

  return (
    <div className="DocListModif flex px-10 flex-col items-center space-y-8 xl:space-y-0">
      <h2 className="font-bold text-xl pb-5 text-blue-700/90 pl-5">Documents disponibles</h2>
      <ul className="bg-white rounded-sm w-8/12 h-48 overflow-y-scroll mb-3 ring-1 ring-opacity-5 ring-black shadow-sm">
        {props.data.map((item, index) => {
          let title = item.directus_files_id.title;

          return (
            <li key={index} className="py-2 px-3 hover:bg-gray-100 text-gray-600 hover:text-black text-sm">
              <div className="flex justify-between rounded items-center">
                <div className="flex flex-col">
                  {item.user_id === null
                    ? 'Public'
                    : `Privé pour ${item.user_id.first_name + ' ' + item.user_id.last_name}`}
                  <div className="flex items-center space-x-1 hover:underline">
                    <DocumentIcon />
                    <a href={API + 'assets/' + item.directus_files_id.id + '?download'}>
                      {title.length > 25 ? title.substring(0, 30) + '...' : title}
                    </a>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <div>
                    <a className="hover:text-blue-500" href={API + 'assets/' + item.directus_files_id.id + '?download'}>
                      <DownloadOnceIcon />
                    </a>
                  </div>
                  <div>
                    {loading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <button
                        className="hover:text-red-600"
                        onClick={() => {
                          handleRemove(item);
                        }}>
                        <RemoveIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="pt-5 flex space-x-5">
        <button
          className="flex justify-center ring-1 ring-black ring-opacity-5 shadow-sm bg-gray-200 px-3 py-2 space-x-1 rounded-sm hover:bg-blue-500 hover:text-white"
          onClick={handleDownloadAll}>
          <DownloadAllIcon />
          <p>Tout télécharger</p>
        </button>
        <button
          className="flex justify-center ring-1 ring-black ring-opacity-5 shadow-sm bg-gray-200 px-3 py-2 space-x-1 rounded-sm hover:bg-blue-500 hover:text-white"
          onClick={handleDisplay}>
          <AddIcon />
          <p>Ajouter</p>
        </button>
      </div>
    </div>
  );
}

export default DocListModif;
