import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

type oneUserDispo = {
  id: string;
  name: string;
  last_name: string;
  team: { group_id: { name: string; id: number; parentgroup: { name: string } } };
  email: string;
  phones: string;
  other_id: number;
  step: string;
  validation: string;
  files_asso_id: number;
  punctual_files_id: number;
  evaluation_id: number;
  evaluation_id_2: number;
  evaluation_finish: boolean;
  finish: boolean;
  date: string;
};

const ExportListEvent = ({ ListOfParticipant, name }) => {
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    const list = [['Nom', 'Prénom', 'email', 'phones', 'Promotion', 'Date inscription', 'Signature']];
    ListOfParticipant.map((oneUserDispo: oneUserDispo) => {
      list.push([
        oneUserDispo.last_name,
        oneUserDispo.name.replace(oneUserDispo.last_name, ''),
        oneUserDispo.email,
        oneUserDispo.phones !== null ? "'" + oneUserDispo.phones : oneUserDispo.phones,
        oneUserDispo.team?.group_id.name,
        new Date(oneUserDispo.date).toLocaleString()
      ]);
    });
    setCsvData(list);
  }, []);
  return (
    <div>
      <CSVLink
        filename={'Export_participants_' + name + '_' + new Date().toLocaleDateString()}
        data={csvData}
        separator=";">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                clipRule="evenodd"
              />
            </svg>
            <div className="ml-4 flex min-w-0 flex-1 gap-2">
              <span className="truncate font-medium">{"Export des personnes inscrites à l'évènement"}</span>
              <span className="flex-shrink-0 text-gray-400">CSV</span>
            </div>
          </div>
          <div className="ml-4 flex flex-shrink-0 space-x-4">
            <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
              </svg>
            </button>
          </div>
        </li>
      </CSVLink>
    </div>
  );
};

export default ExportListEvent;
