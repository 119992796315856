import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FormDataService from '../services/form.service';
import { RootState } from '../store';

export type Choice = {
  name: string;
};

export type Question = {
  id: string;
  name: string;
  description: string;
  questions_id: string;
  questions_choice_id: Array<{ questions_choice_id: Choice }>;
  questions_choice_parents: Array<object>;
  questions_parents: Array<object>;
  required: boolean;
  type: string;
};

export type Form = {
  id: string;
  name: string;
  questions_id: Array<{ questions_id: Question }>;
};

type FormState = {
  items: Form[];
  currentItem?: Form;
  isLoading: boolean;
};

const initialState: FormState = {
  items: [],
  currentItem: null,
  isLoading: false
};

export const createForm = createAsyncThunk('forms/create', async (data: object) => {
  const res = await FormDataService.create(data);
  return res.data;
});

export const retrieveForms = createAsyncThunk('forms/retrieve', async () => {
  const res = await FormDataService.getAll();
  return res.data;
});

export const updateForm = createAsyncThunk('forms/update', async (id: string, data: object) => {
  const res = await FormDataService.update(id, data);
  return res.data;
});

export const deleteForm = createAsyncThunk('forms/delete', async (id: string) => {
  await FormDataService.delete(id);
  return { id };
});

export const retrieveForm = createAsyncThunk('forms/get', async (id: string) => {
  const res = await FormDataService.get(id);
  return res.data;
});

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createForm.fulfilled, (state, action) => {
      state.items.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(retrieveForms.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(retrieveForms.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(retrieveForms.fulfilled, (state, action) => {
      return { items: action.payload, isLoading: false };
    });
    builder.addCase(updateForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateForm.fulfilled, (state, action) => {
      const index = state.items.findIndex((form) => form.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload
      };
    });
    builder.addCase(deleteForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteForm.fulfilled, (state, action) => {
      const index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
      state.isLoading = false;
    });
    builder.addCase(retrieveForm.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(retrieveForm.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(retrieveForm.fulfilled, (state, action) => {
      state.currentItem = action.payload.data;
      state.isLoading = false;
    });
  }
});

const { reducer } = formSlice;
export default reducer;

export const selectLoading = (state: RootState) => state.forms.isLoading;
export const selectCurrentForm = (state: RootState) => state.forms.currentItem;
