/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import DispoStepService from '../../oldService/dispoStepService';
import UserDispoService from '../../oldService/userDispoService';

const NewPipelineMultiple = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const [dispoStep, setDispoStep] = useState([]);
  const [allUserDispo, setAllUserDispo] = useState([]);

  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);
  UserDispoService.FormatGetAllUser(allUserDispo, setAllUserDispo, id);

  const userOnCLick = (user) => {
    if (
      user.validation ||
      (user.step.name === 'Évaluation' && user.evaluation_finish !== null && user.validation === false)
    ) {
      props.setUserModal(user);
      document.body.classList.add('ModalOpenNoScrollBody');
      props.setDisplayModal(true);
      props.setBlur(true);
      props.setPunSelUser(allUserDispo.find((item) => item.id === user.id).punctual_files_id);
    }
    if (!user.validation && (user.step.name === 'Convention' || user.step.name === 'Signature')) {
      props.setUserModal(user);
      props.setBlur(true);
      props.setPunSelUser(allUserDispo.find((item) => item.id === user.id).punctual_files_id);
      document.body.classList.add('ModalOpenNoScrollBody');
      props.setDisplayModalAdminModify(true);
    }
  };

  return (
    <div className="NewPipelineMultiple" id="pipeline">
      <div className="flex justify-around bg-white min-h-[10vh]">
        {dispoStep &&
          dispoStep
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex flex-col items-center justify-center text-white bg-blue-500 w-full shadow-sm ring-1 ring-black ring-opacity-5 ${
                    index === 0 && 'rounded-tl-lg rounded-bl-lg'
                  } ${index === dispoStep.length - 1 && 'rounded-tr-lg rounded-br-lg'}`}>
                  <div key={index} className="text-center py-2">
                    <h2 className="">
                      {props.dispo &&
                        props.dispo.step &&
                        props.dispo.step.find((step) => step.step_id.id === item.step_id).step_id.name}
                    </h2>
                    {!props.perso && (
                      <p className="text-gray-300 text-sm">
                        {props.users.filter((use) => use.step.id === item.step_id).length} utilisateur
                        {props.users.filter((use) => use.step.id === item.step_id).length > 1 ? 's' : ''}
                      </p>
                    )}
                  </div>
                  <div
                    className={`bg-gray-100 h-full w-full flex flex-col gap-5 items-center py-5 ${
                      index === 0 && 'rounded-bl-lg'
                    } ${index === dispoStep.length - 1 && 'rounded-br-lg'}`}>
                    {!props.perso &&
                      props.users
                        .filter((use) => use.step.id === item.step_id)
                        .sort((a, b) => {
                          if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
                            return -1;
                          }
                          if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((user, indexU) => {
                          return (
                            <div key={indexU} className="w-full px-3">
                              <button
                                key={indexU}
                                className={`relative flex justify-start items-center h-14 gap-5 rounded-lg bg-white text-black ${
                                  user.validation ||
                                  user.step.name === 'Convention' ||
                                  user.step.name === 'Signature' ||
                                  (user.step.name === 'Évaluation' && user.evaluation_finish !== null)
                                    ? 'hover:bg-blue-500 hover:text-white cursor-pointer'
                                    : 'cursor-default'
                                } w-full`}
                                onClick={() => {
                                  userOnCLick(user);
                                }}>
                                <span
                                  className={`h-full rounded-l-lg w-4 ${
                                    user.step.name === 'Convention' || user.step.name === 'Signature'
                                      ? user.validation
                                        ? 'bg-orange-500'
                                        : 'bg-blue-200'
                                      : user.step.name === 'Évaluation'
                                      ? user.validation
                                        ? 'bg-orange-500'
                                        : user.evaluation_finish !== null
                                        ? 'bg-green-600'
                                        : 'bg-gray-200'
                                      : user.validation
                                      ? 'bg-orange-500'
                                      : 'bg-gray-200'
                                  }`}></span>
                                <p className="text-base">{user.name}</p>
                              </button>
                            </div>
                          );
                        })}
                    {props.perso &&
                      props.users
                        .filter((use) => use.step_id.id === item.step_id)
                        .map((user, indexU) => {
                          return (
                            <div key={indexU} className="w-full px-3">
                              <button
                                key={indexU}
                                className="relative flex justify-start items-center h-14 gap-5 rounded-lg bg-white text-black w-full">
                                <span
                                  className={`h-full rounded-l-lg w-4 ${
                                    user.validation ? 'bg-orange-500' : user.finish ? 'bg-green-500' : 'bg-gray-200'
                                  }`}></span>
                                <p className="text-xs">{user.user.first_name + ' ' + user.user.last_name}</p>
                              </button>
                            </div>
                          );
                        })}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default NewPipelineMultiple;
