import React, { useEffect, useState } from 'react';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';

import { PROD } from '../../oldService/authHeader';

const SignatureForm = ({ userDispo }) => {
  const [punctual, setPunctual] = useState([]);
  console.log(punctual[0]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  useEffect(() => {
    if (punctual[0] && punctual[0].convention_doc.signed_file) {
      UserDispoService.PatchUserDispoValidate(userDispo.id, null, true).then(() => {
        window.location.reload();
      });
    }
  }, [punctual]);

  return (
    <div className="w-full space-y-5">
      <h2 className="font-bold text-xl text-blue-700/90">Signature</h2>
      <div>
        <iframe
          className="w-full h-screen"
          title="Signature"
          src={
            punctual[0] &&
            punctual[0].convention_doc.user_signature_link + (PROD ? '' : '&disable_domain_validation=true')
          }
        />
      </div>
    </div>
  );
};

export default SignatureForm;
