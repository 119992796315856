import React, { useEffect, useState } from 'react';
import FormDisplay from '../Form/FormDisplay';
import { evaluations_asso_president, evaluations_asso_volunteer } from '../../../data';
import EvaluationServices from '../../../oldService/evaluationService';
import { useNavigate } from 'react-router-dom';
import UserDispoService from '../../../oldService/userDispoService';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import { Evaluation, QuestionEvaluation, UserEvaluate } from '../../../usualTypes/BasicTypes';

type typeProps = {
  responsible: boolean;
  lastEval: Evaluation;
  dispoId: string;
  userEvaluate: UserEvaluate;
  step: string;
};
const AssoEvaluation = ({ responsible, lastEval, dispoId, userEvaluate, step }: typeProps) => {
  const [evaluationToSubmit, setEvaluationToSubmit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [idQuestionInTotal, setIdQuestionInTotal] = useState([]);
  const [errorLength, setErrorLength] = useState(false);
  const [requiredAnswer, setRequiredAnswer] = useState([]);
  const navigate = useNavigate();

  const getAllQuestionForTotal = () => {
    const list = [];
    responsible
      ? evaluations_asso_president.map(
          (question: QuestionEvaluation) => question.type === 'starRating' && list.push(question.id)
        )
      : evaluations_asso_volunteer.map(
          (question: QuestionEvaluation) => question.type === 'starRating' && list.push(question.id)
        );
    return list;
  };

  const getAllQuestionRequired = () => {
    const list = [];
    responsible
      ? evaluations_asso_president.map(
          (question: QuestionEvaluation) => question.mandatory === true && list.push(question.id)
        )
      : evaluations_asso_volunteer.map(
          (question: QuestionEvaluation) => question.mandatory === true && list.push(question.id)
        );
    return list;
  };

  useEffect(() => {
    setIdQuestionInTotal(getAllQuestionForTotal());
    setRequiredAnswer(getAllQuestionRequired());
  }, [evaluations_asso_president, evaluations_asso_volunteer]);

  useEffect(() => {
    if (lastEval) {
      setEvaluationToSubmit(lastEval.evals);
    }
  }, [lastEval]);

  const calculTotal = () => {
    let total = 0;
    evaluationToSubmit.map((answer) => {
      if (idQuestionInTotal.includes(answer.id_question)) {
        total += parseInt(answer.answer);
      }
    });
    return responsible ? (total / 4.5).toFixed(2) : (total / 4).toFixed(2);
  };

  const handleSubmit = async () => {
    let error = false;
    const inTheArray = (id: number) => evaluationToSubmit.some((question) => question.id_question === id);
    const allRequiredIsAnswer = requiredAnswer.every(inTheArray);
    if (!allRequiredIsAnswer) {
      setErrorLength(true);
      return;
    }
    setLoading(true);
    if (lastEval) {
      try {
        await EvaluationServices.DeleteEvaluation(lastEval.id);
      } catch (err) {
        console.log(err);
        error = true;
        setRequestError(error);
        setLoading(false);
      }
    }
    try {
      const evaluation = await EvaluationServices.postNewEvaluation(
        evaluationToSubmit,
        calculTotal(),
        responsible ? 'membre du bureau' : 'bénévole',
        userEvaluate.id,
        dispoId
      );
      if (step === '1') {
        if (responsible) {
          await DispDirUsersService.PatchDispDirUserEval(
            userEvaluate.id_table_link_dispo_user,
            evaluation.data.data.id
          );
        } else {
          await UserDispoService.PatchUserDispoEval(userEvaluate.id_table_link_dispo_user, evaluation.data.data.id);
        }
      } else if (step === '2') {
        if (responsible) {
          await DispDirUsersService.PatchDispDirUserEval2(
            userEvaluate.id_table_link_dispo_user,
            evaluation.data.data.id
          );
        } else {
          await UserDispoService.PatchUserDispoEval2(userEvaluate.id_table_link_dispo_user, evaluation.data.data.id);
        }
      }
    } catch (err) {
      console.log(err);
      error = true;
      setRequestError(error);
      setLoading(false);
    }
    if (!error) {
      setLoading(false);
      navigate(-1);
    }
  };
  return (
    <div className="space-y-5 px-10 py-5 rounded-sm bg-gray-100">
      <div>
        <h2 className="font-medium pb-1 text-xl text-blue-700">
          Vous pouvez attribuer la note que vous souhaitez dans chaque domaine
        </h2>
        <h2 className="font-medium pb-1 text-xl text-blue-700">
          Les notations vont de 1⭐ pas satisfaisant à 10⭐ très satisfaisant
        </h2>
      </div>
      <FormDisplay
        questionArray={responsible ? evaluations_asso_president : evaluations_asso_volunteer}
        evaluationToSubmit={evaluationToSubmit}
        setEvaluationToSubmit={setEvaluationToSubmit}
        OnClickSubmit={handleSubmit}
        error={requestError}
        loading={loading}
        errorLength={errorLength}
      />
    </div>
  );
};

export default AssoEvaluation;
