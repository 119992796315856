import React, { useState } from 'react';
import Modal from '../Modal/Modal';

import './UserCreateEvent.css';

function UserCreateEvent(props) {
  const [display, setDisplay] = useState(false);

  const handleDisplay = () => {
    setDisplay(true);
    props.setBlur(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  return (
    <div className="UserCreateEvent">
      <div className="flex justify-center">
        <button
          className={`bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6 ${
            props.blur === true ? 'blur-sm' : 'blur-none'
          }`}
          onClick={handleDisplay}>
          Ajouter un évènement
        </button>
      </div>
      {display && <Modal setDisplay={setDisplay} setBlur={props.setBlur} />}
    </div>
  );
}

export default UserCreateEvent;
