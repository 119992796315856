import React from 'react';
import { useForm } from 'react-hook-form';

function ModalDecline({ setDisplay, onDecline }) {
  const { register, handleSubmit } = useForm();

  const handleDisplay = () => {
    setDisplay(false);
  };

  const onSubmit = (data) => {
    onDecline(data);
    handleDisplay();
  };

  return (
    <div className="Modal">
      <div
        id="decline-modal"
        tabIndex="-1"
        className="overflow-y-scroll overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
        aria-modal="true"
        role="dialog">
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDisplay}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6 px-6 lg:px-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="mb-4 text-xl font-medium text-gray-900">Refuser</h3>
                <div className="space-y-6" action="#">
                  <div>
                    <label htmlFor="reason" className="block mb-2 text-sm font-medium text-gray-900">
                      Motif du refus
                    </label>
                    <textarea
                      name="reason"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Description"
                      {...register('reason')}
                    />
                  </div>
                  <div className="flex justify-center space-x-5">
                    <button
                      className="bg-gray-200 px-4 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                      type="submit">
                      Refuser
                    </button>
                    <button
                      className="bg-gray-200 px-4 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-red-500 hover:text-white"
                      onClick={handleDisplay}>
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDecline;
