import React, { useEffect, useState } from 'react';
import AnswerFormService from '../../oldService/answerFormService';
import DocumentsServices from '../../oldService/documentServices';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';
import { findNextStep } from '../../oldService/steps/findNextStep';
import AkanemaApiService from '../../oldService/akanemaApi';
import { API } from '../../oldService/authHeader';
import { MailJetServices } from '../../oldService/mailjetService';
import UserService from '../../oldService/userService';
import ModalDecline from '../ModalDecline';
import ModalErrorPunctual from '../ModalErrorPunctual/ModalErrorPunctual';
import { useNavigate } from 'react-router-dom';

const ModalValidAdminPunctualV2 = ({ userModal, displayModal, setDisplayModal, setBlur, punSelUser, dispo }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const navigate = useNavigate();

  const [userDispo, setUserDispo] = useState([]);
  const [punctual, setPunctual] = useState([]);
  const [dispoStep, setDispoStep] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeclineModalOpened, setIsDeclineModalOpened] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const oneUserDispo = await UserDispoService.getUserDispoDispo(id, userModal.id);
      setUserDispo(oneUserDispo.data.data[0]);
      const onePunctFile = await PunctualFilesServices.GetOnePunctualFiles(punSelUser);
      setPunctual(onePunctFile.data.data);
      setDispoStep(dispo.step);
    };
    if (displayModal) {
      fetchData();
    }
  }, [displayModal]);

  const handleDisplay = () => {
    document.body.classList.remove('ModalOpenNoScrollBody');
    setDisplayModal(false);
    setBlur(false);
  };

  const [linkConvention, setLinkConvention] = useState('');
  const [linkEvaluation, setLinkEvaluation] = useState('');
  useEffect(() => {
    if (punctual[0] && userDispo.step_id.name === 'Signature') {
      setLinkConvention(API + 'assets/' + punctual[0].convention_doc.signed_file + '?download');
    } else if (punctual[0] && userDispo.step_id.name === 'Évaluation') {
      setLinkEvaluation(punctual[0].evaluation_doc.attachment_urls[0]);
    }
  }, [punctual]);

  const handleAccept = async () => {
    try {
      setLoading(true);
      if (userDispo.step_id.name === 'Évaluation') {
        await UserDispoService.PatchUserDispoStep(userDispo.id, userDispo.step_id.id);
        await UserDispoService.PatchUserDispoFinish(userDispo.id);
      } else {
        await UserDispoService.PatchUserDispoStep(
          userDispo.id,
          findNextStep(dispoStep.find((item) => item.step_id.id === userDispo.step_id.id).order, dispoStep).step_id.id
        );
      }

      if (userDispo.step_id.name === 'Convention') {
        const newPassword =
          'Sign' + punctual[0].contact.id.substr(0, 5) + '!' + punctual[0].contact.first_name.substr(0, 2);
        await MailJetServices.SendMail(
          punctual[0].contact.email,
          newPassword,
          userDispo.user.last_name + ' ' + userDispo.user.first_name,
          punctual[0].organization_id.name,
          punctual[0].convention_doc.data.start_date,
          punctual[0].convention_doc.data.end_date
        );

        await UserService.PatchUserPassword(punctual[0].contact.id, newPassword);
        const res = await AkanemaApiService.Sign(
          punctual[0].convention_doc.id,
          punctual[0].id,
          punctual[0].convention_doc.french ? 3 : 4
        );

        await DocumentsServices.PatchUserSignatureDocument(
          punctual[0].convention_doc.id,
          res.data.data.details.signers[0].signature_link
        );

        await DocumentsServices.PatchContactSignatureDocument(
          punctual[0].convention_doc.id,
          res.data.data.details.signers[1].signature_link
        );

        console.log('3');
        document.body.classList.remove('ModalOpenNoScrollBody');
        window.location.reload();
      } else if (userDispo.step_id.name === 'Signature') {
        console.log('hbhr');
        await PunctualFilesServices.PatchEvaluate(punctual[0].id, true);
        document.body.classList.remove('ModalOpenNoScrollBody');
        window.location.reload();
      } else {
        document.body.classList.remove('ModalOpenNoScrollBody');
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const handleModifStep = () => {
    document.body.classList.remove('ModalOpenNoScrollBody');
    navigate('/admin/punctual/modifForm', { state: { userDispo: userDispo, dispo: dispo } });
  };
  const handleDecline = async ({ reason }) => {
    setLoading(true);
    try {
      if (userDispo.step_id.name === "Projet d'engagement") {
        await DocumentsServices.DeleteDocument(punctual[0].project_doc.id);
        await AnswerFormService.DeleteAnserForm(punctual[0].project_answer);
        await PunctualFilesServices.DeletePunctualFiles(punctual[0].id);
        await UserDispoService.PatchUserDispoValidate(userDispo.id, null, false, reason);
        window.location.reload();
      } else if (userDispo.step_id.name === 'Convention') {
        // Step 1
        // punctual[0].contact_list.map(async (item) => {
        //     await PunctualFilesDirectusUsersServices.DeletePunFilesDirectusUsers(item.id);
        //     return item;
        // });

        // Step 2
        await DocumentsServices.DeleteDocument(punctual[0].mission_doc.id);
        // await AnswerFormService.DeleteAnserForm(punctual[0].mission_answer);
        await PunctualFilesServices.PacthPunctualFilesMission(punctual[0].id, null, null);

        // Step 3
        await DocumentsServices.DeleteDocument(punctual[0].convention_doc.id);
        // await AnswerFormService.DeleteAnserForm(punctual[0].convention_doc);
        await PunctualFilesServices.PatchPunctualFilesConvention(punctual[0].id, null, null);
        await PunctualFilesServices.PatchOrgaContactPunctualFiles(punctual[0].id, null);

        // UserDispo State
        await UserDispoService.PatchUserDispoValidate(userDispo.id, null, false, reason);
        document.body.classList.remove('ModalOpenNoScrollBody');
        window.location.reload();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  if (punctual[0] === undefined) {
    return <h1>loading</h1>;
  }
  if (userDispo.step_id.name === "Projet d'engagement" && punctual[0].project_doc.attachment_urls === null) {
    return <ModalErrorPunctual handleDisplay={handleDisplay} />;
  }
  if (
    userDispo.step_id.name === 'Convention' &&
    (punctual[0].mission_doc.attachment_urls === null || punctual[0].convention_doc.attachment_urls === null)
  ) {
    return <ModalErrorPunctual handleDisplay={handleDisplay} />;
  }
  if (userDispo.step_id.name === 'Descriptif mission' && punctual[0].mission_doc.attachment_urls === null) {
    return <ModalErrorPunctual handleDisplay={handleDisplay} />;
  }
  if (userDispo.step_id.name === 'Évaluation' && punctual[0].evaluation_doc.attachment_urls === null) {
    return <ModalErrorPunctual handleDisplay={handleDisplay} />;
  }
  return (
    <>
      {isDeclineModalOpened && (
        <ModalDecline
          setDisplay={setIsDeclineModalOpened}
          onDecline={async (data) => {
            await handleDecline(data);
          }}
        />
      )}

      <div className={`ModalValidAdmin ${isDeclineModalOpened ? 'blur-sm' : ''}`}>
        <div
          id="authentication-modal"
          tabIndex="-1"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-40 w-full md:inset-0 h-modal md:h-full justify-center items-center flex backgoundModalColor"
          aria-modal="true"
          role="dialog">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded shadow">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                onClick={handleDisplay}
                disabled={loading}>
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="py-6 px-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900">Validation de l'étape</h3>
                <div className="space-y-6 text-center">
                  <p className="text-center">
                    Demande en attente de {userModal.name} pour l'étape "{userDispo.step_id && userDispo.step_id.name}
                    ".
                  </p>
                  {userDispo.step_id && userDispo.step_id.name === "Projet d'engagement" && (
                    <div className="flex justify-center hover:underline">
                      <a href={punctual[0] && punctual[0].project_doc.attachment_urls[0]} className="text-blue-500">
                        Projet d'engagement
                      </a>
                    </div>
                  )}
                  {userDispo.step_id && userDispo.step_id.name === "Organisation d'accueil" && (
                    <div className="space-y-10">
                      <div className="space-y-4">
                        <p className="font-bold text-blue-500 text-center text-lg">
                          {punctual[0] && punctual[0].organization_id.name}
                        </p>
                        <div className="flex justify-center">
                          <div>
                            <div className="flex">
                              <p className="text-blue-500 pr-1">Adresse:</p>
                              <p>{punctual[0] && punctual[0].organization_id.adress},</p>
                              <p>
                                {punctual[0] && punctual[0].organization_id.postal_code}{' '}
                                {punctual[0] && punctual[0].organization_id.city},
                              </p>
                              <p>{punctual[0] && punctual[0].organization_id.country}</p>
                            </div>
                            <div className="flex">
                              <p className="text-blue-500 pr-1">Numéro SIRET/RNA:</p>
                              <p>{punctual[0] && punctual[0].organization_id.numero_siret_rna}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="font-bold text-blue-500 text-center text-lg">{`Contact${
                          punctual[0] && punctual[0].contact_list.length > 1 ? 's' : ''
                        }`}</p>
                        <div className="flex justify-center">
                          <div>
                            {punctual[0] &&
                              punctual[0].contact_list.map((item, index) => {
                                return (
                                  <div key={index} className="flex space-x-5">
                                    <p className="text-blue-500">
                                      {item.directus_users_id.first_name} {item.directus_users_id.last_name}
                                    </p>
                                    <a
                                      href={
                                        item.directus_users_id.email ? 'mailto:' + item.directus_users_id.email : ''
                                      }
                                      className="hover:underline">
                                      {item.directus_users_id.email}
                                    </a>
                                    <p>{item.directus_users_id.phones}</p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {userDispo.step_id && userDispo.step_id.name === 'Descriptif mission' && (
                    <div className="flex justify-center hover:underline">
                      <a href={punctual[0] && punctual[0].mission_doc.attachment_urls[0]} className="text-blue-500">
                        Fiche mission
                      </a>
                    </div>
                  )}
                  {userDispo.step_id && userDispo.step_id.name === 'Convention' && (
                    <div className="text-center">
                      <a
                        href={punctual[0] && punctual[0].mission_doc.attachment_urls[0]}
                        className="text-blue-500 hover:underline">
                        Fiche mission
                      </a>
                      <br />
                      <a
                        href={punctual[0] && punctual[0].convention_doc.attachment_urls[0]}
                        className="text-blue-500 hover:underline">
                        Convention
                      </a>
                    </div>
                  )}
                  {userDispo.step_id && userDispo.step_id.name === 'Signature' && (
                    <div className="flex justify-center hover:underline">
                      <a href={linkConvention} className="text-blue-500">
                        Convention Signée
                      </a>
                    </div>
                  )}
                  {userDispo.step_id && userDispo.step_id.name === 'Évaluation' && (
                    <div className="flex justify-center hover:underline">
                      <a href={linkEvaluation} className="text-blue-500">
                        Evaluation remplie
                      </a>
                    </div>
                  )}
                  <div className="flex justify-around items-center pt-2">
                    {!loading ? (
                      <>
                        <div>
                          <button
                            className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                            onClick={async () => {
                              await handleAccept();
                            }}>
                            Valider
                          </button>
                        </div>
                        {userDispo.step_id &&
                          userDispo.step_id.name !== 'Signature' &&
                          userDispo.step_id.name !== 'Évaluation' && (
                            <div>
                              <button
                                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-red-500 hover:text-white flex space-x-1 mb-6"
                                onClick={() => {
                                  setIsDeclineModalOpened(true);
                                }}>
                                Refuser
                              </button>
                            </div>
                          )}
                        {userDispo.step_id && userDispo.step_id.name === 'Convention' && (
                          <div>
                            <button
                              className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                              onClick={handleModifStep}>
                              Modifier
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mb-4">
                        <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalValidAdminPunctualV2;
