import { useEffect } from 'react';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/organization_directus_users_1';

const PostOrgaDirUsers1 = (orgaId, contactId) => {
  return axios.post(
    API_URL,
    {
      organization_id: orgaId,
      directus_users_id: contactId
    },
    { headers: authHeader() }
  );
};

const GetAllOrgaDirUsers1 = () => {
  const fields = [
    'id',
    'organization_id',
    'directus_users_id.id',
    'directus_users_id.group',
    'directus_users_id.first_name',
    'directus_users_id.last_name',
    'directus_users_id.email',
    'directus_users_id.last_access',
    'directus_users_id.phones',
    'directus_users_id.addrstreet',
    'directus_users_id.addrzipcode',
    'directus_users_id.addrcountry',
    'directus_users_id.function',
    'directus_users_id.email',
    'directus_users_id.phones',
    'directus_users_id.group.group_id.name',
    'directus_users_id.group.group_id.id',
    'directus_users_id.group.group_id.parentgroup.name',
    'directus_users_id.group.group_id.parentgroup'
  ];
  return axios.get(API_URL + '?fields=' + fields.join(',') + '&limit=-1', {
    headers: authHeader()
  });
};

const GetAllOrgaDirUsersForOrga = (orgaId) => {
  const fields = [
    'id',
    'organization_id',
    'directus_users_id.id',
    'directus_users_id.group',
    'directus_users_id.first_name',
    'directus_users_id.last_name',
    'directus_users_id.email',
    'directus_users_id.phones',
    'directus_users_id.last_access',
    'directus_users_id.addrstreet',
    'directus_users_id.addrzipcode',
    'directus_users_id.addrcountry',
    'directus_users_id.function',
    'directus_users_id.email',
    'directus_users_id.phones',
    'directus_users_id.group.group_id.name',
    'directus_users_id.group.group_id.id',
    'directus_users_id.group.group_id.parentgroup.name',
    'directus_users_id.group.group_id.parentgroup'
  ];
  return axios.get(API_URL + '?filter[organization_id][_eq]=' + orgaId + '&fields=' + fields.join(',') + '&limit=-1', {
    headers: authHeader()
  });
};

const FormatGetAllOrgaDirUsers1 = (orgaDir, setOrgaDir) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await OrgaDirUsers1Services.GetAllOrgaDirUsers1();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setOrgaDir(list);
    };
    MakeList();
  }, []);
  return orgaDir;
};

const FormatGetAllUsersForOrga = (orgaDir, setOrgaDir, orgaId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await GetAllOrgaDirUsersForOrga(orgaId);
      data.data.data.map((user) => {
        const item = user.directus_users_id;
        list.push({
          search:
            item.last_name +
            ' ' +
            item.first_name +
            ' ' +
            item.group.slice(-1)[0]?.group_id.name +
            ' ' +
            item.email +
            ' ' +
            item.phones,
          id: item.id,
          name: item.last_name + ' ' + item.first_name,
          team: item.group.slice(-1),
          email: item.email,
          email2: item.email2,
          lastAccess: new Date(item.last_access).toUTCString(),
          first_name: item.first_name,
          last_name: item.last_name,
          phone: item.phones,
          phones: item.phones,
          function: item.function
        });
        return item;
      });
      setOrgaDir(list);
    };
    MakeList();
  }, []);
  return orgaDir;
};
const getOrganizationByUser = (userId) => {
  return axios.get(
    API_URL +
      '?filter[directus_users_id][_eq]=' +
      userId +
      '&fields=id,organization_id.name,organization_id.city,organization_id.postal_code,organization_id.country,organization_id.numero_rna,organization_id.numero_siret_rna,organization_id.adress',
    { headers: authHeader() }
  );
};
export const OrgaDirUsers1Services = {
  PostOrgaDirUsers1,
  GetAllOrgaDirUsers1,
  FormatGetAllOrgaDirUsers1,
  FormatGetAllUsersForOrga,
  getOrganizationByUser
};
