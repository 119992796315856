import React, { useEffect, useState, useContext } from 'react';
import FormService from '../../oldService/formService';
import Select from 'react-select';
import { OrgaDirUsers1Services } from '../../oldService/organizationDirectusUsers1Services.js';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import ChooseProject from '../ChooseProject/ChooseProject';
import UserService from '../../oldService/userService';
import AnswerFormService from '../../oldService/answerFormService';
import DocumentsServices from '../../oldService/documentServices';
import { PdfConvertDataConvention } from '../../oldService/pdfServices';
import PdfServices from '../../oldService/pdfServices';
import UserDispoService from '../../oldService/userDispoService';
import { findNextStep } from '../../oldService/steps/findNextStep';
import DispoStepService from '../../oldService/dispoStepService';
import DispoService from '../../oldService/dispService';
import { OrganizationServices } from '../../oldService/organizatonServices';
import ModalNewContact from '../ModalNewContact/ModalNewContact';
import { AuthContext } from '../../AuthProvider';

const ConventionForm = ({ userDispo }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [emailUsed, setEmailUsed] = useState(false);

  const [answer, setAnswer] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContact2, setSelectedContact2] = useState([]);
  const [selectedOrga, setSelectedOrga] = useState([]);

  const templateIdFR = '1F9qPcfFiVqejX30DYt0G8G8k7vfuABkuIeQ_JfVABvw';
  const templateIdEN = '1XyR8X5-3Qs8kKVyTOH4kalwBaDDxRBmTbmBuOmokO-U';

  const [organizations, setOrganizations] = useState([]);
  OrganizationServices.FormatGetAllOrganizations(organizations, setOrganizations);

  const [sortedOrga, setSortedOrga] = useState([]);

  useEffect(() => {
    setSortedOrga(
      organizations.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        else return 0;
      })
    );
  }, [organizations]);

  const optionsOrga = sortedOrga.map((item) => {
    return { value: item, label: item.name };
  });

  const [dispo, setDispo] = useState([]);
  DispoService.FormatGetOneDispo(dispo, setDispo, id);

  const { user } = useContext(AuthContext);
  const groups = user.group.slice(-1)[0]?.group_id?.name;

  const [displayModal, setDisplayModal] = useState(false);
  const [blur, setBlur] = useState(false);
  const [step1Id, setStep1Id] = useState(null);
  const [step2Id, setStep2Id] = useState(null);

  const [formFrench, setFormFrench] = useState([]);
  FormService.FormatGetForm(formFrench, setFormFrench, 'Convention');

  const [formEnglish, setFormEnglish] = useState([]);
  FormService.FormatGetForm(formEnglish, setFormEnglish, 'Convention EN');

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [punctual, setPunctual] = useState([]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  const [hours, setHours] = useState(null);
  const [weeks, setWeeks] = useState(null);

  const [answerMission, setAnswerMission] = useState([]);

  useEffect(() => {
    if (punctual[0] && punctual[0].mission_answer) setAnswerMission(punctual[0].mission_answer.answer);
  }, [punctual]);

  const [frenchMission, setFrenchMission] = useState(true);

  let pdfName = 'Convention-' + groups + '-' + user.last_name + '-' + user.first_name + '-Convention';

  const HandleSubmit = () => {
    setLoading(true);
    PunctualFilesServices.PatchPunctualFilesContact(punctual[0].id, selectedContact.value.id)
      .then(() => {
        console.log('-1');
        PunctualFilesServices.PatchPunctualFilesContactEval(punctual[0].id, selectedContact2.value.id)
          .then(() => {
            console.log('0');
            AnswerFormService.PostAnswerForm(answer, user.id, null, 'convention')
              .then((resAns) => {
                console.log('1');
                DocumentsServices.PostDocument(
                  PdfConvertDataConvention(
                    answer,
                    answerMission,
                    user,
                    punctual[0],
                    punctual[0].date_created.substr(0, 10)
                  ),
                  'convention',
                  null,
                  frenchMission
                )
                  .then((resDoc) => {
                    console.log('2');
                    PdfServices.PostPdf(resDoc.data.data.id, frenchMission ? templateIdFR : templateIdEN, pdfName)
                      .then(() => {
                        console.log('3');
                        PunctualFilesServices.PatchPunctualFilesConvention(
                          punctual[0].id,
                          resAns.data.data.id,
                          resDoc.data.data.id
                        )
                          .then(() => {
                            console.log('4');
                            if (userDispo.step_id.tovalidate) {
                              UserDispoService.PatchUserDispoValidate(userDispo.id, null, true)
                                .then(() => {
                                  console.log('end2.1');
                                  window.location.reload();
                                })
                                .catch(() => {
                                  console.log('errend2.1');
                                });
                            } else {
                              UserDispoService.PatchUserDispoStep(
                                userDispo.id,
                                findNextStep(
                                  dispoStep.find((item) => item.step_id === userDispo.step_id.id).order,
                                  dispoStep
                                ).step_id
                              )
                                .then(() => {
                                  console.log('end2.2');
                                  window.location.reload();
                                })
                                .catch(() => {
                                  console.log('errend2.2');
                                });
                            }
                          })
                          .catch(() => {
                            console.log('err4');
                          });
                      })
                      .catch((err3) => {
                        console.log(err3);
                      });
                  })
                  .catch((err2) => {
                    console.log(err2);
                  });
              })
              .catch((err1) => {
                console.log(err1);
              });
          })
          .catch(() => {
            console.log('err0');
          });
      })
      .catch(() => {
        console.log('err-1');
      });
  };

  const LanguageOnClick = (id) => {
    if (id === 0) {
      setFrenchMission(true);
    } else if (id === 1) {
      setFrenchMission(false);
    }
  };

  useEffect(() => {
    if (hours && weeks) HandleAnswerChange(66, toString(hours * weeks), null);
  }, [hours, weeks]);

  const optionsContact = contacts
    .filter((item) => item.organization_id === selectedOrga.id)
    .map((item) => {
      return {
        value: item.directus_users_id,
        label:
          (item.directus_users_id && item.directus_users_id.first_name) +
          ' ' +
          (item.directus_users_id && item.directus_users_id.last_name) +
          ' - ' +
          (item.directus_users_id && item.directus_users_id.email)
      };
    });

  const HandleAnswerChange = (index, value, id) => {
    if (answer.find((item) => item.index === index)) {
      const list = answer;

      list.find((item) => item.index === index).data = value;
      setAnswer(list);
    } else {
      const list = answer;
      list.push({ index: index, data: value, id: id });
      setAnswer(list);
    }
  };

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  return (
    <div className="px-10 space-y-10">
      <div className={`flex justify-center space-x-20 ${blur ? 'blur-sm' : 'blur-none'}`}>
        <button
          onClick={() => {
            LanguageOnClick(0);
          }}
          className={`${frenchMission && 'border-2'} border-blue-500 p-1 rounded-sm`}>
          <img src="https://flagcdn.com/fr.svg" width="40" alt="France" />
        </button>
        <button
          onClick={() => {
            LanguageOnClick(1);
          }}
          className={`${!frenchMission && 'border-2'} border-blue-500 p-1 rounded-sm`}>
          <img src="https://flagcdn.com/gb.svg" width="45" alt="United Kingdom" />
        </button>
      </div>
      {displayModal && (
        <ModalNewContact
          setDisplay={setDisplayModal}
          setBlur={setBlur}
          step2Id={step2Id}
          setStep2Id={setStep2Id}
          selectedOrga={selectedOrga}
          step1Id={step1Id}
          setContactList={contacts}
          contactList={setContacts}
          convention={true}
          setEmailUsed={setEmailUsed}
        />
      )}
      <div className={`p-8 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
        <div className="space-y-2">
          <h2 className="font-bold text-xl text-blue-700/90">
            {frenchMission ? 'Demander ma convention' : 'Request my agreement'}
          </h2>
        </div>
      </div>
      <div className={`${blur ? 'blur-sm' : 'blur-none'}`}>
        {frenchMission ? (
          <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
            {formFrench[0] &&
              formFrench[0].questions_id.map((item, index) => {
                return (
                  <div key={index}>
                    {item.questions_id.name === 'Sélectionnez le contact signataire de la convention' ||
                    item.questions_id.name === 'Sélectionnez votre tuteur' ||
                    item.questions_id.name === "Votre organisation d'accueil" ? (
                      <div>
                        {item.questions_id.name === "Votre organisation d'accueil" ? (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            <Select
                              options={optionsOrga}
                              placeholder="Sélectionner..."
                              onChange={(orga) => {
                                HandleAnswerChange(1.1, orga.value.name, null);
                                HandleAnswerChange(1.2, orga.value.adress, null);
                                HandleAnswerChange(1.3, orga.value.postal_code, null);
                                HandleAnswerChange(1.4, orga.value.city, null);
                                setSelectedOrga(orga.value);
                                setStep1Id(orga.value.id);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            {selectedOrga.name && (
                              <div className="text-center space-y-3">
                                <div className="flex flex-col justify-center items-center">
                                  {emailUsed && (
                                    <p className="text-red-500 font-bold mb-2">Email du contact déjà utilisé.</p>
                                  )}
                                  <button
                                    className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                    onClick={() => {
                                      setBlur(true);
                                      setDisplayModal(true);
                                    }}>
                                    Ajouter contact
                                  </button>
                                </div>
                                <p className="text-blue-500 font-semibold">ou</p>
                              </div>
                            )}
                            <Select
                              options={optionsContact}
                              placeholder="Sélectionner..."
                              onChange={(cont) => {
                                HandleAnswerChange(
                                  0.1,
                                  cont.value.last_name + ' ' + cont.value.first_name,
                                  item.questions_id.id
                                );
                                HandleAnswerChange(0.2, cont.value.function, item.questions_id.id);
                                HandleAnswerChange(0.3, cont.value.email, item.questions_id.id);
                                HandleAnswerChange(0.4, cont.value.phones, item.questions_id.id);
                                item.questions_id.name === 'Sélectionnez le contact signataire de la convention'
                                  ? setSelectedContact(cont)
                                  : setSelectedContact2(cont);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="space-y-2">
                        <h3 className="text-lg">
                          {item.questions_id.name} <Obligatory />
                        </h3>
                        {item.questions_id.type === 'text' && (
                          <textarea
                            className="w-full p-2 h-28 border border-gray-300"
                            placeholder="À compléter"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'choice' && (
                          <ChooseProject
                            list={item.questions_id.questions_choice_id.map((itemQues) => {
                              return itemQues.questions_choice_id.name;
                            })}
                            index={index}
                            setChoice={setAnswer}
                            choice={answer}
                            id={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'url' && (
                          <input
                            type="url"
                            className="w-full p-2 border border-gray-300"
                            placeholder="https://"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'description' && (
                          <div>
                            <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                            <input
                              type={'text'}
                              name="date"
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              onChange={(e) => {
                                HandleAnswerChange(index, e.target.value, item.questions_id.id);
                              }}
                            />
                          </div>
                        )}
                        {item.questions_id.type === 'date' && (
                          <input
                            type="date"
                            className="w-full p-2 border border-gray-300"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'nbr' && (
                          <input
                            type="number"
                            className="w-full p-2 border border-gray-300"
                            placeholder="À compléter"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                              if (item.questions_id.name === 'Durée hebdomadaire de la mission') {
                                setHours(e.target.value);
                              } else if (item.questions_id.name === 'Nombre de semaines de mission') {
                                setWeeks(e.target.value);
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
            {formEnglish[0] &&
              formEnglish[0].questions_id.map((item, index) => {
                return (
                  <div key={index}>
                    {item.questions_id.name === 'Select the signatory contact of the agreement' ||
                    item.questions_id.name === 'Your host organization' ||
                    item.questions_id.name === 'Select your tutor' ? (
                      <div>
                        {item.questions_id.name === 'Your host organization' ? (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            <Select
                              options={optionsOrga}
                              placeholder="Sélectionner..."
                              onChange={(orga) => {
                                HandleAnswerChange(1.1, orga.value.name, null);
                                HandleAnswerChange(1.2, orga.value.adress, null);
                                HandleAnswerChange(1.3, orga.value.postal_code, null);
                                HandleAnswerChange(1.4, orga.value.city, null);
                                setSelectedOrga(orga.value);
                                setStep1Id(orga.value.id);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            {selectedOrga.name && (
                              <div className="text-center space-y-3">
                                <div className="flex flex-col justify-center items-center">
                                  {emailUsed && (
                                    <p className="text-red-500 font-bold mb-2">Contact email already used.</p>
                                  )}
                                  <button
                                    className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                    onClick={() => {
                                      setBlur(true);
                                      setDisplayModal(true);
                                    }}>
                                    Add contact
                                  </button>
                                </div>
                                <p className="text-blue-500 font-semibold">or</p>
                              </div>
                            )}
                            <Select
                              options={optionsContact}
                              placeholder="Sélectionner..."
                              onChange={(cont) => {
                                HandleAnswerChange(
                                  0.1,
                                  cont.value.last_name + ' ' + cont.value.first_name,
                                  item.questions_id.id
                                );
                                HandleAnswerChange(0.2, cont.value.function, item.questions_id.id);
                                HandleAnswerChange(0.3, cont.value.email, item.questions_id.id);
                                HandleAnswerChange(0.4, cont.value.phones, item.questions_id.id);
                                item.questions_id.name === 'Select the signatory contact of the agreement'
                                  ? setSelectedContact(cont)
                                  : setSelectedContact2(cont);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="space-y-2">
                        <h3 className="text-lg">
                          {item.questions_id.name} <Obligatory />
                        </h3>
                        {item.questions_id.type === 'text' && (
                          <textarea
                            className="w-full p-2 h-28 border border-gray-300"
                            placeholder="À compléter"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'choice' && (
                          <ChooseProject
                            list={item.questions_id.questions_choice_id.map((itemQues) => {
                              return itemQues.questions_choice_id.name;
                            })}
                            index={index}
                            setChoice={setAnswer}
                            choice={answer}
                            id={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'url' && (
                          <input
                            type={'url'}
                            className="w-full p-2 border border-gray-300"
                            placeholder="https://"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'description' && (
                          <div>
                            <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                            <input
                              type={'text'}
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              onChange={(e) => {
                                HandleAnswerChange(index, e.target.value, item.questions_id.id);
                              }}
                            />
                          </div>
                        )}
                        {item.questions_id.type === 'date' && (
                          <input
                            type="date"
                            className="w-full p-2 border border-gray-300"
                            placeholder="https://"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                            }}
                          />
                        )}
                        {item.questions_id.type === 'nbr' && (
                          <input
                            type="number"
                            className="w-full p-2 border border-gray-300"
                            placeholder="À compléter"
                            onChange={(e) => {
                              HandleAnswerChange(index, e.target.value, item.questions_id.id);
                              if (item.questions_id.name === 'Weekly duration of the mission') {
                                setHours(e.target.value);
                              } else if (item.questions_id.name === 'Number of weeks of your mission') {
                                setWeeks(e.target.value);
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
        <div className="flex justify-center pt-10">
          {loading ? (
            <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
          ) : (
            <button
              className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
              onClick={HandleSubmit}>
              <p>{frenchMission ? 'Soumettre' : 'Submit'}</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConventionForm;
