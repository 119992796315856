import React, { createContext, useState } from 'react';

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLogged, setIsLogged] = useState();

  return <AuthContext.Provider value={{ user, isLogged, setIsLogged, setUser }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
