import React from 'react';

import './Submenu.css';
import { NavLink } from 'react-router-dom';

function SubMenu({ type, menu }) {
  return (
    <div className="SubMenu flex flex-col">
      {menu.map((item, index) => {
        return (
          <NavLink
            to={'/user/' + type + '/' + (index + 1)}
            className={({ isActive }) =>
              isActive ? 'py-2 pl-5 text-white bg-gray-900/70' : 'py-2 pl-5 text-gray-400 hover:text-white'
            }
            key={item}>
            {item}
          </NavLink>
        );
      })}
    </div>
  );
}

export default SubMenu;
