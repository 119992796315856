import React from 'react';
import ModifBtn from '../ModifBtn/ModifBtn';
import './TagList.css';
function TagList(props) {
  return (
    <div className="TagList">
      <div className="flex items-center space-x-5 pt-10 px-10 overflow-x-scroll xl:overflow-x-hidden">
        <h2 className="font-medium text-lg">Responsables:</h2>
        <div className="flex items-center space-x-5">
          {props.responsibleList.map((item, index) => {
            return (
              <button
                key={index}
                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white relative"
                onClick={() => {
                  window.location = `mailto:${item.directus_users_id.email}`;
                }}>
                {item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name}
                {item.role === 'president' && (
                  <svg
                    className="crownForThePresident"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"
                    style={{ fill: '#f5d505' }}>
                    <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                  </svg>
                )}
              </button>
            );
          })}
          {props.modif && <ModifBtn />}
        </div>
      </div>
      <div className="flex items-center space-x-5 pt-5 px-10 overflow-x-scroll xl:overflow-x-hidden">
        <h2 className="font-medium text-lg">Etiquettes (TAGS):</h2>
        <div className="flex items-center space-x-5">
          {props.tagList.map((item, index) => {
            return (
              <button
                key={index}
                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white">
                {item.label}
              </button>
            );
          })}
          {props.modif && <ModifBtn />}
        </div>
      </div>
    </div>
  );
}

export default TagList;
