import React, { useContext, useEffect, useState } from 'react';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import MailIcon from '../../../oldComponents/MailIcon/MailIcon';
import { AuthContext } from '../../../AuthProvider';
import EvaluationServices from '../../../oldService/evaluationService';
import { useLocation } from 'react-router-dom';
import PunctualEvaluation from '../../../components/Evaluations/punctualEvaluation';
import AssoEvaluation from '../../../components/Evaluations/assoEvaluation';
import { UserEvaluate } from '../../../usualTypes/BasicTypes';

type State = {
  userEvaluate: UserEvaluate;
  responsible: boolean;
  dispoId: string;
  step: string;
};

const Evaluation = () => {
  const { user }: { user: object } = useContext(AuthContext);
  const [lastEval, setLastEval] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const myState = useLocation().state as State;
  const dispo = queryParams.get('dispo');
  const userEvaluate: UserEvaluate = myState.userEvaluate;

  useEffect(() => {
    const getLastEval = async () => {
      const lastEvalReq = await EvaluationServices.GetOneEvaluation(userEvaluate.evaluation_id?.id);
      setLastEval(lastEvalReq.data.data[0]);
    };
    if (userEvaluate.evaluation_id !== null) {
      getLastEval();
    }
  }, []);
  return (
    <div className={`App flex w-screen min-h-screen`}>
      <NavbarLeft user={user} />
      <div className="flex flex-col h-full w-full page-Container">
        {dispo === 'punctual' ? (
          <NavbarTop user={user} urls={['home']} />
        ) : (
          <NavbarTop user={user} urls={['home', 'plans']} />
        )}
        <div className="HomeComponent p-10 space-y-8">
          <h1 className="font-bold text-3xl text-blue-500">Évaluation</h1>
          <div className="space-y-5 px-10 py-5 rounded-sm bg-gray-100 flex align-items justify-between">
            <div>
              <h2 className="font-medium pb-1 text-xl text-blue-700">{userEvaluate.name}</h2>
              <p className="text-gray-600/90">{userEvaluate.team?.group_id.name}</p>
            </div>
            <div>
              <a className="flex hover:text-blue-500" href={`mailto:${userEvaluate.email}`}>
                <MailIcon />
                Envoyer un mail
              </a>
            </div>
          </div>
          {dispo === 'punctual' && (
            <PunctualEvaluation
              lastEval={lastEval}
              userEvaluate={userEvaluate}
              idPunctualFile={userEvaluate.id_table_link_dispo_user}
            />
          )}
          {dispo === 'asso' && (
            <AssoEvaluation
              responsible={myState.responsible}
              lastEval={lastEval}
              dispoId={myState.dispoId}
              userEvaluate={userEvaluate}
              step={myState.step}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Evaluation;
