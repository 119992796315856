import React from 'react';
import Card from '../Card/Card';
import CreationCard from '../CreationCard/CreationCard';

function CardList(props) {
  return (
    <div className="CardList grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
      {props.type === 'plans' && props.user.role.name === 'Administrator' && <CreationCard type={props.type} />}
      {props.data.map((item) => {
        const nbrValidation = props.validation ? props.validation.find((dispo) => item.id === dispo.dispositif) : null;
        return (
          <Card
            key={item.id}
            data={item}
            type={props.type}
            role={props.user ? props.user.role.name : props.role}
            template={item.template}
            nbrValidation={nbrValidation ? nbrValidation.count.validation : 0}
          />
        );
      })}
    </div>
  );
}

export default CardList;
