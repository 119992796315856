import axios from 'axios';
import authHeader from './authHeader';
import { API, PROD } from './authHeader';

const API_URL = API + 'users';

const PostContact = (first_name, last_name, functionC, email, phones) => {
  //PROD /PREPROD
  const roleId = PROD ? '327aa084-6b81-4bfd-8257-ec6714ffa49c' : 'de5a9851-6b80-4708-b290-d7c97b52f0f3';
  var password = Math.random().toString(36).slice(-8);

  return axios.post(
    API_URL,
    {
      first_name: first_name,
      last_name: last_name,
      function: functionC,
      email: email,
      phones: phones,
      role: roleId,
      password: password
    },
    { headers: authHeader() }
  );
};

const PatchContact = async (id, data) => {
  return await axios.patch(API_URL + '/' + id, data, { headers: authHeader() });
};

export const ContactServices = {
  PatchContact,
  PostContact
};
