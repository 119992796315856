import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import UserDispoService from '../../oldService/userDispoService';
import { evaluations_asso_president, evaluations_asso_volunteer } from '../../data';

const ExportEvals = ({ dispos, name }) => {
  const [csvData, setCsvData] = useState([]);
  const [evaluationResponsible, setEvaluationResponsible] = useState([]);
  const [evaluationVolunteer, setEvaluationVolunteer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const promises = dispos.map(async (child) => {
        const result = await DispDirUsersService.getAllEvalOfADispo(child.id);
        const result2 = await UserDispoService.getAllEvalOfADispo(child.id);
        return { respEvals: result.data.data, volunEvals: result2.data.data };
      });
      setIsLoading(true);
      const results = await Promise.all(promises);
      const respEvals = results.flatMap((result) => result.respEvals);
      const volunEvals = results.flatMap((result) => result.volunEvals);

      setEvaluationResponsible(respEvals);
      setEvaluationVolunteer(volunEvals);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let list = [
      [
        'Nom',
        'Prénom',
        'mail',
        'Téléphone',
        'Promo',
        'Type',
        'Note /20',
        'commentaire',
        'Évaluateur',
        'Mail évaluateur',
        'Promo évaluateur',
        'Dispositif',
        'Semestre',
        "Date d'évaluation"
      ]
    ];
    evaluationResponsible.map((item) => {
      const arrayEval = Object.entries(item);
      let dispoName = '';
      let dispoEvalStep = 0;
      arrayEval.map((res) => {
        if (res[0] === 'dispositif_id') {
          dispoName = res[1].name;
          dispoEvalStep = res[1].evaluation_step;
        }
        if (res[1] === null) return;
        if (res[0] === 'evaluation_id') {
          const objEval = res[1];
          if (dispoEvalStep === '1') return;
          const commentaireObj = objEval.evals.find(
            (evalua) =>
              evalua.id_question === evaluations_asso_president.find((question) => question.type === 'textField').id
          );
          const commentaire = commentaireObj !== undefined ? commentaireObj.answer : 'pas de commentaire';
          list.push([
            objEval.user.last_name,
            objEval.user.first_name,
            objEval.user.email,
            objEval.user.phones !== null ? "'" + objEval.user.phones : objEval.user.phones,
            objEval.user.group.slice(-1)[0] && objEval.user.group.slice(-1)[0].group_id.name,
            objEval.type,
            objEval.total,
            commentaire,
            objEval.user_created.first_name + ' ' + objEval.user_created.last_name,
            objEval.user_created.email,
            objEval.user_created.group.slice(-1)[0] && objEval.user_created.group.slice(-1)[0].group_id.name,
            dispoName,
            'Evaluation S1',
            new Date(objEval.date_created).toLocaleString()
          ]);
        }
        if (res[0] === 'evaluation_id_2') {
          const objEval = res[1];
          if (dispoEvalStep === '2') return;
          const commentaireObj = objEval.evals.find(
            (evalua) =>
              evalua.id_question === evaluations_asso_president.find((question) => question.type === 'textField').id
          );
          const commentaire = commentaireObj !== undefined ? commentaireObj.answer : 'pas de commentaire';
          list.push([
            objEval.user.last_name,
            objEval.user.first_name,
            objEval.user.email,
            objEval.user.phones !== null ? "'" + objEval.user.phones : objEval.user.phones,
            objEval.user.group.slice(-1)[0] && objEval.user.group.slice(-1)[0].group_id.name,
            objEval.type,
            objEval.total,
            commentaire,
            objEval.user_created.first_name + ' ' + objEval.user_created.last_name,
            objEval.user_created.email,
            objEval.user_created.group.slice(-1)[0] && objEval.user_created.group.slice(-1)[0].group_id.name,
            dispoName,
            'Evaluation S2',
            new Date(objEval.date_created).toLocaleString()
          ]);
        }
      });
      return item;
    });
    evaluationVolunteer.map((item) => {
      const arrayEval = Object.entries(item);
      let dispoName = '';
      let dispoEvalStep = 0;
      arrayEval.map((res) => {
        if (res[0] === 'dispositif') {
          dispoName = res[1].name;
          dispoEvalStep = res[1].evaluation_step;
        }
        if (res[1] === null) return;
        if (res[0] === 'evaluation_id') {
          const objEval = res[1];
          if (dispoEvalStep === '1') return;
          const commentaireObj = objEval.evals.find(
            (evalua) =>
              evalua.id_question === evaluations_asso_volunteer.find((question) => question.type === 'textField').id
          );
          const commentaire = commentaireObj !== undefined ? commentaireObj.answer : 'pas de commentaire';
          list.push([
            objEval.user.last_name,
            objEval.user.first_name,
            objEval.user.email,
            objEval.user.phones !== null ? "'" + objEval.user.phones : objEval.user.phones,
            objEval.user.group.slice(-1)[0] && objEval.user.group.slice(-1)[0].group_id.name,
            objEval.type,
            objEval.total,
            commentaire,
            objEval.user_created.first_name + ' ' + objEval.user_created.last_name,
            objEval.user_created.email,
            objEval.user_created.group.slice(-1)[0] && objEval.user_created.group.slice(-1)[0].group_id.name,
            dispoName,
            'Evaluation S1',
            new Date(objEval.date_created).toLocaleString()
          ]);
        }
        if (res[0] === 'evaluation_id_2') {
          const objEval = res[1];
          if (dispoEvalStep === '2') return;
          const commentaireObj = objEval.evals.find(
            (evalua) =>
              evalua.id_question === evaluations_asso_volunteer.find((question) => question.type === 'textField').id
          );
          const commentaire = commentaireObj !== undefined ? commentaireObj.answer : 'pas de commentaire';
          list.push([
            objEval.user.last_name,
            objEval.user.first_name,
            objEval.user.email,
            objEval.user.phones !== null ? "'" + objEval.user.phones : objEval.user.phones,
            objEval.user.group.slice(-1)[0] && objEval.user.group.slice(-1)[0].group_id.name,
            objEval.type,
            objEval.total,
            commentaire,
            objEval.user_created.first_name + ' ' + objEval.user_created.last_name,
            objEval.user_created.email,
            objEval.user_created.group.slice(-1)[0] && objEval.user_created.group.slice(-1)[0].group_id.name,
            dispoName,
            'Evaluation S2',
            new Date(objEval.date_created).toLocaleString()
          ]);
        }
      });
      return item;
    });
    setCsvData(list);
  }, [evaluationResponsible, evaluationVolunteer]);

  return (
    <div>
      {isLoading ? (
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                clipRule="evenodd"
              />
            </svg>
            <div className="ml-4 flex min-w-0 flex-1 gap-2">
              <span className="truncate font-medium">Préparation de l'export...</span>
              <span className="flex-shrink-0 text-gray-400">CSV</span>
            </div>
          </div>
        </li>
      ) : (
        <CSVLink
          filename={'Export_' + name + '_Evaluation_' + new Date().toLocaleDateString()}
          data={csvData}
          className=""
          separator=";">
          <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                <span className="truncate font-medium">
                  {'Evaluations complétées par les responsables associatifs'}
                </span>
                <span className="flex-shrink-0 text-gray-400">CSV</span>
              </div>
            </div>
            <div className="ml-4 flex flex-shrink-0 space-x-4">
              <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                </svg>
              </button>
            </div>
          </li>
        </CSVLink>
      )}
    </div>
  );
};

export default ExportEvals;
