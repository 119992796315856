import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif_tag';

const postDispTag = (dispId, tagId) => {
  return axios.post(
    API_URL,
    {
      dispositif_id: dispId,
      tag_id: tagId
    },
    { headers: authHeader() }
  );
};

const getDispTag = (id) => {
  return axios.get(API_URL + '?filter[dispositif_id][_eq]=' + id + '&fields=id,tag_id.label,tag_id.id', {
    headers: authHeader()
  });
};

const FormatGetDispTag = (dispTag, setDispTag, id) => {
  useEffect(() => {
    const list = [];

    const MakeDispTag = async () => {
      const data = await DispoTagService.getDispTag(id);
      data.data.data.map((item) => {
        list.push({
          id: item.id,
          other_id: item.tag_id.id,
          name: item.tag_id.label
        });
        return item;
      });
      setDispTag(list);
    };
    MakeDispTag();
  }, []);
  return dispTag;
};

const deleteDispTag = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const DispoTagService = {
  postDispTag,
  getDispTag,
  deleteDispTag,
  FormatGetDispTag
};

export default DispoTagService;
