import React from 'react';
import PlusIcon from '../PlusIcon/PlusIcon';

import './CreationCard.css';

function CreationCard() {
  return (
    <div className="CreationCard flex flex-col justify-center items-center my-12">
      <a
        className="px-5 py-5 xl:px-2 xl:py-2 rounded-xl border font-medium bg-blue-500 text-white hover:bg-gray-300 hover:text-black"
        href="/admin/plans/new">
        <PlusIcon />
      </a>
      <h2 className="font-bold text-xl py-5">Ajouter</h2>
    </div>
  );
}

export default CreationCard;
