import React, { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import { User } from '../../usualTypes/BasicTypes';
import PendingService from '../../oldService/pendingService';
import UserDispoService from '../../oldService/userDispoService';
import BigAnimateButton from '../BigAnimateButton';

const RightPartHeaderNoStatus = ({ pending, dispoId, dispoType, setChangeInPage }) => {
  const { user }: { user: User } = useContext(AuthContext);
  const handleSubmitRequest = () => {
    if (dispoType === 'event') {
      UserDispoService.PostUserDispo(dispoId, user.id).then(() => {
        window.location.reload();
      });
    } else {
      PendingService.PostPending(dispoId, user.id).then(() => {
        if (dispoType === 'asso') {
          setChangeInPage((prev) => prev + 1);
        } else {
          window.location.reload();
        }
      });
    }
  };
  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-1/2">
        {pending ? (
          <BigAnimateButton handleOnClick={() => null} btnText="En Attente de validation">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </BigAnimateButton>
        ) : (
          <BigAnimateButton handleOnClick={handleSubmitRequest} btnText="Participer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </BigAnimateButton>
        )}
      </div>
    </div>
  );
};

export default RightPartHeaderNoStatus;
