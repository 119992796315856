import React, { useState, useEffect, useContext } from 'react';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import AuthService from '../../../oldService/authService';
import DispoService from '../../../oldService/dispService';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import DispDirFileService from '../../../oldService/dispDirFile';
import UserDispoService from '../../../oldService/userDispoService';
import PendingService from '../../../oldService/pendingService';
import NewPipelineMultiple from '../../../oldComponents/NewPipelineMultiple/NewPipelineMultiple';
import TablePending from '../../../oldComponents/TablePending/TablePending';
import EventService from '../../../oldService/eventService';
import ModalModifPlan from '../../../oldComponents/ModalModifPlan/ModalModifPlan';
import ModalAddDoc from '../../../oldComponents/ModalAddDoc/ModalAddDoc';
import CommiProjectFormCompo from '../../../oldComponents/CommiProjectFormCompo/CommiProjectFormCompo';
import NoteContent from '../../../oldComponents/NoteContent/NoteContent';
import DispoStepService from '../../../oldService/dispoStepService';
import ModalValidAdminPunctual from '../../../oldComponents/ModalValidAdminPunctual/ModalValidAdminPunctual';
import ModalValidAdminPunctualV2 from '../../../oldComponents/ModalValidAdminPunctualV2';
import ModalAdminPunctualActionPipe from '../../../components/ModalAdminPunctualActionPipe';

import './PunctualDetails.css';
import { PROD } from '../../../oldService/authHeader';
import CommiProjectV2FormCompo from '../../../oldComponents/CommiProjectV2FormCompo/CommiProjectV2FormCompo';
import { AuthContext } from '../../../AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import NewListOfParticipant from '../../../components/NewListOfParticipant';
import NewListOfEvent from '../../../components/NewListOfEvent';
import Modal from '../../../oldComponents/Modal/Modal';
import NewHeaderPresDispo from '../../../components/NewHeaderPresDispo';
import ModalManageDocument from '../../../components/ModalManageDocument';
import ModalExportEvaluation from '../../../components/ModalExportEvaluation';

const PunctualDetails = () => {
  const templateIdPunctualV2 = PROD ? 444 : 384;
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);
  const [blur, setBlur] = useState(false);
  const [displayAddDoc, setDisplayAddDoc] = useState(false);
  const [displayModif, setDisplayModif] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [dispo, setDispo] = useState();
  const [admin, setAdmin] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [inscription, setInscription] = useState([]);
  const [pending, setPending] = useState([]);
  const [userList, setUserList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [dirUser, setDirUser] = useState([]);
  const [events, setEvents] = useState([]);
  const [userDispoService, setUserDispoService] = useState([]);
  const [note, setNote] = useState('');
  const [userDispo, setUserDispo] = useState([]);
  const [step2Display, setStep2Display] = useState(1);

  const [userModal, setUserModal] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [punSelUser, setPunSelUser] = useState();
  const [step1Id, setStep1Id] = useState(null);
  const [userDispoFiles, setUserDispoFiles] = useState([]);
  const [filesPunctual, setFilesPunctual] = useState([]);

  const [dispoStep, setDispoStep] = useState([]);
  const [firstStep, setFirstStep] = useState(null);
  const [reload, setReload] = useState(false);
  const [userAlreadyInIt, setUserAlreadyInIt] = useState([]);
  const [displayModalCreateEvent, setDisplayModalCreateEvent] = useState(false);

  const [displayModalAdminModify, setDisplayModalAdminModify] = useState(false);
  const [displayValidationGenerate, setDisplayValidationGenerate] = useState(false);
  const [displayModalAllDocument, setDisplayModalAllDocument] = useState(false);
  const [displayModalExport, setDisplayModalExport] = useState(false);

  const state = useLocation().state;
  const navigate = useNavigate();
  let myUserDispo;
  let userDispoId;
  const handleDisplayModalCreateEvent = () => {
    setBlur(true);
    setDisplayModalCreateEvent(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  const handleDisplayModif = () => {
    setDisplayModif(true);
    setBlur(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  const handleDisplayModalManageDoc = () => {
    setBlur(true);
    setDisplayModalAllDocument(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  const handleDisplayModalExport = () => {
    setBlur(true);
    setDisplayModalExport(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleCloseModalOnEscape = () => {
    setBlur(false);
    setDisplayModal(false);
    setDisplayAddDoc(false);
    setDisplayModif(false);
    setDisplayModalAllDocument(false);
    setDisplayModalAdminModify(false);
    setDisplayModalExport(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleCloseModalOnEscape();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  useEffect(() => {
    setReload(false);
    if (admin) {
      DispDirFileService.FormatGetOneDispFileAdmin(setFileList, id);
    } else {
      DispDirFileService.FormatGetOneDispFileNew(fileList, setFileList, id, user.id);
    }
  }, [reload, admin]);

  useEffect(() => {
    if (user.role.name === 'Administrator') {
      setAdmin(true);
    } else {
      const fetchData = async () => {
        const data = await DispDirUsersService.getDirectusUserDispo(id, user.id);
        if (data.data.data[0]) setAdmin(true);
        else setAdmin(false);
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (state !== null) {
      if (state.isValidate === true) {
        setDisplayValidationGenerate(true);
        setTimeout(() => {
          setDisplayValidationGenerate(false);
          navigate(location.pathname + '?id=' + id, { state: null });
        }, 8000);
      }
    }
  }, [state]);
  if (token) {
    UserDispoService.FormatOneUserDispo(userDispoService, setUserDispoService, id);
    DispoService.FormatGetOneDispo(dispo, setDispo, id);
    UserDispoService.FormatGetUserDispoDispo(inscription, setInscription, id, user.id);
    UserDispoService.FormatGetAllUser(userList, setUserList, id);
    PendingService.FormatGetUserPendingTrueFalse(pending, setPending, id, user.id);
    UserDispoService.FormatUserEngaAll(dirUser, setDirUser);
    PendingService.FormatGetAllPending(pendingList, setPendingList, id);
    EventService.FormatGetAllEventTemplate(events, setEvents, id);
    myUserDispo = UserDispoService.FormatOneUserDispoNote(id);
    userDispoId = UserDispoService.FormatOneUserDispoId(id);
    DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);
    UserDispoService.FormatGetOneUserDispo(userDispo, setUserDispo, id);
    UserDispoService.FormatGetOneUserDispoFiles(userDispoFiles, setUserDispoFiles, id);
    UserDispoService.FormatGetOneUserDispoPunctualFiles(filesPunctual, setFilesPunctual, id);
  }
  const [filesPunSorted, setFilesPunShorted] = useState(null);

  useEffect(() => {
    const list = [];

    if (
      filesPunctual &&
      filesPunctual.project_doc &&
      userDispo?.step_id &&
      userDispo?.step_id?.name !== "Projet d'engagement"
    ) {
      list.push({
        directus_files_id: {
          id: 0,
          title: " Mon projet d'engagement"
        },
        link: filesPunctual.project_doc && filesPunctual.project_doc.attachment_urls
      });
    }
    if (filesPunctual && filesPunctual.mission_doc && userDispo?.step_id && userDispo?.step_id?.name !== 'Convention') {
      list.push({
        directus_files_id: {
          id: 0,
          title: 'Ma fiche mission'
        },
        link: filesPunctual.mission_doc && filesPunctual.mission_doc.attachment_urls
      });
    }
    if (
      filesPunctual &&
      filesPunctual.convention_doc &&
      userDispo?.step_id &&
      userDispo?.step_id?.name !== 'Convention'
    ) {
      list.push({
        directus_files_id: {
          id: 0,
          title: 'Ma convention'
        },
        link: filesPunctual.convention_doc && filesPunctual.convention_doc.attachment_urls
      });
    }
    if (filesPunctual && filesPunctual.convention_doc && filesPunctual.convention_doc.signed) {
      list.push({
        directus_files_id: {
          id: filesPunctual.convention_doc.signed_file !== null ? filesPunctual.convention_doc.signed_file : null,
          title: 'Ma convention signée'
        }
      });
    }
    setFilesPunShorted(list);
  }, [filesPunctual, userDispo]);

  useEffect(() => {
    setNote(myUserDispo);
  }, [myUserDispo]);

  useEffect(() => {
    const list = [];
    dispoStep.map((item) => {
      list.push(item.order);
      return item;
    });
    const min = Math.min(...list);
    const firstStep = dispoStep.find((item) => item.order === min);
    setFirstStep(firstStep);
  }, [dispoStep]);

  useEffect(() => {
    if (dispo?.responsibles) {
      const allInvolve = [];
      allInvolve.push(...userList, ...dispo.responsibles.map((resp) => resp.directus_users_id));
      setUserAlreadyInIt(allInvolve);
    }
  }, [userList, dispo]);
  return (
    <div className="PunctualDetails flex max-w-[100vw] min-h-screen">
      {dispo != undefined && (
        <>
          {displayValidationGenerate && (
            <div className="flex w-96 shadow-lg rounded-lg fixed top-3 right-3 z-[100] animation-custom-css">
              <div className="bg-green-600 py-4 px-6 rounded-l-lg flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-white fill-current"
                  viewBox="0 0 16 16"
                  width="20"
                  height="20">
                  <path
                    fillRule="evenodd"
                    d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"></path>
                </svg>
              </div>
              <div className="px-4 py-6 bg-white rounded-r-lg flex justify-between items-center w-full border border-l-transparent border-gray-200">
                <div className="text-container-custom-size">
                  La génération de la nouvelle convention à bien été lancée
                </div>
              </div>
            </div>
          )}
          <NavbarLeft user={user} sub={1} blur={blur} />
          <div className="flex flex-col h-full w-full limit-Dispo">
            <NavbarTop user={user} blur={blur} urls={['home', 'plans']} />
            <div className="p-10 space-y-8">
              <div className="space-y-14 py-4">
                <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                  <NewHeaderPresDispo
                    dispo={dispo}
                    handleDisplayModalCreateEvent={handleDisplayModalCreateEvent}
                    handleDisplayModif={handleDisplayModif}
                    excludedUsers={userAlreadyInIt}
                    dispoId={id}
                    firstStep={firstStep}
                    handleDisplayModalManageDoc={handleDisplayModalManageDoc}
                    docList={fileList}
                    handleDisplayModalExport={handleDisplayModalExport}
                    admin={admin}
                    inscription={inscription}
                    pending={pending}
                    dispoType={'punctual'}
                    withIndividualStep={dispo.parent === null}
                    userDispo={userList.find((item) => item.id === user.id)}
                  />
                </div>
              </div>
              {(inscription || admin) && userList && (
                <div
                  className={`p-5 grid grid-cols-2 bg-gray-50 rounded ${
                    blur === true ? 'blur-sm' : 'blur-none'
                  } gap-x-20`}>
                  <NewListOfParticipant
                    listOfParticipant={userList}
                    admin={admin}
                    excludedUsers={userAlreadyInIt}
                    dispoId={id}
                    firstStep={firstStep}
                  />
                  <NewListOfEvent
                    listOfEvent={events}
                    admin={admin}
                    setDisplayModalCreate={setDisplayModalCreateEvent}
                  />
                </div>
              )}
              {inscription && !admin && (
                <div className={`p-5 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                  <NewPipelineMultiple
                    users={userDispoService}
                    setBlur={setBlur}
                    blur={blur}
                    dispo={dispo}
                    perso={true}
                    setUserModal={setUserModal}
                    setDisplayModal={setDisplayModal}
                    setPunSelUser={setPunSelUser}
                  />
                </div>
              )}
              {inscription && !admin && userDispo.step_id && !userDispo.validation && (
                <div>
                  {userDispo.step_id.name === "Organisation d'accueil" && (
                    <div className={`flex ${blur ? 'blur-sm' : 'blur-none'}`}>
                      <button
                        className={`shadow-sm rounded-sm px-2 py-2 hover:bg-blue-500 hover:text-white ${
                          step2Display === 1 ? 'bg-blue-500 text-white' : 'bg-gray-50'
                        }`}
                        onClick={() => {
                          setStep2Display(1);
                        }}>
                        Organisation d'accueil
                      </button>
                      <button
                        className={`shadow-sm rounded-sm px-2 py-2 hover:bg-blue-500 hover:text-white ${
                          step2Display === 2 ? 'bg-blue-500 text-white' : 'bg-gray-50'
                        }`}
                        onClick={() => {
                          if (step1Id) setStep2Display(2);
                        }}>
                        Contact
                      </button>
                    </div>
                  )}

                  {dispo.template === templateIdPunctualV2 ? (
                    <div className="p-5">
                      <CommiProjectV2FormCompo dispo={dispo} userDispo={userDispo} blur={blur} setBlur={setBlur} />
                    </div>
                  ) : (
                    <div className="p-5 bg-gray-50">
                      <CommiProjectFormCompo
                        dispo={dispo}
                        userDispo={userDispo}
                        step1Id={step1Id}
                        setStep1Id={setStep1Id}
                        step2Display={step2Display}
                        setStep2Display={setStep2Display}
                        blur={blur}
                        setBlur={setBlur}
                      />
                    </div>
                  )}
                </div>
              )}
              {inscription && !admin && userDispo.validation && (
                <div className="bg-gray-50 py-5 rounded-sm">
                  <h2 className="font-bold text-xl pb-5 px-5 text-blue-700/90">{userDispo.step_id.name}</h2>
                  <div className="flex justify-center">
                    <p className="text-blue-700 font-bold">L'étape est en attente de validation par un admin.</p>
                  </div>
                </div>
              )}
              {displayModalAllDocument && (
                <ModalManageDocument
                  docList={fileList}
                  setDisplayModalAllDocument={setDisplayModalAllDocument}
                  setBlur={setBlur}
                  setReload={setReload}
                  setDisplayAddDoc={setDisplayAddDoc}
                  admin={admin}
                />
              )}
              {admin && (
                <div className="space-y-5">
                  <div className={`${blur ? 'blur-sm' : 'blur-none'}`}>
                    <NewPipelineMultiple
                      users={userList}
                      setBlur={setBlur}
                      blur={blur}
                      dispo={dispo}
                      setUserModal={setUserModal}
                      setDisplayModal={setDisplayModal}
                      setPunSelUser={setPunSelUser}
                      setDisplayModalAdminModify={setDisplayModalAdminModify}
                    />
                  </div>
                  {displayModal && dispo.template === templateIdPunctualV2 && (
                    <div className="p-5">
                      <ModalValidAdminPunctualV2
                        punSelUser={punSelUser}
                        setBlur={setBlur}
                        displayModal={displayModal}
                        setDisplayModal={setDisplayModal}
                        userModal={userModal}
                        dispo={dispo}
                      />
                    </div>
                  )}
                  {displayModalAdminModify && dispo.template === templateIdPunctualV2 && (
                    <div className="p-5 bg-gray-50">
                      <ModalAdminPunctualActionPipe
                        setDisplayModal={setDisplayModalAdminModify}
                        student={userModal}
                        dispo={dispo}
                        setBlur={setBlur}
                      />
                    </div>
                  )}
                  {displayModal && dispo.template !== templateIdPunctualV2 && (
                    <div className="p-5 bg-gray-50">
                      <ModalValidAdminPunctual
                        punSelUser={punSelUser}
                        setBlur={setBlur}
                        displayModal={displayModal}
                        setDisplayModal={setDisplayModal}
                        userModal={userModal}
                      />
                    </div>
                  )}
                  {pendingList.length !== 0 && (
                    <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                      <h2 className="font-bold text-xl pb-5 text-blue-700/90">Demandes en attente</h2>
                      <TablePending
                        data={pendingList}
                        setPendingList={setPendingList}
                        dispo={dispo}
                        setUserList={setUserList}
                        userList={userList}
                        stepList={dispo.step}
                      />
                    </div>
                  )}
                  <div className={`p-5 rounded`}>
                    <div className="space-y-5">
                      {displayModalCreateEvent && <Modal setBlur={setBlur} setDisplay={setDisplayModalCreateEvent} />}
                      {displayModif && (
                        <ModalModifPlan
                          id={id}
                          setDisplay={setDisplayModif}
                          setBlur={setBlur}
                          data={dispo}
                          excludestudent={userAlreadyInIt}
                          admin={user.role.name === 'Administrator'}
                        />
                      )}
                      {displayAddDoc && (
                        <ModalAddDoc
                          userList={userList}
                          setBlur={setBlur}
                          setDisplayAddDoc={setDisplayAddDoc}
                          dispoId={id}
                          setReload={setReload}
                        />
                      )}
                      {displayModalExport && (
                        <ModalExportEvaluation
                          dispoChildren={dispo.id}
                          dispoName={dispo.name}
                          setDisplayModal={setDisplayModalExport}
                          setBlur={setBlur}
                          DispoType={'punctual'}
                          Members={userList}
                          Responsibles={dispo.responsibles}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {inscription && !admin && (
                <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                  <NoteContent note={note} setNote={setNote} id={userDispoId} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PunctualDetails;
