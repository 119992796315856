import React, { useState, useEffect } from 'react';
import TableList from '../TableList/TableList';
import Searchbar from '../Searchbar/Searchbar';

import './PeopleList.css';
import TableListPlans from '../TableListPlans/TableListPlans';
// import DispoService from "../../oldService/dispService";

function PeopleList(props) {
  const list = props.data;
  const [searchField, setSearchField] = useState('');
  const [filteredList, setFilteredList] = useState(list);
  const [exportList, setExportList] = useState([]);

  useEffect(() => {
    const newFilteredList = list.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchField);
    });
    setFilteredList(newFilteredList);
  }, [list, searchField]);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="PeopleList w-full">
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-xl pb-5 text-blue-700/90">{props.title}</h2>
        <div className="w-1/3">
          <Searchbar placeholder={'Rechercher un nom...'} onChangeHandler={onSearchChange} />
        </div>
      </div>
      {filteredList[0] === undefined ? (
        <div className="flex justify-center items-center">Aucun résultat pour le moment.</div>
      ) : (
        <div>
          {props.plans ? (
            <TableListPlans
              data={filteredList}
              userDispo={props.dirUser}
              planDetails={props.planDetails}
              list={exportList}
              setList={setExportList}
            />
          ) : (
            <TableList
              admin={props.admin}
              data={filteredList}
              userDispo={props.dirUser}
              planDetails={true}
              list={exportList}
              setList={setExportList}
              contact={props.contact}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PeopleList;
