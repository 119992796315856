import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import App from './App';
import Login from './pages/Login/Login';
import Account from './pages/Account/Account';
import reportWebVitals from './reportWebVitals';
import CommitmentsDetails from './pages/user/CommitmentsDetails/CommitmentsDetails';
import NewPlan from './pages/Admin/NewPlan/NewPlan';
import Commitments from './pages/user/Commitments/Commitments';
import Events from './pages/user/Events/Events';
import Plans from './pages/user/Plans/Plans';
import PlansDetails from './pages/user/PlansDetails/PlansDetails';
import UserAsso from './pages/user/UserAsso/UserAsso';
import ManagePlan from './pages/Admin/ManagePlan/ManagePlan';
import Followed from './pages/Admin/Followed/Followed';
import Rating from './pages/user/Rating/Rating';
import EventDetails from './pages/user/EventDetails/EventDetails';
import PunctualDetails from './pages/user/PunctualDetails/PunctualDetails';
import PunctualDocs from './pages/user/PunctualDocs/PunctualDocs';
import Organization from './pages/Admin/Organization';
import Form from './pages/Form';
import AuthProvider from './AuthProvider';
import AuthLayout from './components/AuthLayout/AuthLayout';
import EvaluationContact from './pages/Contact/EvaluationContact';
import Evaluation from './pages/user/Evaluation/Evaluation';
import AssociationDocs from './pages/AssociationDocs/AssociationDocs';
import ModificationSteps from './pages/Admin/ModificationSteps';
// import Test from './pages/Test/Test';

if (process.env.REACT_APP_SENTRY_URL && process.env.REACT_APP_SENTRY_URL.substr(0, 4) === 'http') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<AuthLayout />}>
            <Route path="/home" element={<App />} />
            <Route path="/account" element={<Account />} />
            <Route path="/form/:id" element={<Form />} />
            <Route path="/user/commitments/:subFilter" element={<Commitments />} />
            <Route path="/user/commitments/details" element={<CommitmentsDetails />} />
            <Route path="/user/plans/:subFilter" element={<Plans />} />
            <Route path="/user/plans/details" element={<PlansDetails />} />
            <Route path="/user/events/:subFilter" element={<Events />} />
            <Route path="/user/events/details" element={<EventDetails />} />
            <Route path="/user/asso/details" element={<UserAsso />} />
            <Route path="/user/evaluation" element={<Rating />} />
            <Route path="/admin/plans/new" element={<NewPlan />} />
            <Route path="/admin/plans/management" element={<ManagePlan />} />
            <Route path="/admin/followed" element={<Followed />} />
            <Route path="/admin/organizations/:organizationId" element={<Organization />} />
            <Route path="/admin/punctual/modifForm" element={<ModificationSteps />} />

            <Route path="/user/punctual/details" element={<PunctualDetails />} />
            <Route path="/user/punctualV2/details" element={<PunctualDetails />} />
            <Route path="/user/punctual/documents" element={<PunctualDocs />} />
            <Route path="/user/asso/documents" element={<AssociationDocs />} />

            <Route path="/contact/evaluation" element={<EvaluationContact />} />
            <Route path="/evaluation" element={<Evaluation />} />
          </Route>
          {/* <Route path='/test' element={<Test />} /> */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
