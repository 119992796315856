import React, { useEffect } from 'react';

function Input({ id, name, register, type = 'text', unregister, validationSchema }) {
  useEffect(() => {
    register(name, validationSchema);
    return () => {
      unregister(name);
    };
  }, [register, unregister]);
  return (
    <input
      type={type}
      className="w-full p-2 border border-gray-300"
      placeholder={type === 'url' ? 'https://' : 'À compléter'}
      id={id}
      name={name}
      {...register(name, validationSchema)}
    />
  );
}

export default Input;
