import React, { useState, useContext } from 'react';
import AuthService from '../../oldService/authService';
import NavbarTop from '../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../oldComponents/NavbarLeft/NavbarLeft';
import ContentAccount from '../../oldComponents/ContentAccount/ContentAccount';
import ManagePersoData from '../../oldComponents/ManagePersoData/ManagePersoData';
import { AuthContext } from '../../AuthProvider';
import './Account.css';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);

  const descriptionPersoData =
    'En application de l’article 17.1 du Règlement général sur la protection des données (RGPD), vous pouvez demander une copie des informations vous concernant ou leur suppression. Pour toute demande, merci de nous contacter via le bouton dédié ci-dessous.';

  const [blurPass, setBlurPass] = useState(false);

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <>
        {user != null && (
          <div className="Account flex w-screen min-h-screen">
            <NavbarLeft user={user} sub={5} blur={blurPass} />
            <div className="flex flex-col h-full w-full page-Container">
              <NavbarTop urls={['home']} blur={blurPass} user={user} />
              <div className="p-10 space-y-8">
                <h1 className={`font-bold text-3xl text-blue-500 ${blurPass ? 'blur-sm' : 'blur-none'}`}>MON COMPTE</h1>
                <div className="px-3 py-4 space-y-8 bg-gray-50 rounded-sm">
                  <div>
                    <h2 className={`font-bold text-xl pb-5 text-blue-700/90 ${blurPass ? 'blur-sm' : 'blur-none'}`}>
                      Identité et coordonnées
                    </h2>
                    <ContentAccount
                      data={user}
                      blurPass={blurPass}
                      setBlurPass={setBlurPass}
                      token={token.access_token}
                    />
                  </div>
                  <div className={`${blurPass ? 'blur-sm' : 'blur-none'}`}>
                    <h2 className="font-bold text-xl pb-5 text-blue-700/90">Gérer mes données personnelles (RGPD)</h2>
                    <ManagePersoData descriptionPersoData={descriptionPersoData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Account;
