import React from 'react';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import DocumentIcon from '../DocumentIcon/DocumentIcon';
import DownloadOnceIcon from '../DownloadOnceIcon/DonwloadOnceIcon';
import { API } from '../../oldService/authHeader';

function DocList(props) {
  let list = [];
  if (props.filesPunSorted) {
    list = props.data.concat(props.filesPunSorted);
  } else {
    list = props.data;
  }

  const handleDownloadAll = () => {
    list.map((item) => {
      if (item.link) {
        window.open(item.link);
      } else {
        window.open(API + 'assets/' + item.directus_files_id.id + '?download');
      }
      return item;
    });
  };

  return (
    <div className="space-x-5 px-10 space-y-8 xl:space-y-0 w-full">
      <h2 className="font-bold text-xl pb-5 text-blue-700/90 pl-5">Documents disponibles</h2>
      <ul className="bg-white rounded-sm w-full h-48 overflow-y-scroll mb-5 text-gray-600 ring-1 ring-opacity-5 ring-black shadow-sm">
        {list.map((item, index) => {
          const title = item.directus_files_id.title;
          const link = item.link
            ? item.link
            : item.directus_files_id.id
            ? API + 'assets/' + item.directus_files_id.id + '?download'
            : 'javascript:void(0);';

          return (
            <li key={index} className="py-2 px-3 hover:bg-gray-200 hover:text-black">
              <a href={link} className="flex justify-between rounded">
                <div className="flex flex-col">
                  {item.user_id === null ? 'Public' : 'Privé pour moi'}
                  <div className="flex justify-center items-center space-x-1">
                    <DocumentIcon />
                    <p>{title.length > 30 ? title.substring(0, 30) + '...' : title}</p>
                  </div>
                </div>
                <DownloadOnceIcon />
              </a>
            </li>
          );
        })}
      </ul>
      <div className="flex justify-center pt-5">
        <button
          className="flex justify-center bg-gray-200 py-3 px-2 space-x-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
          onClick={handleDownloadAll}>
          <DownloadIcon />
          <p>Tout télécharger</p>
        </button>
      </div>
    </div>
  );
}

export default DocList;
