import React, { useState } from 'react';

import './ChooseProject.css';

function ChooseProject({ setChoice, choice, ...props }) {
  const [input, setInput] = useState(null);

  const HandleInputChange = (value, index) => {
    if (value && !choice.find((item) => item.index === props.index)) {
      const list = choice;

      list.push({ index: props.index, data: props.list[index], id: props.id });
      setChoice(list);
      setInput(index);
    } else if (value && choice.find((item) => item.index === props.index)) {
      let list = choice;

      list.find((item) => item.index === props.index).data = props.list[index];
      setChoice(list);
      setInput(index);
    } else {
      let list = choice;

      list.find((item) => item.index === props.index).data = '';
      setChoice(list);
      setInput(null);
    }
  };

  const HandleButtonChange = (index) => {
    if (choice.find((item) => item.index === props.index)) {
      let list = choice;

      list.find((item) => item.index === props.index).data = props.list[index];
      setChoice(list);
      setInput(index);
    } else if (!choice.find((item) => item.index === props.index)) {
      const list = choice;

      list.push({ index: props.index, data: props.list[index], id: props.id });
      setChoice(list);
      setInput(index);
    }
    if (input === index) {
      let list = choice;

      list.find((item) => item.index === props.index).data = '';
      setChoice(list);
      setInput(null);
    }
  };

  return (
    <div className="space-y-2">
      {props.list.map((item, index) => {
        return (
          <div key={index} className="space-x-1">
            <input
              type="checkbox"
              onChange={(e) => {
                HandleInputChange(e.target.checked, index);
              }}
              checked={input === index}
            />
            <button
              className={`${index % 2 === 0 ? 'text-blue-500' : 'text-blue-700'}`}
              onClick={() => {
                HandleButtonChange(index);
              }}>
              {item}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default ChooseProject;
