import React, { useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormService from '../../oldService/formService';
import DispoStepService from '../../oldService/dispoStepService';
import RadioGroup from '../Fields/RadioGroup';
import AnswerFormService from '../../oldService/answerFormService';
import DocumentsServices from '../../oldService/documentServices';
import { PdfConvertDataMission } from '../../oldService/pdfServices';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';
import { findNextStep } from '../../oldService/steps/findNextStep';
import PdfServices from '../../oldService/pdfServices';
import { OrgaDirUsers1Services } from '../../oldService/organizationDirectusUsers1Services.js';
import Select from 'react-select';
import DispoService from '../../oldService/dispService';
import ModalNewContact from '../ModalNewContact/ModalNewContact';
import { AuthContext } from '../../AuthProvider';

const IdentityMissionForm = ({ userDispo, blur, setBlur }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [emailUsed, setEmailUsed] = useState(false);

  const [displayModal, setDisplayModal] = useState(false);

  const templateId = '1wsOGepV2DFEgoZtX5qhNdA5H1CiTOh0SEEGuoEVFQUw';

  const [frenchMission, setFrenchMission] = useState(true);

  const { user } = useContext(AuthContext);
  let groups = user.group.slice(-1)[0]?.group_id?.name;

  const [loading, setLoading] = useState(false);

  const [formFrench, setFormFrench] = useState([]);
  FormService.FormatGetForm(formFrench, setFormFrench, 'Fiche Mission');

  const [formEnglish, setFormEnglish] = useState([]);
  FormService.FormatGetForm(formEnglish, setFormEnglish, ' Mission Sheet');

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [punctual, setPunctual] = useState([]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  const optionsContact =
    punctual[0] &&
    contacts
      .filter((item) => item.organization_id === punctual[0].organization_id.id)
      .map((item) => {
        return {
          value: item.directus_users_id,
          label:
            (item.directus_users_id && item.directus_users_id.first_name) +
            ' ' +
            (item.directus_users_id && item.directus_users_id.last_name)
        };
      });

  const [dispo, setDispo] = useState([]);
  DispoService.FormatGetOneDispo(dispo, setDispo, id);

  let pdfName = 'Fiche mission-' + groups + '-' + user.last_name + '-' + user.first_name + '-Convention';

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  const formatData = (data) => {
    const formattedData = [];
    if (frenchMission) {
      if (formFrench[0]) {
        formFrench[0].questions_id.map((item, index) => {
          if (index === 4) {
            formattedData.push({
              id: item.questions_id.id,
              index: 4.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 99,
              data: punctual[0].organization_id.name
            });
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    } else {
      if (formEnglish[0]) {
        formEnglish[0].questions_id.map((item, index) => {
          if (index === 4) {
            formattedData.push({
              id: item.questions_id.id,
              index: 4.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 99,
              data: punctual[0].organization_id.name
            });
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    }

    return formattedData;
  };

  const LanguageOnClick = (id) => {
    if (id === 0) {
      setFrenchMission(true);
    } else if (id === 1) {
      setFrenchMission(false);
    }
  };

  const [step2Id, setStep2Id] = useState(null);

  const onSubmit = async (data) => {
    const formattedData = formatData(data);

    setLoading(true);
    try {
      const resAns = await AnswerFormService.PostAnswerForm(formattedData, user.id, null, 'mission');
      const resDoc = await DocumentsServices.PostDocument(PdfConvertDataMission(formattedData, user, groups));
      await PdfServices.PostPdf(resDoc.data.data.id, templateId, pdfName);
      await PunctualFilesServices.PacthPunctualFilesMission(punctual[0].id, resAns.data.data.id, resDoc.data.data.id);
      if (userDispo.step_id.tovalidate) {
        await UserDispoService.PatchUserDispoValidate(userDispo.id, null, true);
        window.location.reload();
      } else {
        await UserDispoService.PatchUserDispoStep(
          userDispo.id,
          findNextStep(dispoStep.find((item) => item.step_id === userDispo.step_id.id).order, dispoStep).step_id
        );
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  return (
    <div className="px-10 space-y-10">
      {displayModal && (
        <ModalNewContact
          setDisplay={setDisplayModal}
          setBlur={setBlur}
          step1Id={punctual[0] && punctual[0].organization_id.id}
          step2Id={step2Id}
          setStep2Id={setStep2Id}
          selectedOrga={punctual[0] && punctual[0].organization_id}
          contactList={contacts}
          setContactList={setContacts}
          convention={true}
          setEmailUsed={setEmailUsed}
        />
      )}
      <div className={blur ? 'blur-sm' : 'blur-none'}>
        <div className="flex justify-center space-x-20">
          <button
            onClick={() => {
              LanguageOnClick(0);
            }}
            className={`${frenchMission && 'border-2'} border-blue-500 p-1 rounded-sm`}>
            <img src="https://flagcdn.com/fr.svg" width="40" alt="France" />
          </button>
          <button
            onClick={() => {
              LanguageOnClick(1);
            }}
            className={`${!frenchMission && 'border-2'} border-blue-500 p-1 rounded-sm`}>
            <img src="https://flagcdn.com/gb.svg" width="45" alt="United Kingdom" />
          </button>
        </div>

        <div className="p-8 rounded">
          {frenchMission ? (
            <div className="space-y-2">
              <h2 className="font-bold text-xl text-blue-700/90">Demander la validation de ma mission</h2>
              <p className="text-gray-700">
                L’Objectif de la mission humanitaire, sociale ou citoyenne est d’apprendre à dépasser ses préjugés, au
                contact direct des personnes moins favorisées et de découvrir des milieux engagés par un service de
                terrain bénévole utile sur deux mois.
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              <h2 className="font-bold text-xl text-blue-700/90">Ask for the validation of my mission</h2>
              <p className="text-gray-700">
                The objective of the humanitarian, social or civic mission is to learn to overcome prejudices, in direct
                contact with less privileged people and to discover environments involved by a useful voluntary field
                service over two months.
              </p>
            </div>
          )}
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {frenchMission ? (
              <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
                {formFrench[0] &&
                  formFrench[0].questions_id.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.questions_id.name === 'Sélectionner le contact qui sera votre tuteur' ? (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <div className="text-center space-y-3">
                              <div className="flex flex-col justify-center items-center">
                                {emailUsed && (
                                  <p className="text-red-500 font-bold mb-2">Email du contact déjà utilisé.</p>
                                )}
                                <div>
                                  <button
                                    className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                    onClick={() => {
                                      setBlur(true);
                                      setDisplayModal(true);
                                    }}>
                                    Ajouter contact
                                  </button>
                                </div>
                              </div>
                              <p className="text-blue-500 font-semibold">ou</p>
                            </div>
                            <Controller
                              name={item.questions_id.id}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <Select options={optionsContact} placeholder="Sélectionner..." {...field} />
                              )}
                            />
                          </div>
                        ) : (
                          <div className="space-y-2">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            {item.questions_id.type === 'text' && (
                              <textarea
                                className="w-full p-2 h-28 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            )}
                            {item.questions_id.type === 'choice' && (
                              <RadioGroup
                                items={item.questions_id.questions_choice_id.map((itemQues) => {
                                  return itemQues.questions_choice_id.name;
                                })}
                                index={index}
                                register={register}
                                validationSchema={{ required: true }}
                                id={item.questions_id.id}
                                name={item.questions_id.id}
                              />
                            )}
                            {item.questions_id.type === 'url' && (
                              <input
                                type={'url'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="https://"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            )}
                            {item.questions_id.type === 'description' && (
                              <div>
                                <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                                <input
                                  type={'text'}
                                  className="w-full p-2 border border-gray-300"
                                  placeholder="À compléter"
                                  {...register(item.questions_id.id, { required: true })}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
                {formEnglish[0] &&
                  formEnglish[0].questions_id.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.questions_id.name === 'Select the contact who will be your tutor' ? (
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <div className="text-center space-y-3">
                              <div className="flex flex-col justify-center items-center">
                                {emailUsed && (
                                  <p className="text-red-500 font-bold mb-2">Contact email already used.</p>
                                )}
                                <div>
                                  <button
                                    className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                    onClick={() => {
                                      setBlur(true);
                                      setDisplayModal(true);
                                    }}>
                                    Add contact
                                  </button>
                                </div>
                              </div>
                              <p className="text-blue-500 font-semibold">or</p>
                            </div>
                            <Controller
                              name={item.questions_id.id}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <Select options={optionsContact} placeholder="Sélectionner..." {...field} />
                              )}
                            />
                          </div>
                        ) : (
                          <div className="space-y-2">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            {item.questions_id.type === 'text' && (
                              <textarea
                                className="w-full p-2 h-28 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            )}
                            {item.questions_id.type === 'choice' && (
                              <RadioGroup
                                items={item.questions_id.questions_choice_id.map((itemQues) => {
                                  return itemQues.questions_choice_id.name;
                                })}
                                index={index}
                                register={register}
                                validationSchema={{ required: true }}
                                id={item.questions_id.id}
                                name={item.questions_id.id}
                              />
                            )}
                            {item.questions_id.type === 'url' && (
                              <input
                                type={'url'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="https://"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            )}
                            {item.questions_id.type === 'description' && (
                              <div>
                                <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                                <input
                                  type={'text'}
                                  className="w-full p-2 border border-gray-300"
                                  placeholder="À compléter"
                                  {...register(item.questions_id.id, { required: true })}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="flex justify-center pt-10">
              {loading ? (
                <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
              ) : (
                <button
                  className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
                  type="submit">
                  <p>{frenchMission ? 'Soumettre' : 'Submit'}</p>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IdentityMissionForm;
