import React, { useState, useEffect, useContext } from 'react';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import Ratings from '../../oldComponents/Ratings/Ratings';
import { AuthContext } from '../../AuthProvider';
import NavbarTop from '../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../oldComponents/NavbarLeft/NavbarLeft';

type User = {
  id: number;
};
const EvaluationContact = () => {
  const [usersPunctual, setUsersPunctual] = useState([]);
  const { user }: { user: User } = useContext(AuthContext);
  PunctualFilesServices.FormatGetAllPunctualFilesContactEvaluate(usersPunctual, setUsersPunctual, user.id);

  const [formatedList, setFormatedList] = useState([]);

  useEffect(() => {
    const list = [];

    usersPunctual.map((item) => {
      list.push({
        id: item.user.id,
        name: item.user.last_name + ' ' + item.user.first_name,
        email: item.user.email,
        id_table_link_dispo_user: item.id,
        evaluation_id: item.evaluation,
        team: item.user.group.slice(-1)[0]
      });
      return item;
    });
    setFormatedList(list);
  }, [usersPunctual]);

  return (
    <div className={`App flex w-screen min-h-screen`}>
      <NavbarLeft user={user} />
      <div className={`flex flex-col h-full w-full page-Container`}>
        <NavbarTop urls={['home']} user={user} />
        <div className="HomeComponent p-10 space-y-8">
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Mes Évaluations</h2>
          <Ratings data={formatedList} contact={true} asso={false} />
        </div>
      </div>
    </div>
  );
};

export default EvaluationContact;
