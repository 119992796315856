import axios from 'axios';
import authHeader, { API } from './authHeader';
import UserService from './userService';

const API_URL = API + 'auth';

const login = (email, password, setLoading, setErrorLogin, setUser, setIsLogged) => {
  axios
    .post(API_URL + '/login', {
      email,
      password
    })
    .then((response) => {
      setLoading(false);
      if (response.data) {
        localStorage.setItem('token', JSON.stringify(response.data.data));
        UserService.getUser(setUser, setIsLogged);
      }
    })
    .catch(() => {
      setLoading(false);
      setErrorLogin(true);
    });
};

const logout = (refresh_token) => {
  localStorage.clear();
  axios
    .post(API_URL + '/logout', {
      refresh_token
    })
    .then(() => {
      window.location.reload();
    });
};

const refresh = (refresh_token) => {
  return axios
    .post(API_URL + '/refresh', {
      refresh_token
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem('token', JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

const resetPassword = (token, password) => {
  return axios.post(
    API_URL + '/password/reset',
    {
      token: token,
      password: password
    },
    { headers: authHeader() }
  );
};

const reqResetPassword = (email) => {
  return axios.post(
    API_URL + '/password/request',
    {
      email: email
    },
    { headers: authHeader() }
  );
};

const getCurrentToken = () => {
  return JSON.parse(localStorage.getItem('token'));
};

const AuthService = {
  login,
  logout,
  refresh,
  resetPassword,
  reqResetPassword,
  getCurrentToken
};

export default AuthService;
