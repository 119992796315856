import React, { useEffect, useState } from 'react';
import { stepsNewPlan, stepsNewPlanModel4 } from '../../data.js';
type step = {
  order: number;
  step_id: {
    id: number;
    name: string;
  };
};

type newStep = {
  order: number;
  name: string;
  current: boolean;
  complete: boolean;
  stepid: number;
  shortDescription: string;
};

type stepInData = {
  title: string;
  description: string;
  shortDescription: string;
};
const RightPartHeaderStep = ({ steps, userDispo }) => {
  const [stepsToDisplay, setStepsToDisplay] = useState([]);
  const actualStepId = userDispo?.step?.id;
  useEffect(() => {
    if (actualStepId) {
      const numberToDisplay = 5;
      const orderActualStep = steps.find((step: step) => step.step_id.id === actualStepId).order;
      const AllOrder = steps.map((step: step) => step.order);
      AllOrder.sort((a: number, b: number) => a - b);
      const indexActualStepOrder = AllOrder.indexOf(orderActualStep);
      let newArrayStep: number[];
      if (indexActualStepOrder + (numberToDisplay - 1) > AllOrder.length) {
        newArrayStep = AllOrder.slice(-numberToDisplay);
      } else {
        const start = Math.max(indexActualStepOrder - 1, 0);
        let end: number;
        if (orderActualStep === 0) {
          end = indexActualStepOrder + numberToDisplay;
        } else {
          end = indexActualStepOrder + (numberToDisplay - 1);
        }
        newArrayStep = AllOrder.slice(start, end);
      }
      const arrayOfStep = steps
        .map((step: step) => {
          if (!newArrayStep.includes(step.order)) {
            return;
          }
          if (newArrayStep.includes(step.order)) {
            const arrayOfAllStep = [...stepsNewPlan, ...stepsNewPlanModel4];
            const shortDescription = arrayOfAllStep.find(
              (st: stepInData) => st.title === step.step_id.name
            )?.shortDescription;
            if (step.order === orderActualStep) {
              if (step.order === steps.length - 1 && userDispo.finish === true) {
                return {
                  order: step.order,
                  name: step.step_id.name,
                  current: true,
                  complete: true,
                  stepid: step.step_id.id,
                  shortDescription: shortDescription
                };
              }
              return {
                order: step.order,
                name: step.step_id.name,
                current: true,
                complete: false,
                stepid: step.step_id.id,
                shortDescription: shortDescription
              };
            } else if (step.order < orderActualStep) {
              return {
                order: step.order,
                name: step.step_id.name,
                current: false,
                complete: true,
                stepid: step.step_id.id,
                shortDescription: shortDescription
              };
            } else {
              return {
                order: step.order,
                name: step.step_id.name,
                current: false,
                complete: false,
                stepid: step.step_id.id,
                shortDescription: shortDescription
              };
            }
          }
        })
        .filter((step: newStep) => step !== undefined && step !== null)
        .sort((a: newStep, b: newStep) => a.order - b.order);
      setStepsToDisplay(arrayOfStep);
    }
  }, [actualStepId]);

  return (
    <nav aria-label="Progress" className="flex w-full justify-center">
      <ol role="list" className="overflow-hidden">
        {stepsToDisplay.map((step: newStep, stepIdx: number) => (
          <li key={step.stepid} className={`${stepIdx !== steps.length ? 'pb-10' : ''} relative`}>
            {step.complete ? (
              <>
                {step.order !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-newViolet-200"
                    aria-hidden="true"
                  />
                ) : null}
                <a className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center text-white justify-center rounded-full bg-newViolet-100 group-hover:bg-newViolet-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.shortDescription}</span>
                  </span>
                </a>
              </>
            ) : step.current ? (
              <>
                {step.order !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <a href="#pipeline" className="group relative flex items-start" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-newViolet-100 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-newViolet-100" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-newViolet-200">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.shortDescription}</span>
                  </span>
                </a>
              </>
            ) : (
              <>
                {step.order !== steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <a className="group relative flex items-start">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.shortDescription}</span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default RightPartHeaderStep;
