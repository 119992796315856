import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'files';

const getFile = () => {
  return axios.get(API_URL + '?fields=id,title', { headers: authHeader() });
};

const getOneFile = (name) => {
  return axios.get(API_URL + '?filter[title][_eq]=' + name + '&fields=id,title', {
    headers: authHeader
  });
};

const fileUpdate = (formData) => {
  return axios.post(API_URL, formData, { headers: authHeader() });
};

const deleteFile = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const FormatGetFiles = (setFileList, fileList) => {
  useEffect(() => {
    const list = [];

    const MakeFile = async () => {
      const data = await FileService.getFile();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setFileList(list);
    };
    MakeFile();
  }, []);
  return fileList;
};

const FormatGetOneFile = (name, setFile, file) => {
  useEffect(() => {
    const list = [];

    const Makefile = async () => {
      const data = await FileService.getOneFile(name);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setFile(list);
    };
    Makefile();
  }, []);
  return file;
};

const FileService = {
  getFile,
  getOneFile,
  fileUpdate,
  deleteFile,
  FormatGetFiles,
  FormatGetOneFile
};

export default FileService;
