import React, { useState, useContext } from 'react';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import DispoService from '../../oldService/dispService';
import UserDispoService from '../../oldService/userDispoService';
// import UserDispoService from "../../oldService/userDispoService";
import DispoTagService from '../../oldService/dispTagService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthProvider';
import LoadingButton from '../LoadingButton/LoadingButton';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import BasicErrorMessage from '../ErrorMessage/basic/BasicErrorMessage';
import { useEffect } from 'react';
import FileService from '../../oldService/fileService';

// import ChoiceCard from "../ChoiceCard/ChoiceCard";

function Modal(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const { user } = useContext(AuthContext);

  // const [internal, setInternal] = useState(true);
  // const [open, setOpen] = useState(true);
  // const [confirmation, setConfirmation] = useState(true);

  const [title, setTitle] = useState(null);
  const [des, setDes] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startHour, setStartHour] = useState(null);
  const [endhour, setEndHour] = useState(null);
  const [location, setLocation] = useState(null);
  const [status, setStatus] = useState('Private');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [fileId, setFileId] = useState();
  const listRequired = [
    { name: "Nom de l'évènement" },
    { name: 'Date de début' },
    { name: 'Date de fin' },
    { name: 'photo' }
  ];
  const handleDisplay = () => {
    props.setDisplay(false);
    props.setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };
  const navigate = useNavigate();
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleDisplay();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  const handleSubmit = async () => {
    // handleDisplay();
    if ((title !== null) & (startDate !== null) & (endDate !== null) & (fileId !== null)) {
      setLoading(true);
      let idNewEvent = null;
      const formData = new FormData();
      formData.append('title', fileId.name);
      formData.append('file', fileId);
      try {
        const idPhoto = await FileService.fileUpdate(formData);
        const result = await DispoService.PostEvent(
          title,
          des,
          startDate,
          endDate,
          startHour,
          endhour,
          location,
          id,
          status,
          idPhoto.data.data.id
        );
        idNewEvent = result.data.data.id;
        await DispDirUsersService.postDirectusUser(result.data.data.id, user.id);
        await UserDispoService.PostUserDispo(result.data.data.id, user.id);
        await DispoTagService.postDispTag(result.data.data.id, 28);
        await DispoTagService.postDispTag(result.data.data.id, 13);
        setLoading(false);
        document.body.classList.remove('ModalOpenNoScrollBody');
        navigate('/user/events/details?id=' + result.data.data.id);
      } catch (err) {
        if (idNewEvent !== null) {
          await DispoService.DeleteDispo(idNewEvent);
        }
        setRequestError(true);
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };
  const onChangeFile = (e) => {
    setFileId(e.target.files[0]);
  };
  return (
    <div className="Modal">
      <div
        id="authentication-modal"
        tabIndex="-1"
        className="overflow-y-scroll overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex backgoundModalColor"
        aria-modal="true"
        role="dialog">
        <div className="relative p-4 w-full max-w-lg">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded shadow">
            <button
              type="button"
              disabled={loading}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDisplay}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6 px-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">Ajouter un évènement</h3>
              <div className="space-y-6" action="#">
                {/* TITLE */}
                <div>
                  <div>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                      Nom de l'évènement
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Nom évènement"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  {/* DESCRIPTION */}
                  <div>
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">
                      Description de l'évènement
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Description"
                      required=""
                      onChange={(e) => {
                        setDes(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label className="block m-0 text-sm font-medium text-gray-900" htmlFor="file_input">
                    Photo
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    onChange={onChangeFile}
                    accept={'image/*'}
                  />
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
                    N'importe quel format d'image (Recommandé 500x500px).
                  </p>
                </div>

                {/* START */}
                <div className="grid grid-cols-2 space-x-2">
                  <div>
                    <label htmlFor="start-date" className="block mb-2 text-sm font-medium text-gray-900">
                      Date de début
                    </label>
                    <input
                      type="date"
                      name="start-date"
                      id="start-date"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="start-time" className="block mb-2 text-sm font-medium text-gray-900">
                      Heure de début
                    </label>
                    <input
                      type="time"
                      name="start-time"
                      id="start-time"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      onChange={(e) => {
                        setStartHour(e.target.value);
                      }}
                      defaultValue="08:00"
                    />
                  </div>
                </div>
                {/* END */}
                <div className="grid grid-cols-2 space-x-2">
                  <div>
                    <label htmlFor="end-date" className="block mb-2 text-sm font-medium text-gray-900">
                      Date de fin
                    </label>
                    <input
                      type="date"
                      name="end-date"
                      id="end-date"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="end-time" className="block mb-2 text-sm font-medium text-gray-900">
                      Heure de fin
                    </label>
                    <input
                      type="time"
                      name="end-time"
                      id="end-time"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      onChange={(e) => {
                        setEndHour(e.target.value);
                      }}
                      defaultValue="08:00"
                    />
                  </div>
                </div>
                {/* LOCATION */}
                <div>
                  <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900">
                    Lieu de l'évènement
                  </label>
                  <input
                    type="text"
                    name="location"
                    id="location"
                    className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Lieu"
                    required=""
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </div>
                <div className="ChoiceCard flex flex-col justify-center items-center">
                  <p className="block mb-2 text-sm font-medium text-gray-900">
                    Voulez vous que l'évènement soit public ou privé ?
                  </p>
                  <div className="flex justify-around space-x-5">
                    <div className="flex space-x-1">
                      <input
                        type="checkbox"
                        name="choice1"
                        id="choice1"
                        checked={status === 'Private'}
                        onChange={() => {
                          setStatus('Private');
                        }}
                      />
                      <label htmlFor="choice1" className="block text-sm font-medium text-gray-900">
                        Evènement privé
                      </label>
                    </div>
                    <div className="flex space-x-1">
                      <input
                        type="checkbox"
                        name="choice2"
                        id="choice2"
                        checked={status === 'Public'}
                        onChange={() => {
                          setStatus('Public');
                        }}
                      />
                      <label htmlFor="choice2" className="block text-sm font-medium text-gray-900">
                        Evènement public
                      </label>
                    </div>
                  </div>
                  {status === 'Public' && (
                    <p className="block mb-2 text-sm font-medium text-gray-900 text-center italic mt-1.5">
                      Un évènement public sera visible et accessible à toute personne en dehors de votre association.
                    </p>
                  )}
                </div>
                {/* INTERNAL */}
                {/* <ChoiceCard data={internal} setData={setInternal} title={"Cet évènement est disponible uniquement aux membres du dispositif:"} /> */}
                {/* OPEN */}
                {/* <ChoiceCard data={open} setData={setOpen} title={"L'inscription à cet évènement nécessite une confirmation d'un responsable du dispositif:"} /> */}
                {/* ADMIN CONFIRMATION */}
                {/* <ChoiceCard data={confirmation} setData={setConfirmation} title={"La création de cet évènement nécessite une validation d'un Admin:"} /> */}
                <BasicErrorMessage error={requestError} />
                <ErrorMessage error={error} listRequired={listRequired} />
                <div className="flex justify-center space-x-5">
                  <LoadingButton handleSubmit={handleSubmit} loading={loading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
