import { useState, useEffect } from 'react';
// import UserService from './userService';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';
import { AuthContext } from '../AuthProvider';
import { useContext } from 'react';
import { API_AKANEMA } from './akanemaApi';
const API_URL = API + 'items/user_dispositif';

const PatchUserDispoEval = (userDispoId, evalId) => {
  return axios.patch(API_URL + '/' + userDispoId, { evaluation_id: evalId }, { headers: authHeader() });
};

const PatchUserDispoValidation = (userDispoId) => {
  return axios.patch(API_URL + '/' + userDispoId, { validation: true }, { headers: authHeader() });
};

const PatchUserDispoEval2 = (userDispoId, evalId) => {
  return axios.patch(API_URL + '/' + userDispoId, { evaluation_id_2: evalId }, { headers: authHeader() });
};

const GetAllUserDispoAll = () => {
  return axios.get(
    API_URL +
      '?fields=id,punctual_files_id.convention_doc.id,punctual_files_id.convention_doc.signed,punctual_files_id.convention_doc.signed_file,punctual_files_id.organization_id,validation&limit=-1',
    { headers: authHeader() }
  );
};

const FormatGetAllUserDispoAll = (userDispos, setUserDispos) => {
  useEffect(() => {
    const list = [];
    const Makelist = async () => {
      const data = await GetAllUserDispoAll();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setUserDispos(list);
    };
    Makelist();
  }, []);
  return userDispos;
};

const getUserEnga = (id) => {
  return axios.get(
    API_URL +
      '?filter={"_and":[{"user":{"_eq":"' +
      id +
      '"}},{"dispositif":{"archived":{"_eq":"false"}}},{"dispositif":{"template":{"_neq":"293"}}}]}&fields=dispositif.name,dispositif.status,dispositif.id,dispositif.description,dispositif.picture,dispositif.template,dispositif.archived,dispositif.date_created,date_created',
    { headers: authHeader() }
  );
};

const getUserDispo = () => {
  return axios.get(
    API_URL + '?fields=id,dispositif.name,dispositif.status,dispositif.id,dispositif.description,user.id,note',
    { headers: authHeader() }
  );
};

const getAllUser = (id) => {
  return axios.get(
    API_URL +
      '?filter[dispositif][_eq]=' +
      id +
      '&fields=id,user.first_name,user.last_name,user.email,user.phones,date_created,user.group.group_id.name,user.group.group_id.id,user.group.group_id.parentgroup.name,user.id,step_id.name,step_id.id,step_id.tovalidate,validation,files_asso_id,punctual_files_id.id,punctual_files_id.evaluation_doc,evaluation_id.id,evaluation_id.total,evaluation_id_2.id,evaluation_id_2.total,finish,dispositif.id,dispositif.name,date_created&limit=-1',
    { headers: authHeader() }
  );
};

const getUserEvent = (id) => {
  return axios.get(
    API_URL +
      '?filter={"_and":[{"user":{"_eq":"' +
      id +
      '"}},{"dispositif":{"archived":{"_eq":"false"}}},{"dispositif":{"template":{"_eq":"293"}}}]}&fields=id,dispositif.name,dispositif.status,dispositif.id,dispositif.description,note,dispositif.picture,dispositif.template,dispositif.responsibles.directus_users_id,dispositif.archived',
    { headers: authHeader() }
  );
};

const getUserDispoDispo = (dispoId, userId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif][_eq]=' +
      dispoId +
      '&filter[user][_eq]=' +
      userId +
      '&fields=punctual_files_id,dispositif,files_asso_id,id,note,step_id.name,step_id.id,step_id.tovalidate,step_id.isoptional,user.id,user.first_name,user.last_name,user.email,validation,decline_reason',
    { headers: authHeader() }
  );
};

const getOneUserDispo = (id, dispo) => {
  return axios.get(
    API_URL +
      '?filter[user][_eq]=' +
      id +
      '&filter[dispositif][_eq]=' +
      dispo +
      '&fields=dispositif,files_asso_id,id,note,step_id.name,step_id.id,step_id.tovalidate,step_id.isoptional,user.id,user.first_name,user.last_name,user.email,validation,punctual_files_id,decline_reason,finish',
    { headers: authHeader() }
  );
};

const deleteOneUserDispo = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const patchUserDispoNote = async (id, content) => {
  await axios.patch(API_URL + '/' + id, { note: content }, { headers: authHeader() });
};

const PatchUserDispoPunctualFiles = (userdispoId, punctualFilesId) => {
  return axios.patch(API_URL + '/' + userdispoId, { punctual_files_id: punctualFilesId }, { headers: authHeader() });
};

const PatchUserDispoValidate = (userDispoId, filesAssoId, validate, declineReason = null) => {
  return axios.patch(
    API_URL + '/' + userDispoId,
    {
      validation: validate,
      files_asso_id: filesAssoId,
      decline_reason: declineReason
    },
    { headers: authHeader() }
  );
};

const PatchUserDispoStep = (userDispoId, stepId) => {
  return axios.patch(
    API_URL + '/' + userDispoId,
    {
      step_id: stepId,
      validation: false
    },
    { headers: authHeader() }
  );
};

const PostUserDispo = (dispoId, userId, stepId) => {
  return axios.post(
    API_URL,
    {
      dispositif: dispoId,
      user: userId,
      step_id: stepId
    },
    { headers: authHeader() }
  );
};

const PatchUserDispoValFiles = (userDispoId, validation, filesAsso) => {
  return axios.patch(
    API_URL + '/' + userDispoId,
    {
      validation: validation,
      files_asso_id: filesAsso
    },
    { headers: authHeader() }
  );
};

const FormatUserEnga = (user) => {
  const [enga, setEnga] = useState([]);

  useEffect(() => {
    const mes_engagements = [];

    const getEnga = async (user) => {
      const data = await UserDispoService.getUserEnga(user.id);
      data.data.data
        .sort((a, b) => new Date(b.dispositif.date_created) - new Date(a.dispositif.date_created))
        .map((engagements) => mes_engagements.push(engagements.dispositif));
      setEnga(mes_engagements);
    };
    if (user) getEnga(user);
  }, [user]);
  return enga;
};

const FormatUserEngaAll = (enga, setEnga) => {
  useEffect(() => {
    const mes_engagements = [];

    const getEnga = async () => {
      const data = await UserDispoService.getUserDispo();
      data.data.data.map((engagements) => mes_engagements.push(engagements));
      // eslint-disable-next-line eqeqeq
      setEnga(mes_engagements);
    };
    getEnga();
  }, []);
  return enga;
};

const FormatGetOneUserDispo = (userDispo, setUserDispo, dispoId, userId = null) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await UserDispoService.getOneUserDispo(userId ?? user.id, dispoId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setUserDispo(list[0]);
    };
    MakeList();
  }, [userId]);
  return userDispo;
};

const FormatOneUserDispoNote = (dispo) => {
  const [userDispo, setUserDispo] = useState();

  const { user } = useContext(AuthContext);
  useEffect(() => {
    const getUserDispo = async () => {
      const data = await UserDispoService.getOneUserDispo(user.id, dispo);
      setUserDispo(data.data.data[0]?.note);
    };
    getUserDispo();
  }, [dispo]);
  return userDispo;
};

const FormatOneUserDispo = (dispo, setDispo, dispoId) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const list = [];

    const Makedispo = async () => {
      const data = await UserDispoService.getOneUserDispo(user.id, dispoId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDispo(list);
    };
    Makedispo();
  }, []);
  return dispo;
};

const FormatOneUserDispoId = (dispo) => {
  const [userDispo, setUserDispo] = useState();

  const { user } = useContext(AuthContext);
  useEffect(() => {
    const getUserDispo = async () => {
      const data = await UserDispoService.getOneUserDispo(user.id, dispo);
      setUserDispo(data.data.data[0]?.id);
    };
    getUserDispo();
  }, [dispo]);
  return userDispo;
};

const FormatGetUserEvent = (event, setEvent, id) => {
  useEffect(() => {
    const list = [];

    const MakeUserEvent = async () => {
      const data = await UserDispoService.getUserEvent(id);
      data.data.data.map((item) => {
        list.push(item.dispositif);
        return item;
      });
      setEvent(list);
    };
    MakeUserEvent();
  }, []);
  return event;
};

const FormatGetUserDispoDispo = (inscription, setInscription, dispoId, userId) => {
  useEffect(() => {
    const MakeList = async () => {
      const data = await getUserDispoDispo(dispoId, userId);
      if (data.data.data[0]) setInscription(true);
      else setInscription(false);
    };
    MakeList();
  }, []);
  return inscription;
};

const FormatGetUserDispoDispoList = (userDispo, setUserDispo, dispoId, userId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await (await getUserDispoDispo(dispoId, userId)).data.data;

      data.map((item) => {
        list.push(item);
        return item;
      });
      setUserDispo(list[0]);
    };
    MakeList();
  }, []);
  return userDispo;
};

const FormatGetAllUser = (users, setUsers, id) => {
  useEffect(() => {
    const MakeUserList = async () => {
      const list = [];

      const data = await UserDispoService.getAllUser(id);
      data.data.data.map((item) => {
        list.push({
          id: item.user.id,
          name: item.user.first_name + ' ' + item.user.last_name,
          last_name: item.user.last_name,
          team: item.user.group.slice(-1)[0],
          email: item.user.email,
          phones: item.user.phones,
          other_id: item.id,
          step: item.step_id,
          validation: item.validation,
          files_asso_id: item.files_asso_id,
          punctual_files_id: item.punctual_files_id?.id,
          evaluation_id: item.evaluation_id,
          evaluation_id_2: item.evaluation_id_2,
          evaluation_finish: item.punctual_files_id?.evaluation_doc,
          finish: item.finish,
          date: item.date_created
        });
        return item;
      });
      setUsers(list);
    };
    MakeUserList();
  }, []);
  return users;
};

const GetOneUserDispoFiles = (id, dispo) => {
  return axios.get(
    API_URL +
      '?filter[user][_eq]=' +
      id +
      '&filter[dispositif][_eq]=' +
      dispo +
      '&fields=dispositif,files_asso_id,id,note,step_id.name,step_id.id,step_id.tovalidate,step_id.isoptional,user.id,user.first_name,user.last_name,user.email,validation,punctual_files_id',
    { headers: authHeader() }
  );
};

const FormatGetOneUserDispoFiles = (userDispo, setUserDispo, dispoId) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetOneUserDispoFiles(user.id, dispoId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setUserDispo(list[0]);
    };
    Makelist();
  }, []);
};

const GetOneUserDispoPunctualFiles = (id, userId) => {
  return axios.get(
    API_URL +
      '?filter[user][_eq]=' +
      userId +
      '&filter[dispositif][_eq]=' +
      id +
      '&fields=punctual_files_id.project_doc.attachment_urls,punctual_files_id.mission_doc.attachment_urls,punctual_files_id.convention_doc.attachment_urls,punctual_files_id.convention_doc.signed_file,punctual_files_id.convention_doc.signed',
    { headers: authHeader() }
  );
};

const FormatGetOneUserDispoPunctualFiles = (filesPun, setFilesPun, id) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetOneUserDispoPunctualFiles(id, user.id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setFilesPun(list[0]?.punctual_files_id);
    };
    Makelist();
  }, []);
  return filesPun;
};

const getAllValidationOfDispo = () => {
  return axios.get(
    API_URL +
      '?fields[]=dispositif&aggregate[count]=validation&groupBy[]=dispositif&filter={"_and":[{"validation":{"_eq":true}}]}',
    { headers: authHeader() }
  );
};

const getCountAllUserByDispo = () => {
  return axios.get(API_URL + '?fields[]=dispositif&aggregate[count]=*&groupBy[]=dispositif', {
    headers: authHeader()
  });
};

const getIdUserDispoByPunctualFiles = (punctualFiles) => {
  return axios.get(
    API_URL + '?filter[punctual_files_id][_eq]=' + punctualFiles + '&fields=id,dispositif,dispositif.name',
    {
      headers: authHeader()
    }
  );
};
const getIdUserDispoByFilesVieAsso = (files_vie_asso_id) => {
  return axios.get(
    API_URL +
      '?filter[files_asso_id][_eq]=' +
      files_vie_asso_id +
      '&fields=id,step_id.name,step_id.id,step_id.tovalidate,dispositif',
    {
      headers: authHeader()
    }
  );
};

const getAllEvalOfADispo = (dispoId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif][_eq]=' +
      dispoId +
      '&fields=dispositif.name,dispositif.id,dispositif.evaluation_step,evaluation_id.total,evaluation_id.date_created,evaluation_id.evals,evaluation_id.type,evaluation_id.user_created.first_name,evaluation_id.user_created.email,evaluation_id.user_created.last_name,evaluation_id.user_created.group.group_id.name,evaluation_id.user_created.group.group_id.parentgroup.name,evaluation_id_2.date_created,evaluation_id_2.total,evaluation_id_2.evals,evaluation_id_2.type,evaluation_id_2.user_created.first_name,evaluation_id_2.user_created.email,evaluation_id_2.user_created.last_name,evaluation_id_2.user_created.group.group_id.name,evaluation_id_2.user_created.group.group_id.parentgroup.name,evaluation_id.user.first_name,evaluation_id.user.email,evaluation_id.user.phones,evaluation_id.user.last_name,evaluation_id.user.group.group_id.name,evaluation_id.user.group.group_id.parentgroup.name,evaluation_id_2.user.first_name,evaluation_id_2.user.email,evaluation_id_2.user.phones,evaluation_id_2.user.last_name,evaluation_id_2.user.group.group_id.name,evaluation_id_2.user.group.group_id.parentgroup.name',
    { headers: authHeader() }
  );
};

const getAllFileOfUserInDispo = (id) => {
  return axios.get(
    API_URL +
      '?filter[dispositif][_eq]=' +
      id +
      '&fields=id,user.first_name,user.last_name,user.email,user.group.group_id.name,user.group.group_id.id,user.group.group_id.parentgroup.name,user.id,files_asso_id.id,files_asso_id.statuts,files_asso_id.proces_verbal,files_asso_id.recepisse,files_asso_id.budget,files_asso_id.planning,files_asso_id.soutenance_1,files_asso_id.soutenance_2,files_asso_id.finalReport.*.*&limit=-1',
    { headers: authHeader() }
  );
};

const PatchUserDispoFinish = (userDispoId) => {
  return axios.patch(API_URL + '/' + userDispoId, { validation: false, finish: true }, { headers: authHeader() });
};

const moveAStudentToPrevStep = (idUserDispo) => {
  return axios.post(API_AKANEMA + '/api/user-dispositifs/' + idUserDispo + '/move-to-previous-step');
};

const requestStatsPunctual = (dispoId) => {
  return axios.post(API_AKANEMA + '/api/dispositifs/' + dispoId + '/export');
};
const UserDispoService = {
  PatchUserDispoEval,
  PatchUserDispoEval2,
  GetAllUserDispoAll,
  FormatGetAllUserDispoAll,
  getUserEnga,
  getUserDispo,
  getOneUserDispo,
  getUserDispoDispo,
  patchUserDispoNote,
  PatchUserDispoValFiles,
  deleteOneUserDispo,
  PostUserDispo,
  PatchUserDispoValidate,
  PatchUserDispoPunctualFiles,
  FormatUserEnga,
  FormatUserEngaAll,
  FormatOneUserDispoNote,
  FormatOneUserDispoId,
  FormatOneUserDispo,
  getUserEvent,
  FormatGetUserEvent,
  getAllUser,
  FormatGetAllUser,
  FormatGetUserDispoDispo,
  FormatGetUserDispoDispoList,
  GetOneUserDispoFiles,
  FormatGetOneUserDispoFiles,
  PatchUserDispoStep,
  FormatGetOneUserDispo,
  GetOneUserDispoPunctualFiles,
  FormatGetOneUserDispoPunctualFiles,
  getAllValidationOfDispo,
  getCountAllUserByDispo,
  PatchUserDispoValidation,
  getIdUserDispoByPunctualFiles,
  getIdUserDispoByFilesVieAsso,
  getAllEvalOfADispo,
  getAllFileOfUserInDispo,
  PatchUserDispoFinish,
  moveAStudentToPrevStep,
  requestStatsPunctual
};

export default UserDispoService;
