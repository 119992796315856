import React from 'react';

const OrganizationDetails = ({ organization }) => {
  return (
    <div className="OrganizationDetails">
      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Description de l'organisation</h2>
        </div>
      </div>
      <div className="flex flex-wrap">
        <p className="w-1/2">
          Raison sociale: <span className="font-semibold">{organization.name}</span>
        </p>
        <p className="w-1/2">
          SIRET: <span className="font-semibold">{organization.numero_siret_rna}</span>
        </p>
        <p className="w-1/2">
          RNA: <span className="font-semibold">{organization.numero_rna}</span>
        </p>
        <p className="w-1/2">
          Adresse postale (N° et rue): <span className="font-semibold">{organization.name}</span>
        </p>
        <p className="w-1/2">
          Code postal: <span className="font-semibold">{organization.postal_code}</span>
        </p>
        <p className="w-1/2">
          Ville: <span className="font-semibold">{organization.city}</span>
        </p>
        <p className="w-1/2">
          Pays: <span className="font-semibold">{organization.country}</span>
        </p>
        <p className="w-1/2">
          Bénéficiaires principaux: <span className="font-semibold">{organization.beneficiaries}</span>
        </p>
      </div>
    </div>
  );
};

export default OrganizationDetails;
