import React, { useEffect, useState, useContext } from 'react';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import Searchbar from '../../../oldComponents/Searchbar/Searchbar';
import { API } from '../../../oldService/authHeader';
import AuthService from '../../../oldService/authService';
import { PunctualFilesServices } from '../../../oldService/punctualFilesService';
import UserDispoService from '../../../oldService/userDispoService';
import DispDirFileService from '../../../oldService/dispDirFile';
import { AuthContext } from '../../../AuthProvider';
import { useNavigate } from 'react-router-dom';

const PunctualDocs = () => {
  const navigate = useNavigate();
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [userDispoList, setUserDispoList] = useState([]);
  const [punctuals, setPunctuals] = useState([]);
  const [allPunctuals, setAllPunctuals] = useState([]);
  const [filteredPun, setFilteredPun] = useState([]);
  const [docList, setDocList] = useState([]);
  const [fileList, setFileList] = useState([]);

  if (token) {
    UserDispoService.FormatGetAllUser(userDispoList, setUserDispoList, id);
    PunctualFilesServices.FormatGetAllPunctualFilesDoc(allPunctuals, setAllPunctuals);
    DispDirFileService.FormatGetOneDispFile(fileList, setFileList, id, -1);
  }

  useEffect(() => {
    setPunctuals(
      userDispoList.map((item) => {
        return item.punctual_files_id;
      })
    );
  }, [userDispoList]);

  useEffect(() => {
    const list = [];
    punctuals.map((item) => {
      list.push(allPunctuals.find((pun) => pun.id === item));
      return item;
    });
    setFilteredPun(list);
  }, [allPunctuals]);

  useEffect(() => {
    const list = [];
    filteredPun.map((item) => {
      item &&
        item.project_doc &&
        item.project_doc !== null &&
        item.project_doc !== undefined &&
        list.push({
          id: item.project_doc.id,
          name:
            item.project_doc.name !== null
              ? item.project_doc.name
              : item.user.first_name + ' ' + item.user.last_name + ' Projet',
          url: item.project_doc.attachment_urls,
          user: item.user.first_name + ' ' + item.user.last_name
        });
      item &&
        item.mission_doc &&
        item.mission_doc !== null &&
        item.mission_doc !== undefined &&
        list.push({
          id: item.mission_doc.id,
          name:
            item.mission_doc.name !== null
              ? item.mission_doc.name
              : item.user.first_name + ' ' + item.user.last_name + ' Mission',
          url: item.mission_doc.attachment_urls,
          user: item.user.first_name + ' ' + item.user.last_name
        });
      item &&
        item.convention_doc &&
        item.convention_doc !== null &&
        item.convention_doc !== undefined &&
        list.push({
          id: item.convention_doc.id,
          name:
            item.convention_doc.name !== null
              ? item.convention_doc.name
              : item.user.first_name + ' ' + item.user.last_name + ' Convention',
          url: item.convention_doc.attachment_urls,
          user: item.user.first_name + ' ' + item.user.last_name
        });
      item &&
        item.convention_doc &&
        item.convention_doc !== null &&
        item.convention_doc !== undefined &&
        item.convention_doc.signed_file &&
        item.convention_doc.signed_file !== null &&
        item.convention_doc.signed_file !== undefined &&
        list.push({
          id: item.convention_doc.id,
          name:
            item.convention_doc.name !== null
              ? `${item.convention_doc.name} Signée`
              : item.user.first_name + ' ' + item.user.last_name + ' Convention Signée',
          signed: item.convention_doc.signed_file,
          user: item.user.first_name + ' ' + item.user.last_name
        });
      item &&
        item.evaluation_doc &&
        item.evaluation_doc !== null &&
        item.evaluation_doc !== undefined &&
        item.evaluation_doc.attachment_urls.length !== 0 &&
        list.push({
          id: item.evaluation_doc.id,
          name: item.evaluation_doc.name,
          url: item.evaluation_doc.attachment_urls,
          user: item.user.first_name + ' ' + item.user.last_name
        });
      return item;
    });

    setDocList([
      ...list,
      ...fileList
        .filter((item) => item.user_id)
        .map((item) => ({
          id: item.directus_files_id.id,
          name: item.directus_files_id.title,
          user: item.user_id.first_name + ' ' + item.user_id.last_name,
          url: API + 'assets/' + item.directus_files_id.id + '?download'
        }))
    ]);
  }, [filteredPun, fileList]);

  const [searchField, setSearchField] = useState('');

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="PunctualDocs flex w-screen min-h-screen">
      <NavbarLeft user={user} sub={1} />
      <div className="flex flex-col h-full w-full">
        <NavbarTop user={user} urls={['home', 'plans']} />
        <div className="p-10 space-y-8">
          <div className="flex justify-between w-full">
            <div className={`w-full`}>
              <button className="underline font-medium" onClick={() => navigate(-1)}>
                {' '}
                &#60; Retour{' '}
              </button>
              <h1 className="font-bold text-3xl text-blue-500">Documents</h1>
              <div className="mt-5 space-y-5">
                <div className="flex justify-end">
                  <div className="w-4/12">
                    <Searchbar placeholder={'Rechercher par nom...'} onChangeHandler={onSearchChange} />
                  </div>
                </div>
                <div className="py-5 rounded-sm bg-gray-50 border">
                  <div className="grid grid-cols-2 px-5 pb-3 border-b">
                    <p className="font-semibold">Nom</p>
                    <p className="font-semibold">Document</p>
                  </div>
                  <div className="text-sm">
                    {docList &&
                      docList
                        .filter((doc) => {
                          return doc.user.toLocaleLowerCase().includes(searchField);
                        })
                        .sort((a, b) => {
                          if (a.name < b.name) return -1;
                          else if (a.name > b.name) return 1;
                          else return 0;
                        })
                        .map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={`grid grid-cols-2 py-2 px-5 ${
                                index % 2 === 0 && 'bg-white rounded-sm'
                              } border border-black/0 hover:border-black/10 rounded-sm`}>
                              <a
                                href={item.url ? item.url : API + 'assets/' + item.signed + '?download'}
                                className="hover:underline">
                                {item.user}
                              </a>
                              <a
                                href={item.url ? item.url : API + 'assets/' + item.signed + '?download'}
                                className="hover:underline">
                                {item.name}
                              </a>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PunctualDocs;
