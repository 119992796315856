import React, { useState } from 'react';
import { API } from '../../oldService/authHeader';
import DispDirFileService from '../../oldService/dispDirFile';
import FileService from '../../oldService/fileService';

const ModalManageDocument = ({ docList, setDisplayModalAllDocument, setBlur, setReload, setDisplayAddDoc, admin }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadAll = () => {
    const downloadPromises = docList.map((item) => {
      const url = API + 'assets/' + item.directus_files_id.id + '?download';
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = item.directus_files_id.title;
          a.click();
          URL.revokeObjectURL(url);
        });
    });
    Promise.all(downloadPromises);
  };
  const handleDowloadOne = (doc) => {
    const url = API + 'assets/' + doc.directus_files_id.id + '?download';
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = doc.directus_files_id.title;
        a.click();
        URL.revokeObjectURL(url);
      });
  };
  const handleRemove = async (file) => {
    setLoading(true);
    DispDirFileService.DeleteOneDispFile(file.id).then(() => {
      FileService.deleteFile(file.directus_files_id.id).then(() => {
        setLoading(false);
        setReload((prev) => prev + 1);
      });
    });
  };
  return (
    <div
      id="authentication-modal"
      className="overflow-x-hidden fixed z-50 w-full md:inset-0 flex justify-center padding-custom-modal backgoundModalColor">
      <div className="ModalAddDoc bg-white rounded shadow max-h-[90vh] overflow-y-scroll">
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setDisplayModalAllDocument(false);
              setBlur(false);
              document.body.classList.remove('ModalOpenNoScrollBody');
            }}>
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="py-6 px-6 space-y-6 w-[40vw]">
          <h3 className="mb-4 text-xl font-medium text-gray-900">Tous les documents</h3>
          <div className="flex flex-col justify-center">
            <div className="w-full">
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                  {docList.length === 0 && (
                    <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6">
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path
                          fillRule="evenodd"
                          d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p className="text-base">Aucun document n'est disponible</p>
                    </li>
                  )}
                  {docList.map((doc, index) => (
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                      <div className="flex w-0 flex-1 items-center">
                        <svg
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true">
                          <path
                            fillRule="evenodd"
                            d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">{doc.directus_files_id.title}</span>
                          {doc.user_id === null ? (
                            <span className="flex-shrink-0 text-gray-400">Public</span>
                          ) : (
                            <span className="flex-shrink-0 text-gray-400">
                              Privé pour {doc.user_id.first_name + ' ' + doc.user_id.last_name}
                              {doc.user_id.group.slice(-1)[0] &&
                                ' (' + doc.user_id.group.slice(-1)[0].group_id.name + ')'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="ml-4 flex flex-shrink-0 space-x-4">
                        <button
                          type="button"
                          className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                          onClick={() => {
                            handleDowloadOne(doc);
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                          </svg>
                        </button>
                        {admin && (
                          <>
                            <span className="text-gray-200" aria-hidden="true">
                              |
                            </span>
                            <button
                              type="button"
                              className="rounded-md bg-white font-medium text-red-600 hover:text-red-800"
                              onClick={() => {
                                handleRemove(doc);
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
              onClick={() => {
                handleDownloadAll();
              }}>
              <svg
                className="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
              </svg>
              Tout télécharger
            </button>
            {admin && (
              <button
                type="button"
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2"
                onClick={() => {
                  setDisplayAddDoc(true);
                }}>
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <path d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6" />
                </svg>
                Ajouter un document
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalManageDocument;
