import React from 'react';

function ManagePersoData({ descriptionPersoData }) {
  const HandleModifPass = () => {
    window.location.replace('mailto:esdes.vieetudiante@univ-catholyon.fr');
  };

  return (
    <div className="ManagePersoData space-y-8">
      <p className="px-10">{descriptionPersoData}</p>
      <div className="flex justify-center">
        <div className="w-1/4">
          <button
            onClick={HandleModifPass}
            className="w-full bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white">
            Nous contacter
          </button>
        </div>
        {/*<div className='w-1/4'>*/}
        {/*    <button className='w-full bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white'>Demander la suppression</button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default ManagePersoData;
