import React, { useEffect } from 'react';
import ContactForm from '../ContactForm/ContactForm';

const ModalNewContact = ({
  setDisplay,
  setBlur,
  step2Id,
  setStep2Id,
  selectedOrga,
  step1Id,
  setContactList,
  contactList,
  convention,
  setEmailUsed,
  onContactAdded
}) => {
  const handleDisplay = () => {
    setDisplay(false);
    setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleDisplay();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  return (
    <div className="Modal">
      <div
        id="authentication-modal"
        tabIndex="-1"
        className="overflow-y-scroll overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex backgoundModalColor"
        aria-modal="true"
        role="dialog">
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDisplay}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6 px-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">Ajouter un contact</h3>
              <div className="space-y-6" action="#">
                <ContactForm
                  onContactAdded={onContactAdded}
                  step2Id={step2Id}
                  setStep2Id={setStep2Id}
                  selectedOrga={selectedOrga}
                  step1Id={step1Id}
                  setBlur={setBlur}
                  setDisplay={setDisplay}
                  setContactList={setContactList}
                  contactList={contactList}
                  convention={convention}
                  setEmailUsed={setEmailUsed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewContact;
