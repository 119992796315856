import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import UserDispoService from '../../oldService/userDispoService';

const ExportVieAsso = ({ childrenDispo, name }) => {
  const [csvData, setCsvData] = useState([]);
  const [responsibleOfChildList, setResponsibleOfChildList] = useState([]);
  const [volunteerOfChildList, setVolunteerOfChildList] = useState([]);
  const convertRole = (role: string) => {
    let result: string;
    role === 'president'
      ? (result = 'Président-e')
      : role === 'vice-president'
      ? (result = 'Vice-Président-e')
      : role === 'treasurer'
      ? (result = 'Trésorier')
      : (result = 'Secrétaire');
    return result;
  };
  useEffect(() => {
    const fetchData = async () => {
      const promises = childrenDispo.map(async (child) => {
        const res1 = await DispDirUsersService.getDirDispUser(child.id);
        const res2 = await UserDispoService.getAllUser(child.id);
        return { respEvals: res1.data.data, volunEvals: res2.data.data };
      });
      const results = await Promise.all(promises);

      const respEvals = results.flatMap((result) => result.respEvals);
      const volunEvals = results.flatMap((result) => result.volunEvals);

      setResponsibleOfChildList(respEvals);
      setVolunteerOfChildList(volunEvals);
    };

    fetchData();
  }, []);
  useEffect(() => {
    const list = [
      [
        'Nom',
        'Prénom',
        'email',
        'Téléphone',
        'Promotion',
        'Nom association',
        'Rôle',
        'Complément de rôle',
        'Date engagement',
        'id association'
      ]
    ];
    responsibleOfChildList.map((oneDirDisp) => {
      list.push([
        oneDirDisp.directus_users_id.first_name,
        oneDirDisp.directus_users_id.last_name,
        oneDirDisp.directus_users_id.email,
        oneDirDisp.directus_users_id.phones !== null
          ? "'" + oneDirDisp.directus_users_id.phones
          : oneDirDisp.directus_users_id.phones,
        oneDirDisp.directus_users_id.group.slice(-1)[0] &&
          oneDirDisp.directus_users_id.group.slice(-1)[0].group_id.name,
        oneDirDisp.dispositif_id.name,
        convertRole(oneDirDisp.role),
        oneDirDisp.role_details,
        new Date(oneDirDisp.date_created).toLocaleString(),
        oneDirDisp.dispositif_id.id
      ]);
    });
    volunteerOfChildList.map((oneUserDispo) => {
      list.push([
        oneUserDispo.user.last_name,
        oneUserDispo.user.first_name,
        oneUserDispo.user.email,
        oneUserDispo.user.phones !== null ? "'" + oneUserDispo.user.phones : oneUserDispo.user.phones,
        oneUserDispo.user.group.slice(-1)[0] && oneUserDispo.user.group.slice(-1)[0].group_id.name,
        oneUserDispo.dispositif.name,
        'Bénévole',
        null,
        new Date(oneUserDispo.date_created).toLocaleString(),
        oneUserDispo.dispositif.id
      ]);
    });
    setCsvData(
      list.sort((a: string[], b: string[]) => {
        return parseInt(a[8]) - parseInt(b[8]);
      })
    );
  }, [responsibleOfChildList, volunteerOfChildList]);
  return (
    <div>
      <CSVLink
        filename={'Export_' + name + '_listeassomembres_' + new Date().toLocaleDateString()}
        data={csvData}
        separator=";">
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div className="flex w-0 flex-1 items-center">
            <svg
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                clipRule="evenodd"
              />
            </svg>
            <div className="ml-4 flex min-w-0 flex-1 gap-2">
              <span className="truncate font-medium">{'Liste des associations et de leurs membres'}</span>
              <span className="flex-shrink-0 text-gray-400">CSV</span>
            </div>
          </div>
          <div className="ml-4 flex flex-shrink-0 space-x-4">
            <button type="button" className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
              </svg>
            </button>
          </div>
        </li>
      </CSVLink>
    </div>
  );
};

export default ExportVieAsso;
