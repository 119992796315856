export const stockData = [
  {
    id: 1,
    name: 'USER Elitoth',
    lastName: 'USER',
    firstName: 'Elioth',
    emailPrimary: 'test@gmail.com',
    emailSecondary: 'cenestpasunmail@akanema.fr',
    phone: '06 07 08 09 10',
    step: 2,
    role: 'user',
    dispositifs: [],
    team: ['Asso', 'test']
  },
  {
    id: 2,
    name: 'BUREAU Valentin',
    lastName: 'BUREAU',
    firstName: 'Valentin',
    emailPrimary: 'test@gmail.com',
    emailSecondary: 'cenestpasunmail@akanema.fr',
    phone: '06 07 08 09 10',
    step: 1,
    role: 'user',
    dispositifs: [
      {
        id: 1,
        name: 'test',
        description: 'blabla'
      },
      {
        id: 2,
        name: 'test2',
        description: 'blabla2'
      }
    ],
    team: ['Test']
  },
  {
    id: 3,
    name: 'À Changer',
    lastName: 'ADMIN',
    firstName: 'Eliottttttttth',
    emailPrimary: 'test@gmail.com',
    emailSecondary: 'cenestpasunmail@akanema.fr',
    phone: '06 07 08 09 10',
    step: 1,
    role: 'admin',
    dispositifs: [
      {
        id: 1,
        name: 'test',
        description: 'blabla'
      },
      {
        id: 2,
        name: 'test2',
        description: 'blabla2'
      }
    ],
    team: ['Test']
  }
];

export const stockDispositifs = [
  {
    id: 1,
    name: 'Dispositif 1',
    status: 'En cours'
  },
  {
    id: 2,
    name: 'Dispositif 2',
    status: 'Attente'
  },
  {
    id: 3,
    name: 'Dispositif 3',
    status: 'Archivé'
  }
];

export const stockEngagements = [
  {
    id: 1,
    name: 'Engagement 1',
    status: 'Attente',
    respo: 'Valentin WOEHREL',
    tag: ['Solicity', 'Lyon', 'France'],
    date: '05/07/2022'
  },
  {
    id: 2,
    name: 'Engagement 2',
    status: 'Archivé',
    respo: 'Valentin WOEHREL',
    tag: ['Solicity'],
    date: '05/08/2022'
  },
  {
    id: 3,
    name: 'Engagement 3',
    status: 'En cours',
    respo: 'Valentin WOEHREL',
    tag: ['Solicity', 'France'],
    date: '06/07/2022'
  },
  {
    id: 4,
    name: 'Engagement 4',
    status: 'Archivé',
    respo: 'Valentin WOEHREL',
    tag: ['Solicity', 'Lyon', 'France', 'Test'],
    date: '01/12/2023'
  },
  {
    id: 5,
    name: 'Engagement 5',
    status: 'En cours',
    respo: 'Valentin WOEHREL',
    tag: ['Lyon', 'France'],
    date: '13/04/2024'
  }
];

export const responsibleList = [
  {
    id: 1,
    name: 'Jean TEST',
    mail: 'test@gmail.com'
  },
  {
    id: 2,
    name: 'Elioth DUMAS',
    mail: 'test@akanema.fr'
  }
];

export const tagList = [
  {
    id: 1,
    name: 'Solicity'
  },
  {
    id: 2,
    name: '2021-2022'
  },
  {
    id: 3,
    name: 'BIB'
  },
  {
    id: 4,
    name: 'Lyon'
  },
  {
    id: 5,
    name: 'France'
  }
];

export const docList = [
  'Document 1',
  'Document 2',
  'Document 3',
  'Document 4',
  'Document 5',
  'Document 6',
  'Document 7',
  'Document 8'
];

export const studList = [
  {
    id: 1,
    name: 'Elioth DUMAS',
    firstName: 'Elioth',
    lastName: 'DUMAS',
    step: 3,
    team: '4A 21-22',
    date: '07/07/2022',
    action: true
  },
  {
    id: 2,
    name: 'Valentin WOEHREL',
    firstName: 'Valentin',
    lastName: 'WOEHREL',
    step: 1,
    team: '3A 21-22',
    date: '13/04/2022',
    action: true
  },
  {
    id: 3,
    name: 'Jean DUMAS',
    firstName: 'Elioth',
    lastName: 'DUMAS',
    step: 6,
    team: '4A 21-22',
    date: '07/07/2022',
    action: false
  },
  {
    id: 4,
    name: 'Valentin HENIZ',
    firstName: 'Valentin',
    lastName: 'WOEHREL',
    step: 2,
    team: '3A 21-22',
    date: '13/04/2022',
    action: true
  },
  {
    id: 5,
    name: ',ke,kz DUMAS',
    firstName: 'Elioth',
    lastName: 'DUMAS',
    step: 2,
    team: '4A 21-22',
    date: '07/07/2022',
    action: false
  },
  {
    id: 6,
    name: 'Hello WORLD',
    firstName: 'Valentin',
    lastName: 'WOEHREL',
    step: 1,
    team: '3A 21-22',
    date: '13/04/2022',
    action: true
  },
  {
    id: 7,
    name: 'Abricot ABRICOTIER',
    firstName: 'Abricot',
    lastName: 'ABRICOTIER',
    step: 3,
    team: '4A 21-22',
    date: '07/07/2022',
    action: true
  }
];

/* VERSION 2 */

export const mes_dispositifs = [
  {
    id: 1,
    name: 'Dispositif 1',
    description: 'Description du dispositif 1',
    manager: ['Valentin'],
    status: 'En cours',
    action: true,
    tag: ['tag1', 'test'],
    nbrHours: 150,
    nbrContri: 42
  },
  {
    id: 2,
    name: 'Vie Associative',
    description: "Ici c'est la vie Asso !",
    manager: ['Valentin', 'Elioth'],
    status: 'En cours',
    tag: ['JeSuisUnTag', 'tag2'],
    nbrHours: 175,
    nbrContri: 20
  },
  {
    id: 3,
    name: 'Dispositif 3',
    description: 'Description du dispositif 3',
    manager: ['Elioth'],
    status: 'Archivé',
    tag: ['ok', 'hehehe'],
    nbrHours: 200,
    nbrContri: 35
  },
  {
    id: 4,
    name: 'Dispositif 4',
    description: 'Description du dispositif 4',
    manager: ['Moi'],
    status: 'Attente',
    action: true,
    tag: ['Asso', 'France'],
    nbrHours: 50,
    nbrContri: 3
  },
  {
    id: 5,
    name: 'Dispositif 5',
    description: 'Description du dispositif 5',
    manager: ['Abricooooot'],
    status: 'Attente',
    action: true,
    tag: ['TAG', 'France'],
    nbrHours: 150,
    nbrContri: 30
  },
  {
    id: 6,
    name: 'Dispositif 6',
    description: 'Description du dispositif 6',
    manager: ['Abricooooot', 'Elioth', 'Valentin'],
    status: 'Archivé',
    tag: ['Hello'],
    nbrHours: 150,
    nbrContri: 23
  },
  {
    id: 7,
    name: 'Dispositif 7',
    description: 'Description du dispositif 7',
    manager: ['Elioth'],
    status: 'Attente',
    tag: ['tag1', 'World'],
    nbrHours: 200,
    nbrContri: 17
  }
];

export const mes_évènements = [
  {
    id: 1,
    name: 'Forum Asso',
    description: "C'est le forum asso !",
    dateStart: '01/01/2020',
    dateEnd: '05/01/2020',
    status: 'En cours'
  },
  {
    id: 2,
    name: 'WEI',
    description: "Week-end d'intégration.",
    dateStart: '06/01/2020',
    dateEnd: '07/01/2020',
    status: 'En cours'
  },
  {
    id: 3,
    name: 'Tous responsables !',
    description: 'On est pas tous responsables ici !?!?!',
    dateStart: '09/01/2020',
    dateEnd: '10/01/2020',
    status: 'En cours'
  }
  // {
  //     "id": 4,
  //     "name": "Évènement 4",
  //     "description": "Description du dispositif 4",
  //     dateStart: "01/01/2020",
  //     dateEnd: "05/01/2020",
  //     status: "Attente"
  // },
  // {
  //     "id": 5,
  //     "name": "Évènement 5",
  //     "description": "Description du dispositif 5",
  //     dateStart: "06/01/2020",
  //     dateEnd: "07/01/2020",
  //     status: "Archivé"
  // },
  // {
  //     "id": 6,
  //     "name": "Évènement 6",
  //     "description": "Description du dispositif 6",
  //     dateStart: "09/01/2020",
  //     dateEnd: "10/01/2020",
  //     status: "En cours"
  // }
];

export const mes_engagements = [
  {
    id: 1,
    name: 'Engagement 1',
    description: 'Description du dispositif 1',
    status: 'En cours'
  },
  {
    id: 2,
    name: 'Engagement 2',
    description: "Ici c'est le forum !",
    status: 'En cours'
  },
  {
    id: 3,
    name: 'Engagement 3',
    description: 'Description du dispositif 3',
    status: 'En cours'
  },
  {
    id: 4,
    name: 'Engagement 4',
    description: 'Description du dispositif 4',
    status: 'Archivé'
  },
  {
    id: 5,
    name: 'Engagement 5',
    description: 'Description du dispositif 5',
    status: 'Attente'
  },
  {
    id: 6,
    name: 'Engagement 6',
    description: 'Description du dispositif 6',
    status: 'Archivé'
  },
  {
    id: 7,
    name: 'Engagement 7',
    description: 'Description du dispositif 7',
    status: 'Attente'
  }
];

// VERSION FINALE

export const doc_description = [
  {
    name: 'Statuts',
    description:
      '«Il s’agit en quelque sorte d’un contrat qui engage les personnes signataires les unes envers les autres. (Les règles de fonctionnement de l’association - Les conditions d’adhésion et de sortie de l’organisation associative - Les obligations de chacun).»'
  },
  {
    name: 'Procès-Verbal',
    description:
      '«Le procès-verbal fournit des comptes rendus écrits des délibérations d’une association et permet d’établir si les décisions ont été prises conformément aux règles ou non.»'
  },
  {
    name: 'Récépissé',
    description:
      '«Le récépissé certifie la déclaration de l’association à la préfecture. Le récépissé est un document important qui doit être conservé par l’association. Le récépissé sera en effet précieux pour toutes les démarches que l’association sera amenée à effectuer.»'
  },
  {
    name: 'Budget prévisionnel',
    description:
      '«Le budget prévisionnel est un document incontournable et un outil indispensable pour anticiper les dépenses et les recettes d’une association et pour obtenir des financements.»'
  },
  {
    name: 'Planning prévisionnel',
    description:
      '«Planifier ses projets au début de l’année facilite l’organisation du travail ainsi que l’établissement des budgets.»'
  }
];

export const url = [
  {
    name: 'home',
    adress: '/home',
    display: 'Accueil'
  },
  {
    name: 'account',
    adress: '/account',
    display: 'Mon Compte'
  },
  {
    name: 'commitments',
    adress: '/user/commitments/0',
    display: 'Engagements'
  },
  {
    name: 'plans',
    adress: '/user/plans/0',
    display: 'Dispositifs'
  },
  {
    name: 'events',
    adress: '/user/events/0',
    display: 'Évènements'
  },
  {
    name: 'followed',
    adress: '/admin/followed',
    display: 'Annuaire'
  }
];

export const stepsNewPlan = [
  {
    title: 'Administratif',
    description:
      'Les utilisateurs peuvent ici déposer les documents officiels relatifs à la création de leur association : Statuts, récépissé et Procès-verbal. Une validation est recommandée afin de s’assurer de la conformité des documents déposés.',
    shortDescription: 'Déposez vos documents administratifs'
  },
  {
    title: 'Identité asso',
    description:
      'Les utilisateurs créent leur association sur la plateforme en indiquant différentes informations spécifiques: le nom, la description, le logo, le nombre d’heures d’engagements nécessaire à l’intégration de leur association.',
    shortDescription: 'Renseignez la fiche descriptive de votre association'
  },
  {
    title: 'Membres bureau',
    description:
      'Le responsable de l’association ajoute les membres du bureau qui participe avec lui à la vie de l’association. Les membres à ajouter sont préenregistrés sur la plateforme.',
    shortDescription: 'Renseignez les responsables de votre association'
  },
  {
    title: 'Bénévoles',
    description:
      'Les bénévoles peuvent postuler directement depuis la plateforme mais si une liste est déjà établie, les responsables de dispositifs peuvent ici ajouter les premiers membres. Les membres à ajouter sont préenregistrés sur la plateforme.',
    shortDescription: 'Déclarez vos bénévoles'
  },
  {
    title: 'Évaluations S1',
    description:
      'A la fin du semestre, les responsables seront en mesure d’évaluer les étudiants sur leur engagement. Ils pourront ici renseigner différents items afin de donner un feedback sur le semestre et la participation.',
    shortDescription: 'Evaluez les membres de votre association pour le S1'
  },
  {
    title: 'Soutenance S1',
    description:
      'Les responsables déposeront le support et les éléments relatifs à la soutenance de fin de semestre afin de les porter à la connaissance des jurys.',
    shortDescription: 'Déposez votre présentation de soutenance'
  },
  {
    title: 'Évaluations S2',
    description:
      'A la fin du semestre, les responsables seront en mesure d’évaluer les étudiants sur leur engagement. Ils pourront ici renseigner différents items afin de donner un feedback sur le semestre et la participation.',
    shortDescription: 'Evaluez les membres de votre association pour le S2'
  },
  {
    title: 'Soutenance S2',
    description:
      'Les responsables déposeront le support et les éléments relatifs à la soutenance de fin de semestre afin de les porter à la connaissance des jurys.',
    shortDescription: 'Déposez votre présentation de soutenance'
  },
  {
    title: 'Bilan',
    description:
      'En fin d’exercice, le bureau remettra un rapport moral et un rapport financier pour l’exercice passé.',
    shortDescription: "Déposez vos documents de bilan de l'année"
  }
];

export const stepsNewPlanModel3 = [
  {
    title: "Projet d'engagement",
    description:
      'Dans ce projet d’engagement, vous décrirez vos motivations, votre projet de bénévolat ainsi que votre autocritique. Ce formulaire est à compléter même si vous n’avez pas encore trouvé de mission. Il vous permettra par la suite de voir comment votre projet a évolué, muri une fois votre mission réalisée.'
  },
  {
    title: "Organisation d'accueil",
    description:
      'Il s’agit de la « fiche d’identité » de votre association. Vous devrez renseigner des informations indispensables et bien veiller qu’il n’y ait pas d’erreurs, ce qui entrainerait le refus de votre convention. Vous pouvez demander à votre association de vous aider. Vous avez besoin de connaitre sa raison sociale (le nom de l’association, ex : « Les Restaurants du cœur »  et non Les Restos du Cœur), son numéro de SIRET, RNA (sauf pour les associations à l’étranger) l’adresse, le nom  et coordonnées de votre tuteur etc…'
  },
  {
    title: 'Descriptif mission',
    description:
      'Vous décrirez les différentes tâches qui vous seront confiées par l’association lors de votre mission. Soyez précis, toute description où il n’est pas mis en évidence que vous serez en contact direct avec des personnes fragiles (bénéficiaires) pour minimum 60% de votre temps, sera refusée.'
  },
  {
    title: 'Convention',
    description:
      'Une fois les 3 étapes réalisées avec succès et validées par nos soins (avec notre signature), vous devez signer votre convention et veiller que votre organisme d’accueil la signe également. Sans les 3 signatures, votre mission ne pourra être validée.'
  },
  {
    title: 'Signature',
    description: ''
  },
  {
    title: 'Évaluation',
    description:
      '10 jours avant la fin de votre mission, votre tuteur recevra un lien pour vous évaluer. Il est conseillé de valider cette étape avec lui afin d’évaluer ce que votre investissement a apporté à son association et à vous-même.'
  }
];

export const stepsNewPlanModel4 = [
  {
    title: "Projet d'engagement",
    description:
      'Dans ce projet d’engagement, vous décrirez vos motivations, votre projet de bénévolat ainsi que votre autocritique. Ce formulaire est à compléter même si vous n’avez pas encore trouvé de mission. Il vous permettra par la suite de voir comment votre projet a évolué, muri une fois votre mission réalisée.',
    shortDescription: 'Décrivez votre projet'
  },
  {
    title: 'Convention',
    description:
      'Une fois les 3 étapes réalisées avec succès et validées par nos soins (avec notre signature), vous devez signer votre convention et veiller que votre organisme d’accueil la signe également. Sans les 3 signatures, votre mission ne pourra être validée.',
    shortDescription: 'Demandez votre convention'
  },
  {
    title: 'Signature',
    description: '',
    shortDescription: 'Signez votre convention'
  },
  {
    title: 'Évaluation',
    description:
      '10 jours avant la fin de votre mission, votre tuteur recevra un lien pour vous évaluer. Il est conseillé de valider cette étape avec lui afin d’évaluer ce que votre investissement a apporté à son association et à vous-même.',
    shortDescription: 'Votre tuteur complète votre évaluation'
  }
];

export const evaluations_member = [
  {
    id: 1,
    title: 'Evaluation 1',
    description: "Description de l'évaluation 1"
  },
  {
    id: 2,
    title: 'Evaluation 2',
    description: "Description de l'évaluation 2"
  },
  {
    id: 3,
    title: 'Evaluation 3',
    description: "Description de l'évaluation 3"
  },
  {
    id: 4,
    title: 'Evaluation 4',
    description: "Description de l'évaluation 4"
  },
  {
    id: 5,
    title: 'Evaluation 5',
    description: "Description de l'évaluation 5"
  }
];

export const evaluations_asso_volunteer = [
  {
    id: 1,
    title: 'Engagement',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 2,
    title: 'Présence',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 3,
    title: 'Assiduité et Bonne volonté',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 4,
    title: 'Capacité relationnelle 1',
    description: 'Capacité à travailler en interne',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 5,
    title: 'Capacité relationnelle 2',
    description: "Capacité à représenter l'association en externe",
    type: 'starRating',
    mandatory: true
  },
  {
    id: 6,
    title: 'Capacité émotionnelle et opérationnelle 1',
    description: "Autonomie et initiatives pertinentes dans l'action",
    type: 'starRating',
    mandatory: true
  },
  {
    id: 7,
    title: 'Capacité émotionnelle et opérationnelle 2',
    description: 'Adaptation et polyvalence',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 8,
    title: 'Capacité émotionnelle et opérationnelle 3',
    description: 'Production de résultats et impact',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 9,
    title: 'Observations',
    description: 'Observations...',
    type: 'textField',
    mandatory: false
  }
];

export const evaluations_asso_president = [
  {
    id: 1,
    title: 'Engagement',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 2,
    title: 'Présence',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 3,
    title: 'Assiduité et Bonne volonté',
    description: '',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 4,
    title: 'Capacité managériale 1',
    description: 'Capacité à travailler en équipe, à mobiliser',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 5,
    title: 'Capacité managériale 2',
    description: "Fait preuve d'empathie et manifeste un respect de l'autre",
    type: 'starRating',
    mandatory: true
  },
  {
    id: 6,
    title: 'Capacité managériale 3',
    description:
      "Capacité à représenter l'association, s'exprime de manière claire et efficace à l'oral comme à l'écrit",
    type: 'starRating',
    mandatory: true
  },
  {
    id: 7,
    title: 'Bilan / Résultat et impact 1',
    description: 'Initiatives / créativité dans les projets',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 8,
    title: 'Bilan / Résultat et impact 2',
    description: 'Production et évaluation de résultats avec impact dans un délai imparti',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 9,
    title: 'Bilan / Résultat et impact 3',
    description: 'Analyse, prise de recul et exercice de son esprit critique',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 10,
    title: 'Observations',
    description: 'Observations...',
    type: 'textField',
    mandatory: false
  }
];

export const evaluations_punctual = [
  {
    id: 1,
    title: 'Capacité méthodologique 1',
    description: "Capacité à cadrer avec vous l'action à mener",
    en_title: 'Methodological skills 1',
    en_description: 'Ability to work with you to define the action to be taken',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 2,
    title: 'Capacité méthodologique 2',
    description: 'Capacité à analyser une situation',
    en_title: 'Methodological skills 2',
    en_description: 'Ability to analyse a situation',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 3,
    title: 'Capacité méthodologique 3',
    description: 'Capacité à réaliser la mission',
    en_title: 'Methodological skills 3',
    en_description: 'Ability to carry out the assignment',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 4,
    title: 'Capacité relationnelles et savoirs-êtres 1',
    description: 'Capacité à travailler en équipe de manière constructive',
    en_title: 'Relational and know-how skills 1',
    en_description: 'Ability to work constructively in a team',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 5,
    title: 'Capacité relationnelles et savoirs-êtres 2',
    description: 'Bonne volonté / Assiduité',
    en_title: 'Relational and know-how skills 2',
    en_description: 'Good will / Attendance',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 6,
    title: 'Capacité relationnelles et savoirs-êtres 3',
    description: 'Prise de recul et exercice de son esprit critique',
    en_title: 'Relational and know-how skills 3',
    en_description: 'Taking a step back and exercising your critical faculties',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 7,
    title: 'Capacité relationnelles et savoirs-êtres 4',
    description: "Attention à l'autre et capacité à maitriser ses relations avec les bénéficiaires",
    en_title: 'Relational and know-how skills 4',
    en_description: 'Attentiveness to others and the ability to manage relationships with beneficiaries',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 8,
    title: 'Capacités opérationnelles 1',
    description: "Autonomie et initiatives pertinentes dans l'action",
    en_title: 'Operational skills 1',
    en_description: 'Autonomy and initiative in action',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 9,
    title: 'Capacités opérationnelles 2',
    description: 'Adaptation et résistance au stress',
    en_title: 'Operational skills 2',
    en_description: 'Adapting to and withstanding stress',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 10,
    title: 'Capacités opérationnelles 3',
    description: 'Atteinte des objectifs',
    en_title: 'Operational skills 3',
    en_description: 'Achieving objectives',
    type: 'starRating',
    mandatory: true
  },
  {
    id: 11,
    title: "Compétences acquises par l'étudiant",
    description: 'Compétences...',
    en_title: 'Skills acquired by the student',
    en_description: 'Skills...',
    type: 'textField',
    mandatory: false
  },
  {
    id: 12,
    title: 'Observations',
    description: 'Observations...',
    en_title: 'Comments',
    en_description: 'Comments...',
    type: 'textField',
    mandatory: false
  },
  {
    id: 13,
    title: "Souhaitez-vous accueillir un étudiant l'année prochaine ?",
    en_title: 'Would you like to welcome a student next year ?',
    answer: ['Oui', 'Non'],
    en_answer: ['Yes', 'No'],
    type: 'checkBox',
    mandatory: true
  }
];

export const pdf_mission_solicity = {
  promo: null,
  'Nom utilisateur': null,
  'Prénom utilisateur': null,
  'Mon groupe': null,
  date_création: null,
  'Motivation 1': null,
  'Motivation 2': null,
  Etape_projet: null,
  Description_projet: null,
  Description_projet2: null,
  Description_projet3: null,
  Structure_accueil1: null,
  Structure_accueil2: null,
  Structure_accueil3: null,
  Bénéficiares: null,
  Utilité: null,
  Aide: null,
  Ressources1: null,
  Ressources2: null,
  Ressources3: null,
  Compétences1: null,
  Compétences2: null,
  'Auto-évaluation': null,
  pitch: null
};
