import React from 'react';
// import { useState } from "react";
import Dropdown from '../Dropdown/Dropdown';
import AdressBar from '../AdressBar/AdressBar';
// import NavbarMobile from "../NavbarMobile/NavbarMobile";
import DropDownHelp from '../DropDownHelp/DropDownHelp';

import './NavbarTop.css';

function NavbarTop(props) {
  return (
    <nav className={`NavbarTop relative z-50 bg-white border-0 ${props.blur === true ? 'blur-sm' : 'blur-none'}`}>
      <div className="flex justify-between items-center px-6 py-6 h-20">
        <div>
          <AdressBar urls={props.urls} />
        </div>

        {/* THAT WAS FOR MOBILE VERSION */}

        {/* <div className="absolute left-0 px-10 flex items-center xl:invisible">
                <button onClick={() => {setShowMenu(!showMenu)}} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div> */}
        {/* <h1 className="absolute left-1/2 transform -translate-x-1/2 font-bold xl:invisible">AKANEMA</h1> */}

        <nav className="flex items-center space-x-10 visible">
          <DropDownHelp />
          <Dropdown lastName={props.user?.last_name} firstName={props.user?.first_name} />
        </nav>

        {/* {showMenu && (
                <NavbarMobile />
            )} */}
      </div>
    </nav>
  );
}

export default NavbarTop;
