import { useEffect } from 'react';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/pending';

const GetAllPending = (dispoId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      dispoId +
      '&fields=id,dispositif_id,user_id,user_id.id,user_id.first_name,user_id.last_name,date_created,user_id.group.group_id.name',
    { headers: authHeader() }
  );
};
const GetUserPending = (dispoId, userId) => {
  return axios.get(API_URL + '?filter[dispositif_id][_eq]=' + dispoId + '&filter[user_id][_eq]=' + userId, {
    headers: authHeader()
  });
};

const DeletePending = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const PostPending = (dispoId, userId) => {
  return axios.post(
    API_URL,
    {
      dispositif_id: dispoId,
      user_id: userId
    },
    { headers: authHeader() }
  );
};

const FormatGetAllPending = (pendingList, setPendingList, dispoId) => {
  useEffect(() => {
    const list = [];

    const MakePendingList = async () => {
      const data = await PendingService.GetAllPending(dispoId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setPendingList(list);
    };
    MakePendingList();
  }, []);
  return pendingList;
};

const FormatGetUserPendingTrueFalse = (pending, setPending, dispoId, userId) => {
  useEffect(() => {
    const MakeList = async () => {
      const data = await PendingService.GetUserPending(dispoId, userId);
      if (data.data.data[0]) setPending(true);
      else setPending(false);
    };
    MakeList();
  }, []);
  return pending;
};

const PendingService = {
  GetAllPending,
  GetUserPending,
  PostPending,
  DeletePending,
  FormatGetAllPending,
  FormatGetUserPendingTrueFalse
};

export default PendingService;
