import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CardList from '../../../oldComponents/CardList/CardList';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import Searchbar from '../../../oldComponents/Searchbar/Searchbar';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import UserDispoService from '../../../oldService/userDispoService';
import { AuthContext } from '../../../AuthProvider';
import './Commitments.css';

const Commitments = () => {
  const params = useParams();
  const [searchField, setSearchField] = useState('');
  const [filteredComms, setFilteredComms] = useState([]);
  const [filter, setFilter] = useState(parseInt(params.subFilter, 10));
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const newFilteredComms = data.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchField);
    });
    setFilteredComms(newFilteredComms);
  }, [searchField]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await UserDispoService.getUserEnga(user.id);
      setData(data.data.data.map((dispo) => dispo.dispositif));
      setFilteredComms(
        data.data.data
          .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
          .map((dispo) => dispo.dispositif)
      );
    };
    fetchData();
  }, []);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="Commitments flex max-w-[100vw] min-h-screen">
      <NavbarLeft sub={2} user={user} />
      <div className="flex flex-col h-full w-full page-Container">
        <NavbarTop urls={['home', 'commitments']} user={user} />
        <div className="p-10 space-y-8">
          <h1 className="font-bold text-3xl text-blue-500">LES ENGAGEMENTS AUXQUELS JE PARTICIPE</h1>
          <div className="px-3 py-4 space-y-6 bg-gray-50 rounded">
            <div className="flex flex-col items-center space-y-6 px-5 xl:flex-row xl:space-y-0 xl:justify-between">
              <div className="w-1/3">
                <Searchbar placeholder={'Rechercher par nom...'} onChangeHandler={onSearchChange} />
              </div>
              <div className="flex space-x-2 xl:space-x-1">
                <button
                  className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${
                    filter === 0 ? 'bg-blue-500 text-white' : 'bg-gray-300'
                  }`}
                  onClick={() => {
                    setFilter(0);
                  }}>
                  Tous
                </button>
                {/* <button className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${filter === 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`} onClick={() => { setFilter(1) }}>En cours</button>
                                    <button className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${filter === 3 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`} onClick={() => { setFilter(3) }}>Archivés</button> */}
              </div>
            </div>
            <CardList data={filteredComms} role={user.role.name} type={'commitments'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commitments;
