import axios from 'axios';
import { PROD } from './authHeader';

const BACK_URL = process.env.REACT_APP_BACK_URL;

const GetSiret = (setData, siretId) => {
  var config = {
    method: 'post',
    url: BACK_URL + '/siret',
    params: { siretId: siretId }
  };

  return axios(config).then((res) => {
    setData(res.data);
  });
};

export const SirenServices = {
  GetSiret
};
