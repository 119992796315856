import React from 'react';
import PendingService from '../../oldService/pendingService';
import UserDispoService from '../../oldService/userDispoService';
import { PROD } from '../../oldService/authHeader';

function TablePending({ dispotype, setChangeInPage, stepList, ...props }) {
  //PROD /PREPROD
  const templateIdPunctual = PROD ? 321 : 319;
  const templateIdPunctualV2 = PROD ? 444 : 384;

  const HandleDecline = (pending) => {
    PendingService.DeletePending(pending.id);
    props.setPendingList(props.data.filter((item) => item !== pending));
  };

  const HandleAccept = async (pending) => {
    await PendingService.DeletePending(pending.id);
    if (props.dispo.template === 291) {
      await UserDispoService.PostUserDispo(props.data.id, props.user.id);
    } else if (props.dispo.template === 292) {
      let stepId = 0;
      if (props.dispo.parent === null) {
        props.dispo.step.find((item) => item.step_id.name === 'Administratif')
          ? (stepId = props.dispo.step.find((item) => item.step_id.name === 'Administratif'))
          : (stepId = props.dispo.step.find((item) => item.step_id.name === 'Identité asso'));
        await UserDispoService.PostUserDispo(props.dispo.id, pending.user_id.id, stepId.step_id.id);
      } else {
        await UserDispoService.PostUserDispo(props.dispo.id, pending.user_id.id, null);
      }
    } else if ([templateIdPunctual, templateIdPunctualV2].includes(props.dispo.template)) {
      const list = [];
      stepList.map((item) => {
        list.push(item.order);
        return item;
      });
      const min = Math.min(...list);
      const stepId = stepList.find((item) => item.order === min).step_id;

      await UserDispoService.PostUserDispo(props.dispo.id, pending.user_id.id, stepId);
    }
    props.setPendingList(props.data.filter((item) => item !== pending));
    if (dispotype === 'asso') {
      setChangeInPage((prev) => prev + 1);
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <h2 className="font-bold text-xl pb-8 text-blue-700/90">{props.title}</h2>
          {props.data[0] === undefined ? (
            <div className="flex justify-center items-center">Aucun résultat pour le moment.</div>
          ) : (
            <div className="max-h-96 overflow-y-scroll md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 shadow ring-1 ring-black ring-opacity-5">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                      Nom
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Prénom
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Groupe
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Date demande
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item) => {
                    return (
                      <tr key={item.id} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap py-4 pl-4 text-center pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {item.user_id && item.user_id.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {item.user_id && item.user_id.first_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {item.user_id && item.user_id.group.slice(-1)[0]?.group_id.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {item.date_created && new Date(item.date_created).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 font-semibold flex justify-center space-x-6">
                          <button
                            className="flex flex-col items-center hover:text-blue-500"
                            onClick={() => {
                              HandleAccept(item);
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            Accepter
                          </button>
                          <button
                            className="flex flex-col items-center hover:text-red-600"
                            onClick={() => {
                              HandleDecline(item);
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            Refuser
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TablePending;
