import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../../../oldService/authService';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import PeopleList from '../../../oldComponents/PeopleList/PeopleList';
import ContentElem from '../../../oldComponents/ContentElem/ContentElem';
import TableEvents from '../../../oldComponents/TableEvents/TableEvents';
import TablePending from '../../../oldComponents/TablePending/TablePending';
import ModalModifPlan from '../../../oldComponents/ModalModifPlan/ModalModifPlan';
import UserCreateEvent from '../../../oldComponents/UserCreateEvent/UserCreateEvent';
import DispoService from '../../../oldService/dispService';
import UserDispoService from '../../../oldService/userDispoService';
import EventService from '../../../oldService/eventService';
import PendingService from '../../../oldService/pendingService';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import ModalAddDoc from '../../../oldComponents/ModalAddDoc/ModalAddDoc';
import DispDirFileService from '../../../oldService/dispDirFile';

import './PlansDetails.css';
import { useNavigate } from 'react-router-dom';
import NoteContent from '../../../oldComponents/NoteContent/NoteContent';
import { AuthContext } from '../../../AuthProvider';

const PlansDetails = () => {
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);

  const [blur, setBlur] = useState(false);
  const [displayModif, setDisplayModif] = useState(false);
  const [displayAddDoc, setDisplayAddDoc] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const responsibleList = DispoService.FormatRespDispo(id);
  const tagList = DispoService.FormatTagDispo(id);

  let userDispoId;
  let myUserDispo;
  const [userList, setUserList] = useState([]);
  const [dirUser, setDirUser] = useState([]);
  const [dispo, setDispo] = useState([]);
  const [events, setEvents] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [admin, setAdmin] = useState();
  const [inscription, setInscription] = useState();
  const [pending, setPending] = useState();
  const [fileList, setFileList] = useState([]);
  const [note, setNote] = useState('');

  if (token) {
    UserDispoService.FormatGetAllUser(userList, setUserList, id);
    UserDispoService.FormatUserEngaAll(dirUser, setDirUser);
    DispoService.FormatGetOneDispo(dispo, setDispo, id);
    EventService.FormatGetAllEventTemplate(events, setEvents, id);
    PendingService.FormatGetAllPending(pendingList, setPendingList, id);
    DispDirUsersService.FormatGetDirectusUserDispo(admin, setAdmin, id, user.id);
    UserDispoService.FormatGetUserDispoDispo(inscription, setInscription, id, user.id);
    PendingService.FormatGetUserPendingTrueFalse(pending, setPending, id, user.id);
    DispDirFileService.FormatGetOneDispFile(fileList, setFileList, id);
    myUserDispo = UserDispoService.FormatOneUserDispoNote(id);
    userDispoId = UserDispoService.FormatOneUserDispoId(id);
  }

  useEffect(() => {
    setNote(myUserDispo);
  }, [myUserDispo]);

  const handleDisplayModif = () => {
    setDisplayModif(true);
    setBlur(true);
  };

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <div className="Asso flex w-screen min-h-screen">
        {user != null && (
          <>
            <NavbarLeft sub={1} blur={blur} user={user} />
            <div className="flex flex-col h-full w-full">
              <NavbarTop blur={blur} urls={['home', 'plans']} user={user} />
              <div className="p-10 space-y-8">
                <div className="flex justify-between">
                  <div>
                    <h1 className={`font-bold text-3xl text-blue-500 ${blur ? 'blur-sm' : 'blur-none'}`}>
                      {dispo.name}
                    </h1>
                    <p className={`text-sm pt-3 ${blur ? 'blur-sm' : 'blur-none'}`}>
                      <span className="text-gray-500">Disponible jusqu'en: </span>
                      <span>{dispo.enddate}</span>
                    </p>
                  </div>
                  <div>
                    <button
                      className={`bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6 ${
                        blur ? 'blur-sm' : 'blur-none'
                      }`}
                      onClick={handleDisplayModif}>
                      Modifier Dispositif
                    </button>
                  </div>
                </div>
                <div className="space-y-14 py-4 px-5">
                  <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                    <ContentElem
                      image={dispo.picture}
                      list={responsibleList}
                      tag={tagList}
                      doc={true}
                      docList={fileList}
                      admin={admin}
                      inscription={inscription}
                      setInscription={setInscription}
                      user={user}
                      data={dispo}
                      pending={pending}
                      setBlur={setBlur}
                      setDisplayAddDoc={setDisplayAddDoc}
                    />
                  </div>
                  {(inscription || admin) && (
                    <div>
                      <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                        <h2 className="font-bold text-xl pb-5 text-blue-700/90">Demandes en attente</h2>
                        <TablePending
                          data={pendingList}
                          setPendingList={setPendingList}
                          dispo={dispo}
                          setUserList={setUserList}
                          userList={userList}
                        />
                      </div>
                      <div className="p-5 bg-gray-50 rounded ">
                        <h2
                          className={`font-bold text-xl pb-5 text-blue-700/90 ${
                            blur === true ? 'blur-sm' : 'blur-none'
                          }`}>
                          Évènements associés
                        </h2>
                        <div className="space-y-5">
                          {events[0] && (
                            <div className={`${blur === true ? 'blur-sm' : 'blur-none'}`}>
                              <TableEvents data={events} />
                            </div>
                          )}
                          <UserCreateEvent setBlur={setBlur} blur={blur} />
                          {displayModif && (
                            <ModalModifPlan id={id} setDisplay={setDisplayModif} setBlur={setBlur} data={dispo} />
                          )}
                          {displayAddDoc && (
                            <ModalAddDoc
                              userList={userList}
                              setBlur={setBlur}
                              setDisplayAddDoc={setDisplayAddDoc}
                              dispoId={id}
                            />
                          )}
                        </div>
                      </div>
                      <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                        <PeopleList title={'Liste des participants'} data={userList} dirUser={dirUser} plans={true} />
                      </div>
                      <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                        <NoteContent note={note} setNote={setNote} id={userDispoId} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default PlansDetails;
