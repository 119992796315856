import React, { useState, useContext } from 'react';
import DispoService from '../../oldService/dispService';
import MultipleSelect from '../../oldComponents/MultipleSelect/MultipleSelect';
import UserService from '../../oldService/userService';
import TagService from '../../oldService/tagService';

import './ModalModifPlan.css';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import DispoTagService from '../../oldService/dispTagService';
import FileService from '../../oldService/fileService';
import { AuthContext } from '../../AuthProvider';
import { useNavigate } from 'react-router-dom';

function ModalModifPlan({ excludestudent = [], ...props }) {
  const [title, setTitle] = useState(props.data.name);
  const [shortDescription, setShortDescription] = useState(props.data.shortdescription);
  const [description, setDescription] = useState(props.data.description);
  const [nbrHour, setNbrHour] = useState(props.data.nbrhour);
  const [date, setDate] = useState(props.data.enddate);
  const [fileId, setFileId] = useState();
  const [studList, setStudList] = useState([]);
  UserService.FormatUserList(studList, setStudList, props.admin);
  const [tagList, setTagList] = useState([]);
  TagService.FormatgetAllTag(tagList, setTagList);
  const [respo, setRespo] = useState([]);
  DispDirUsersService.FormatGetDirDispUser(respo, setRespo, props.id);
  const [tag, setTag] = useState([]);
  DispoTagService.FormatGetDispTag(tag, setTag, props.id);
  const { user } = useContext(AuthContext);
  const formData = new FormData();
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleShortDescriptionChange = (e) => {
    setShortDescription(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  const onChangeNbrHour = (e) => {
    setNbrHour(e.target.value);
  };

  const handleArchivedDispo = async (id) => {
    if (
      window.confirm(
        'Voulez-vous vraiment archiver ce dispositif ? Il ne sera accessble plus que par les administrateurs.'
      )
    ) {
      await DispoService.archiveDispo(id);
      document.body.classList.remove('ModalOpenNoScrollBody');
      navigate('/user/plans/0');
    }
  };
  const handleDisplayClose = () => {
    props.setDisplay(false);
    props.setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };

  const handleDisplay = async () => {
    if (fileId) {
      formData.append('title', fileId.name);
      formData.append('file', fileId);
      FileService.fileUpdate(formData).then((result) => {
        DispoService.patchDispo(
          props.id,
          title,
          shortDescription,
          description,
          result.data.data.id,
          date,
          nbrHour
        ).then(() => {
          window.location.reload();
        });
      });
    } else {
      DispoService.patchDispo(
        props.id,
        title,
        shortDescription,
        description,
        props.data.picture ? props.data.picture : null,
        date,
        nbrHour
      ).then(() => {
        window.location.reload();
      });
    }
  };
  const onChangeFile = (e) => {
    setFileId(e.target.files[0]);
  };
  return (
    <div className="ModalModifPlan">
      <div
        id="authentication-modal"
        className="overflow-y-scroll overflow-x-hidden fixed z-50 w-full top-0 right-0 left-0 md:inset-0 flex justify-center items-center backgoundModalColor">
        <div className="absolute bg-white rounded shadow w-10/12 my-10 min-h-[70vh]">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={handleDisplayClose}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-6 px-6 space-y-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900">Modifier le Dispositif</h3>
            <div className="w-full">
              <div
                className={`grid max-w-xs ${
                  user.role.name === 'Administrator' ? 'grid-cols-3' : 'grid-cols-2'
                }  gap-1 p-1 mx-auto my-2 bg-gray-100 rounded-lg`}
                role="group">
                <button
                  type="button"
                  className={`px-5 py-1.5 text-xs font-medium rounded-lg ${
                    pageIndex === 0 ? 'text-white bg-gray-900' : 'text-gray-900 hover:bg-gray-200'
                  }`}
                  onClick={() => setPageIndex(0)}>
                  Général
                </button>
                <button
                  type="button"
                  className={`px-5 py-1.5 text-xs font-medium rounded-lg ${
                    pageIndex === 1 ? 'text-white bg-gray-900' : 'text-gray-900 hover:bg-gray-200'
                  }`}
                  onClick={() => setPageIndex(1)}>
                  Responsables
                </button>
                {user.role.name === 'Administrator' && (
                  <button
                    type="button"
                    className={`px-5 py-1.5 text-xs font-medium rounded-lg ${
                      pageIndex === 2 ? 'text-white bg-gray-900' : 'text-gray-900 hover:bg-gray-200'
                    }`}
                    onClick={() => setPageIndex(2)}>
                    Tags
                  </button>
                )}
              </div>
            </div>
            {pageIndex === 0 && (
              <>
                <div className="flex w-full gap-3">
                  <div className="w-1/3">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                      Nom du dispositif
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Dispositif"
                      defaultValue={title}
                      onChange={handleTitleChange}
                    />
                  </div>
                  <div className="w-1/3">
                    <label htmlFor="short-description" className="block mb-2 text-sm font-medium text-gray-900">
                      Description courte
                    </label>
                    <input
                      type="text"
                      name="short-descriptiob"
                      id="short-description"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Description courte"
                      defaultValue={shortDescription}
                      onChange={handleShortDescriptionChange}
                    />
                  </div>
                  <div className="w-1/3">
                    <label htmlFor="nbrHour" className="block mb-2 text-sm font-medium text-gray-900">
                      Nombre d'heures d'engagement minimum estimé
                    </label>
                    <input
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="nbrHour"
                      min={0}
                      type="number"
                      placeholder="Heures"
                      value={nbrHour}
                      onChange={onChangeNbrHour}
                    />
                  </div>
                </div>
                <div className="flex w-full gap-3">
                  <div className="w-1/3">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">
                      Description longue
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg h-40 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Description longue"
                      defaultValue={description}
                      required=""
                      onChange={handleDescriptionChange}
                    />
                  </div>
                  <div className="w-1/3">
                    <span className="block mb-2 text-sm font-medium text-gray-900">Modifier la photo</span>
                    <label className="flex flex-col justify-center items-center px-10 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100 h-40">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="mb-3 w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <div>
                          <p className="text-sm text-gray-500">
                            <span className="font-semibold">Cliquer pour uploader</span> ou glisser et déposer
                          </p>
                          <p className="flex justify-center text-sm text-gray-500">image uniquement.</p>
                          <p className="flex justify-center text-sm text-gray-500">500 x 500 recommandé.</p>
                        </div>
                        <div className="flex justify-center text-sm text-gray-500 pt-4 font-semibold">
                          {fileId && <p>{fileId.name}</p>}
                        </div>
                      </div>
                      <input
                        onChange={onChangeFile}
                        accept={'image/*'}
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <div className="w-1/3">
                    <label htmlFor="date">Date de fin de validité</label>
                    <input
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="date"
                      type="date"
                      value={date}
                      onChange={onChangeDate}
                    />
                  </div>
                </div>
                <div className="py-5">
                  <button
                    type="button"
                    className="text-red-500 hover:text-white border border-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-base px-5 py-2.5 text-center mr-2 mb-2"
                    onClick={() => handleArchivedDispo(props.id)}>
                    Archiver le dispositif
                  </button>
                </div>
              </>
            )}
            {pageIndex === 1 && (
              <div className="px-5 py-5">
                <MultipleSelect
                  data={studList}
                  title={'Liste des responsables'}
                  type="member"
                  id={props.id}
                  listBefore={respo}
                  excludestudent={excludestudent}
                  dispoType={props.dispoType}
                />
              </div>
            )}
            {pageIndex === 2 && (
              <div className="px-5 py-5">
                <MultipleSelect data={tagList} title={'Liste des tags'} type="tag" id={props.id} listBefore={tag} />
              </div>
            )}
            <div className="flex justify-center">
              <button
                className="bg-gray-200 px-4 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                onClick={handleDisplay}>
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalModifPlan;
