import axios from 'axios';
import authHeader from '../oldService/authHeader';
import { API } from '../oldService/authHeader';

const API_URL = API + 'items/files_vie_asso_files_1';

const postNewDocument = (idFileVieAsso, idDocument) => {
  return axios.post(
    API_URL,
    { files_vie_asso_id: idFileVieAsso, directus_files_id: idDocument },
    { headers: authHeader() }
  );
};

const deleteDocument = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};
const Files_vie_asso_files_service = {
  postNewDocument,
  deleteDocument
};

export default Files_vie_asso_files_service;
