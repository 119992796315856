import DispoStepService from '../dispoStepService';
import StepService from '../stepService';

export const StepsCreationPunctualV2 = (steps, dispoId) => {
  let order = [0, 1, 2, 3];

  if (steps[0].activated) {
    StepService.PostStep(steps[0].name, steps[0].validation, !steps[0].obligatory).then((result) => {
      DispoStepService.PostDispoStep(dispoId, result.data.data.id, order[0]);
    });
  }
  if (steps[1].activated) {
    StepService.PostStep(steps[1].name, steps[1].validation, !steps[1].obligatory).then((result) => {
      DispoStepService.PostDispoStep(dispoId, result.data.data.id, order[1]);
    });
  }
  if (steps[2].activated) {
    StepService.PostStep(steps[2].name, steps[2].validation, !steps[2].obligatory).then((result) => {
      DispoStepService.PostDispoStep(dispoId, result.data.data.id, order[2]);
    });
  }
  if (steps[3].activated) {
    StepService.PostStep(steps[3].name, steps[3].validation, !steps[3].obligatory).then((result) => {
      DispoStepService.PostDispoStep(dispoId, result.data.data.id, order[3]);
    });
  }
};
