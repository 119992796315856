import React from 'react';
import { API } from '../../oldService/authHeader';
import './NewHeaderPresDispo.css';
import RightPartHeaderAdmin from '../RightPartHeaderAdmin';
import RightPartHeaderNoStatus from '../RightPartHeaderNoStatus';
import RightPartHeaderStep from '../RightPartHeaderStep';
import RightPartHeaderEvent from '../RightPartHeaderEvent';

const NewHeaderPresDispo = ({
  dispo,
  handleDisplayModalCreateEvent,
  handleDisplayModif,
  excludedUsers,
  dispoId,
  firstStep,
  handleDisplayModalManageDoc,
  docList,
  handleDisplayModalExport,
  admin,
  inscription,
  pending,
  setChangeInPage,
  dispoType,
  withIndividualStep,
  userDispo,
  dispoChildren,
  event = {}
}) => {
  const image =
    dispo.picture !== null ? API + 'assets/' + dispo.picture : require('../../assets/images/black-square.jpg');

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-10">
        <h1 className="font-bold text-3xl text-blue-500 uppercase">{dispo.name}</h1>
        <div className="tag">
          {dispo.tag.map((tag, index: number) => (
            <span className="bg-rose-100/30 text-rose-200 text-base font-medium mr-2 px-3 py-0.5 rounded" key={index}>
              {tag.tag_id.label}
            </span>
          ))}
        </div>
      </div>
      <p className="text-sm pb-5">
        <span className="text-gray-500">Disponible jusqu'au: </span>
        <span>{new Date(dispo.enddate).toLocaleDateString()}</span>
      </p>
      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <div className="flex gap-8 items-center">
            <img className="img-content" src={image} alt="display element" />
            <div className="flex flex-col gap-5">
              <span className="text-lg">{dispo.description}</span>
              <span>Responsables :</span>
              <div className="grid grid-cols-2 gap-3">
                {dispo.responsibles.map((resp, index: number) => (
                  <a href={`mailto:${resp.directus_users_id.email}`} className="flex items-center gap-2" key={index}>
                    <div className="flex flex-col relative">
                      {resp.role === 'president' && (
                        <svg
                          className="crownForPresident"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 576 512"
                          style={{ fill: '#f5d505' }}>
                          <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                        </svg>
                      )}
                      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full bg-saumon-200">
                        <span className="font-medium text-xl text-white uppercase">
                          {resp.directus_users_id.first_name[0] + resp.directus_users_id.last_name[0]}
                        </span>
                      </div>
                    </div>
                    <span key={index}>
                      {resp.directus_users_id.first_name + ' ' + resp.directus_users_id.last_name}
                    </span>
                  </a>
                ))}
              </div>
              {(admin || inscription) && dispoType !== 'event' && (
                <button
                  className="w-10/12 h-16 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-blue-500 transition duration-300 ease-out border-2 border-newViolet-200 rounded-full shadow-md group"
                  onClick={() => {
                    handleDisplayModalManageDoc();
                  }}>
                  <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-newViolet-200 group-hover:translate-x-0 ease">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                    </svg>
                  </span>
                  <span className="absolute flex items-center justify-center w-full h-full text-newViolet-200 transition-all duration-300 transform group-hover:translate-x-full ease">
                    Voir tous les documents
                    <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-white bg-red-600 rounded-full">
                      {docList.length}
                    </span>
                  </span>
                  <span className="relative invisible">Voir tous les documents</span>
                </button>
              )}
            </div>
          </div>
        </div>
        {dispoType === 'event' && !admin && (
          <RightPartHeaderEvent event={dispo} inscription={inscription} dispoId={dispoId} userDispo={userDispo} />
        )}
        {admin && (
          <RightPartHeaderAdmin
            excludedUsers={excludedUsers}
            dispoId={dispoId}
            firstStep={firstStep}
            handleDisplayModalCreateEvent={handleDisplayModalCreateEvent}
            handleDisplayModif={handleDisplayModif}
            handleDisplayModalExport={handleDisplayModalExport}
            dispoChildren={dispoChildren}
            dispoType={dispoType}
            parentDispo={withIndividualStep}
            event={event}
          />
        )}
        {dispoType !== 'event' && !inscription && !pending && !admin && (
          <RightPartHeaderNoStatus
            pending={pending}
            dispoId={dispoId}
            dispoType={dispoType}
            setChangeInPage={setChangeInPage}
          />
        )}
        {pending && (
          <RightPartHeaderNoStatus
            pending={pending}
            dispoId={dispoId}
            dispoType={dispoType}
            setChangeInPage={setChangeInPage}
          />
        )}
        {inscription && withIndividualStep && <RightPartHeaderStep steps={dispo.step} userDispo={userDispo} />}
      </div>
    </div>
  );
};

export default NewHeaderPresDispo;
