import React from 'react';

import './CategoriesFollowed.css';

function CategoriesFollowed({ category, setCategory }) {
  return (
    <div className="CategoriesFollowed">
      <div className="flex">
        <button
          className={`p-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white ${
            category === 0 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
          }`}
          onClick={() => {
            setCategory(0);
          }}>
          Utilisateurs
        </button>
        <button
          className={`p-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white ${
            category === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
          }`}
          onClick={() => {
            setCategory(1);
          }}>
          Dispositifs
        </button>
        <button
          className={`p-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white ${
            category === 2 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
          }`}
          onClick={() => {
            setCategory(2);
          }}>
          Organisations
        </button>
      </div>
    </div>
  );
}

export default CategoriesFollowed;
