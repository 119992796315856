import React from 'react';
import { useNavigate } from 'react-router-dom';

const NewListOfEvent = ({ listOfEvent, admin, setDisplayModalCreate }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full max-h-[32rem] overflow-x-hidden overflow-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8">
      <div className="flex items-center justify-between mb-4">
        <h5 className="text-xl font-bold leading-none text-gray-900">Liste des évènements</h5>
      </div>
      <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200">
          {admin && (
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-base font-bold text-gray-900 truncate">Créer un nouvel évènement</p>
                </div>
                <button
                  className="text-newViolet-300 hover:text-newViolet-200"
                  onClick={() => {
                    setDisplayModalCreate(true);
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M3 3h18v18H3zM12 8v8m-4-4h8" />
                  </svg>
                </button>
              </div>
            </li>
          )}
          {listOfEvent.length === 0 && (
            <li className="py-2">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 text-jaune-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    Aucun évènement de prévu dans ce dispositif
                  </p>
                </div>
              </div>
            </li>
          )}
          {listOfEvent.map((event, index) => (
            <li className="py-2" key={index}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 text-jaune-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{event.name}</p>
                  <div className="flex items-center">
                    <p className="text-sm text-gray-500 truncate">
                      {new Date(`${event.startdate}T${event.start_hourly}`).toLocaleString(undefined, {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path d="M5 12h13M12 5l7 7-7 7" />
                    </svg>
                    <p className="text-sm text-gray-500 truncate">
                      {new Date(`${event.enddate}T${event.end_hourly}`).toLocaleString(undefined, {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </p>
                  </div>
                </div>
                <button
                  className="text-saumon-300 hover:text-saumon-200"
                  onClick={() => navigate('/user/events/details?id=' + event.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewListOfEvent;
