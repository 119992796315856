import React, { useEffect, useState } from 'react';
import FormService from '../../../../oldService/formService';
import Select from 'react-select';
import { OrgaDirUsers1Services } from '../../../../oldService/organizationDirectusUsers1Services.js';
import { PunctualFilesServices } from '../../../../oldService/punctualFilesService';
import DispoStepService from '../../../../oldService/dispoStepService';
import DispoService from '../../../../oldService/dispService';
import ModalNewContact from '../../../ModalNewContact/ModalNewContact';
import { Controller, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';
import updateErrors from '../updateErrors';
import updateSuccess from '../updateSuccess';
import RadioGroup from '../../../Fields/RadioGroup';

const Step3 = ({ defaultValues, formRef, userDispo, organization }) => {
  const { actions } = useStateMachine({ updateAction, updateErrors, updateSuccess });
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [emailUsed, setEmailUsed] = useState(false);
  const [currentContactField, setCurrentContactField] = useState(false);

  const [dispo, setDispo] = useState([]);
  DispoService.FormatGetOneDispo(dispo, setDispo, id);

  const [displayModal, setDisplayModal] = useState(false);
  const [blur, setBlur] = useState(false);
  const [step2Id, setStep2Id] = useState(null);

  const [formFrench, setFormFrench] = useState([]);
  FormService.FormatGetForm(formFrench, setFormFrench, 'Convention');

  const [formEnglish, setFormEnglish] = useState([]);
  FormService.FormatGetForm(formEnglish, setFormEnglish, 'Convention EN');

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [punctual, setPunctual] = useState([]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  React.useEffect(() => {
    const subscription = watch((value) => {
      actions.updateAction({ data: formatData(value), raw: value });
    });
    return () => subscription.unsubscribe();
  }, [watch, formFrench, formEnglish]);

  React.useEffect(() => {
    actions.updateErrors({ step3: errors });
  }, [errors]);

  useEffect(() => {
    setValue('french_mission', true);
  }, []);

  const onSubmit = async () => {
    actions.updateSuccess({ step3: true });
  };

  const LanguageOnClick = (id) => {
    if (id === 0) {
      setValue('french_mission', true);
    } else if (id === 1) {
      setValue('french_mission', false);
    }
  };

  const optionsContact = contacts
    .filter((item) => item.organization_id === organization?.id)
    .map((item) => {
      return {
        value: item.directus_users_id,
        label:
          (item.directus_users_id && item.directus_users_id.first_name) +
          ' ' +
          (item.directus_users_id && item.directus_users_id.last_name) +
          ' - ' +
          (item.directus_users_id && item.directus_users_id.email)
      };
    });

  const formatData = (data) => {
    const formattedData = [];
    let hours = 0;
    let weeks = 0;
    if (watch('french_mission')) {
      if (formFrench[0]) {
        formFrench[0].questions_id.map((item, index) => {
          if (
            ['Sélectionnez votre tuteur', 'Sélectionnez le contact signataire de la convention'].includes(
              item.questions_id.name
            ) &&
            data[item.questions_id.id]
          ) {
            let index = 0;
            if (item.questions_id.name === 'Sélectionnez votre tuteur') {
              index = 1;
            }

            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.5,
              data: data[item.questions_id.id].value.id
            });
          } else if (item.questions_id.name === "Nombre d'heures par semaine de la mission") {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
            hours = data[item.questions_id.id];
            if (weeks) {
              formattedData.push({ id: null, index: 66, data: hours * weeks });
            }
          } else if (item.questions_id.name === 'Nombre de semaines de mission') {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
            weeks = data[item.questions_id.id];
            if (hours) {
              formattedData.push({ id: null, index: 66, data: hours * weeks });
            }
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    } else {
      if (formEnglish[0]) {
        formEnglish[0].questions_id.map((item, index) => {
          if (
            ['Select your tutor', 'Select the signatory contact of the agreement'].includes(item.questions_id.name) &&
            data[item.questions_id.id]
          ) {
            let index = 0;
            if (item.questions_id.name === 'Select your tutor') {
              index = 1;
            }

            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: index + 0.5,
              data: data[item.questions_id.id].value.id
            });
          } else if (item.questions_id.name === 'Weekly duration of the mission') {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
            hours = data[item.questions_id.id];
            if (weeks) {
              formattedData.push({ id: null, index: 66, data: hours * weeks });
            }
          } else if (item.questions_id.name === 'Number of weeks of your mission') {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
            weeks = data[item.questions_id.id];
            if (hours) {
              formattedData.push({ id: null, index: 66, data: hours * weeks });
            }
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    }

    return formattedData;
  };

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  return (
    <div className="">
      <div className={`flex justify-center space-x-20 ${blur ? 'blur-sm' : 'blur-none'}`}>
        <button
          onClick={() => {
            LanguageOnClick(0);
          }}
          className={`${watch('french_mission') && 'border-2'} border-blue-500 p-1 rounded-sm`}>
          <img src="https://flagcdn.com/fr.svg" width="40" alt="France" />
        </button>
        <button
          onClick={() => {
            LanguageOnClick(1);
          }}
          className={`${!watch('french_mission') && 'border-2'} border-blue-500 p-1 rounded-sm`}>
          <img src="https://flagcdn.com/gb.svg" width="45" alt="United Kingdom" />
        </button>
      </div>
      {displayModal && (
        <ModalNewContact
          setDisplay={setDisplayModal}
          setBlur={setBlur}
          step1Id={organization?.id}
          step2Id={step2Id}
          setStep2Id={setStep2Id}
          organization={organization}
          setContactList={contacts}
          contactList={setContacts}
          onContactAdded={(newContact) => {
            setContacts([...contacts, newContact]);
            setValue(currentContactField, {
              value: newContact.directus_users_id,
              label:
                (newContact.directus_users_id && newContact.directus_users_id.first_name) +
                ' ' +
                (newContact.directus_users_id && newContact.directus_users_id.last_name) +
                ' - ' +
                (newContact.directus_users_id && newContact.directus_users_id.email)
            });
          }}
          convention={true}
          setEmailUsed={setEmailUsed}
        />
      )}
      <div className={`rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
        <div className="space-y-2 mb-5">
          <h2 className="font-bold text-2xl text-blue-700/90 text-center">
            {watch('french_mission') ? 'Demander ma convention' : 'Request my agreement'}
          </h2>
        </div>
      </div>
      <div className={`${blur ? 'blur-sm' : 'blur-none'} px-10 space-y-10`}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          {watch('french_mission') ? (
            <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
              {formFrench[0] &&
                formFrench[0].questions_id.map((item, index) => {
                  if (item.questions_id.name === "Votre organisation d'accueil") {
                    return;
                  }
                  return (
                    <div key={index}>
                      {item.questions_id.name === 'Sélectionnez le contact signataire de la convention' ||
                      item.questions_id.name === 'Sélectionnez votre tuteur' ? (
                        <div>
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            {organization?.name ? (
                              <>
                                <div className="text-center space-y-3">
                                  <div className="flex flex-col justify-center items-center">
                                    {emailUsed && (
                                      <p className="text-red-500 font-bold mb-2">Email du contact déjà utilisé.</p>
                                    )}
                                    <button
                                      className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                      onClick={() => {
                                        setCurrentContactField(item.questions_id.id);
                                        setBlur(true);
                                        setDisplayModal(true);
                                        document.body.classList.add('ModalOpenNoScrollBody');
                                      }}>
                                      Ajouter contact
                                    </button>
                                  </div>
                                  <p className="text-blue-500 font-semibold">ou</p>
                                </div>
                                <Controller
                                  rules={{ required: true }}
                                  name={item.questions_id.id}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <Select options={optionsContact} placeholder="Sélectionner..." {...field} />
                                  )}
                                />
                              </>
                            ) : (
                              <p className="text-red-500 font-bold">
                                {item.questions_id.name === 'Sélectionnez le contact signataire de la convention'
                                  ? 'Sélectionnez une organisation avant de pouvoir choisir un contact.'
                                  : 'Sélectionnez une organisation avant de pouvoir choisir votre tuteur.'}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-2">
                          <h3 className="text-lg">
                            {item.questions_id.name} <Obligatory />
                          </h3>
                          {item.questions_id.type === 'text' && (
                            <textarea
                              className="w-full p-2 h-28 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'choice' && (
                            <RadioGroup
                              items={item.questions_id.questions_choice_id.map((itemQues) => {
                                return itemQues.questions_choice_id.name;
                              })}
                              index={index}
                              register={register}
                              validationSchema={{ required: true }}
                              id={item.questions_id.id}
                              name={item.questions_id.id}
                            />
                          )}
                          {item.questions_id.type === 'url' && (
                            <input
                              type={'url'}
                              className="w-full p-2 border border-gray-300"
                              placeholder="https://"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'date' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'date'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                          {item.questions_id.type === 'nbr' && (
                            <input
                              type="number"
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'description' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'text'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {errors[item.questions_id.id]?.type === 'required' && (
                        <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                      )}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
              {formEnglish[0] &&
                formEnglish[0].questions_id.map((item, index) => {
                  if (item.questions_id.name === 'Your host organization') {
                    return;
                  }
                  return (
                    <div key={index}>
                      {item.questions_id.name === 'Select the signatory contact of the agreement' ||
                      item.questions_id.name === 'Select your tutor' ? (
                        <div>
                          <div className="space-y-3">
                            <h3 className="text-lg">
                              {item.questions_id.name} <Obligatory />
                            </h3>
                            <p className="text-gray-500 text-sm pb-2">
                              {item.questions_id.type === 'description' && item.questions_id.description}
                            </p>
                            {organization?.name ? (
                              <>
                                <div className="text-center space-y-3">
                                  <div className="flex flex-col justify-center items-center">
                                    {emailUsed && (
                                      <p className="text-red-500 font-bold mb-2">Contact email already used.</p>
                                    )}
                                    <button
                                      className="px-3 py-2 bg-gray-200 hover:text-white hover:bg-blue-500 shadow-sm rounded-sm"
                                      onClick={() => {
                                        setCurrentContactField(item.questions_id.id);
                                        setBlur(true);
                                        setDisplayModal(true);
                                        document.body.classList.add('ModalOpenNoScrollBody');
                                      }}>
                                      Add contact
                                    </button>
                                  </div>
                                  <p className="text-blue-500 font-semibold">or</p>
                                </div>
                                <Controller
                                  rules={{ required: true }}
                                  name={item.questions_id.id}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <Select options={optionsContact} placeholder="Sélectionner..." {...field} />
                                  )}
                                />
                              </>
                            ) : (
                              <p className="text-red-500 font-bold">
                                {item.questions_id.name === 'Select the signatory contact of the agreement'
                                  ? 'Select an organization before you can choose a contact.'
                                  : 'Select an organization before you can choose a tutor.'}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-2">
                          <h3 className="text-lg">
                            {item.questions_id.name} <Obligatory />
                          </h3>
                          {item.questions_id.type === 'text' && (
                            <textarea
                              className="w-full p-2 h-28 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'choice' && (
                            <RadioGroup
                              items={item.questions_id.questions_choice_id.map((itemQues) => {
                                return itemQues.questions_choice_id.name;
                              })}
                              index={index}
                              register={register}
                              validationSchema={{ required: true }}
                              id={item.questions_id.id}
                              name={item.questions_id.id}
                            />
                          )}
                          {item.questions_id.type === 'url' && (
                            <input
                              type={'url'}
                              className="w-full p-2 border border-gray-300"
                              placeholder="https://"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'date' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'date'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                          {item.questions_id.type === 'nbr' && (
                            <input
                              type="number"
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'description' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'text'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {errors[item.questions_id.id]?.type === 'required' && (
                        <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Step3;
