import React, { useEffect, useState } from 'react';
import ConventionForm from '../../../oldComponents/Forms/ConventionForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dispositif, User, UserDispo } from '../../../usualTypes/BasicTypes';
import userService from '../../../oldService/userService';
import TemplatePage from '../../Template/BasicPage';
//USER DISPO
//USER
//DISPO
type State = {
  dispo: Dispositif;
  userDispo: UserDispo;
};
const ModificationSteps = () => {
  const [blur, setBlur] = useState(false);
  const [userModify, setUserModify] = useState<User>();
  const myState = useLocation().state as State;
  const userDispo = myState.userDispo;
  useEffect(() => {
    const fetchUser = async () => {
      const data = await userService.GetOneUserWithId(userDispo.user.id);
      setUserModify(data.data.data[0]);
    };
    fetchUser();
  }, []);
  const dispo = myState.dispo;
  const navigate = useNavigate();
  return (
    <TemplatePage>
      {userModify && (
        <>
          <button className="underline font-medium" onClick={() => navigate(-1)}>
            {' '}
            &#60; Retour{' '}
          </button>
          <div
            className="flex p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
            role="alert">
            <svg
              aria-hidden="true"
              className="flex-shrink-0 inline w-5 h-5 mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Info</span>
            <div className="text-lg">
              <span className="font-medium">Attention !</span> Voici le formulaire de convention de l'étudiant-e{' '}
              <span className="font-semibold underline">{`${userModify.first_name + ' ' + userModify.last_name}`}</span>{' '}
              , modifier et valider le formulaire reviendra à soumettre le formulaire comme si vous étiez sur le compte
              de l'étudiant.
            </div>
          </div>
          <ConventionForm
            dispo={dispo}
            userDispo={userDispo}
            blur={blur}
            setBlur={setBlur}
            userModify={userModify}
            adminModify={true}
          />
        </>
      )}
    </TemplatePage>
  );
};

export default ModificationSteps;
