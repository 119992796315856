import React, { useState } from 'react';
import FillingDoc from '../FillingDoc/FillingDoc';
import MemberSelect from '../MemberSelect/MemberSelect';
import FormNewPLan from '../FormNewPlan/FormNewPlan';
import ButtonsList from '../ButtonsList/ButtonsList';
import HomeCardList from '../HomeCardList/HomeCardList';
import PptSoutenance from '../PptSoutenance/PptSoutenance';
import { useEffect } from 'react';
import ReportContent from '../ReportContent/ReportContent';
import Card from '../Card/Card';

function AssoContent({ setChangeInPage, dispoChildren, ...props }) {
  const [childDispo, setChildDispo] = useState({});
  useEffect(() => {
    const child = dispoChildren.find((dispo) => dispo.files_vie_asso_id === props.userDispo.files_asso_id);
    setChildDispo(child);
  }, [props.userDispo]);

  return (
    <div className="AssoContent space-y-14">
      {!props.dispo.parent && (
        <div>
          {!props.admin && (
            <div>
              <ButtonsList steps={props.dispo.step} userDispo={props.userDispo} admin={props.admin} />
              <div className="bg-gray-50 p-5 rounded">
                <div>
                  {props.userDispo.step.name === 'Administratif' && !props.userDispo.validation && (
                    <FillingDoc
                      user={props.user}
                      userDispo={props.userDispo}
                      dispo={props.dispo}
                      admin={props.admin}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Identité asso' && !props.userDispo.validation && (
                    <FormNewPLan
                      asso={true}
                      models={'model1'}
                      dispo={props.dispo}
                      user={props.user}
                      admin={props.admin}
                      userDispo={props.userDispo}
                      dispoAsso={true}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Membres bureau' && !props.userDispo.validation && (
                    <MemberSelect
                      office={true}
                      admin={props.admin}
                      dispo={props.dispo}
                      userDispo={props.userDispo}
                      dispoAsso={true}
                      respo={true}
                      actualUser={props.user}
                      childDispo={childDispo}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Bénévoles' && !props.userDispo.validation && (
                    <MemberSelect
                      office={false}
                      admin={props.admin}
                      dispo={props.dispo}
                      userDispo={props.userDispo}
                      dispoAsso={true}
                      childDispo={childDispo}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Évaluations S1' && !props.userDispo.validation && (
                    <p className="flex justify-center">
                      Les évaluations sont disponibles dans le dispositif de votre association.
                    </p>
                  )}
                  {props.userDispo.step.name === 'Soutenance S1' && !props.userDispo.validation && (
                    <PptSoutenance
                      soutenance_number={1}
                      childDispo={childDispo}
                      idFilesVieAsso={props.userDispo.files_asso_id}
                      actualStep={props.userDispo.step}
                      userDispoId={props.userDispo.other_id}
                      parentDispoId={props.dispo.id}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Évaluations S2' && !props.userDispo.validation && (
                    <p className="flex justify-center">
                      Les évaluations sont disponibles dans le dispositif de votre association.
                    </p>
                  )}
                  {props.userDispo.step.name === 'Soutenance S2' && !props.userDispo.validation && (
                    <PptSoutenance
                      soutenance_number={2}
                      childDispo={childDispo}
                      idFilesVieAsso={props.userDispo.files_asso_id}
                      actualStep={props.userDispo.step}
                      userDispoId={props.userDispo.other_id}
                      parentDispoId={props.dispo.id}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                  {props.userDispo.step.name === 'Bilan' && !props.userDispo.validation && !props.userDispo.finish && (
                    <ReportContent
                      childDispo={childDispo}
                      id_file_vie_asso={props.userDispo.files_asso_id}
                      userDispoId={props.userDispo.other_id}
                      setChangeInPage={setChangeInPage}
                    />
                  )}
                </div>
                {props.userDispo.validation && (
                  <div className="flex justify-center py-10 text-lg">
                    En attente de la validation de l'étape par un admin.
                  </div>
                )}
                {props.userDispo.finish && (
                  <div className="flex justify-center py-10 text-lg">
                    Vos documents bilans ont été validés par un admin, vous avez maintenant terminé le processus
                    administratif
                    <span>&#127881;</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!props.admin &&
        props.dispo.parent === null &&
        childDispo &&
        props.userDispo.step &&
        props.userDispo.step.name !== 'Membres bureau' && (
          <div className="bg-gray-50 p-5 rounded">
            <h2 className="font-bold text-xl pb-5 text-blue-700/90">Votre association</h2>
            <div className="grid grid-cols-5">
              <Card data={childDispo} template={childDispo.template} assoToAsso={true} />
            </div>
          </div>
        )}
    </div>
  );
}

export default AssoContent;
