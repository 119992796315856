import React, { useState, useContext } from 'react';
import AuthService from '../../../oldService/authService';
import square from '../../../assets/images/black-square.jpg';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import ContentElem from '../../../oldComponents/ContentElem/ContentElem';
import TableEvents from '../../../oldComponents/TableEvents/TableEvents';
import ModalModifPlan from '../../../oldComponents/ModalModifPlan/ModalModifPlan';
import ModalValidAdmin from '../../../oldComponents/ModalValidAdmin/ModalValidAdmin';
import UserCreateEvent from '../../../oldComponents/UserCreateEvent/UserCreateEvent';
import PipelineMultiple from '../../../oldComponents/PipelineMultiple/PipelineMultiple';
import TablePending from '../../../oldComponents/TablePending/TablePending';
import { docList, responsibleList, tagList, mes_évènements, studList, stepsNewPlan } from '../../../data';
import './ManagePlan.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthProvider';

const ManagePlan = () => {
  const title = 'NOM DU DISPOSITIF';
  const description = 'Description du dispositif';
  const [displayModif, setDisplayModif] = useState(false);
  const [displayValid, setDisplayValid] = useState(false);
  const [blur, setBlur] = useState(false);
  const token = AuthService.getCurrentToken();
  const [userValid, setUserValid] = useState('');
  const [stepValid, setStepValid] = useState('');
  const { user } = useContext(AuthContext);
  const handleDisplayModif = () => {
    setDisplayModif(true);
    setBlur(true);
  };

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <div className="NewPLan flex w-screen min-h-screen">
        {user != null && (
          <>
            <NavbarLeft user={user} sub={1} blur={blur} />
            <div className="flex flex-col h-full w-full">
              <NavbarTop user={user} blur={blur} urls={['home', 'plans']} />
              <div className="p-10 space-y-8">
                <div className="flex justify-between">
                  <h1 className={`font-bold text-3xl text-blue-500 ${blur ? 'blur-sm' : 'blur-none'}`}>{title}</h1>
                  <div className={`${blur ? 'blur-sm' : 'blur-none'}`}>
                    <button
                      className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                      onClick={handleDisplayModif}>
                      Modifier Dispositif
                    </button>
                  </div>
                </div>
                <div className="space-y-14 py-4 px-5">
                  <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                    <ContentElem
                      square={square}
                      list={responsibleList}
                      tag={tagList}
                      description={description}
                      asso={true}
                      docList={docList}
                    />
                  </div>
                  {displayModif && <ModalModifPlan setDisplay={setDisplayModif} setBlur={setBlur} />}
                  {displayValid && (
                    <ModalValidAdmin setDisplay={setDisplayValid} setBlur={setBlur} user={userValid} step={stepValid} />
                  )}
                  <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                    <PipelineMultiple
                      user={studList}
                      steps={stepsNewPlan}
                      cols={4}
                      setDisplayValid={setDisplayValid}
                      setBlur={setBlur}
                      setUserValid={setUserValid}
                      setStepValid={setStepValid}
                    />
                  </div>
                  <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                    <TablePending data={studList} title="Demandes en attente" />
                  </div>
                  <div className="p-5 bg-gray-50 rounded">
                    <h2 className={`font-bold text-xl text-blue-700/90 ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                      Évènements archivés
                    </h2>
                    <div className="space-y-5">
                      <div className={`${blur === true ? 'blur-sm' : 'blur-none'}`}>
                        <TableEvents data={mes_évènements} />
                      </div>
                      <UserCreateEvent setBlur={setBlur} blur={blur} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default ManagePlan;
