import React, { useContext } from 'react';
import UserDispoService from '../../oldService/userDispoService';
import { AuthContext } from '../../AuthProvider';
import { User } from '../../usualTypes/BasicTypes';

const RightPartHeaderEvent = ({ event, inscription, dispoId, userDispo }) => {
  const { user }: { user: User } = useContext(AuthContext);
  const handleSubmitRequest = () => {
    if (inscription) {
      UserDispoService.deleteOneUserDispo(userDispo.other_id).then(() => {
        window.location.reload();
      });
    } else {
      UserDispoService.PostUserDispo(dispoId, user.id).then(() => {
        window.location.reload();
      });
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[20%_auto_auto_auto_auto] justify-center items-center bg-white rounded-lg w-11/12">
        <div className="flex justify-center items-center w-full h-full bg-saumon-200 rounded-t-lg text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </div>
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
          <span className="font-semibold">
            {' Du ' +
              new Date(`${event.startdate}T${event.start_hourly}`).toLocaleString(undefined, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }) +
              ' au ' +
              new Date(`${event.enddate}T${event.end_hourly}`).toLocaleString(undefined, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              })}
          </span>
        </div>
        <div className="flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </svg>
          <span className="font-semibold">{event.location}</span>
        </div>
        <div className="flex justify-center items-center">
          <button
            className="w-9/12 h-16 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-newViolet-200 rounded-full shadow-md group"
            onClick={() => {
              handleSubmitRequest();
            }}>
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-newViolet-200 group-hover:translate-x-0 ease">
              {inscription ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <line x1="12" y1="2" x2="12" y2="6"></line>
                  <line x1="12" y1="18" x2="12" y2="22"></line>
                  <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                  <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                  <line x1="2" y1="12" x2="6" y2="12"></line>
                  <line x1="18" y1="12" x2="22" y2="12"></line>
                  <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                  <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
              )}
            </span>
            <span className="absolute flex items-center justify-center w-full h-full text-newViolet-200 transition-all duration-300 transform group-hover:translate-x-full ease">
              {inscription ? 'Désinscription' : "S'inscrire"}
            </span>
            <span className="relative invisible">{inscription ? 'Désinscription' : "S'inscrire"}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default RightPartHeaderEvent;
