import React from 'react';
import square from '../../assets/images/black-square.jpg';
import { API, PROD } from '../../oldService/authHeader';

import './Card.css';
import { useNavigate } from 'react-router-dom';

function Card(props) {
  const image = props.data.picture ? API + 'assets/' + props.data.picture : square;
  //PROD /PREPROD
  const templateIdPunctual = PROD ? 321 : 319;
  const templateIdPunctualV2 = PROD ? 444 : 384;
  const navigate = useNavigate();
  function getPath() {
    if (props.type === 'events') {
      navigate('/user/events/details?id=' + props.data.id);
    } else if (props.type === 'commitments' && props.template === 291) {
      navigate('/user/commitments/details?id=' + props.data.id);
    } else if (props.type === 'plans' && props.data.template === 291) {
      navigate('/user/plans/details?id=' + props.data.id);
    } else if (props.data.template === templateIdPunctual) {
      navigate('/user/punctual/details?id=' + props.data.id);
    } else if (props.data.template === templateIdPunctualV2) {
      navigate('/user/punctualV2/details?id=' + props.data.id);
    } else {
      navigate('/user/asso/details?id=' + props.data.id);
      if (props.assoToAsso) {
        location.reload();
      }
    }
  }
  return (
    <div className="Card flex flex-col justify-center items-center py-5 space-y-3 border rounded-sm my-3 md:mx-5 md:my-5 bg-gray-50 hover:bg-gray-100">
      <img className="img p-2" src={image} alt="display element" />
      <div className="-pt-2 text-center">
        <h2 className="font-semibold text-xl">{props.data.name}</h2>
      </div>
      <button
        className="relative px-5 py-3 xl:px-3 xl:py-1 rounded-sm bg-gray-300 border font-medium hover:bg-blue-500 hover:text-white"
        onClick={() => getPath()}>
        Détails
        {props.role === 'Administrator' && props.nbrValidation > 0 && (
          <div className="absolute -top-1 right-0">
            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-orange-500 rounded-full -top-2 -right-2">
              {props.nbrValidation}
            </div>
          </div>
        )}
      </button>
    </div>
  );
}

export default Card;
