import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/punctual_files_directus_users';

const PostPunFilesDirectusUsers = (punctualId, contactId) => {
  return axios.post(
    API_URL,
    {
      punctual_files_id: punctualId,
      directus_users_id: contactId
    },
    { headers: authHeader() }
  );
};

const DeletePunFilesDirectusUsers = (contactId) => {
  return axios.delete(API_URL + '/' + contactId, { headers: authHeader() });
};

export const PunctualFilesDirectusUsersServices = {
  PostPunFilesDirectusUsers,
  DeletePunFilesDirectusUsers
};
