import React, { useEffect } from 'react';
import Select from 'react-select';
import { useState } from 'react';
import { OrganizationServices } from '../../oldService/organizatonServices';
import { OrgaDirUsers1Services } from '../../oldService/organizationDirectusUsers1Services.js';
import HostOrganizationForm from '../HostOrganizationForm/HostOrganizationForm';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';
import { findNextStep } from '../../oldService/steps/findNextStep';
import DispoStepService from '../../oldService/dispoStepService';
import ChoiceCard from '../ChoiceCard/ChoiceCard';
import SirenChoose from '../SirenChoose/SirenChoose';
import RemoveIcon from '../RemoveIcon/RemoveIcon';
import ModalNewContact from '../ModalNewContact/ModalNewContact';
import { PunctualFilesDirectusUsersServices } from '../../oldService/puctualFilesDirectusUsersService';

const AllOrganizationForm = ({ userDispo, step1Id, setStep1Id, step2Display, setStep2Display, blur, setBlur }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrga, setSelectedOrga] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [dispoStep, setDispoStep] = useState([]);
  const [origineFr, setOrigineFr] = useState(true);
  const [sirenData, setSirenData] = useState({});
  const [orgaChoice, setOrgaChoice] = useState(0);
  const [contactList, setContactList] = useState([]);
  const [removeContact, setRemoveContact] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [step2Id, setStep2Id] = useState(null);
  const [emailUsed, setEmailUsed] = useState(false);

  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);
  OrganizationServices.FormatGetAllOrganizations(organizations, setOrganizations);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [sortedOrga, setSortedOrga] = useState(
    organizations.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      else return 0;
    })
  );

  useEffect(() => {
    setSortedOrga(
      organizations.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        else return 0;
      })
    );
  }, [organizations]);

  const optionsOrga = sortedOrga.map((item) => {
    return { value: item, label: item.name };
  });

  const optionsContact = !selectedOrga
    ? []
    : contacts
        .filter((item) => item.organization_id === selectedOrga.value.id)
        .map((item) => {
          return {
            value: item.directus_users_id,
            label:
              (item.directus_users_id && item.directus_users_id.first_name) +
              ' ' +
              (item.directus_users_id && item.directus_users_id.last_name) +
              ' ' +
              (item.directus_users_id && item.directus_users_id.email)
          };
        });

  const handleContactSubmit = () => {
    PunctualFilesServices.PatchOrgaContactPunctualFiles(userDispo.punctual_files_id, step1Id, null)
      .then(() => {
        console.log('1.0');
        contactList.map((item, index) => {
          PunctualFilesDirectusUsersServices.PostPunFilesDirectusUsers(userDispo.punctual_files_id, item.id)
            .then(() => {
              console.log(index);
            })
            .catch(() => {
              console.log('err ' + index);
            });
          return item;
        });
        if (userDispo.step_id.tovalidate) {
          UserDispoService.PatchUserDispoValidate(userDispo.id, null, true)
            .then(() => {
              console.log('end2.1');
              window.location.reload();
            })
            .catch(() => {
              console.log('errend2.1');
            });
        } else {
          UserDispoService.PatchUserDispoStep(
            userDispo.id,
            findNextStep(dispoStep.find((item) => item.step_id === userDispo.step_id.id).order, dispoStep).step_id
          )
            .then(() => {
              console.log('end2.2');
              window.location.reload();
            })
            .catch(() => {
              console.log('errend2.2');
            });
        }
      })
      .catch(() => {
        console.log('err1.0');
      });
  };

  const SelectOnClick = () => {
    setStep1Id(selectedOrga.value.id);
    setStep2Display(2);
  };

  useEffect(() => {
    console.log('step1:');
    console.log(step1Id);
  }, [step1Id]);

  useEffect(() => {
    setContactList(contactList.filter((item) => item !== removeContact));
  }, [removeContact]);

  return (
    <div>
      {step2Display === 1 && (
        <div className="space-y-20">
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Organisation d'accueil</h2>
          <div className="flex justify-center items-center space-x-20">
            <div>
              <button
                className={`shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white ${
                  orgaChoice === 0 ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
                onClick={() => {
                  setOrgaChoice(0);
                }}>
                Sélectionner une
                <br />
                organisation existante
              </button>
            </div>
            <p className="font-bold">ou</p>
            <div>
              <button
                className={`shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white ${
                  orgaChoice === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
                onClick={() => {
                  setOrgaChoice(1);
                }}>
                Créer une
                <br />
                organisation d'accueil
              </button>
            </div>
          </div>
          <div className="px-5">
            {orgaChoice === 0 && (
              <div>
                <p className="font-bold text-blue-700/90 pb-5">Sélectionner une organisation d'accueil</p>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-6/12">
                    <Select
                      options={optionsOrga}
                      isClearable={true}
                      onChange={(item) => {
                        setSelectedOrga(item);
                      }}
                      placeholder="Sélectionner..."
                    />
                  </div>
                  {selectedOrga && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-6/12 space-y-5 flex justify-center pt-5">
                        <div className="flex flex-col">
                          <div className="flex">
                            <p>
                              <span className="font-semibold text-blue-500">Adresse:</span> {selectedOrga.value.adress},
                            </p>
                            <p>
                              {selectedOrga.value.postal_code} {selectedOrga.value.city},
                            </p>
                            <p>{selectedOrga.value.country}</p>
                          </div>
                          <p>
                            <span className="font-semibold text-blue-500">Numéro Siret:</span>{' '}
                            {selectedOrga.value.numero_siret_rna}
                          </p>
                          <p>
                            <span className="font-semibold text-blue-500">Public bénéficiaire:</span>{' '}
                            {selectedOrga.value.beneficiaries}
                          </p>
                        </div>
                      </div>
                      <div className="pt-10">
                        <button
                          className="bg-gray-200 shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white"
                          onClick={SelectOnClick}>
                          Sélectionner
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {orgaChoice === 1 && (
              <div>
                <p className="font-bold text-blue-700/90 pb-5">Créer une organisation d'accueil</p>
                <div className="flex justify-center">
                  <div className="space-y-10">
                    <div>
                      <p>Mon organisation d'accueil se situe en France:</p>
                      <ChoiceCard data={origineFr} setData={setOrigineFr} />
                    </div>
                    {origineFr && <SirenChoose data={sirenData} setData={setSirenData} />}
                  </div>
                </div>
                <div className="pt-10">
                  <HostOrganizationForm
                    setStep1Id={setStep1Id}
                    step1Id={step1Id}
                    sirenData={sirenData}
                    organizations={organizations}
                    setStep2Display={setStep2Display}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {step2Display === 2 && (
        <div>
          {displayModal && (
            <ModalNewContact
              setDisplay={setDisplayModal}
              setBlur={setBlur}
              step2Id={step2Id}
              setStep2Id={setStep2Id}
              selectedOrga={selectedOrga}
              step1Id={step1Id}
              setContactList={setContactList}
              contactList={contactList}
              emailUsed={emailUsed}
              setEmailUsed={setEmailUsed}
            />
          )}
          <div className={`${blur ? 'blur-sm' : 'blur-none'}`}>
            <h2 className="font-bold text-xl pb-5 text-blue-700/90">Contact</h2>
            <div className="px-10 space-y-10">
              <div className="flex flex-col justify-center items-center space-y-10">
                <div className="w-full flex justify-between">
                  <div className="flex flex-col items-center w-full">
                    <h2 className="font-bold text-xl text-blue-700/90 mb-10">Sélectionner un ou plusieurs contacts</h2>
                    <div className="w-full flex justify-center items-center">
                      <div className="w-6/12">
                        <Select
                          options={optionsContact}
                          onChange={(item) => {
                            setSelectedContact(item);
                          }}
                          placeholder="Sélectionner..."
                        />
                      </div>
                      <div>
                        <button
                          className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white bg-gray-200"
                          onClick={() => {
                            if (!contactList.find((cont) => cont.id === selectedContact.value.id)) {
                              setContactList([...contactList, selectedContact.value]);
                            }
                          }}>
                          Sélectionner
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="font-bold text-xl">ou</p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full">
                    <h2 className="font-bold text-xl text-blue-700/90 mb-10">Créer un contact</h2>
                    <div className="w-full flex flex-col justify-center items-center space-y-3">
                      {emailUsed && <p className="text-red-500 font-bold">Email du contact déjà utilisé.</p>}
                      <div>
                        <button
                          className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white bg-gray-200"
                          onClick={() => {
                            setDisplayModal(true);
                            setBlur(true);
                          }}>
                          Créer un contact
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {contactList && (
                  <div className="flex justify-center w-full">
                    <div className="px-30 w-full">
                      <div className="grid grid-cols-5 py-2 px-5 bg-white font-bold rounded-sm shadow-sm ring-1 ring-black ring-opacity-5">
                        <p className="">Prénom</p>
                        <p className="">NOM</p>
                        <p className="">Email</p>
                        <p className="">Téléphone</p>
                        <p className="">Action</p>
                      </div>
                      {contactList.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={` grid grid-cols-5 py-1 px-5 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 ${
                              index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                            }`}>
                            <p>{item.first_name ? item.first_name : ' '}</p>
                            <p>{item.last_name ? item.last_name : ' '}</p>
                            <a href={item.email && 'mailto:' + item.email}>{item.email ? item.email : ' '}</a>
                            <p>{item.phones ? item.phones : ' '}</p>
                            <button
                              className="hover:text-red-500"
                              onClick={() => {
                                setRemoveContact(item);
                              }}>
                              <RemoveIcon />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <button
                  className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white bg-gray-200"
                  onClick={handleContactSubmit}>
                  Soumettre
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllOrganizationForm;
