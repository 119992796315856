import React, { useEffect, useState } from 'react';
// import ExportBtn from '../ExportBtn/ExportBtn';
import './PipelineMultiple.css';
import ModalValidAdmin from '../ModalValidAdmin/ModalValidAdmin';
import DispoService from '../../oldService/dispService';

const PipelineMultiple = ({ steps, users, setBlur, blur, dispo, setChangeInPage }) => {
  const [display, setDisplay] = useState(false);
  const [userModal, setUserModal] = useState();

  if (!steps) {
    window.location.reload();
  }
  const handleCloseModalOnEscape = () => {
    setBlur(false);
    setDisplay(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleCloseModalOnEscape();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  const [allDispo, setAllDispo] = useState([]);
  DispoService.FormatDispoList(allDispo, setAllDispo);

  const [dispoCreate, setDispoCreate] = useState([]);

  const handleButton = (item2) => {
    if (item2.validation) {
      setUserModal(item2);
      setDisplay(true);
      setBlur(true);
      document.body.classList.add('ModalOpenNoScrollBody');
      if (item2.files_asso_id) {
        setDispoCreate(allDispo.find((item) => item.files_vie_asso_id === item2.files_asso_id));
      } else {
        setDispoCreate('');
      }
    }
  };

  return (
    <div className="PipelineMultiple" id="pipeline">
      {display && (
        <ModalValidAdmin
          setDisplay={setDisplay}
          setBlur={setBlur}
          user={userModal}
          dispo={dispo}
          dispoCreate={dispoCreate ? dispoCreate : ''}
          setChangeInPage={setChangeInPage}
        />
      )}
      <div className={`flex justify-center min-h-[10vh] ${blur ? 'blur-sm' : 'blur-none'}`}>
        {steps
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((step, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center text-white bg-blue-500 w-full shadow-sm ring-1 ring-black ring-opacity-5 ${
                index === 0 && 'rounded-tl-lg rounded-bl-lg'
              } ${index === steps.length - 1 && 'rounded-tr-lg rounded-br-lg'}`}>
              <div key={index} className="text-center py-2">
                <h2 className="">{step.step_id.name}</h2>
                <p className="text-gray-300 text-sm">
                  {users.filter((item) => item.step.name === step.step_id.name).length} utilisateur
                  {users.filter((item) => item.step.name === step.step_id.name).length > 1 ? 's' : ''}
                </p>
              </div>
              <div
                className={`bg-gray-100 h-full w-full flex flex-col gap-5 items-center py-5 ${
                  index === 0 && 'rounded-bl-lg'
                } ${index === steps.length - 1 && 'rounded-br-lg'}`}>
                {users
                  .filter((user) => user.step.id === step.step_id.id)
                  .map((user, index) => (
                    <div className="w-full px-2" key={index}>
                      <button
                        className={`relative flex justify-start items-center h-14 gap-3 rounded-lg bg-white text-black w-full ${
                          user.validation ? 'hover:bg-blue-500 hover:text-white cursor-pointer' : 'cursor-default'
                        }`}
                        onClick={() => {
                          handleButton(user);
                        }}>
                        <span
                          className={`h-full rounded-l-lg w-4 ${
                            user.validation ? 'bg-orange-500' : user.finish ? 'bg-green-500' : 'bg-gray-200'
                          }`}></span>
                        <p className="text-xs">{user.name}</p>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PipelineMultiple;
