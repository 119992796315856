import React from 'react';
import CommiProjectForm from '../CommiProjectForm/CommiProjectForm';
import AllOrganizationForm from '../AllOrganizationForm/AllOrganizationForm';
import IdentityMissionForm from '../IdentityMissionForm/IdentityMissionForm';
import ConventionForm from '../ConventionForm/ConventionForm';
import SignatureForm from '../SignatureForm/SignatureForm';

const CommiProjectFormCompo = ({
  dispo,
  userDispo,
  step1Id,
  setStep1Id,
  step2Display,
  setStep2Display,
  blur,
  setBlur
}) => {
  return (
    <div>
      {userDispo.step_id.name === "Projet d'engagement" && (
        <div>
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Projet d'engagement</h2>
          <CommiProjectForm userDispo={userDispo} dispo={dispo} />
        </div>
      )}
      {userDispo.step_id.name === "Organisation d'accueil" && (
        <div>
          <AllOrganizationForm
            userDispo={userDispo}
            step1Id={step1Id}
            setStep1Id={setStep1Id}
            step2Display={step2Display}
            setStep2Display={setStep2Display}
            blur={blur}
            setBlur={setBlur}
          />
        </div>
      )}
      {userDispo.step_id.name === 'Descriptif mission' && (
        <div>
          <IdentityMissionForm userDispo={userDispo} blur={blur} setBlur={setBlur} />
        </div>
      )}
      {userDispo.step_id.name === 'Convention' && (
        <div className="flex justify-center items-center">
          <ConventionForm userDispo={userDispo} />
        </div>
      )}
      {userDispo.step_id.name === 'Signature' && (
        <div className="flex justify-center items-center">
          <SignatureForm userDispo={userDispo} />
        </div>
      )}
    </div>
  );
};

export default CommiProjectFormCompo;
