import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DispoService from '../../../oldService/dispService';
import CardList from '../../../oldComponents/CardList/CardList';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import Searchbar from '../../../oldComponents/Searchbar/Searchbar';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';

import './Plans.css';
import { AuthContext } from '../../../AuthProvider';
import UserDispoService from '../../../oldService/userDispoService';

const Plans = () => {
  const [planList, setPlanList] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [filterCheck, setFilterCheck] = useState(0);
  const [isArchived, setIsArchived] = useState(false);
  const [newData, setNewData] = useState([]);
  const [dispoIamIn, setDispoIamIn] = useState([]);
  const [dispoWithValidation, setDispoWithValidation] = useState([]);

  const { user } = useContext(AuthContext);
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    setFilterCheck(parseInt(params.subFilter, 10));
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const allDispo = await DispoService.getAllDispo();
      const allDispoUserIsIn = await UserDispoService.getUserEnga(user.id);
      const dataDispoWithValidation = await UserDispoService.getAllValidationOfDispo();
      setPlanList(
        allDispo.data.data
          .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
          .filter((plan) => plan.responsibles.length !== 0)
      );
      setDispoIamIn(allDispoUserIsIn.data.data.map((dispo) => dispo.dispositif));
      setDispoWithValidation(dataDispoWithValidation.data.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const newList = planList.filter((plan) => plan.archived === isArchived);
    setNewData(newList);
    setFilterCheck(0);
  }, [planList, isArchived]);

  useEffect(() => {
    const filterList = planList?.filter(
      (plan) => plan?.name.toLocaleLowerCase().includes(searchField) && plan?.archived === isArchived
    );
    setNewData(filterList);
  }, [searchField]);

  useEffect(() => {
    if (filterCheck === 0) {
      const filterList = planList.filter((plan) => plan.archived === isArchived);
      setNewData(filterList);
    } else if (filterCheck === 1) {
      const filterList = planList.filter(
        (plan) =>
          plan?.responsibles.find((responsible) => responsible.directus_users_id.id === user.id) &&
          plan?.archived === isArchived
      );
      setNewData(filterList);
    } else if (filterCheck === 2) {
      const filterList = planList.filter(
        (plan) =>
          plan?.responsibles.find((responsible) => responsible.directus_users_id.id === user.id) === undefined &&
          plan?.archived === isArchived &&
          dispoIamIn.find((dispo) => dispo.id === plan.id) === undefined
      );
      setNewData(filterList);
    }
  }, [filterCheck]);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="Plans flex max-w-[100vw] min-h-screen">
      <NavbarLeft sub={1} user={user} />
      <div className="flex flex-col h-full page-Container">
        <NavbarTop urls={['home', 'plans']} user={user} />
        <div className="p-10 space-y-8">
          <h1 className="font-bold text-3xl text-blue-500">TOUS LES DISPOSITIFS</h1>
          <div className="px-3 py-4 space-y-6 bg-gray-50 rounded">
            <div className="flex flex-col items-center space-y-6 px-5 xl:flex-row xl:space-y-0 xl:justify-between">
              <div className="w-1/3">
                <Searchbar placeholder={'Rechercher par nom...'} onChangeHandler={onSearchChange} />
              </div>
              {user.role.name === 'Administrator' ? (
                <div className="flex space-x-2 xl:space-x-1">
                  <button
                    className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${
                      isArchived ? 'bg-gray-300' : 'bg-blue-500 text-white'
                    }`}
                    onClick={() => {
                      setIsArchived(false);
                    }}>
                    En cours
                  </button>
                  <button
                    className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${
                      isArchived ? 'bg-blue-500 text-white' : 'bg-gray-300'
                    }`}
                    onClick={() => {
                      setIsArchived(true);
                    }}>
                    Archivé
                  </button>
                </div>
              ) : (
                <div className="flex space-x-2 xl:space-x-1">
                  <div className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white bg-blue-500 text-white`}>
                    En cours
                  </div>
                </div>
              )}
            </div>
            <div className="relative">
              <div className="absolute right-5 -top-3 flex space-x-2">
                <div className="flex items-center space-x-1">
                  <input
                    type="checkbox"
                    id="all"
                    onChange={() => {
                      setFilterCheck(0);
                    }}
                    checked={filterCheck === 0}
                  />
                  <label htmlFor="all">Tous</label>
                </div>
                <div className="flex items-center space-x-1">
                  <input
                    type="checkbox"
                    id="respo"
                    onChange={() => {
                      setFilterCheck(1);
                    }}
                    checked={filterCheck === 1}
                  />
                  <label htmlFor="respo">Dont je suis responsable</label>
                </div>
                <div className="flex items-center space-x-1">
                  <input
                    type="checkbox"
                    id="engaded"
                    onChange={() => {
                      setFilterCheck(2);
                    }}
                    checked={filterCheck === 2}
                  />
                  <label htmlFor="engaded">Possibilité de s'inscrire</label>
                </div>
              </div>
            </div>
            <CardList data={newData} type={'plans'} user={user} validation={dispoWithValidation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
