export const client = process.env.REACT_APP_CLIENT_NAME;
import React from 'react';
import SubMenu from '../Submenu/Submenu';
import './NavbarLeft.css';
import { NavLink } from 'react-router-dom';

function NavbarLeft(props) {
  return (
    <div
      className={`NavbarLeft bg-blue-800 sticky top-0 text-white max-h-screen pt-14 visible ${
        props.blur ? 'blur-sm' : 'blur-none'
      }`}>
      <div>
        <a href="/home" className="relative flex justify-center pb-10">
          <img src={`/assets/images/${client}_navleft.png`} alt={'logo'} className="w-8/12" />
        </a>
      </div>
      {props.user.role.name === 'Contact' ? (
        <nav className="flex flex-col justify-start px-8">
          <NavLink to="/home" className={({ isActive }) => (isActive ? 'text-white bg-gray-900' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mes signatures</p>
          </NavLink>
          <NavLink to="/contact/evaluation" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mes évaluations</p>
          </NavLink>
        </nav>
      ) : (
        <nav className="flex flex-col justify-start px-8">
          <NavLink to="/home" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Accueil</p>
          </NavLink>
          {
            <NavLink to="/user/plans/0" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
              <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mes Dispositifs</p>
            </NavLink>
          }
          {props.sub === 1 && (
            <div>
              <SubMenu type="plans" menu={['Je gère', "Je peux m'inscrire"]} />
            </div>
          )}
          <NavLink to="/user/commitments/0" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mes engagements</p>
          </NavLink>
          {/* {props.sub === 2 && (
            <div>
              <SubMenu type="commitments" />
            </div>
          )} */}
          <NavLink to="/user/events/0" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mes Évènements</p>
          </NavLink>
          {props.sub === 3 && (
            <div>
              <SubMenu type="events" menu={['Je suis inscrit', "Je peux m'inscrire", 'Je gère', 'Public']} />
            </div>
          )}
          {props.user.role.name === 'Administrator' && (
            <NavLink to="/admin/followed" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
              <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Annuaire</p>
            </NavLink>
          )}
          <NavLink to="/account" className={({ isActive }) => (isActive ? 'text-white bg-gray-900/70' : '')}>
            <p className="py-2 my-2 pl-2 text-gray-200 rounded-sm hover:text-white text-left">Mon Compte</p>
          </NavLink>
        </nav>
      )}
    </div>
  );
}

export default NavbarLeft;
