import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif_directus_files';

const PostDispFile = (dispoId, fileId, userId = null) => {
  return axios.post(
    API_URL,
    {
      dispositif_id: dispoId,
      directus_files_id: fileId,
      user_id: userId
    },
    { headers: authHeader() }
  );
};

const GetOneDispFile = (id, userId = null) => {
  const filters = ['filter[dispositif_id][_eq]=' + id];

  if (userId === null) {
    filters.push('filter[user_id][_null]=true');
  }

  if (userId !== null && userId !== -1) {
    filters.push('filter[user_id][_eq]=' + userId);
  }

  return axios.get(
    API_URL +
      '?' +
      filters.join('&') +
      '&fields=id,directus_files_id.id,directus_files_id.title,dispositif_id,user_id,user_id.first_name,user_id.last_name,user_id.group.group_id.name',
    { headers: authHeader() }
  );
};

const DeleteOneDispFile = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const FormatGetOneDispFile = (fileList, setFileList, id, userId) => {
  useEffect(() => {
    const list = [];

    const MakeFileList = async () => {
      const data = await (await DispDirFileService.GetOneDispFile(id, userId)).data.data;

      data.map((item) => {
        list.push(item);
        return item;
      });
      setFileList(list);
    };
    MakeFileList();
  }, []);
  return fileList;
};
const FormatGetOneDispFileNew = (fileList, setFileList, id, userId) => {
  const list = [];

  const MakeFileList = async () => {
    const data = await (await DispDirFileService.GetOneDispFile(id, userId)).data.data;

    data.map((item) => {
      list.push(item);
      return item;
    });
    const fileForEveryOne = await DispDirFileService.GetOneDispFile(id, null);
    fileForEveryOne.data.data.map((item) => {
      list.push(item);
    });
    setFileList(list);
  };
  MakeFileList();
};
const getAllFileForAdmin = (id) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      id +
      '&fields=id,directus_files_id.id,directus_files_id.title,dispositif_id,user_id,user_id.first_name,user_id.last_name,user_id.group.group_id.name',
    { headers: authHeader() }
  );
};
const FormatGetOneDispFileAdmin = (setFileList, id) => {
  const list = [];

  const MakeFileList = async () => {
    const allfile = await getAllFileForAdmin(id);

    allfile.data.data.map((item) => {
      list.push(item);
      return item;
    });
    setFileList(list);
  };
  MakeFileList();
};

const DispDirFileService = {
  PostDispFile,
  GetOneDispFile,
  DeleteOneDispFile,
  FormatGetOneDispFile,
  FormatGetOneDispFileNew,
  FormatGetOneDispFileAdmin
};

export default DispDirFileService;
