import React, { useEffect, useState } from 'react';

function TableOrganizations(props) {
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckAll = (statuts) => {
    if (statuts === true) {
      props.setList(
        props.data.reduce((allData, item) => {
          return [...allData, ...getExportData(item)];
        }, [])
      );
    } else {
      props.setList([]);
    }
  };

  const getExportData = (item) => {
    const orgaContacts = props.contacts
      .filter((c) => c.organization_id === item.id && c.directus_users_id)
      .map((d) => {
        const contactItem = d.directus_users_id;
        return {
          contact_first_name: contactItem.first_name,
          contact_last_name: contactItem.last_name,
          contact_team: contactItem.team?.map((gr) => {
            if (gr.group_id.parentgroup) {
              return [gr.group_id.name, gr.group_id.parentgroup.name];
            } else {
              return gr.group_id.name;
            }
          }),
          contact_email: contactItem.email,
          contact_email2: contactItem.email2,
          contact_phone: contactItem.phones,
          contact_last_access: contactItem.lastAccess,
          contact_id: contactItem.id
        };
      });
    if (orgaContacts && orgaContacts.length > 0) {
      return orgaContacts.map((d) => ({ ...d, ...item }));
    } else {
      const emptyContact = {
        contact_first_name: null,
        contact_last_name: null,
        contact_team: null,
        contact_email: null,
        contact_email2: null,
        contact_phone: null,
        contact_last_access: null,
        contact_id: null
      };
      return [{ ...emptyContact, ...item }];
    }
  };

  const handleAdd = (statuts, item) => {
    if (statuts === true) {
      props.setList([...props.list, ...getExportData(item)]);
    } else {
      props.setList(props.list.filter((itemList) => itemList.id !== item.id));
    }
  };

  useEffect(() => {
    [...new Set(props.list.map((d) => d.id))].length === props.data.length ? setCheckAll(true) : setCheckAll(false);
  }, [props.list, props.data]);

  return (
    <div className="TableOrganizations">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 h-96 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckAll(e.target.checked);
                        }}
                        checked={checkAll}
                      />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Raison sociale
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      SIRET
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Adresse postale (N° et rue)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Code postal
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Ville
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Pays
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Bénéficiaires principaux
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item, index) => {
                    return (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              handleAdd(e.target.checked, item);
                            }}
                            checked={props.list[0] !== undefined && props.list.find((li) => li.id === item.id)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-start text-sm font-semibold text-gray-900">
                          <a href={`/admin/organizations/${item.id}`} className="hover:underline">
                            {item.name}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          {item.numero_siret_rna}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">{item.adress}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          {item.postal_code}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">{item.city}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">{item.country}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          {item.beneficiaries}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableOrganizations;
