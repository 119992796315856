import { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif';

const GetAllDispoWithParent = (parentId) => {
  return axios.get(
    API_URL +
      '?filter[parent][_eq]=' +
      parentId +
      '&filter[template][_eq]=' +
      292 +
      '&fields=archived,date_created,description,enddate,evaluation_step,files_vie_asso_id,id,name,parent,template,status,responsibles.directus_users_id.id,picture',
    {
      headers: authHeader()
    }
  );
};

const FormatGetAllDispoWithParent = (dispos, setDispos, parentId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllDispoWithParent(parentId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDispos(list);
    };
    Makelist();
  }, []);
  return dispos;
};

const GetDispoValid = (parent, filesAssoId) => {
  return axios.get(
    API_URL +
      '?filter[archived][_eq]=false&filter[parent][_eq]=' +
      parent +
      '&filter[files_vie_asso_id][_eq]=' +
      filesAssoId +
      '&fields=id,name,nbrhour,description,shortdescription,enddate,picture,responsibles.role,responsibles.role_details,responsibles.id,responsibles.directus_users_id.first_name,responsibles.directus_users_id.last_name,responsibles.directus_users_id.email,responsibles.directus_users_id.id',
    { headers: authHeader() }
  );
};

const getOneDispo = (id) => {
  return axios.get(
    API_URL +
      '?filter[id][_eq]=' +
      id +
      '&fields=name,description,shortdescription,status,tag.tag_id.label,responsibles.directus_users_id.group.group_id.name,responsibles.directus_users_id.group.group_id.id,responsibles.directus_users_id.group.group_id.parentgroup.name,responsibles.directus_users_id.id,responsibles.directus_users_id.first_name,responsibles.directus_users_id.email,responsibles.directus_users_id.last_name,responsibles.directus_users_id.phones,picture,responsibles.role,responsibles.role_details,responsibles.id,responsibles.date_created,responsibles.evaluation_id.id,responsibles.evaluation_id.total,responsibles.evaluation_id_2.id,responsibles.evaluation_id_2.total,enddate,nbrhour,id,step.step_id.name,step.step_id.tovalidate,step.step_id.isoptional,step.step_id.id,step.order,template,startdate,location,start_hourly,end_hourly,parent.id,parent.name,parent.picture,parent.template,evaluation_step,files_vie_asso_id',
    { headers: authHeader() }
  );
};

const getUserDispo = (id) => {
  return axios.get(
    API_URL +
      '?filter[archived][_eq]=false&filter[responsibles][directus_users_id][_eq]=' +
      id +
      '&filter[template][_neq]=293&fields=id,name,status,description,picture,nbrhour,template,date_created',
    { headers: authHeader() }
  );
};

const patchDispo = (id, name, shortDes, des, picture, date, hour) => {
  return axios.patch(
    API_URL + '/' + id,
    {
      name: name,
      shortdescription: shortDes,
      description: des,
      picture: picture,
      enddate: date,
      nbrhour: hour
    },
    { headers: authHeader() }
  );
};

const PatchTemplateDispo = (id, template) => {
  return axios.patch(
    API_URL + '/' + id,
    {
      template: template
    },
    { headers: authHeader() }
  );
};

const postDispo = (name, des, shortDes, picture, date, hour, template, parent) => {
  return axios.post(
    API_URL,
    {
      name: name,
      description: des,
      shortdescription: shortDes,
      picture: picture,
      enddate: date,
      nbrhour: hour,
      template: template,
      parent: parent
    },
    { headers: authHeader() }
  );
};

const PostDispoAsso = (name, des, shortDes, picture, date, hour, template, parent, fileAsso) => {
  return axios.post(
    API_URL,
    {
      name: name,
      description: des,
      shortdescription: shortDes,
      picture: picture,
      enddate: date,
      nbrhour: hour,
      template: template,
      parent: parent,
      files_vie_asso_id: fileAsso,
      statuts: 'En cours'
    },
    { headers: authHeader() }
  );
};

const FormatUserDispo = (dispo, setDispo, userId) => {
  useEffect(() => {
    const mes_dispositifs = [];

    const getDispo = async () => {
      const data = await DispoService.getUserDispo(userId);
      data.data.data
        .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
        .map((dispositifs) => mes_dispositifs.push(dispositifs));
      setDispo(mes_dispositifs);
    };
    getDispo();
  }, []);

  return dispo;
};

const getRespDispo = (id) => {
  return axios.get(
    API_URL +
      '?filter[id][_eq]=' +
      id +
      '&fields=responsibles.directus_users_id.id,responsibles.directus_users_id.first_name,responsibles.directus_users_id.last_name,responsibles.directus_users_id.email,responsibles.role',
    { headers: authHeader() }
  );
};

const getTagDispo = (id) => {
  return axios.get(API_URL + '?filter[id][_eq]=' + id + '&fields=tag.tag_id.id,tag.tag_id.label', {
    headers: authHeader()
  });
};

const getAllDispo = () => {
  return axios.get(
    API_URL +
      '?filter[template][_neq]=293&fields=id,name,description,responsibles.directus_users_id.first_name,responsibles.directus_users_id.last_name,responsibles.directus_users_id.id,tag.tag_id.label,picture,template,files_vie_asso_id,nbrhour,archived,date_created&limit=-1',
    {
      headers: authHeader()
    }
  );
};

const getNotAllDispo = (id) => {
  return axios.get(
    API_URL +
      '?filter[archived][_eq]=false&filter[responsibles][directus_users_id][_neq]=' +
      id +
      '&fields=id,name,status,description,picture',
    { headers: authHeader() }
  );
};

const FormatGetOneDispo = (dispoList, setDispoList, id) => {
  useEffect(() => {
    const list = [];
    const Makelist = async () => {
      const data = await getOneDispo(id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDispoList(list[0]);
    };
    Makelist();
  }, []);
  return dispoList;
};

const FormatGetNotAllDispo = (dispo, setDispo, id) => {
  useEffect(() => {
    const list = [];

    const MakeDispoList = async () => {
      const data = await DispoService.getNotAllDispo(id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDispo(list);
    };
    MakeDispoList();
  }, []);
  return dispo;
};

const FormatRespDispo = () => {
  const [resp, setResp] = useState([]);

  useEffect(() => {
    const responsibleList = [];

    const getResp = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('id');
      const data = await DispoService.getRespDispo(id);
      data.data.data.map((responsible) =>
        responsible.responsibles.map((data) =>
          responsibleList.push({
            email: data.directus_users_id.email,
            first_name: data.directus_users_id.first_name,
            id: data.directus_users_id.id,
            last_name: data.directus_users_id.last_name,
            role: data.role
          })
        )
      );
      setResp(responsibleList);
    };
    getResp();
  }, []);

  return resp;
};

const FormatTagDispo = () => {
  const [tag, setTag] = useState([]);

  useEffect(() => {
    const tagList = [];

    const getTag = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('id');
      const data = await DispoService.getTagDispo(id);
      data.data.data.map((label) => label.tag.map((data) => tagList.push(data.tag_id)));
      setTag(tagList);
    };
    getTag();
  }, []);

  return tag;
};

const FormatDispoList = (dispos, setDispos) => {
  useEffect(() => {
    const dispo_list = [];

    const getDispoList = async () => {
      const dispos = await getAllDispo();
      dispos.data.data.map((item) => {
        dispo_list.push(item);
        return item;
      });
      setDispos(dispo_list);
    };
    getDispoList();
  }, []);
  return dispos;
};

const FormatOneDispo = (dispo, setDispo, id) => {
  useEffect(() => {
    const dispo_list = [];

    const MakeDispoList = async () => {
      const data = await DispoService.getOneDispo(id);
      data.data.data.map((item) => {
        dispo_list.push(item);
        return item;
      });
      setDispo(dispo_list[0]);
    };
    MakeDispoList();
  }, []);
  return dispo;
};

const FormatGetDispoValid = (dispoList, setDispoList, parent, filesAssoId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await (await GetDispoValid(parent, filesAssoId)).data.data;

      data.map((item) => {
        list.push(item);
        return item;
      });
      setDispoList(list[0]);
    };
    MakeList();
  }, []);
  return dispoList;
};

const createDispo = () => {
  return axios.post(API_URL, {
    headers: authHeader()
  });
};

const PostEvent = (title, des, startDate, endDate, startHour, endHour, location, parent, status, picture = null) => {
  return axios.post(
    API_URL,
    {
      name: title,
      description: des,
      startdate: startDate,
      enddate: endDate,
      start_hourly: startHour,
      end_hourly: endHour,
      location: location,
      template: 293,
      parent: parent,
      status,
      picture
    },
    { headers: authHeader() }
  );
};

const PatchDispoEvaluationStep = (dispoId, evaluationStep) => {
  return axios.patch(API_URL + '/' + dispoId, { evaluation_step: evaluationStep }, { headers: authHeader() });
};

const GetDispoWithFilesAssoId = (id) => {
  return axios.get(API_URL + '?filter[files_vie_asso_id][_eq]=' + id, { headers: authHeader() });
};

const FormatGetDispoWithFilesAssoId = (dispo, setDispo, filesId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetDispoWithFilesAssoId(filesId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDispo(list);
    };
    Makelist();
  }, []);
  return dispo;
};

const DeleteDispo = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const getAllEvent = () => {
  return axios.get(
    API_URL +
      '?filter[template][_eq]=293&fields=id,name,archived,description,picture,template,parent,responsibles.directus_users_id,status,archived,date_created&limit=-1',
    {
      headers: authHeader()
    }
  );
};

const modifDateEvent = (eventId, startdate, start_hourly, enddate, end_hourly) => {
  return axios.patch(
    API_URL + '/' + eventId,
    { startdate, start_hourly, enddate, end_hourly },
    { headers: authHeader() }
  );
};

const archiveDispo = (id) => {
  return axios.patch(API_URL + '/' + id, { archived: true }, { headers: authHeader() });
};
const DispoService = {
  GetAllDispoWithParent,
  FormatGetAllDispoWithParent,
  getOneDispo,
  getUserDispo,
  PostDispoAsso,
  FormatUserDispo,
  getRespDispo,
  FormatRespDispo,
  getTagDispo,
  FormatTagDispo,
  createDispo,
  getAllDispo,
  FormatDispoList,
  patchDispo,
  postDispo,
  getNotAllDispo,
  FormatGetNotAllDispo,
  FormatGetOneDispo,
  FormatOneDispo,
  GetDispoValid,
  FormatGetDispoValid,
  PatchTemplateDispo,
  DeleteDispo,
  PatchDispoEvaluationStep,
  PostEvent,
  FormatGetDispoWithFilesAssoId,
  getAllEvent,
  archiveDispo,
  modifDateEvent,
  GetDispoWithFilesAssoId
};

export default DispoService;
