import React, { useEffect, useState } from 'react';
import StarEvalIcon from '../../../../../oldComponents/StarEvalIcon/StarEvalIcon';
import Rating from 'react-rating';
import { QuestionEvaluation } from '../../../../../usualTypes/BasicTypes';

type ObjQuestion = {
  id_question: number;
  answer: number | string;
};
type Props = {
  question: QuestionEvaluation;
  onChange: (id: number, value: string | number) => void;
  oldEvals: ObjQuestion[];
};
const StarRating = ({ question, onChange, oldEvals }: Props) => {
  const [score, setScore] = useState<number>(0);

  const getOldValue = () => {
    if (oldEvals) {
      const indexEvals = oldEvals.findIndex((obj) => obj.id_question === question.id);
      if (indexEvals === -1) return;
      setScore(oldEvals[indexEvals].answer as number);
    }
  };
  useEffect(() => {
    getOldValue();
  }, [oldEvals]);
  return (
    <div className="RatingCard flex justify-between items-center py-5">
      <div>
        <div className="flex">
          <h3 className="font-semibold">{question.title}</h3>
          {question.mandatory && <span className="text-red-500">*</span>}
        </div>
        <p className="text-gray-500/80 text-sm">{question.description}</p>
      </div>
      <div className="flex">
        <Rating
          initialRating={score}
          onChange={(value) => {
            setScore(value);
            onChange(question.id, value);
          }}
          start={0}
          stop={10}
          emptySymbol={
            <div className="text-gray-500">
              <StarEvalIcon />
            </div>
          }
          placeholderSymbol={
            <div className="text-yellow-200">
              <StarEvalIcon />
            </div>
          }
          fullSymbol={
            <div className="text-yellow-400">
              <StarEvalIcon />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default StarRating;
