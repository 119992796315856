import React, { useEffect, useState } from 'react';

import './TablePlans.css';
import UserDispoService from '../../oldService/userDispoService';

function TablePlans(props) {
  const [checkAll, setCheckAll] = useState(false);
  const [nbrParticipant, setNbrParticipant] = useState([]);

  const handleCheckAll = (statuts) => {
    if (statuts === true) {
      props.setList(
        props.data.map((item) => {
          return {
            name: item.name,
            description: item.description,
            number_hours: item.nbrhour,
            responsibles: item.responsibles.map((resp) => {
              return resp.directus_users_id.first_name + ' ' + resp.directus_users_id.last_name;
            }),
            tag: item.tag.map((tag) => {
              return tag.tag_id.label;
            }),
            id: item.id
          };
        })
      );
    } else {
      props.setList([]);
    }
  };

  const handleAdd = (statuts, item) => {
    if (statuts === true) {
      props.setList([
        ...props.list,
        {
          name: item.name,
          description: item.description,
          number_hours: item.nbrhour,
          responsibles: item.responsibles.map((resp) => {
            return resp.directus_users_id.first_name + ' ' + resp.directus_users_id.last_name;
          }),
          tag: item.tag.map((tag) => {
            return tag.tag_id.label;
          }),
          id: item.id
        }
      ]);
    } else {
      props.setList(
        props.list.filter(
          (itemList) =>
            itemList.name !== item.name &&
            itemList.description !== item.description &&
            itemList.number_hours !== item.number_hours &&
            itemList.responsibles !== item.responsibles &&
            itemList.tag !== item.tag
        )
      );
    }
  };

  useEffect(() => {
    props.list.length === props.data.length ? setCheckAll(true) : setCheckAll(false);
  }, [props.list, props.data]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await UserDispoService.getCountAllUserByDispo();
      setNbrParticipant(data.data.data);
    };
    fetchData();
  }, []);

  return (
    <div className="TablePlans">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 h-96 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckAll(e.target.checked);
                        }}
                        checked={checkAll}
                      />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Dispositif
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Responsable(s)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Tag(s)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Nombre d'heures
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Nombre de participants
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item, index) => {
                    return (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              handleAdd(e.target.checked, item);
                            }}
                            checked={props.list[0] !== undefined && props.list.find((li) => li.id === item.id)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-start text-sm font-semibold text-gray-900">
                          <a
                            href={
                              item.template === 291
                                ? '/user/plans/details?id=' + item.id
                                : '/user/asso/details?id=' + item.id
                            }
                            className="hover:underline">
                            {item.name}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          {item.description !== null
                            ? item.description.length > 15
                              ? item.description.substring(0, 15) + ' ...'
                              : item.description
                            : ''}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          <div className="flex justify-center items-center space-x-1">
                            {item.responsibles.map((item, index, array) => {
                              return (
                                <div key={index}>
                                  {index < 2 && (
                                    <div className="flex space-x-1">
                                      <p>
                                        {item.directus_users_id &&
                                          item.directus_users_id.last_name + ' ' + item.directus_users_id.first_name}
                                      </p>
                                      {index === 0 && array.length > 1 ? <span>/</span> : ''}
                                      {index === 1 && <span>...</span>}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          <div className="flex justify-center items-center space-x-1">
                            {item.tag.map((item, index, array) => {
                              return (
                                <div key={index}>
                                  {item && (
                                    <div className="flex space-x-1">
                                      <p>{item.tag_id.label}</p>
                                      {index < array.length - 1 ? <span>/</span> : ''}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">{item.nbrhour}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          {nbrParticipant.find((dispo) => dispo.dispositif === item.id)?.count}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablePlans;
