import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/evaluation';

const GetAllEvaluations = () => {
  return axios.get(
    API_URL +
      '?limit=-1&fields=id,field1,total,type,user.id,user.first_name,user.last_name,user_created.id,user_created.first_name,user_created.last_name,user_created.group.group_id.name,user.group.group_id.name,dispositif.id,dispositif.name',
    { headers: authHeader() }
  );
};

const FormatGetAllEvaluations = (evals, setEvals) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllEvaluations();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setEvals(list);
    };
    Makelist();
  }, []);
  return evals;
};

const GetOneEvaluation = (id) => {
  return axios.get(API_URL + '?filter[id][_eq]=' + id, { headers: authHeader() });
};

const GetAllEvalWithDispo = (dispoId) => {
  return axios.get(API_URL + '?filter[dispositif][_eq]=' + dispoId, { headers: authHeader() });
};

const FormatGetAllEvalWithDispo = (evals, setEvals, dispoId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllEvalWithDispo(dispoId);

      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setEvals(list);
    };
    Makelist();
  }, []);
  return evals;
};

const PostEvaluation = (evals, total, field1, field2, welcome, type, user, dispo) => {
  return axios.post(
    API_URL,
    {
      evals: evals,
      total: total,
      field1: field1,
      field2: field2,
      welcome: welcome,
      type: type,
      user: user,
      dispositif: dispo
    },
    { headers: authHeader() }
  );
};

const PostEvaluationAsso = (evals, total, field1, type, user, dispo) => {
  return axios.post(
    API_URL,
    {
      evals: evals,
      total: total,
      field1: field1,
      type: type,
      user: user,
      dispositif: dispo
    },
    { headers: authHeader() }
  );
};

const FormatGetOneEvaluation = (lastEval, setLastEval, evalId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetOneEvaluation(evalId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setLastEval(list);
    };
    Makelist();
  }, []);
  return lastEval;
};

const DeleteEvaluation = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};
const postNewEvaluation = (evaluation, total, type, user, dispoId = null) => {
  return axios.post(
    API_URL,
    {
      evals: evaluation,
      total: total,
      type: type,
      user: user,
      dispositif: dispoId
    },
    { headers: authHeader() }
  );
};
const EvaluationServices = {
  GetAllEvaluations,
  FormatGetAllEvaluations,
  GetOneEvaluation,
  GetAllEvalWithDispo,
  FormatGetAllEvalWithDispo,
  DeleteEvaluation,
  FormatGetOneEvaluation,
  PostEvaluation,
  PostEvaluationAsso,
  postNewEvaluation
};

export default EvaluationServices;
