import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import FormService from '../../../../oldService/formService';
import { useState } from 'react';
import DispoStepService from '../../../../oldService/dispoStepService';
import RadioGroup from '../../../Fields/RadioGroup';
import { PunctualFilesServices } from '../../../../oldService/punctualFilesService';
import { OrgaDirUsers1Services } from '../../../../oldService/organizationDirectusUsers1Services.js';
import DispoService from '../../../../oldService/dispService';
import updateAction from './updateAction';
import updateErrors from '../updateErrors';
import updateSuccess from '../updateSuccess';

const Step2 = ({ defaultValues, formRef, userDispo, blur }) => {
  const { actions } = useStateMachine({ updateAction, updateErrors, updateSuccess });
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [formFrench, setFormFrench] = useState([]);
  FormService.FormatGetForm(formFrench, setFormFrench, 'Fiche Mission');

  const [formEnglish, setFormEnglish] = useState([]);
  FormService.FormatGetForm(formEnglish, setFormEnglish, ' Mission Sheet');

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [punctual, setPunctual] = useState([]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  React.useEffect(() => {
    const subscription = watch((value) => {
      actions.updateAction({ data: formatData(value), raw: value });
    });
    return () => subscription.unsubscribe();
  }, [watch, formFrench]);

  React.useEffect(() => {
    actions.updateErrors({ step2: errors });
  }, [errors]);

  useEffect(() => {
    setValue('french_mission', true);
  }, []);

  const [dispo, setDispo] = useState([]);
  DispoService.FormatGetOneDispo(dispo, setDispo, id);

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  const formatData = (data) => {
    const formattedData = [];
    if (watch('french_mission')) {
      if (formFrench[0]) {
        formFrench[0].questions_id.map((item, index) => {
          if (index === 4 && data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: 4.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 99,
              data: punctual[0].organization_id.name
            });
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    } else {
      if (formEnglish[0]) {
        formEnglish[0].questions_id.map((item, index) => {
          if (index === 4 && data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: 4.1,
              data: data[item.questions_id.id].value.last_name + ' ' + data[item.questions_id.id].value.first_name
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.2,
              data: data[item.questions_id.id].value.function
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.3,
              data: data[item.questions_id.id].value.email
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 4.4,
              data: data[item.questions_id.id].value.phones
            });
            formattedData.push({
              id: item.questions_id.id,
              index: 99,
              data: punctual[0].organization_id.name
            });
          } else if (data[item.questions_id.id]) {
            formattedData.push({
              id: item.questions_id.id,
              index: index,
              data: data[item.questions_id.id]
            });
          }
        });
      }
    }

    return formattedData;
  };

  const LanguageOnClick = (id) => {
    if (id === 0) {
      setValue('french_mission', true);
    } else if (id === 1) {
      setValue('french_mission', false);
    }
  };

  const onSubmit = async () => {
    actions.updateSuccess({ step2: true });
  };

  const isFieldVisible = (question) => {
    return true;
  };

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  return (
    <div>
      <div className={blur ? 'blur-sm' : 'blur-none'}>
        <div className="flex justify-center space-x-20">
          <button
            onClick={() => {
              LanguageOnClick(0);
            }}
            className={`${watch('french_mission') && 'border-2'} border-blue-500 p-1 rounded-sm`}>
            <img src="https://flagcdn.com/fr.svg" width="40" alt="France" />
          </button>
          <button
            onClick={() => {
              LanguageOnClick(1);
            }}
            className={`${!watch('french_mission') && 'border-2'} border-blue-500 p-1 rounded-sm`}>
            <img src="https://flagcdn.com/gb.svg" width="45" alt="United Kingdom" />
          </button>
        </div>

        <div>
          {watch('french_mission') ? (
            <div className="space-y-2">
              <h2 className="font-bold text-2xl text-blue-700/90 text-center">Demander la validation de ma mission</h2>
              <p className="text-gray-700">
                L’Objectif de la mission humanitaire, sociale ou citoyenne est d’apprendre à dépasser ses préjugés, au
                contact direct des personnes moins favorisées et de découvrir des milieux engagés par un service de
                terrain bénévole utile sur deux mois.
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              <h2 className="font-bold text-2xl text-blue-700/90 text-center">Ask for the validation of my mission</h2>
              <p className="text-gray-700">
                The objective of the humanitarian, social or civic mission is to learn to overcome prejudices, in direct
                contact with less privileged people and to discover environments involved by a useful voluntary field
                service over two months.
              </p>
            </div>
          )}
        </div>
        <div className="p-8 rounded">
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            {watch('french_mission') ? (
              <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
                {formFrench[0] &&
                  formFrench[0].questions_id.map((item, index) => {
                    if (!isFieldVisible(item.questions_id)) {
                      return <></>;
                    }
                    if (item.questions_id.name === 'Sélectionner le contact qui sera votre tuteur') {
                      return null;
                    }
                    return (
                      <div key={index}>
                        <div className="space-y-2">
                          <h3 className="text-lg">
                            {item.questions_id.name} <Obligatory />
                          </h3>
                          {item.questions_id.type === 'text' && (
                            <textarea
                              className="w-full p-2 h-28 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'choice' && (
                            <RadioGroup
                              items={item.questions_id.questions_choice_id.map((itemQues) => {
                                return itemQues.questions_choice_id.name;
                              })}
                              index={index}
                              register={register}
                              validationSchema={{ required: true }}
                              id={item.questions_id.id}
                              name={item.questions_id.id}
                            />
                          )}
                          {item.questions_id.type === 'url' && (
                            <input
                              type={'url'}
                              className="w-full p-2 border border-gray-300"
                              placeholder="https://"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'description' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'text'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                          {item.questions_id.type === 'nbr' && (
                            <input
                              type="number"
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                        </div>
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
                {formEnglish[0] &&
                  formEnglish[0].questions_id.map((item, index) => {
                    if (item.questions_id.name === 'Select the contact who will be your tutor') {
                      return null;
                    }
                    return (
                      <div key={index}>
                        <div className="space-y-2">
                          <h3 className="text-lg">
                            {item.questions_id.name} <Obligatory />
                          </h3>
                          {item.questions_id.type === 'text' && (
                            <textarea
                              className="w-full p-2 h-28 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'choice' && (
                            <RadioGroup
                              items={item.questions_id.questions_choice_id.map((itemQues) => {
                                return itemQues.questions_choice_id.name;
                              })}
                              index={index}
                              register={register}
                              validationSchema={{ required: true }}
                              id={item.questions_id.id}
                              name={item.questions_id.id}
                            />
                          )}
                          {item.questions_id.type === 'url' && (
                            <input
                              type={'url'}
                              className="w-full p-2 border border-gray-300"
                              placeholder="https://"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                          {item.questions_id.type === 'description' && (
                            <div>
                              <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                              <input
                                type={'text'}
                                className="w-full p-2 border border-gray-300"
                                placeholder="À compléter"
                                {...register(item.questions_id.id, { required: true })}
                              />
                            </div>
                          )}
                          {item.questions_id.type === 'nbr' && (
                            <input
                              type="number"
                              className="w-full p-2 border border-gray-300"
                              placeholder="À compléter"
                              {...register(item.questions_id.id, { required: true })}
                            />
                          )}
                        </div>
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step2;
