import React from 'react';
import DocumentsServices from '../../oldService/documentServices';
import './ModalSignature.css';

const ModalSignature = ({ setDisplayModal, setBlur, data }) => {
  const handleDisplay = () => {
    setDisplayModal(false);
    setBlur(false);
  };

  return (
    <div className="ModalValidAdmin">
      <div
        id="authentication-modal"
        tabIndex="-1"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex modalSignatureContact_container"
        aria-modal="true"
        role="dialog">
        <div className="relative bg-white rounded shadow modalSignatureCustom_size">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={handleDisplay}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-6 px-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900">Signature du document</h3>
            <div className="space-y-6">
              <div>
                <iframe
                  src={data && data.link + '&disable_domain_validation=true'}
                  title="Signature"
                  className="w-full iframeCustom_size"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSignature;
