import React, { Dispatch, SetStateAction } from 'react';
import ExportEvals from '../../oldComponents/ExportEvals/ExportEvals';
import { Dispositif, User, UserDispo } from '../../usualTypes/BasicTypes';
import ExportVieAsso from '../ExportVieAsso';
import ExportMembersAsso from '../ExportMembersAsso';
import UserDispoService from '../../oldService/userDispoService';
import ExportListEvent from '../ExportListEvent';

type Props = {
  dispoChildren: Dispositif;
  dispoName: string;
  setDisplayModal: Dispatch<SetStateAction<boolean>>;
  setBlur: Dispatch<SetStateAction<boolean>>;
  DispoType: string;
  Members: User[];
  Responsibles: UserDispo[];
};
const ModalExportEvaluation = ({
  dispoChildren,
  dispoName,
  setDisplayModal,
  setBlur,
  DispoType,
  Members,
  Responsibles
}: Props) => {
  const handleDisplay = () => {
    setDisplayModal(false);
    setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };

  const handleExportCSV = async () => {
    const result = await UserDispoService.requestStatsPunctual(dispoChildren);
    const csvDataBlob = new Blob(['\uFEFF' + result.data], { type: 'text/csv;charset=utf-16;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(csvDataBlob);
    link.setAttribute('href', url);
    link.setAttribute('download', `Export_${dispoName}_Suiviavancement_${new Date().toLocaleDateString()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="">
      <div
        id="authentication-modal"
        className="overflow-y-scroll overflow-x-hidden fixed z-50 w-full md:inset-0 flex justify-center padding-custom-modal backgoundModalColor">
        <div className="ModalAddDoc bg-white rounded shadow">
          <div className="relative bg-white rounded shadow w-[40vw]">
            <button
              //   disabled={loading}
              type="button"
              className="absolute top-1 right-1.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDisplay}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-4 px-8">
              <h3 className="mb-2 text-xl font-medium text-gray-900">Exports disponibles pour ce dispositif</h3>
              <div className="text-gray-600 mb-4 italic text-sm">
                Vous retrouverez ci-dessous tous les exports disponibles pour ce dispositif. L'export contiendra toutes
                les informations disponibles ou saisies depuis le début du dispositif jusqu'au moment de l'export
              </div>
              <div className="flex flex-col justify-center">
                <div className="w-full">
                  <dd className="mt-1 text-sm leading-6 text-gray-700">
                    <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                      {DispoType === 'DispoParent' && (
                        <>
                          <ExportEvals dispos={dispoChildren} name={dispoName} />
                          <ExportVieAsso childrenDispo={dispoChildren} name={dispoName} />
                        </>
                      )}
                      {DispoType === 'Asso' && (
                        <ExportMembersAsso members={Members} responsibles={Responsibles} name={dispoName} />
                      )}
                      {DispoType === 'punctual' && (
                        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <svg
                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">
                                {"Suivi de l'avancement pour tous les participants"}
                              </span>
                              <span className="flex-shrink-0 text-gray-400">CSV</span>
                            </div>
                          </div>
                          <div className="ml-4 flex flex-shrink-0 space-x-4">
                            <button
                              type="button"
                              className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                              onClick={() => {
                                handleExportCSV();
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                              </svg>
                            </button>
                          </div>
                        </li>
                      )}
                      {DispoType === 'event' && <ExportListEvent ListOfParticipant={Members} name={dispoName} />}
                    </ul>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExportEvaluation;
