import React from 'react';

import './ChoiceCard.css';

function ChoiceCard({ title, data, setData }) {
  const handleOnChange = (value) => {
    setData(value);
  };

  return (
    <div className="ChoiceCard flex flex-col justify-center items-center">
      <p className="block mb-2 text-sm font-medium text-gray-900">{title}</p>
      <div className="flex justify-around space-x-5">
        <div className="flex space-x-1">
          <input
            type="checkbox"
            name="choice1"
            id="choice1"
            checked={data}
            onChange={() => {
              handleOnChange(true);
            }}
          />
          <label htmlFor="choice1" className="block text-sm font-medium text-gray-900">
            Oui
          </label>
        </div>
        <div className="flex space-x-1">
          <input
            type="checkbox"
            name="choice2"
            id="choice2"
            checked={!data}
            onChange={() => {
              handleOnChange(false);
            }}
          />
          <label htmlFor="choice2" className="block text-sm font-medium text-gray-900">
            Non
          </label>
        </div>
      </div>
    </div>
  );
}

export default ChoiceCard;
