import React, { useState, useEffect } from 'react';
import Searchbar from '../Searchbar/Searchbar';
import RatingTableList from '../RatingTableList/RatingTableList';

function Ratings(props) {
  const studList = props.data;
  const [searchField, setSearchField] = useState('');
  const [filteredStud, setFilteredStud] = useState(studList);

  useEffect(() => {
    const newFilteredStud = studList.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchField);
    });
    setFilteredStud(newFilteredStud);
  }, [searchField, studList]);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="Ratings px-5 py-5 space-y-6">
      <div className="flex justify-between items-center pb-3">
        {!props.contact && <h2 className="font-bold text-xl py-5 text-blue-700/70">{props.title}</h2>}
        <div className="w-1/3">
          <Searchbar placeholder="Chercher nom..." onChangeHandler={onSearchChange} />
        </div>
      </div>
      <RatingTableList
        data={filteredStud}
        dispoId={props.dispoId}
        asso={props.asso}
        responsible={props.responsible}
        step={props.step}
      />
    </div>
  );
}

export default Ratings;
