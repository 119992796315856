import React, { useState, useContext } from 'react';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import SearchList from '../../../oldComponents/SearchList/SearchList';
import CategoriesFollowed from '../../../oldComponents/CategoriesFollowed/CategoriesFollowed';

import './Followed.css';
import { AuthContext } from '../../../AuthProvider';

const Followed = () => {
  const title = 'ANNUAIRE';
  const [category, setCategory] = useState(0);
  const { user } = useContext(AuthContext);

  return (
    <div className="flex min-h-screen">
      <NavbarLeft user={user} />
      <div className="Followed flex flex-col h-full w-full">
        <NavbarTop user={user} urls={['home']} />
        <div className="p-10 space-y-8">
          <h1 className="font-bold text-3xl text-blue-500">{title}</h1>
          <div className=" py-4 px-5">
            <CategoriesFollowed setCategory={setCategory} category={category} />
            <div className="p-5 bg-gray-50 rounded">
              <SearchList category={category} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Followed;
