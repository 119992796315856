import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import NavbarLeft from '../../oldComponents/NavbarLeft/NavbarLeft';
import NavbarTop from '../../oldComponents/NavbarTop/NavbarTop';
import { AuthContext } from '../../AuthProvider';
import UserDispoService from '../../oldService/userDispoService';
import { API } from '../../oldService/authHeader';
import Searchbar from '../../oldComponents/Searchbar/Searchbar';
import { User, Group_id, UserDispo, Dispositif, FilesVieAsso } from '../../usualTypes/BasicTypes';
import { useLocation, useNavigate } from 'react-router-dom';
export type State = {
  childDispo: Dispositif[];
};
type FileReport = {
  directus_files_id: { id: number; title: string };
  files_vie_asso_id: FilesVieAsso;
};
const AssociationDocs = () => {
  const { user }: { user: User } = useContext(AuthContext);

  const [filesAsso, setFilesAsso] = useState([]);
  const [searchField, setSearchField] = useState('');
  const state = useLocation().state as State;
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const documentNameList = [
    'statuts',
    'proces_verbal',
    'recepisse',
    'budget',
    'planning',
    'soutenance_1',
    'soutenance_2',
    'finalReport'
  ];

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userInDispoWithFile = await UserDispoService.getAllFileOfUserInDispo(id);
      setFilesAsso(userInDispoWithFile.data.data);
    };
    fetchData();
  }, []);
  const navigate = useNavigate();
  return (
    <div className="PunctualDocs flex w-screen min-h-screen">
      <NavbarLeft user={user} sub={1} />
      <div className="flex flex-col h-full w-full">
        <NavbarTop user={user} urls={['home', 'plans']} />
        <div className="p-10 space-y-8">
          <div className="flex justify-between w-full">
            <div className={`w-full`}>
              <button className="underline font-medium" onClick={() => navigate(-1)}>
                {' '}
                &#60; Retour{' '}
              </button>
              <h1 className="font-bold text-3xl text-blue-500">Documents</h1>
              <div className="mt-5 space-y-5">
                <div className="flex justify-end">
                  <div className="w-4/12">
                    <Searchbar placeholder={'Rechercher par nom...'} onChangeHandler={onSearchChange} />
                  </div>
                </div>
                <div className="py-5 rounded-sm bg-gray-50 border">
                  <div className="grid grid-cols-2 px-5 pb-3 border-b">
                    <p className="font-semibold">Nom</p>
                    <p className="font-semibold">Document</p>
                  </div>
                  {filesAsso
                    .filter((files: UserDispo) => {
                      const searchString = files.user.first_name + ' ' + files.user.last_name;
                      const otherString = files.user.last_name + ' ' + files.user.first_name;
                      return (
                        searchString.toLocaleLowerCase().includes(searchField) ||
                        otherString.toLowerCase().includes(searchField)
                      );
                    })
                    .map((files: UserDispo) => {
                      if (files.files_asso_id === null) {
                        return;
                      }
                      const dispo = state.childDispo.find(
                        (dispo: Dispositif) => dispo.files_vie_asso_id === files.files_asso_id.id
                      );
                      return documentNameList
                        .filter((name) =>
                          name === 'finalReport'
                            ? files.files_asso_id[name].length !== 0
                            : files.files_asso_id[name] !== null
                        )
                        .map((name, index) => (
                          <div className="text-sm" key={index}>
                            {name === 'finalReport' ? (
                              files.files_asso_id[name].map((otherFiles: FileReport) => (
                                <div
                                  key={otherFiles.directus_files_id.id}
                                  className={`grid grid-cols-2 py-2 px-5 ${
                                    index % 2 === 0 && 'bg-white rounded-sm'
                                  } border border-black/0 hover:border-black/10 rounded-sm`}>
                                  <a
                                    href={API + 'assets/' + otherFiles.directus_files_id.id + '?download'}
                                    className="hover:underline">
                                    {files.user.first_name +
                                      ' ' +
                                      files.user.last_name +
                                      ' - ' +
                                      files.user.group
                                        .slice(-1)
                                        .map((group: Group_id) => ' ' + group.group_id.name + ' ')}
                                  </a>
                                  <a
                                    href={API + 'assets/' + otherFiles.directus_files_id.id + '?download'}
                                    className="hover:underline">
                                    {otherFiles.directus_files_id.title}
                                  </a>
                                </div>
                              ))
                            ) : (
                              <div
                                key={index}
                                className={`grid grid-cols-2 py-2 px-5 ${
                                  index % 2 === 0 && 'bg-white rounded-sm'
                                } border border-black/0 hover:border-black/10 rounded-sm`}>
                                <a
                                  href={API + 'assets/' + files.files_asso_id[name] + '?download'}
                                  className="hover:underline">
                                  {files.user.first_name +
                                    ' ' +
                                    files.user.last_name +
                                    ' - ' +
                                    files.user.group
                                      .slice(-1)
                                      .map((group: Group_id) => ' ' + group.group_id.name + ' ')}
                                </a>
                                {dispo !== undefined ? (
                                  <a
                                    href={API + 'assets/' + files.files_asso_id[name] + '?download'}
                                    className="hover:underline">
                                    {name + '_' + dispo.name}
                                  </a>
                                ) : (
                                  <a
                                    href={API + 'assets/' + files.files_asso_id[name] + '?download'}
                                    className="hover:underline">
                                    {name}
                                  </a>
                                )}
                              </div>
                            )}
                          </div>
                        ));
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssociationDocs;
