import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GenericForm from '../../components/GenericForm';
import { retrieveForm, selectCurrentForm, selectLoading } from '../../slices/form';
import { useAppDispatch, useAppSelector } from '../../hooks';

const Form = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const form = useAppSelector(selectCurrentForm);
  const loading = useAppSelector(selectLoading);
  console.log(form, loading);
  useEffect(() => {
    console.log(id);
    dispatch(retrieveForm(id));
  }, []);

  if (!form) {
    return;
  }

  return (
    <div className="page-form">
      <GenericForm
        onSubmit={(data) => {
          console.log(data);
        }}
        config={form}
      />
    </div>
  );
};

export default Form;
