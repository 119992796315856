import { useEffect } from 'react';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif';

const getAllEvent = () => {
  return axios.get(API_URL + '?filter[template][_eq]=293', { headers: authHeader() });
};

const getAllEventTemplate = (id) => {
  return axios.get(API_URL + '?filter[template][_eq]=293&filter[parent][_eq]=' + id + '&filter[archived][_eq]=false', {
    headers: authHeader()
  });
};

const FormatGetAllEvent = (eventList, setEventList) => {
  useEffect(() => {
    const list = [];

    const MakeEventList = async () => {
      const data = await EventService.getAllEvent();
      data.data.data.map((item) => {
        if (item.status !== 'Archivé') {
          list.push(item);
        }
        return item;
      });
      setEventList(list);
    };
    MakeEventList();
  }, []);
  return eventList;
};

const FormatGetAllEventTemplate = (eventList, setEventList, parentId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await (await EventService.getAllEventTemplate(parentId)).data.data;
      data.map((item) => {
        list.push(item);
        return item;
      });
      setEventList(list);
    };
    MakeList();
  }, []);
  return eventList;
};

const EventService = {
  getAllEvent,
  getAllEventTemplate,
  FormatGetAllEvent,
  FormatGetAllEventTemplate
};

export default EventService;
