import React, { useContext } from 'react';
import UserDispoService from '../../oldService/userDispoService';
import DispoService from '../../oldService/dispService';
import HomeCardList from '../HomeCardList/HomeCardList';
import { useState } from 'react';

import './HomeContent.css';
import { AuthContext } from '../../AuthProvider';

function HomeContent() {
  const { user } = useContext(AuthContext);

  const mes_engagements = UserDispoService.FormatUserEnga(user);

  const [mes_dispositifs, setMes_dispositifs] = useState([]);
  DispoService.FormatUserDispo(mes_dispositifs, setMes_dispositifs, user && user.id);

  const [events, setEvents] = useState([]);
  UserDispoService.FormatGetUserEvent(events, setEvents, user && user.id);

  return (
    <div className="HomeContent space-y-14">
      {((user && user.role.name === 'Administrator') || mes_dispositifs.length > 0) && (
        <div className="bg-gray-50 p-5 rounded">
          <HomeCardList
            user={user}
            role={user.role.name}
            title={'Dispositifs dont je suis responsable:'}
            type={'plans'}
            data={mes_dispositifs}
          />
        </div>
      )}
      {mes_engagements.length > 0 && (
        <div className="bg-gray-50 p-5 rounded">
          <HomeCardList
            user={user}
            role={user.role.name}
            title={'Engagements auxquels je participe:'}
            type={'commitments'}
            data={mes_engagements}
          />
        </div>
      )}
      {events.length > 0 && (
        <div className="bg-gray-50 p-5 rounded">
          <HomeCardList
            user={user}
            role={user.role.name}
            title={'Évènements auxquels je participe:'}
            type={'events'}
            data={events}
          />
        </div>
      )}
    </div>
  );
}

export default HomeContent;
