import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';
import { useEffect } from 'react';

const API_URL = API + 'items/answer_form';

const getOneAnswerForm = (dispoId, userId, type) => {
  return axios.get(
    API_URL +
      '?filter[dispositif][_eq]=' +
      dispoId +
      '&filter[user][_eq]=' +
      userId +
      '&filter[type][_eq]=' +
      type +
      '&sort=-date_created&limit=1',
    { headers: authHeader() }
  );
};

const FormatGetOneAnswerForm = (answer, setAnswer, dispoId, userId, type) => {
  useEffect(() => {
    const MakeList = async () => {
      const data = await getOneAnswerForm(dispoId, userId, type);
      setAnswer(data.data.data[0]);
    };
    MakeList();
  }, [dispoId, userId, type]);
  return answer;
};

const PostAnswerForm = (answer, userId, dispositifId, type, answerRaw) => {
  return axios.post(
    API_URL,
    {
      answer: answer,
      answer_raw: answerRaw,
      user: userId,
      dispositif: dispositifId,
      type: type
    },
    { headers: authHeader() }
  );
};

const DeleteAnserForm = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const AnswerFormService = {
  PostAnswerForm,
  DeleteAnserForm,
  getOneAnswerForm,
  FormatGetOneAnswerForm
};

export default AnswerFormService;
