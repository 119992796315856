import axios from 'axios';
import { PROD } from './authHeader';

const BACK_URL = process.env.REACT_APP_BACK_URL;

const SendMail = (mail, newPassword, userName, orgaName, startDate, endDate) => {
  return axios.post(BACK_URL + '/mailjet', {
    params: {
      mail: mail,
      password: newPassword,
      user: userName,
      orga: orgaName,
      start: startDate,
      end: endDate
    }
  });
};

export const MailJetServices = {
  SendMail
};
