import React, { useEffect, useState } from 'react';
import StarEvalIcon from '../StarEvalIcon/StarEvalIcon';
import Rating from 'react-rating';

function RatingCard(props) {
  const [score, setScore] = useState(props.last ? props.last[props.index] : 0);

  useEffect(() => {
    setScore(props.last && props.last[props.index]);
  }, [props.last]);

  useEffect(() => {
    let list = props.evals;

    list[props.index] = score;
    props.setEvals(list);
  }, [score]);

  return (
    <div className="RatingCard flex justify-between items-center py-5">
      <div>
        <h3 className="font-semibold">{props.title}</h3>
        <p className="text-gray-500/80 text-sm">{props.description}</p>
      </div>
      <div className="flex">
        <Rating
          initialRating={score}
          onChange={(value) => {
            setScore(value);
          }}
          start={0}
          stop={10}
          emptySymbol={
            <div className="text-gray-500">
              <StarEvalIcon />
            </div>
          }
          placeholderSymbol={
            <div className="text-yellow-200">
              <StarEvalIcon />
            </div>
          }
          fullSymbol={
            <div className="text-yellow-400">
              <StarEvalIcon />
            </div>
          }
        />
      </div>
    </div>
  );
}

export default RatingCard;
