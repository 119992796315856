import React, { useEffect, useState } from 'react';
import { QuestionEvaluation } from '../../../../../usualTypes/BasicTypes';

type ObjQuestion = {
  id_question: number;
  answer: string;
};

type Props = {
  question: QuestionEvaluation;
  onChange: (id: number, value: string) => void;
  oldEvals: ObjQuestion[];
};
const CheckBox = ({ question, onChange, oldEvals }: Props) => {
  const [defaultValue, setDefaultValue] = useState('');

  const getOldValue = () => {
    if (oldEvals) {
      const indexEvals = oldEvals.findIndex((obj: ObjQuestion) => obj.id_question === question.id);
      if (indexEvals === -1) return;
      setDefaultValue(oldEvals[indexEvals].answer);
    }
  };
  useEffect(() => {
    getOldValue();
  }, [oldEvals]);
  return (
    <div className="py-5 space-y-2">
      <label htmlFor="comment" className="font-semibold">
        {question.title}
        {question.mandatory && <span className="text-red-500">*</span>}
      </label>
      <div className="flex flex-col justify-center items-center">
        <p className="block mb-2 text-sm font-medium text-gray-900"></p>
        <div className="flex justify-around space-x-5">
          {question.answer.map((answer, index) => (
            <div className="flex space-x-1" key={index}>
              <input
                type="checkbox"
                name={`choice-${index}`}
                id={`choice-${index}`}
                value={answer}
                checked={defaultValue === answer}
                onChange={(e) => {
                  setDefaultValue(e.target.value);
                  onChange(question.id, e.target.value);
                }}
              />
              <label htmlFor={`choice-${index}`} className="block text-sm font-medium text-gray-900">
                {answer}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
