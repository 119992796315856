import React, { ReactNode } from 'react';
type Props = {
  handleOnClick: () => void;
  btnText: string;
  children: ReactNode;
};
const BigAnimateButton = ({ handleOnClick, btnText, children }: Props) => {
  return (
    <button
      className="w-full h-16 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-newViolet-200 rounded-full shadow-md group"
      onClick={() => {
        handleOnClick();
      }}>
      <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-newViolet-200 group-hover:translate-x-0 ease">
        {children}
      </span>
      <span className="absolute flex items-center justify-center w-full h-full text-newViolet-200 transition-all duration-300 transform group-hover:translate-x-full ease">
        {btnText}
      </span>
      <span className="relative invisible">{btnText}</span>
    </button>
  );
};

export default BigAnimateButton;
