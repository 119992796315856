import axios from 'axios';
import moment from 'moment';
import { API_AKANEMA } from './akanemaApi';

const API_URL = API_AKANEMA + '/api/documents/';

export function PdfConvertData(answer, user, groups) {
  return {
    'Nom utilisateur': user.last_name,
    'Prénom utilisateur': user.first_name,
    'Mon groupe': groups,
    'Motivation 1': answer.find((ans) => ans.index === 0) ? answer.find((ans) => ans.index === 0).data : '',
    'Motivation 2': answer.find((ans) => ans.index === 1) ? answer.find((ans) => ans.index === 1).data : '',
    Etape_projet: answer.find((ans) => ans.index === 2) ? answer.find((ans) => ans.index === 2).data : '',
    Description_projet: answer.find((ans) => ans.index === 3) ? answer.find((ans) => ans.index === 3).data : '',
    Description_projet2: answer.find((ans) => ans.index === 4) ? answer.find((ans) => ans.index === 4).data : '',
    Description_projet3: answer.find((ans) => ans.index === 5) ? answer.find((ans) => ans.index === 5).data : '',
    Structure_accueil1: answer.find((ans) => ans.index === 6) ? answer.find((ans) => ans.index === 6).data : '',
    Structure_accueil2: answer.find((ans) => ans.index === 7) ? answer.find((ans) => ans.index === 7).data : '',
    Structure_accueil3: answer.find((ans) => ans.index === 8) ? answer.find((ans) => ans.index === 8).data : '',
    Bénéficiaires: answer.find((ans) => ans.index === 9) ? answer.find((ans) => ans.index === 9).data : '',
    Utilité: answer.find((ans) => ans.index === 10) ? answer.find((ans) => ans.index === 10).data : '',
    Aide: answer.find((ans) => ans.index === 11) ? answer.find((ans) => ans.index === 11).data : '',
    Ressources1: answer.find((ans) => ans.index === 12) ? answer.find((ans) => ans.index === 12).data : '',
    Ressources2: answer.find((ans) => ans.index === 13) ? answer.find((ans) => ans.index === 13).data : '',
    Ressources3: answer.find((ans) => ans.index === 14) ? answer.find((ans) => ans.index === 14).data : '',
    Compétences1: answer.find((ans) => ans.index === 15) ? answer.find((ans) => ans.index === 15).data : '',
    Compétences2: answer.find((ans) => ans.index === 16) ? answer.find((ans) => ans.index === 16).data : '',
    'Auto-évaluation': answer.find((ans) => ans.index === 17) ? answer.find((ans) => ans.index === 17).data : '',
    pitch: answer.find((ans) => ans.index === 18) ? answer.find((ans) => ans.index === 18).data : ''
  };
}

export function PdfConvertDataMission(answer, user, groups) {
  return {
    'Nom utilisateur': user.last_name,
    'Prénom utilisateur': user.first_name,
    'Mon groupe': groups ? groups : '',
    NOM_OA: answer.find((ans) => ans.index === 99) ? answer.find((ans) => ans.index === 99).data : '',
    rue_OA: answer.find((ans) => ans.index === 1) ? answer.find((ans) => ans.index === 1).data : '',
    CP_OA: answer.find((ans) => ans.index === 2) ? answer.find((ans) => ans.index === 2).data : '',
    Ville_OA: answer.find((ans) => ans.index === 3) ? answer.find((ans) => ans.index === 3).data : '',
    Nom_tuteur: answer.find((ans) => ans.index === 4.1) ? answer.find((ans) => ans.index === 4.1).data : '',
    Fonction_tuteur: answer.find((ans) => ans.index === 4.2) ? answer.find((ans) => ans.index === 4.2).data : '',
    Email_tuteur: answer.find((ans) => ans.index === 4.3) ? answer.find((ans) => ans.index === 4.3).data : '',
    tél_tuteur: answer.find((ans) => ans.index === 4.4) ? answer.find((ans) => ans.index === 4.4).data : '',
    Responsabilité: answer.find((ans) => ans.index === 5) ? answer.find((ans) => ans.index === 5).data : '',
    'Equipe_Y/N': answer.find((ans) => ans.index === 6) ? answer.find((ans) => ans.index === 6).data : '',
    Equipe_détails: answer.find((ans) => ans.index === 7) ? answer.find((ans) => ans.index === 7).data : '',
    'Déplacement_Y/N': answer.find((ans) => ans.index === 8) ? answer.find((ans) => ans.index === 8).data : '',
    steps_intégration: answer.find((ans) => ans.index === 9) ? answer.find((ans) => ans.index === 9).data : '',
    qualities: answer.find((ans) => ans.index === 10) ? answer.find((ans) => ans.index === 10).data : '',
    Critères_éval: answer.find((ans) => ans.index === 11) ? answer.find((ans) => ans.index === 11).data : '',
    description_mission: answer.find((ans) => ans.index === 12) ? answer.find((ans) => ans.index === 12).data : '',
    durée: answer.find((ans) => ans.index === 13) ? answer.find((ans) => ans.index === 13).data : ''
  };
}
export function PdfConvertDataMissionV2(answerMission, answerConvention, answerOrganization, user, groups) {
  console.log(answerConvention);
  return {
    'Nom utilisateur': user.last_name,
    'Prénom utilisateur': user.first_name,
    'Mon groupe': groups ? groups : '',
    NOM_OA: answerOrganization.organization.value.name,
    rue_OA: answerOrganization.organization.value.adress,
    CP_OA: answerOrganization.organization.value.postal_code,
    Ville_OA: answerOrganization.organization.value.city,
    Nom_tuteur: answerConvention.find((ans) => ans.index === 1.1)
      ? answerConvention.find((ans) => ans.index === 1.1).data
      : '',
    Fonction_tuteur: answerConvention.find((ans) => ans.index === 1.2)
      ? answerConvention.find((ans) => ans.index === 1.2).data
      : '',
    Email_tuteur: answerConvention.find((ans) => ans.index === 1.3)
      ? answerConvention.find((ans) => ans.index === 1.3).data
      : '',
    tél_tuteur: answerConvention.find((ans) => ans.index === 1.4)
      ? answerConvention.find((ans) => ans.index === 1.4).data
      : '',
    Responsabilité: answerMission.find((ans) => ans.index === 5)
      ? answerMission.find((ans) => ans.index === 5).data
      : '',
    'Equipe_Y/N': answerMission.find((ans) => ans.index === 6) ? answerMission.find((ans) => ans.index === 6).data : '',
    Equipe_détails: answerMission.find((ans) => ans.index === 7)
      ? answerMission.find((ans) => ans.index === 7).data
      : '',
    'Déplacement_Y/N': answerMission.find((ans) => ans.index === 8)
      ? answerMission.find((ans) => ans.index === 8).data
      : '',
    steps_intégration: answerMission.find((ans) => ans.index === 9)
      ? answerMission.find((ans) => ans.index === 9).data
      : '',
    qualities: answerMission.find((ans) => ans.index === 10) ? answerMission.find((ans) => ans.index === 10).data : '',
    Critères_éval: answerMission.find((ans) => ans.index === 11)
      ? answerMission.find((ans) => ans.index === 11).data
      : '',
    description_mission: answerMission.find((ans) => ans.index === 12)
      ? answerMission.find((ans) => ans.index === 12).data
      : '',
    durée: answerMission.find((ans) => ans.index === 13) ? answerMission.find((ans) => ans.index === 13).data : ''
  };
}

export function PdfConvertDataConvention(answer, answerMission, user, punctual, missionDate) {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${year}-${month}-${day}`;

  return {
    user_first_name: user.first_name,
    user_last_name: user.last_name,
    user_email: user.email,
    nom_OA: answer.find((ans) => ans.index === 1.1) ? answer.find((ans) => ans.index === 1.1).data : '',
    rue_OA: answer.find((ans) => ans.index === 1.2) ? answer.find((ans) => ans.index === 1.2).data : '',
    cp_OA: answer.find((ans) => ans.index === 1.3) ? answer.find((ans) => ans.index === 1.3).data : '',
    ville_OA: answer.find((ans) => ans.index === 1.4) ? answer.find((ans) => ans.index === 1.4).data : '',
    tutor_name: answer.find((ans) => ans.index === 0.1) ? answer.find((ans) => ans.index === 0.1).data : '',
    tutor_email: answer.find((ans) => ans.index === 0.3) ? answer.find((ans) => ans.index === 0.3).data : '',
    tutor_phone: answer.find((ans) => ans.index === 0.4) ? answer.find((ans) => ans.index === 0.4).data : '',
    time_week: answer.find((ans) => ans.index === 5) ? answer.find((ans) => ans.index === 5).data : '',
    nbr_week: answer.find((ans) => ans.index === 6) ? answer.find((ans) => ans.index === 6).data : '',
    total_time: answer.find((ans) => ans.index === 66) ? answer.find((ans) => ans.index === 66).data : '',
    start_date: answer.find((ans) => ans.index === 3) ? answer.find((ans) => ans.index === 3).data : '',
    end_date: answer.find((ans) => ans.index === 4) ? answer.find((ans) => ans.index === 4).data : '',
    actual_date: currentDate,
    Responsabilité: answerMission.find((ans) => ans.index === 5)
      ? answerMission.find((ans) => ans.index === 5).data
      : '',
    'Equipe_Y/N': answerMission.find((ans) => ans.index === 6) ? answerMission.find((ans) => ans.index === 6).data : '',
    Equipe_détails: answerMission.find((ans) => ans.index === 7)
      ? answerMission.find((ans) => ans.index === 7).data
      : '',
    'Déplacement_Y/N': answerMission.find((ans) => ans.index === 8)
      ? answerMission.find((ans) => ans.index === 8).data
      : '',
    steps_intégration: answerMission.find((ans) => ans.index === 9)
      ? answerMission.find((ans) => ans.index === 9).data
      : '',
    Critères_éval: answerMission.find((ans) => ans.index === 11)
      ? answerMission.find((ans) => ans.index === 11).data
      : '',
    description_mission: answerMission.find((ans) => ans.index === 12)
      ? answerMission.find((ans) => ans.index === 12).data
      : '',
    mission_date: missionDate
  };
}

export function PdfConvertDataConventionV2(
  answerConvention,
  answerMission,
  answerOrganization,
  user,
  punctual,
  missionDate,
  groups,
  isFrench
) {
  const missionRoad = answerMission.find((ans) => ans.index === 1)
    ? answerMission.find((ans) => ans.index === 1).data
    : '';
  const missionPostalCode = answerMission.find((ans) => ans.index === 2)
    ? answerMission.find((ans) => ans.index === 2).data
    : '';
  const missionCity = answerMission.find((ans) => ans.index === 3)
    ? answerMission.find((ans) => ans.index === 3).data
    : '';
  return {
    user_first_name: user.first_name,
    user_last_name: user.last_name,
    user_email: user.email,
    user_phone: answerConvention.find((ans) => ans.index === 7).data,
    user_groups: groups ? groups : '',
    siret_OA: answerOrganization.organization.value.numero_siret_rna,
    rna_OA: answerOrganization.organization.value.numero_rna,
    nom_OA: answerOrganization.organization.value.name,
    rue_OA: answerOrganization.organization.value.adress,
    cp_OA: answerOrganization.organization.value.postal_code,
    ville_OA: answerOrganization.organization.value.city,
    pays_OA: answerOrganization.organization.value.country,
    beneficiaries_OA: answerOrganization.organization.value.beneficiaries,
    tutor_name: answerConvention.find((ans) => ans.index === 1.1)
      ? answerConvention.find((ans) => ans.index === 1.1).data
      : '',
    tutor_email: answerConvention.find((ans) => ans.index === 1.3)
      ? answerConvention.find((ans) => ans.index === 1.3).data
      : '',
    tutor_phone: answerConvention.find((ans) => ans.index === 1.4)
      ? answerConvention.find((ans) => ans.index === 1.4).data
      : '',
    tutor_function: answerConvention.find((ans) => ans.index === 1.2)
      ? answerConvention.find((ans) => ans.index === 1.2).data
      : '',
    signatory_contact_name: answerConvention.find((ans) => ans.index === 0.1)
      ? answerConvention.find((ans) => ans.index === 0.1).data
      : '',
    signatory_contact_email: answerConvention.find((ans) => ans.index === 0.3)
      ? answerConvention.find((ans) => ans.index === 0.3).data
      : '',
    signatory_contact_phone: answerConvention.find((ans) => ans.index === 0.4)
      ? answerConvention.find((ans) => ans.index === 0.4).data
      : '',
    signatory_contact_function: answerConvention.find((ans) => ans.index === 0.2)
      ? answerConvention.find((ans) => ans.index === 0.2).data
      : '',
    time_week: answerConvention.find((ans) => ans.index === 5)
      ? answerConvention.find((ans) => ans.index === 5).data
      : '',
    nbr_week: answerConvention.find((ans) => ans.index === 6)
      ? answerConvention.find((ans) => ans.index === 6).data
      : '',
    total_time: answerConvention.find((ans) => ans.index === 66)
      ? answerConvention.find((ans) => ans.index === 66).data
      : '',
    start_date: answerConvention.find((ans) => ans.index === 3)
      ? moment(answerConvention.find((ans) => ans.index === 3).data, 'YYYY/MM/DD').format(
          isFrench ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
        )
      : '',
    end_date: answerConvention.find((ans) => ans.index === 4)
      ? moment(answerConvention.find((ans) => ans.index === 4).data, 'YYYY/MM/DD').format(
          isFrench ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
        )
      : '',
    actual_date: moment().format(isFrench ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
    Responsabilité: answerMission.find((ans) => ans.index === 5)
      ? answerMission.find((ans) => ans.index === 5).data
      : '',
    'Equipe_Y/N': answerMission.find((ans) => ans.index === 6) ? answerMission.find((ans) => ans.index === 6).data : '',
    Equipe_détails: answerMission.find((ans) => ans.index === 7)
      ? answerMission.find((ans) => ans.index === 7).data
      : '',
    'Déplacement_Y/N': answerMission.find((ans) => ans.index === 8)
      ? answerMission.find((ans) => ans.index === 8).data
      : '',
    steps_intégration: answerMission.find((ans) => ans.index === 9)
      ? answerMission.find((ans) => ans.index === 9).data
      : '',
    Critères_éval: answerMission.find((ans) => ans.index === 11)
      ? answerMission.find((ans) => ans.index === 11).data
      : '',
    description_mission: answerMission.find((ans) => ans.index === 12)
      ? answerMission.find((ans) => ans.index === 12).data
      : '',
    mission_date: moment(missionDate).format(isFrench ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
    mission_location: `${missionRoad} ${missionPostalCode} ${missionCity}`,
    mission_skills: answerMission.find((ans) => ans.index === 10)
      ? answerMission.find((ans) => ans.index === 10).data
      : ''
  };
}

export function pdfConvertEvaluationPunctual(
  student,
  tuteur,
  orga,
  total,
  skills_final,
  next_year,
  observations,
  evaluation
) {
  const monGroupe = student.group.find((group) => group.group_id.parentgroup !== null);
  let promo;
  if (monGroupe === undefined) {
    promo = student.group.find((group) => group.group_id.parentgroup === null)?.group_id.name;
  } else {
    promo = monGroupe?.group_id.parentgroup.name;
  }
  return {
    promo: promo !== undefined && promo,
    'Nom utilisateur': student.last_name,
    'Prénom utilisateur': student.first_name,
    'Mon groupe': monGroupe !== undefined && monGroupe.group_id.name,
    Nom_tuteur: tuteur.first_name,
    Fonction_tuteur: tuteur.function,
    Email_tuteur: tuteur.email,
    tél_tuteur: tuteur.phones,
    NOM_OA: orga.organization_id.name,
    rue_OA: orga.organization_id.adress,
    CP_OA: orga.organization_id.postal_code,
    Ville_OA: orga.organization_id.city,
    SIRET: orga.organization_id.numero_siret_rna,
    RNA: orga.organization_id.numero_rna,
    Méthodo_1: evaluation[0],
    Méthodo_2: evaluation[1],
    Méthodo_3: evaluation[2],
    relation_1: evaluation[3],
    relation_2: evaluation[4],
    relation_3: evaluation[5],
    relation_4: evaluation[6],
    opé_1: evaluation[7],
    opé_2: evaluation[8],
    opé_3: evaluation[9],
    Total: total,
    note: parseFloat(total / 5).toFixed(2),
    skills_final,
    next_year: next_year ? 'Oui' : 'Non',
    observations,
    date_création: moment().format('DD/MM/YYYY')
  };
}
export function pdfConvertEvaluationPunctualV2(promo, group, student, tuteur, orga, total, evaluation) {
  return {
    promo: promo !== undefined ? promo : null,
    'Nom utilisateur': student.name,
    'Prénom utilisateur': null,
    'Mon groupe': group !== undefined ? group : null,
    Nom_tuteur: tuteur.first_name,
    Fonction_tuteur: tuteur.function,
    Email_tuteur: tuteur.email,
    tél_tuteur: tuteur.phones,
    NOM_OA: orga.organization_id.name,
    rue_OA: orga.organization_id.adress,
    CP_OA: orga.organization_id.postal_code,
    Ville_OA: orga.organization_id.city,
    SIRET: orga.organization_id.numero_siret_rna,
    RNA: orga.organization_id.numero_rna,
    Méthodo_1: evaluation.find((ans) => ans.id_question === 1).answer,
    Méthodo_2: evaluation.find((ans) => ans.id_question === 2).answer,
    Méthodo_3: evaluation.find((ans) => ans.id_question === 3).answer,
    relation_1: evaluation.find((ans) => ans.id_question === 4).answer,
    relation_2: evaluation.find((ans) => ans.id_question === 5).answer,
    relation_3: evaluation.find((ans) => ans.id_question === 6).answer,
    relation_4: evaluation.find((ans) => ans.id_question === 7).answer,
    opé_1: evaluation.find((ans) => ans.id_question === 8).answer,
    opé_2: evaluation.find((ans) => ans.id_question === 9).answer,
    opé_3: evaluation.find((ans) => ans.id_question === 10).answer,
    Total: total,
    note: parseFloat(total / 5).toFixed(2),
    skills_final:
      evaluation.find((ans) => ans.id_question === 11) !== undefined
        ? evaluation.find((ans) => ans.id_question === 11).answer
        : 'Pas de réponse',
    next_year: evaluation.find((ans) => ans.id_question === 13).answer,
    observations:
      evaluation.find((ans) => ans.id_question === 12) !== undefined
        ? evaluation.find((ans) => ans.id_question === 12).answer
        : 'Pas de réponse',
    date_création: moment().format('DD/MM/YYYY')
  };
}

const PostPdf = (documentId, templateId, name) => {
  return axios.post(API_URL + documentId + '/generate-pdf-async', {
    template_id: templateId,
    name: name
  });
};

const PdfServices = {
  PostPdf
};

export default PdfServices;
