import React, { useState } from 'react';
import { stepsNewPlan, stepsNewPlanModel3, stepsNewPlanModel4 } from '../../data';
import FillingDocCard from '../FillingDocCard/FillingDocCard';
import MultipleChoicePlans from '../MultipleChoicePlans/MutlipleChoicePlans';
import DispoService from '../../oldService/dispService';
import { useNavigate } from 'react-router-dom';
import StepService from '../../oldService/stepService';
import UserDispoService from '../../oldService/userDispoService';
import DispoStepService from '../../oldService/dispoStepService';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import FileService from '../../oldService/fileService';
import DispoTagService from '../../oldService/dispTagService';
import { StepsCreationPunctual } from '../../oldService/steps/StepsCreationPunctual';
import { PROD } from '../../oldService/authHeader';

import './FormNewPlan.css';
import { StepsCreationPunctualV2 } from '../../oldService/steps/StepsCreationPunctualV2';
import LoadingButton from '../LoadingButton/LoadingButton';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import moveToNextStep from '../../services/logicFunction/moveToNextStep';

function FormNewPLan(props) {
  //PROD / PREPROD
  const templateIdPunctual = PROD ? 321 : 319;
  const templateIdPunctualV2 = PROD ? 444 : 384;
  const [title, setTitle] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState();
  const [fileId, setFileId] = useState(null);
  const formData = new FormData();
  const [nbrHour, setNbrHour] = useState('0');
  const [loading, setLoading] = useState(false);
  const parent = props.asso ? props.dispo.id : null;
  const [error, setError] = useState(false);
  const listRequired = [
    { name: 'Titre' },
    { name: 'Description complète' },
    { name: 'Date de fin de validité' },
    { name: 'Image' }
  ];
  const [steps, setSteps] = useState([
    {
      name: 'Administratif',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Identité asso',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Membres bureau',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Bénévoles',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Évaluations S1',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Soutenance S1',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Évaluations S2',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Soutenance S2',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Bilan',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    }
  ]);

  const [stepsModel3, setStepsModel3] = useState([
    {
      name: "Projet d'engagement",
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: "Organisation d'accueil",
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Descriptif mission',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Convention',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Signature',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Évaluation',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    }
  ]);

  const [stepsModel4, setStepsModel4] = useState([
    {
      name: "Projet d'engagement",
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Convention',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Signature',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    },
    {
      name: 'Évaluation',
      activated: true,
      validation: true,
      obligatory: true,
      id: null
    }
  ]);

  const navigate = useNavigate();

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeShortDesc = (e) => {
    setShortDesc(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  const onChangeNbrHour = (e) => {
    setNbrHour(e.target.value);
  };

  const handleSubmit = async () => {
    if ((title !== '') & (description !== '') & (date !== undefined) & (fileId !== null)) {
      setLoading(true);
      let template;
      switch (props.models) {
        case 'model1':
          template = 291;
          break;
        case 'model2':
          template = 292;
          break;
        case 'model3':
          template = templateIdPunctual;
          break;
        case 'model4':
          template = templateIdPunctualV2;
          break;
      }

      let uploadedFileId = null;

      if (fileId) {
        formData.append('title', fileId.name);
        formData.append('file', fileId);
        const fileUpdateResult = await FileService.fileUpdate(formData);
        uploadedFileId = fileUpdateResult.data.data.id;
      }

      try {
        const dispoId = await createDispo(uploadedFileId, template);
        if (props.dispoAdmin) {
          await DispDirUsersService.postDirectusUser(dispoId, props.user.id);
          if (props.models === 'model2') {
            await createSteps(dispoId);
            await DispoTagService.postDispTag(dispoId, 28);
            await DispoTagService.postDispTag(dispoId, 14);
            navigate('/user/asso/details?id=' + dispoId);
          } else if (props.models === 'model1') {
            await DispoTagService.postDispTag(dispoId, 16);
            await DispoTagService.postDispTag(dispoId, 12);
            navigate('/user/plans/details?id=' + dispoId);
          } else if (props.models === 'model3') {
            await StepsCreationPunctual(stepsModel3, dispoId);
            await DispoTagService.postDispTag(dispoId, 16);
            await DispoTagService.postDispTag(dispoId, 27);
            navigate('/user/punctual/details?id=' + dispoId);
          } else if (props.models === 'model4') {
            await StepsCreationPunctualV2(stepsModel4, dispoId);
            await DispoTagService.postDispTag(dispoId, 28);
            await DispoTagService.postDispTag(dispoId, 27);
            navigate('/user/punctualV2/details?id=' + dispoId);
          }
        } else {
          if (props.userDispo.step.tovalidate === false) {
            await moveToNextStep(
              props.dispo.id,
              props.userDispo.step.id,
              props.userDispo.other_id,
              dispoId,
              async () => {
                await DispoService.PatchTemplateDispo(dispoId, 292);
                setLoading(false);
                // window.location.reload();
                props.setChangeInPage((prev) => prev + 1);
              }
            );
          } else {
            setLoading(false);
            // window.location.reload();
            props.setChangeInPage((prev) => prev + 1);
          }
        }
      } catch (error) {
        // handle error
        console.log(error);
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  const createDispo = async (fileId, template) => {
    let dispoId;
    if (props.dispoAdmin === true) {
      const resultDispo = await DispoService.postDispo(
        title,
        description,
        shortDesc,
        fileId,
        date,
        nbrHour,
        template,
        parent
      );
      dispoId = resultDispo.data.data.id;
    } else if (props.dispoAsso === true) {
      const result = await DispoService.PostDispoAsso(
        title,
        description,
        shortDesc,
        fileId,
        date,
        nbrHour,
        null,
        props.dispo.id,
        props.userDispo.files_asso_id
      );
      await UserDispoService.PatchUserDispoValidate(props.userDispo.other_id, props.userDispo.files_asso_id, true);
      await DispoTagService.postDispTag(result.data.data.id, 28);
      await DispoTagService.postDispTag(result.data.data.id, 15);
      dispoId = result.data.data.id;
    }
    return dispoId;
  };

  const createSteps = async (dispoId) => {
    steps.map(async (item, index) => {
      if (item.activated) {
        const stepResult = await StepService.PostStep(item.name, item.validation, !item.obligatory);
        const stepId = stepResult.data.data.id;
        await DispoStepService.PostDispoStep(dispoId, stepId, index);
      }
      return item;
    });
  };

  return (
    <div>
      <div className={`FormNewPlan py-5 space-y-5 ${props.asso === true ? 'px-10' : ''}`}>
        {props.models && (
          <div>
            <h2 className="font-medium text-xl py-5 text-blue-700">Formulaire des informations élémentaires</h2>
            <div className="grid grid-cols-2">
              <div className="flex flex-col px-5 space-y-5">
                <div className="flex flex-col">
                  <label htmlFor="title" className="text-left">
                    Titre
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-2 placeholder-gray-500/80"
                    type="text"
                    name="title"
                    value={title}
                    onChange={onChangeTitle}
                    placeholder="Dispositif"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="short-desc" className="text-left">
                    Description Courte
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-2 placeholder-gray-500/80"
                    name="short-desc"
                    type="text"
                    value={shortDesc}
                    onChange={onChangeShortDesc}
                    placeholder="Description courte"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="desc" className="text-left">
                    Description Complète
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm h-80 px-2 placeholder-gray-500/80"
                    name="desc"
                    placeholder="Description"
                    value={description}
                    onChange={onChangeDescription}
                  />
                </div>
                {/* <div id='counter' className='text-right'>
                                    <span className={`${description.length < 100 ? 'text-red-500' : 'text-black'}`}>{description.length}</span>/100
                                </div> */}
              </div>
              <div className="flex flex-col px-5 space-y-5">
                <div>
                  <label>
                    Image<span className="text-red-500">*</span>
                  </label>
                  <FillingDocCard accept={'image/*'} setFile={setFileId} file={fileId} dim={'500x500'} />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="date" className="text-left">
                    Date de fin de validité
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm"
                    name="date"
                    type="date"
                    value={date}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nbrHour" className="text-left">
                    Nombre d'heures d'engagement minimum estimé
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-2 placeholder-gray-500/80"
                    name="nbrHour"
                    min={0}
                    type="number"
                    placeholder="Heures"
                    value={nbrHour}
                    onChange={onChangeNbrHour}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {(props.models === 'model2' || props.models === 'model3' || props.models === 'model4') && !props.asso && (
          <div>
            <h2 className="font-medium text-xl py-5 pt-14 text-blue-700">Contenu du dispositif</h2>
            <div className="flex flex-col justify-center items-center space-y-10">
              {props.models === 'model2' &&
                stepsNewPlan.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-11/12 p-5 hover:bg-white ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
                      <MultipleChoicePlans
                        key={index}
                        title={item.title}
                        description={item.description}
                        steps={steps}
                        setSteps={setSteps}
                        index={index}
                      />
                    </div>
                  );
                })}
              {props.models === 'model3' &&
                stepsNewPlanModel3.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-11/12 p-5 hover:bg-white ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
                      <MultipleChoicePlans
                        key={index}
                        title={item.title}
                        description={item.description}
                        steps={stepsModel3}
                        setSteps={setStepsModel3}
                        index={index}
                      />
                    </div>
                  );
                })}
              {props.models === 'model4' &&
                stepsNewPlanModel4.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-11/12 p-5 hover:bg-white ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
                      <MultipleChoicePlans
                        key={index}
                        title={item.title}
                        description={item.description}
                        steps={stepsModel4}
                        setSteps={setStepsModel4}
                        index={index}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <ErrorMessage error={error} listRequired={listRequired} />
        {props.models && !props.admin && (
          <div className="flex justify-center pt-10">
            <LoadingButton loading={loading} handleSubmit={handleSubmit} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FormNewPLan;
