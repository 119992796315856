import React, { useContext, useEffect, useState } from 'react';
import { evaluations_punctual } from '../../../data';
import FormDisplay from '../Form/FormDisplay';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthProvider';
import { Evaluation, QuestionEvaluation, User, UserDispo, UserEvaluate } from '../../../usualTypes/BasicTypes';
import EvaluationServices from '../../../oldService/evaluationService';
import { OrgaDirUsers1Services } from '../../../oldService/organizationDirectusUsers1Services.js';
import { PunctualFilesServices } from '../../../oldService/punctualFilesService';
import UserDispoService from '../../../oldService/userDispoService';
import DocumentsServices from '../../../oldService/documentServices';
import PdfServices, { pdfConvertEvaluationPunctualV2 } from '../../../oldService/pdfServices';

type Props = {
  lastEval: Evaluation;
  userEvaluate: UserEvaluate;
  idPunctualFile: string;
};
const PunctualEvaluation = ({ lastEval, userEvaluate, idPunctualFile }: Props) => {
  const { user }: { user: { id: User } } = useContext(AuthContext);
  const [evaluationToSubmit, setEvaluationToSubmit] = useState([]);
  const [idQuestionInTotal, setIdQuestionInTotal] = useState([]);
  const [orga, setOrga] = useState({});
  const [idUserDispo, setIdUserDispo] = useState<UserDispo>();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorLength, setErrorLength] = useState(false);
  const [requiredAnswer, setRequiredAnswer] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const organisation = await OrgaDirUsers1Services.getOrganizationByUser(user.id);
      setOrga(organisation.data.data);
      const idUserDispo = await UserDispoService.getIdUserDispoByPunctualFiles(idPunctualFile);
      setIdUserDispo(idUserDispo.data.data[0]);
    };
    fetchData();
  }, []);
  const getAllQuestionForTotal = () => {
    const list = [];
    evaluations_punctual.map(
      (question: QuestionEvaluation) => question.type === 'starRating' && list.push(question.id)
    );
    return list;
  };
  const getAllQuestionRequired = () => {
    const list = [];
    evaluations_punctual.map((question: QuestionEvaluation) => question.mandatory === true && list.push(question.id));
    return list;
  };
  useEffect(() => {
    setIdQuestionInTotal(getAllQuestionForTotal());
    setRequiredAnswer(getAllQuestionRequired());
  }, [evaluations_punctual]);

  useEffect(() => {
    if (lastEval) {
      setEvaluationToSubmit(lastEval.evals);
    }
  }, [lastEval]);
  const calculTotal = () => {
    let total = 0;
    evaluationToSubmit.map((answer) => {
      if (idQuestionInTotal.includes(answer.id_question)) {
        total += parseInt(answer.answer);
      }
    });
    return total;
  };

  const handleSubmit = async () => {
    let error = false;

    const inTheArray = (id: number) => evaluationToSubmit.some((question) => question.id_question === id);
    const allRequiredIsAnswer = requiredAnswer.every(inTheArray);
    if (!allRequiredIsAnswer) {
      setErrorLength(true);
      return;
    }
    setLoading(true);
    if (lastEval) {
      try {
        await EvaluationServices.DeleteEvaluation(lastEval.id);
      } catch (err) {
        console.log(err);
        error = true;
        setRequestError(error);
        setLoading(false);
      }
    }
    try {
      let maPromo = userEvaluate.team?.group_id.parentgroup?.name;
      let monGroup;
      if (maPromo === undefined) {
        maPromo = userEvaluate.team?.group_id.name;
      } else {
        monGroup = userEvaluate.team?.group_id.name;
      }

      const document_name = `${idUserDispo.dispositif.name}_${maPromo !== undefined ? maPromo : ''} ${
        monGroup !== undefined ? ',' + monGroup : ''
      }_${userEvaluate.name}_Evaluation`;
      const evaluation = await EvaluationServices.postNewEvaluation(
        evaluationToSubmit,
        calculTotal(),
        'punctual',
        userEvaluate.id
      );
      await PunctualFilesServices.PatchEvaluation(idPunctualFile, evaluation.data.data.id);
      const resDoc = await DocumentsServices.PostDocument(
        pdfConvertEvaluationPunctualV2(
          maPromo,
          monGroup,
          userEvaluate,
          user,
          orga[0],
          calculTotal(),
          evaluationToSubmit
        ),
        'Evaluation'
      );
      await PdfServices.PostPdf(resDoc.data.data.id, '1xe0REcj5Y6PWQILEWLbwNmFiJGflV1UhBa2sti7k0zs', document_name);
      await PunctualFilesServices.PatchPunctualFilesEvaluation(idPunctualFile, resDoc.data.data.id);
      await UserDispoService.PatchUserDispoValidation(idUserDispo.id);
    } catch (err) {
      console.log(err);
      error = true;
      setRequestError(error);
      setLoading(false);
    }
    if (!error) {
      setLoading(false);
      navigate(-1);
    }
  };
  return (
    <div className="space-y-5 px-10 py-5 rounded-sm bg-gray-100">
      <div>
        <h2 className="font-medium pb-1 text-xl text-blue-700">
          Vous pouvez attribuer la note que vous souhaitez dans chaque domaine
        </h2>
      </div>
      <FormDisplay
        questionArray={evaluations_punctual}
        evaluationToSubmit={evaluationToSubmit}
        setEvaluationToSubmit={setEvaluationToSubmit}
        OnClickSubmit={handleSubmit}
        error={requestError}
        loading={loading}
        errorLength={errorLength}
      />
    </div>
  );
};

export default PunctualEvaluation;
