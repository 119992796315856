import React, { useState, useEffect } from 'react';
import Searchbar from '../Searchbar/Searchbar';
import TableList from '../TableList/TableList';
import TablePlans from '../TablePlans/TablePlans';
import TableOrganizations from '../TableOrganizations';
// import ExportBtn from '../ExportBtn/ExportBtn';
// import MailBtn from '../MailBtn/MailBtn';
import UserService from '../../oldService/userService';
import DispoService from '../../oldService/dispService';
import UserDispoService from '../../oldService/userDispoService';
import { OrganizationServices } from '../../oldService/organizatonServices';

import './SearchList.css';
import { CSVLink } from 'react-csv';
import { OrgaDirUsers1Services } from '../../oldService/organizationDirectusUsers1Services.js';
import moment from 'moment';

function SearchList(props) {
  const [users, setUsers] = useState([]);
  // console.log(users);
  UserService.FormatUserList(users, setUsers);
  const [searchField, setSearchfield] = useState('');
  const [filteredData, setFilteredData] = useState(
    users.sort((a, b) => {
      if (a.last_name + ' ' + a.first_name < b.last_name + ' ' + b.first_name) return -1;
      if (a.last_name + ' ' + b.last_name + ' ' + b.first_name) return 1;
      return 0;
    })
  );
  const [tableListData, setTableListData] = useState([]);

  const [plans, setPlans] = useState([]);
  DispoService.FormatDispoList(plans, setPlans);
  const [searchFieldPlan, setSearchFieldPlan] = useState('');
  const [filteredPlan, setFilteredPlan] = useState(plans);
  const [tablePlansData, setTablePlansData] = useState([]);

  const [userDispo, setUserDispo] = useState([]);
  UserDispoService.FormatUserEngaAll(userDispo, setUserDispo);

  const [organizations, setOrganizations] = useState([]);
  OrganizationServices.FormatGetAllOrganizations(organizations, setOrganizations);
  const [searchFieldOrganization, setSearchFieldOrganization] = useState('');
  const [filteredOrganization, setFilteredOrganization] = useState(organizations);
  const [tableOrganizationsData, setTableOrganizationsData] = useState([]);

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  // for TableList
  // filter list with filteredString
  useEffect(() => {
    const newFilteredData = users.filter((item) => {
      return item.search.toLocaleLowerCase().includes(searchField);
    });
    setFilteredData(newFilteredData);
  }, [searchField, users]);

  // get filteredString from Searchbar
  const onSearchChange = (e) => {
    const searchFieldString = e.target.value.toLocaleLowerCase();
    setSearchfield(searchFieldString);
  };

  // for TablePlans
  // filter list with filteredString
  useEffect(() => {
    const newFilteredPlan = plans.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchFieldPlan);
    });
    setFilteredPlan(newFilteredPlan);
  }, [searchFieldPlan, plans]);

  // get filteredString from Searchbar
  const onSearchPlanChange = (e) => {
    const searchFieldPlanString = e.target.value.toLocaleLowerCase();
    setSearchFieldPlan(searchFieldPlanString);
  };

  // for TableOrganizations
  // filter list with filteredString
  useEffect(() => {
    const newFilteredOrganization = organizations.filter((item) => {
      return item.search.toLocaleLowerCase().includes(searchFieldOrganization);
    });
    setFilteredOrganization(newFilteredOrganization);
  }, [searchFieldOrganization, organizations]);

  // get filteredString from Searchbar
  const onSearchOrganizationChange = (e) => {
    const searchFieldOrganizationString = e.target.value.toLocaleLowerCase();
    setSearchFieldOrganization(searchFieldOrganizationString);
  };
  return (
    <div className="SearchList">
      {/* LISTE DES UTILISATEURS */}
      {props.category === 0 && (
        <div>
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Liste des Utilisateurs</h2>
          <div className="flex justify-end space-x-2 items-center">
            {/* <MailBtn /> */}
            <CSVLink
              className="flex bg-gray-200 py-1 px-2 space-x-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              data={tableListData}
              filename="utilisateurs.csv"
              separator=";">
              Exporter
            </CSVLink>
            <div className="w-1/3">
              <Searchbar placeholder={'Rechercher...'} onChangeHandler={onSearchChange} />
            </div>
          </div>
          <TableList
            data={filteredData}
            admin={true}
            userDispo={userDispo}
            setList={setTableListData}
            list={tableListData}
          />
        </div>
      )}
      {/* LISTE DES DISPOSITIFS */}
      {props.category === 1 && (
        <div>
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Liste des Dispositifs</h2>
          <div className="flex justify-end space-x-2 items-center">
            <CSVLink
              className="flex bg-gray-200 py-1 px-2 space-x-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              data={tablePlansData}
              filename="dispositifs.csv"
              separator=";">
              Exporter
            </CSVLink>
            <div className="w-1/3">
              <Searchbar placeholder={'Rechercher...'} onChangeHandler={onSearchPlanChange} />
            </div>
          </div>
          <TablePlans
            data={filteredPlan}
            admin={true}
            userDispo={userDispo}
            list={tablePlansData}
            setList={setTablePlansData}
          />
        </div>
      )}
      {/* LISTE DES ORGANISATIONS D'ACCUEIL */}
      {props.category === 2 && (
        <div>
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Liste des Organisations d'accueil</h2>
          <div className="flex justify-end space-x-2 items-center">
            <CSVLink
              className="flex bg-gray-200 py-1 px-2 space-x-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              data={tableOrganizationsData}
              filename={`organisations-et-contacts-${moment().format('DDMMYYYY')}.csv`}
              separator=";">
              Exporter
            </CSVLink>
            <div className="w-1/3">
              <Searchbar placeholder={'Rechercher...'} onChangeHandler={onSearchOrganizationChange} />
            </div>
          </div>
          <TableOrganizations
            data={filteredOrganization}
            admin={true}
            userDispo={userDispo}
            contacts={contacts}
            list={tableOrganizationsData}
            setList={setTableOrganizationsData}
          />
        </div>
      )}
    </div>
  );
}

export default SearchList;
