import React from 'react';

function Searchbar({ onChangeHandler, placeholder }) {
  return (
    <input
      className="Searchbar w-full px-2 py-1 border rounded-sm"
      type="search"
      placeholder={placeholder}
      onChange={onChangeHandler}
    />
  );
}

export default Searchbar;
