import React, { useContext } from 'react';
import NavbarTop from '../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../oldComponents/NavbarLeft/NavbarLeft';
import { AuthContext } from '../../AuthProvider';

const TemplatePage = ({ children }) => {
  const { user }: { user: object } = useContext(AuthContext);
  const blur = false;
  return (
    <div className="PunctualDetails flex w-screen min-h-screen">
      <NavbarLeft user={user} sub={1} blur={blur} />
      <div className="flex flex-col h-full w-full limit-Dispo">
        <NavbarTop user={user} blur={blur} urls={['home', 'plans']} />
        <div className="p-10 space-y-8">{children}</div>
      </div>
    </div>
  );
};

export default TemplatePage;
