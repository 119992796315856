import React from 'react';
import './PipelinePerso.css';

function PipelinePerso({ steps, user, userDispo }) {
  return (
    <div className="PipelinePerso" id="pipeline">
      <div className="flex">
        {steps
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((step, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center text-white bg-blue-500 w-full shadow-sm ring-1 ring-black ring-opacity-5 ${
                index === 0 && 'rounded-tl-lg rounded-bl-lg'
              } ${index === steps.length - 1 && 'rounded-tr-lg rounded-br-lg'}`}>
              <div key={index} className="text-center py-2">
                <h2 className="">{step.step_id.name}</h2>
              </div>
              <div
                className={`bg-gray-100 h-full w-full flex flex-col gap-5 items-center py-5 ${
                  index === 0 && 'rounded-bl-lg'
                } ${index === steps.length - 1 && 'rounded-br-lg'}`}>
                {user.step.name === step.step_id.name && (
                  <div className="w-full px-2">
                    <button className="relative flex justify-start items-center h-14 gap-3 rounded-lg bg-white text-black w-full">
                      <span
                        className={`h-full rounded-l-lg w-4 ${
                          userDispo.validation ? 'bg-orange-500' : userDispo.finish ? 'bg-green-500' : 'bg-gray-200'
                        }`}></span>
                      <p className="text-xs">{user.name}</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default PipelinePerso;
