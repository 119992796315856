import React, { useState, useContext } from 'react';
import Select from 'react-select';
import AuthService from '../../../oldService/authService';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import FormNewPLan from '../../../oldComponents/FormNewPlan/FormNewPlan';

import './NewPlan.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthProvider';

const NewPlan = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const token = AuthService.getCurrentToken();

  const { user } = useContext(AuthContext);
  const options = [
    // { value: 'model1', label: 'Dispositif' },
    { value: 'model2', label: 'Vie Associative' },
    // { value: 'model3', label: 'Mission Ponctuelle' },
    { value: 'model4', label: 'Mission Ponctuelle' }
  ];

  const optionChange = (event) => {
    setSelectedOption(event.value);
  };

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <div className="NewPLan flex w-screen min-h-screen">
        {user != null && (
          <>
            <NavbarLeft user={user} sub={1} />
            <div className="flex flex-col h-full w-full">
              <NavbarTop user={user} urls={['home', 'plans']} />
              <div className="p-10 space-y-8">
                <h1 className="font-bold text-3xl text-blue-500">NOUVEAU DISPOSITIF</h1>
                <div className="px-3 py-4 space-y-10 bg-gray-50 rounded">
                  <h2 className="font-medium text-xl pb-5 text-blue-700">Informations élémentaires</h2>
                  <div className="space-y-5">
                    <Select
                      id="select-patern"
                      placeholder="Choisir un modèle..."
                      defaultValue={selectedOption}
                      options={options}
                      value={options.find((item) => item.value === selectedOption)}
                      onChange={optionChange}
                    />
                    <div className="">
                      <FormNewPLan models={selectedOption} asso={false} user={user} dispoAdmin={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default NewPlan;
