import React from 'react';

import './LoginError.css';

function LoginError() {
  return (
    <div className="LoginError">
      <p className="text-red-500">Identifiant ou mot de passe incorrect.</p>
    </div>
  );
}

export default LoginError;
