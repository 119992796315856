import React from 'react';

import './TableEvents.css';

function TableEvents(props) {
  return (
    <div className="TableEvents">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto overflow-y-scroll max-h-96 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                      Nom
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Date de début
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Date de fin
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item) => {
                    return (
                      <tr key={item.id} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap py-4 pl-4 text-center pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {item.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {item.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {new Date(`${item.startdate}T${item.start_hourly}`).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          {new Date(`${item.enddate}T${item.end_hourly}`).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
                          <a
                            href={`/user/events/details?id=` + item.id}
                            className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white">
                            Détails
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableEvents;
