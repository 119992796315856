import React, { useEffect, useState } from 'react';
import ModalModifPass from '../ModalModifPass/ModalModifPass';
import UserService from '../../oldService/userService';

function ContentAccount({ data, blurPass, setBlurPass, token }) {
  const [modifyPhone, setModifyPhone] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [invalidFormat, setInvalidFormat] = useState(false);

  const HandleModifPass = () => {
    // setBlurPass(true)
    window.location.replace('https://d-prod.responsiblenatives.fr/admin/reset-password');
  };

  const HandleHelp = () => {
    window.location.replace('mailto:esdes.vieetudiante@univ-catholyon.fr');
  };
  useEffect(() => {
    if (/^\+(?:[0-9]?){1,3}[0-9](?:[ -]?[0-9]){5,15}$/.test(data.phones)) {
      setNewPhone(data.phones);
    }
  }, [data]);
  const handleChange = (e) => {
    const { value } = e.target;
    if (/^\+?$/.test(value) || /^\+(?:[0-9]?){1,3}[0-9](?:[ -]?[0-9]{0,14})$/.test(value)) {
      setInvalidFormat(false);
      setNewPhone(value);
    } else {
      setInvalidFormat(true);
    }
  };
  const validatePhoneChange = async () => {
    if (/^\+(?:[0-9]?){1,3}[0-9](?:[ -]?[0-9]){5,15}$/.test(newPhone)) {
      setInvalidFormat(false);
      setModifyPhone(false);
      await UserService.PatchUser(data.id, { phones: newPhone });
      window.location.reload();
    } else {
      setInvalidFormat(true);
    }
  };
  return (
    <div className="ContentAccount">
      <div className={`grid grid-cols-2 ${blurPass ? 'blur-sm' : 'blur-none'}`}>
        <div className="grid grid-cols-2">
          <div className="flex justify-center items-center">
            <div className="relative bg-gray-600 w-14 h-14 rounded-full overflow-hidden">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 w-16 h-16 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"></path>
              </svg>
            </div>
          </div>
          <ul className="space-y-1">
            <li className="font-bold">
              Nom: <span className="font-medium">{data.last_name}</span>
            </li>
            <li className="font-bold">
              Prénom: <span className="font-medium">{data.first_name}</span>
            </li>
            <li className="font-bold">
              Email principal: <span className="font-medium">{data.email}</span>
            </li>
            <li className="font-bold">
              <div className="flex gap-5">
                Téléphone :
                {modifyPhone ? (
                  <input
                    type="tel"
                    placeholder="numéro avec indicatif +000 "
                    pattern="^\+(?:[0-9]?){1,3}[0-9](?:[ \-]?[0-9]){5,15}$"
                    value={newPhone}
                    onChange={handleChange}
                  />
                ) : (
                  <span className="font-medium">{data.phones}</span>
                )}
                {modifyPhone ? (
                  <>
                    <button onClick={() => validatePhoneChange()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </button>
                    <button onClick={() => setModifyPhone(false)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </button>
                  </>
                ) : (
                  <button onClick={() => setModifyPhone(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
                      <line x1="3" y1="22" x2="21" y2="22"></line>
                    </svg>
                  </button>
                )}
              </div>
              {invalidFormat && (
                <div className="flex items-center mt-4 mb-4 text-sm text-red-800" role="alert">
                  <svg
                    className="flex-shrink-0 inline w-4 h-4 mr-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  <div>
                    <span className="font-medium">Format attendu : numéro de téléphone avec indicatif (+000)</span>
                  </div>
                </div>
              )}
            </li>
            <li className="font-bold flex">
              Groupe(s):{' '}
              {data.group.map((item, index, array) => {
                return (
                  <div key={index}>
                    <span className="font-medium px-1">
                      {item.group_id?.parentgroup?.name + ' : ' + item.group_id.name}
                    </span>
                  </div>
                );
              })}
            </li>
          </ul>
        </div>
        <div className="flex flex-col items-center space-y-5">
          <div className="w-1/2">
            <button
              className="w-full bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              onClick={HandleHelp}>
              Signaler une erreur ou demander une modification
            </button>
          </div>
          <div className="w-1/2">
            <button
              className="w-full bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              onClick={HandleModifPass}>
              Modifier mon mot de passe
            </button>
          </div>
        </div>
      </div>
      {blurPass && <ModalModifPass setBlurPass={setBlurPass} token={token} />}
    </div>
  );
}

export default ContentAccount;
