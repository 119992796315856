import React from 'react';

function FillingDocCard(props) {
  const onChangeHandler = (e) => {
    props.setFile(e.target.files[0]);
  };

  return (
    <div className="FillingDocCard px-5 py-5 w-full shadow ring-1 ring-black ring-opacity-5 rounded-sm bg-white">
      <div>
        <p className="text-lg pb-5">
          {props.need === true ? <span className="text-red-600">*</span> : ''}
          {props.title}
        </p>
        <div className="flex justify-center items-center w-full">
          <label className="flex flex-col justify-center items-center px-10 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="mb-3 w-10 h-10 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
              </svg>
              <div>
                <p className="text-sm text-gray-500">
                  <span className="font-semibold">Cliquer pour uploader</span> ou glisser et déposer
                </p>
                <p className="flex justify-center text-sm text-gray-500">{props.accept} uniquement.</p>
                {props.dim && <p className="flex justify-center text-sm text-gray-500">{props.dim} recommandé.</p>}
              </div>
              <div className="flex justify-center text-sm text-gray-500 pt-4 font-semibold">
                {props.file && <p>{props.file.name}</p>}
              </div>
            </div>
            <input onChange={onChangeHandler} accept={props.accept} id="dropzone-file" type="file" className="hidden" />
          </label>
        </div>
      </div>
    </div>
  );
}

export default FillingDocCard;
