import React, { useState, useContext } from 'react';
import AuthService from '../../../oldService/authService';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import { OrganizationServices } from '../../../oldService/organizatonServices';
import { useNavigate, useParams } from 'react-router-dom';
import PeopleList from '../../../oldComponents/PeopleList/PeopleList';
import OrganizationDetails from '../../../oldComponents/OrganizationDetails';
import { OrgaDirUsers1Services } from '../../../oldService/organizationDirectusUsers1Services.js';
import UserDispoService from '../../../oldService/userDispoService';
import UserService from '../../../oldService/userService';
import UpdateOrganizationModal from '../../../oldComponents/Modals/UpdateOrganizationModal';
import { AuthContext } from '../../../AuthProvider';

const Organization = () => {
  const { organizationId } = useParams();
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);
  const [blur, setBlur] = useState(false);
  const [organization, setOrganization] = useState([]);
  OrganizationServices.FormatGetOneOrganization(organization, setOrganization, organizationId);

  const [contacts, setContacts] = useState([]);
  OrgaDirUsers1Services.FormatGetAllUsersForOrga(contacts, setContacts, organizationId);

  const [userDispo, setUserDispo] = useState([]);
  UserDispoService.FormatUserEngaAll(userDispo, setUserDispo);

  const [users, setUsers] = useState([]);
  UserService.FormatUserList(users, setUsers);

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <div className="Organization">
        {user != null && (
          <>
            <div className="Commitments flex w-screen min-h-screen">
              <NavbarLeft user={user} sub={3} blur={blur} />
              <div className="flex flex-col h-full w-full">
                <NavbarTop user={user} urls={['home', 'followed']} blur={blur} />
                <div className="p-10 space-y-8">
                  <div className={`${blur ? 'blur-sm' : 'blur-none'} flex justify-between`}>
                    <h1 className="font-bold text-3xl text-blue-500">{organization.name}</h1>
                    <div>
                      <UpdateOrganizationModal organization={organization} />
                    </div>
                  </div>
                  <div className="space-y-14 py-4 px-5">
                    {/*{blur &&*/}
                    {/*    <ModalModifPlan id={id} setDisplay={setBlur} setBlur={setBlur} data={dispo} />*/}
                    {/*}*/}
                    <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                      <OrganizationDetails organization={organization} />
                    </div>
                    <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                      <PeopleList
                        admin
                        title={'Liste des contacts'}
                        data={contacts}
                        dirUser={userDispo}
                        contact={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default Organization;
