import axios from 'axios';

export const DIRECTUS_API_URL = process.env.REACT_APP_DIRECTUS_API_URL;

const getAuthHeaders = () => {
  const userStr = localStorage.getItem('token');
  let user = null;

  if (userStr) user = JSON.parse(userStr);

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return { Authorization: '' };
  }
};

export default function () {
  return axios.create({
    baseURL: DIRECTUS_API_URL,
    headers: {
      'Content-type': 'application/json',
      ...getAuthHeaders()
    }
  });
}
