import React, { useState } from 'react';
import ModalSignature from '../ModalSignature/ModalSignature';

const UserDocList = ({ userDocList, setDisplayModal, setBlur, displayModal, blur }) => {
  const [selected, setSelected] = useState([]);

  const handleModal = (item) => {
    setBlur(true);
    setDisplayModal(true);
    setSelected(item);
  };

  return (
    <div>
      {displayModal && <ModalSignature setDisplayModal={setDisplayModal} setBlur={setBlur} data={selected} />}
      <div className={blur ? 'blur-sm' : 'blur-none'}>
        <h2 className="font-bold text-xl pb-5 text-blue-700/90">Mes Signatures</h2>
        <div className="RatingTableLis">
          <div className="flex flex-col max-h-96">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-sm">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Document
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {userDocList &&
                        userDocList.map((item, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <button
                                onClick={() => {
                                  handleModal(item);
                                }}
                                className="px-3 py-2 bg-gray-200 rounded-sm shadow-sm text-black hover:bg-blue-500 hover:text-white">
                                Signer
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDocList;
