import React, { useEffect } from 'react';

type Props = React.PropsWithChildren<{
  id: string;
  name: string;
  register: (name: string, validationSchema: any) => object;
  unregister: (name: string) => void;
  validationSchema: any;
}>;

function Textarea({ id, name, register, unregister, validationSchema }: Props) {
  useEffect(() => {
    register(name, validationSchema);
    return () => {
      unregister(name);
    };
  }, [register, unregister]);
  return (
    <textarea
      className="w-full p-2 h-28 border border-gray-300"
      placeholder="À compléter"
      id={id}
      name={name}
      {...register(name, validationSchema)}
    />
  );
}

export default Textarea;
