import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/form';

const GetForm = (name) => {
  return axios.get(
    API_URL +
      '?filter[name][_eq]=' +
      name +
      '&fields=id,name,date_created,date_updated,questions_id.questions_id.name,questions_id.questions_id.id,questions_id.questions_id.type,questions_id.questions_id.questions_choice_id.questions_choice_id.name,questions_id.questions_id.questions_parents.related_questions_id,questions_id.questions_id.questions_choice_parents.questions_choice_id.name,questions_id.questions_id.description,questions_id.questions_id.required',
    { headers: authHeader() }
  );
};

const FormatGetForm = (form, setForm, name) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await (await GetForm(name)).data.data;
      data.map((item) => {
        list.push(item);
        return item;
      });
      setForm(list);
    };
    MakeList();
  }, []);
  return form;
};

const FormService = {
  GetForm,
  FormatGetForm
};

export default FormService;
