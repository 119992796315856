import React from 'react';
import Ratings from '../Ratings/Ratings';

import './EvalContent.css';
import { useEffect } from 'react';
import { useState } from 'react';
import UserDispoService from '../../oldService/userDispoService';
import DispoService from '../../oldService/dispService';
import moveToNextStep from '../../services/logicFunction/moveToNextStep';
import LoadingButton from '../LoadingButton/LoadingButton';

export default function EvalContent({
  listBenevole,
  dispoId,
  asso,
  step,
  listResponsible,
  president,
  file_vie_asso,
  setChangeInPage,
  blur
}) {
  const [isAllEvalSubmit, setIsAllEvalSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const formatedBenevole = () => {
    const list = [];
    listBenevole.map((user) => {
      list.push({
        id: user.id,
        name: user.name,
        email: user.email,
        id_table_link_dispo_user: user.other_id,
        evaluation_id: step === '1' ? user.evaluation_id : user.evaluation_id_2,
        team: user.team
      });
    });
    return list;
  };
  const formatedResponsible = () => {
    const list = [];
    listResponsible.map((user) => {
      list.push({
        id: user.directus_users_id.id,
        name: user.directus_users_id.first_name + ' ' + user.directus_users_id.last_name,
        email: user.directus_users_id.email,
        id_table_link_dispo_user: user.id,
        evaluation_id: step === '1' ? user.evaluation_id : user.evaluation_id_2,
        team: user.directus_users_id.group.slice(-1)[0]
      });
    });
    return list;
  };

  useEffect(() => {
    const allEval = [...formatedBenevole(), ...formatedResponsible()];
    setIsAllEvalSubmit(allEval.every((user) => user.evaluation_id !== null));
  }, [formatedBenevole, formatedResponsible]);

  const validateAllEvaluation = async () => {
    setLoading(true);
    try {
      const userDispoInParent = await UserDispoService.getIdUserDispoByFilesVieAsso(file_vie_asso);
      if (userDispoInParent.data.data[0].step_id.tovalidate) {
        await UserDispoService.PatchUserDispoValidation(userDispoInParent.data.data[0].id);
        await DispoService.PatchDispoEvaluationStep(dispoId, '0');
      } else {
        await moveToNextStep(
          userDispoInParent.data.data[0].dispositif,
          userDispoInParent.data.data[0].step_id.id,
          userDispoInParent.data.data[0].id,
          dispoId,
          async () => {
            await DispoService.PatchDispoEvaluationStep(dispoId, '0');
          }
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    setChangeInPage((prev) => prev + 1);
    setLoading(false);
    // window.location.reload();
  };
  return (
    <>
      {president && isAllEvalSubmit && (
        <div className={`bg-gray-50 rounded-sm border-4 border-amber-200 ${blur ? 'blur-sm' : 'blur-none'}`}>
          <div className="EvalContent space-y-5">
            <div className="px-5 py-5 space-y-6">
              <h2 className="font-bold text-xl py-5 text-blue-700/70">Envoyer les évaluations</h2>
              <div className="text-center">
                <p className="text-center">
                  L'intégralité des évaluations ont été soumises dans votre dispositif. En tant que président-e vous
                  pouvez vérifier puis valider l'intégralité des évaluations en cliquant sur le bouton ci-dessous pour
                  passer à l'étape suivante et indiquer à l'administration que vous avez terminé cette étape.
                </p>
                <span className="text-red-500">
                  Attention ! Vos évaluations ne seront pas visible par l'administration avant votre validation.
                </span>
              </div>
              <div className="flex justify-center">
                <LoadingButton
                  loading={loading}
                  handleSubmit={validateAllEvaluation}
                  btnText="Valider toutes les évaluations"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {president && (
        <div className={`bg-gray-50 rounded-sm ${blur ? 'blur-sm' : 'blur-none'}`}>
          <div className="EvalContent space-y-5">
            <Ratings
              data={formatedResponsible()}
              dispoId={dispoId}
              asso={asso}
              title="Évaluations des membres du bureau"
              responsible={true}
              step={step}
            />
          </div>
        </div>
      )}
      <div className={`bg-gray-50 rounded-sm ${blur ? 'blur-sm' : 'blur-none'}`}>
        <div className="EvalContent space-y-5">
          <Ratings
            data={formatedBenevole()}
            dispoId={dispoId}
            asso={asso}
            title="Évaluations des membres bénévoles"
            responsible={false}
            step={step}
          />
        </div>
      </div>
    </>
  );
}
