import React, { useEffect, useState } from 'react';
import ChoiceCard from '../ChoiceCard/ChoiceCard';

import './MultipleChoicePlans.css';

function MultipleChoicePlans(props) {
  const [activated, setActivated] = useState(true);
  const [validation, setValidation] = useState(true);
  const [obligatory, setObligatory] = useState(true);

  useEffect(() => {
    let newArray = [...props.steps];

    newArray[props.index] = {
      name: props.steps[props.index].name,
      activated: activated,
      validation: validation,
      obligatory: obligatory
    };
    props.setSteps(newArray);
  }, [activated, validation, obligatory]);

  return (
    <div className="flex flex-col justify-center items-center space-y-5">
      <div className="flex flex-col justify-center items-center space-y-5">
        <h3 className="font-semibold">{props.title}</h3>
        <div className="flex justify-center">
          <p className="text-gray-600 text-xs w-1/2">{props.description}</p>
        </div>
      </div>
      <div>
        <ChoiceCard title={"Activer l'étape:"} data={activated} setData={setActivated} />
        <ChoiceCard title={"L'étape nécessite une validation de l'admin:"} data={validation} setData={setValidation} />
        <ChoiceCard title={"L'étape est-elle obligatoire:"} data={obligatory} setData={setObligatory} />
      </div>
    </div>
  );
}

export default MultipleChoicePlans;
