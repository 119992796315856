import React, { useEffect, useState } from 'react';
import UpdateContactModal from '../Modals/UpdateContactModal';
// import WarningIcon from "../WarningIcon/WarningIcon";

function TableList(props) {
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckAll = (statuts) => {
    if (statuts === true) {
      props.setList(
        props.data.map((item) => {
          return {
            first_name: item.first_name,
            last_name: item.last_name,
            team: item.team.map((gr) => {
              if (gr.group_id.parentgroup) {
                return [gr.group_id.name, gr.group_id.parentgroup.name];
              } else {
                return gr.group_id.name;
              }
            }),
            email: item.email,
            email2: item.email2,
            phone: item.phone,
            last_access: item.lastAccess,
            id: item.id
          };
        })
      );
    } else {
      props.setList([]);
    }
  };

  const handleAdd = (statuts, item) => {
    if (statuts === true) {
      props.setList([
        ...props.list,
        {
          first_name: item.first_name,
          last_name: item.last_name,
          team: item.team.map((gr) => {
            if (gr.group_id.parentgroup) {
              return [gr.group_id.name, gr.group_id.parentgroup.name];
            } else {
              return gr.group_id.name;
            }
          }),
          email: item.email,
          email2: item.email2,
          phone: item.phone,
          last_access: item.lastAccess,
          id: item.id
        }
      ]);
    } else {
      props.setList(
        props.list.filter(
          (itemList) =>
            itemList.first_name !== item.first_name &&
            itemList.last_name !== item.last_name &&
            itemList.email !== item.email &&
            itemList.email2 !== item.email2 &&
            itemList.phone !== item.phone
        )
      );
    }
  };

  useEffect(() => {
    props.list.length === props.data.length ? setCheckAll(true) : setCheckAll(false);
  }, [props.list, props.data]);

  return (
    <div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 h-96 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-sm text-start font-semibold text-gray-900">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckAll(e.target.checked);
                        }}
                        checked={checkAll}
                      />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Nom
                    </th>
                    {!props.contact && (
                      <>
                        <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                          Groupe(s)
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                          Dispositif(s)
                        </th>
                      </>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    {props.contact && (
                      <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                        Fonction
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                      Téléphone
                    </th>
                    {props.admin && (
                      <th scope="col" className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900">
                        Dernière Connexion
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item, index) => {
                    return (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              handleAdd(e.target.checked, item);
                            }}
                            checked={props.list[0] !== undefined && props.list.find((li) => li.id === item.id)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-start text-sm font-semibold text-gray-900 underline hover:no-underline">
                          {props.contact ? (
                            <UpdateContactModal contact={item}>{item.name}</UpdateContactModal>
                          ) : (
                            <p>{item.name}</p>
                          )}
                        </td>
                        {!props.contact && (
                          <>
                            <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                              <div className="flex items-center space-x-1">
                                {item &&
                                  item.team.map((item, index, array) => {
                                    return (
                                      <div key={index} className="flex space-x-1">
                                        {index < 2 && (
                                          <div className="flex space-x-1">
                                            {props.admin && item.group_id.parentgroup && (
                                              <p>{item.group_id.parentgroup.name} /</p>
                                            )}
                                            {props.admin ? <p>{item.group_id.name}</p> : <p>{item}</p>}
                                            {index === 0 && array.length > 1 ? <span>/</span> : ''}
                                          </div>
                                        )}
                                        {index === 1 && array.length > 2 && <span>...</span>}
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                              <div className="flex justify-center items-center space-x-1">
                                {props.planDetails
                                  ? props.userDispo
                                      .filter((dispo) => dispo.user.id === item.other_id)
                                      .map((dispoItem, index, array) => {
                                        return (
                                          <div key={index} className="flex space-x-1">
                                            {index < 2 && (
                                              <div className="flex space-x-1">
                                                <p>
                                                  {dispoItem.dispositif &&
                                                    (dispoItem.dispositif.name.length > 15
                                                      ? dispoItem.dispositif.name.substring(0, 15) + '...'
                                                      : dispoItem.dispositif.name)}
                                                </p>
                                                {index < array.length - 1 ? <span>/</span> : ''}
                                              </div>
                                            )}
                                            {index === 1 && array.length > 2 && <span>...</span>}
                                          </div>
                                        );
                                      })
                                  : props.userDispo
                                      .filter((dispo) => dispo.user.id === item.id)
                                      .map((dispoItem, index, array) => {
                                        return (
                                          <div key={index} className="flex space-x-1">
                                            {index < 2 && (
                                              <div className="flex space-x-1">
                                                <p>
                                                  {dispoItem.dispositif &&
                                                    (dispoItem.dispositif.name.length > 15
                                                      ? dispoItem.dispositif.name.substring(0, 15) + '..'
                                                      : dispoItem.dispositif.name)}
                                                </p>
                                                {index < array.length - 1 ? <span>/</span> : ''}
                                              </div>
                                            )}
                                            {index === 1 && array.length > 2 && <span>...</span>}
                                          </div>
                                        );
                                      })}
                              </div>
                            </td>
                          </>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900 underline hover:no-underline">
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </td>
                        {props.contact && (
                          <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                            <p>{item.function}</p>
                          </td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">{item.phone}</td>
                        {props.admin && (
                          <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-900">
                            {new Date(item.lastAccess).toLocaleString() === '01/01/1970 01:00:00' ? (
                              <p>Jamais connecté</p>
                            ) : (
                              new Date(item.lastAccess).toLocaleString()
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableList;
