import React, { useState } from 'react';
import { PortalWithState } from 'react-portal';
import ContactForm from '../../Forms/ContactForm';

function UpdateContactModal({ contact, children }) {
  const [contacts, setContacts] = useState([]);

  return (
    <PortalWithState closeOnEsc>
      {({ openPortal, closePortal, portal }) => (
        <div>
          <a onClick={openPortal}>{children}</a>
          {portal(
            <>
              <div
                className="py-12 fixed bg-gray-700 transition duration-150 ease-in-out z-50 top-0 right-0 bottom-0 left-0 flex backdrop-blur-sm bg-white/30"
                id="modal">
                <div
                  id="authentication-modal"
                  tabIndex="-1"
                  className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
                  aria-modal="true"
                  role="dialog">
                  <div className="relative p-4 w-full max-w-5xl h-full">
                    <div className="relative bg-white rounded shadow">
                      <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        onClick={closePortal}>
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                      <div className="py-6 px-6 lg:px-8">
                        <h3 className="mb-4 text-xl font-medium text-gray-900">Modifier le contact</h3>
                        <div className="space-y-6">
                          <ContactForm
                            contact={contact}
                            onContactAddedOrUpdated={() => {
                              window.location.reload();
                              closePortal();
                            }}
                            contacts={contacts}
                          />
                          <div className="flex justify-around items-center pt-2">
                            <div>
                              <button
                                type="submit"
                                form="ContactForm"
                                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6">
                                Modifier
                              </button>
                            </div>
                            <div>
                              <button
                                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-red-500 hover:text-white flex space-x-1 mb-6"
                                onClick={closePortal}>
                                Fermer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </PortalWithState>
  );
}

export default UpdateContactModal;
