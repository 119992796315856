import React, { useEffect, useState } from 'react';
import AuthService from '../../oldService/authService';

import './ModalModifPass.css';

function ModalModifPass(props) {
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [length, setLength] = useState(false);
  const [number, setNumber] = useState(false);
  const [equal, setEqual] = useState(false);
  const [special, setSpecial] = useState(false);

  const FindCaract = (list, str) => {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < str.length; j++) {
        if (list[i] === str[j]) {
          return true;
        }
      }
    }
    return false;
  };

  const PassOnChange = (e) => {
    setPassword(e.target.value);
  };

  const ConfPassOnChange = (e) => {
    setConfPassword(e.target.value);
    setEqual(password === confPassword);
  };

  const handleDisplay = () => {
    AuthService.resetPassword(props.token, password).then(() => {
      window.location.reload();
    });
  };

  const HandleDisplayClose = () => {
    props.setBlurPass(false);
  };

  useEffect(() => {
    setLength(password.length >= 8);
    setNumber(FindCaract(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'], password));
    setSpecial(FindCaract(['.', ',', ':', ';', '?', '!', '/', '+', '-', '='], password));
    setEqual(password === confPassword);
  }, [password, confPassword]);

  return (
    <div className="ModalValidAdmin">
      <div
        id="authentication-modal"
        tabIndex="-1"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
        aria-modal="true"
        role="dialog">
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={HandleDisplayClose}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-6 px-6 lg:px-8 flex flex-col items-center justify-center">
              <h3 className="mb-4 text-xl font-medium text-gray-900">Modification du mot de passe</h3>
              <div className="space-y-6">
                <div className="flex flex-col">
                  <label>Nouveau mot de passe</label>
                  <input
                    placeholder="********"
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-2 placeholder-gray-500/80"
                    type="password"
                    onChange={PassOnChange}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Confirmation nouveau mot de passe</label>
                  <input
                    placeholder="********"
                    className="shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-2 placeholder-gray-500/80"
                    type="password"
                    onChange={ConfPassOnChange}
                  />
                </div>
                <div className="flex flex-col justify-center items-start text-sm">
                  <p>Le mot de passe:</p>
                  <p className={`${length ? 'text-blue-500' : 'text-red-500'}`}> - Contient au moins 8 caractères.</p>
                  <p className={`${number ? 'text-blue-500' : 'text-red-500'}`}>
                    {' '}
                    - Contient au moins un chiffre de 0 à 9.
                  </p>
                  <p className={`${special ? 'text-blue-500' : 'text-red-500'}`}>
                    {' '}
                    - Contient au moins un caractère spécial (. , : ; ? ! / + - =).
                  </p>
                  <p className={`${equal && confPassword ? 'text-blue-500' : 'text-red-500'}`}>
                    {' '}
                    - Est identique à la confirmation.
                  </p>
                </div>
                <div className="flex justify-center">
                  <button
                    className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white flex space-x-1 mb-6"
                    onClick={handleDisplay}>
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalModifPass;
