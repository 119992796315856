import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';
import { useEffect } from 'react';

const API_URL = API + 'items/step';

const PostStep = (name, toValidate, isOptional) => {
  return axios.post(
    API_URL,
    {
      name: name,
      tovalidate: toValidate,
      isoptional: isOptional
    },
    { headers: authHeader() }
  );
};

const GetOneStep = (id) => {
  return axios.get(API_URL + '?filter[id][_eq]=' + id, { headers: authHeader() });
};

const FormatGetOneStep = (step, setStep, id) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await StepService.GetOneStep(id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setStep(list);
    };
    MakeList();
  }, []);
  return step;
};

const StepService = {
  PostStep,
  GetOneStep,
  FormatGetOneStep
};

export default StepService;
