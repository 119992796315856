import './AdressBar.css';
import { url } from '../../data';

export default function AdressBar({ urls }) {
  return (
    <div className="AdressBar">
      <div className="font-medium text-gray-600 px-3">
        <div className="space-x-2 flex">
          {urls.map((item, index, array) => {
            const value = url.find((val) => val.name === item);

            return (
              <div key={index}>
                <a href={value.adress} className="text-gray-600 font-medium hover:underline">
                  {value.display}
                </a>
                {index + 1 < array.length && <span className="pl-2">/</span>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
