import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import UserService from '../../oldService/userService';
import UserDispoService from '../../oldService/userDispoService';
import LoadingButton from '../LoadingButton/LoadingButton';
import moveToNextStep from '../../services/logicFunction/moveToNextStep';

function MemberSelect(props) {
  const [studList, setStudList] = useState([]);
  UserService.FormatUserList(studList, setStudList);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [list, setList] = useState([]);
  const [removeItem, setRemoveItem] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.office) {
      const user = studList.find((student) => student.id === props.actualUser.id);
      if ((user !== undefined) & (list.length === 0)) {
        setList([...list, { name: user.name, status: 'president', user: user }]);
      }
    }
  }, [studList]);

  const options =
    studList && props.office
      ? studList
          .filter((s) => !list.map((u) => u.user.id).includes(s.id))
          .map((item) => {
            return {
              value: item.id,
              label: item.name
            };
          })
      : studList
          .filter(
            (s) =>
              !list.map((u) => u.user.id).includes(s.id) &
              !props.childDispo?.responsibles.map((r) => r.directus_users_id.id).includes(s.id)
          )
          .map((item) => {
            return {
              value: item.id,
              label: item.name
            };
          });
  const status = [
    { value: 'president', label: 'Président-e' },
    { value: 'vice-president', label: 'Vice-Président-e' },
    { value: 'secretary', label: 'Secrétaire' },
    { value: 'treasurer', label: 'Trésorier' }
  ];
  const checkAvailability = (arr, val) => {
    return arr.some((arrVal) => val === arrVal.status);
  };
  const updataListStatus = () => {
    let listStatus = status;
    const oneTimeOnly = ['president', 'treasurer'];
    oneTimeOnly.map((uniquerole) => {
      if (checkAvailability(list, uniquerole)) {
        listStatus = listStatus.filter((stat) => stat.value !== uniquerole);
      }
    });
    return listStatus;
  };

  const optionsStatus = updataListStatus();

  const optionsChange = (e) => {
    setSelectedOption(e.value);
  };

  const statusChange = (e) => {
    setSelectedStatus(e.value);
  };

  const handleAddItem = () => {
    let user = studList.find((item) => item.id === selectedOption);
    setList([...list, { name: user.name, status: selectedStatus, user: user, role_details: details }]);
    setSelectedOption(null);
    setSelectedStatus(null);
    setDetails('');
  };

  useEffect(() => {
    setList(list.filter((item) => item !== removeItem));
  }, [removeItem]);

  useEffect(() => {
    console.log(list);
  }, [list]);

  const handleAddList = async () => {
    if (window.confirm('Valider la liste des membres ?')) {
      setLoading(true);
      if (props.dispoAsso) {
        if (props.respo) {
          const promises = list.map(async (item) => {
            await DispDirUsersService.postDirectusUser(
              props.childDispo.id,
              item.user.id,
              item.status,
              item.role_details
            );
            return item;
          });
          await Promise.all(promises);
          await validateForm();
          if (props.userDispo.step.tovalidate === false) {
            await moveToNextStep(
              props.dispo.id,
              props.userDispo.step.id,
              props.userDispo.other_id,
              props.childDispo.id,
              () => {
                setLoading(false);
                props.setChangeInPage((prev) => prev + 1);
              }
            );
          } else {
            setLoading(false);
            props.setChangeInPage((prev) => prev + 1);
          }
        } else {
          const promises = list.map(async (item) => {
            await UserDispoService.PostUserDispo(props.childDispo.id, item.user.id);
            return item;
          });
          await Promise.all(promises);
          await validateForm();
          if (props.userDispo.step.tovalidate === false) {
            await moveToNextStep(
              props.dispo.id,
              props.userDispo.step.id,
              props.userDispo.other_id,
              props.childDispo.id,
              () => {
                setLoading(false);
                // window.location.reload();
                props.setChangeInPage((prev) => prev + 1);
              }
            );
          } else {
            setLoading(false);
            // window.location.reload();
            props.setChangeInPage((prev) => prev + 1);
          }
        }
      } else {
        const promises = list.map(async (item) => {
          await DispDirUsersService.postDirectusUser(props.dispo.id, item.user.id);
          return item;
        });
        await Promise.all(promises);
        setLoading(false);
        window.location.reload();
      }
    }
  };

  const validateForm = async () => {
    await UserDispoService.PatchUserDispoValidate(
      props.userDispo.other_id,
      props.userDispo.files_asso_id,
      props.dispo.step.find((item) => item.step_id.name === 'Membres bureau').step_id.tovalidate
    );
  };
  const onChangeDetails = (e) => {
    setDetails(e.target.value);
  };
  return (
    <div className="MemberSelect space-y-5 pt-5 pb-10 px-10">
      <h2 className="font-medium text-xl py-5 text-blue-700">
        {props.office === true ? 'Assignation des membres du bureau' : 'Assignation des membres bénévoles'}
      </h2>
      <div className="flex justify-center items-center space-x-5">
        <div className="w-4/12">
          <Select
            id="select-member"
            placeholder={'Choisir nom...'}
            defaultValue={selectedOption}
            options={options}
            value={selectedOption !== null ? options.find((item) => item.value === selectedOption) : selectedOption}
            onChange={optionsChange}
          />
        </div>
        {props.office === true && (
          <>
            <div className="w-4/12">
              <Select
                id="select-status"
                placeholder={'Choisir rôle...'}
                defaultValue={selectedStatus}
                options={optionsStatus}
                value={selectedStatus !== null ? status.find((item) => item.value === selectedStatus) : selectedStatus}
                onChange={statusChange}
              />
            </div>
            <input
              type="text"
              id="first_name"
              className="bg-white border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5"
              placeholder="Complément..."
              onChange={onChangeDetails}
              value={details}
            />
          </>
        )}
        <div>
          <button
            className="px-3 py-1 bg-gray-300 rounded-sm hover:bg-blue-500 hover:text-white"
            onClick={handleAddItem}>
            Ajouter
          </button>
        </div>
      </div>
      <ul className="">
        {list.map((item, index) => {
          return (
            <li key={index} className="grid grid-cols-4 px-5 py-2 bg-white hover:bg-gray-50">
              <p className="text-start">{item.name}</p>
              <div className="text-center">
                {item.status === 'president' && <p>Président-e</p>}
                {item.status === 'secretary' && <p>Secrétaire</p>}
                {item.status === 'treasurer' && <p>Trésorier</p>}
                {item.status === 'vice-president' && <p>Vice-Président-e</p>}
              </div>
              <p className="text-center">{item.role_details}</p>
              {item.status !== 'president' && (
                <div className="text-end">
                  <button
                    onClick={() => {
                      setRemoveItem(item);
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 hover:text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {/* {!props.admin && */}
      <div className="flex justify-center pt-5">
        <LoadingButton loading={loading} handleSubmit={handleAddList} />
      </div>
      {/* } */}
    </div>
  );
}

export default MemberSelect;
