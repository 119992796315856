import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import DispoTagService from '../../oldService/dispTagService';

import './MultipleSelect.css';

function MultipleSelect(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [removeItem, setRemoveItem] = useState(null);
  const [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDetails, setRoleDetails] = useState(null);
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    const listt = [...props.listBefore];
    setTempList(listt);
  }, [props.listBefore]);

  useEffect(() => {
    setList(tempList);
  }, [tempList]);

  const options =
    props.type === 'member'
      ? props.data
          .filter((member) => !props.excludestudent.map((s) => s.id).includes(member.id))
          .map((item) => {
            return { value: item, label: item.name };
          })
      : props.data.map((item) => {
          return { value: item, label: item.label };
        });
  const optionsChange = (e) => {
    setSelectedOption(e.value);
  };

  const handleAddItem = () => {
    if (list.indexOf(selectedOption) === -1) {
      if (props.type === 'member') {
        DispDirUsersService.postDirectusUser(props.id, selectedOption.id, selectedStatus, roleDetails).then(
          (result) => {
            setList([
              ...list,
              {
                id: result.data.data.id,
                name: selectedOption.name,
                other_id: selectedOption.id,
                role: selectedStatus,
                role_details: roleDetails
              }
            ]);
          }
        );
        setList([...list, selectedOption]);
      }
      if (props.type === 'tag') {
        DispoTagService.postDispTag(props.id, selectedOption.id).then((result) => {
          setList([
            ...list,
            {
              id: result.data.data.id,
              name: selectedOption.label,
              other_id: selectedOption.id
            }
          ]);
        });
      }
    }
  };

  useEffect(() => {
    setList(list.filter((item) => item !== removeItem));
    if (props.type === 'member' && removeItem) {
      DispDirUsersService.DeleteDispDirUser(removeItem.id);
    }
    if (props.type === 'tag' && removeItem) {
      DispoTagService.deleteDispTag(removeItem.id);
    }
  }, [removeItem]);
  const status = [
    { value: 'vice-president', label: 'Vice-Président-e' },
    { value: 'secretary', label: 'Secrétaire' },
    { value: 'treasurer', label: 'Trésorier' }
  ];
  const statusChange = (e) => {
    setSelectedStatus(e.value);
  };
  const onChangeDetails = (e) => {
    setRoleDetails(e.target.value);
  };
  const convertRole = (role) => {
    let result;
    role === 'president'
      ? (result = 'Président-e')
      : role === 'vice-president'
      ? (result = 'Vice-Président-e')
      : role === 'treasurer'
      ? (result = 'Trésorier')
      : (result = 'Secrétaire');
    return result;
  };
  return (
    <div className="MultipleSelect">
      <h2 className="text-left">{props.title}</h2>
      <div className="flex flex-col justify-items-center gap-5 items-center">
        <div className={`flex w-full gap-2 ${props.dispoType !== 'asso' ? 'justify-center' : ''}`}>
          <Select
            id="select-member"
            placeholder={`${props.type === 'tag' ? 'Choisir un tag...' : 'Choisir nom...'}`}
            defaultValue={selectedOption}
            options={options}
            value={options.find((item) => item.value === selectedOption)}
            onChange={optionsChange}
            className="w-1/3"
          />
          {props.type === 'member' && props.dispoType === 'asso' && (
            <>
              <Select
                id="select-status"
                placeholder={'Choisir rôle...'}
                options={status}
                value={status.find((item) => item.value === selectedStatus)}
                onChange={statusChange}
                className="w-1/3"
              />
              <input
                type="text"
                id="first_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5"
                placeholder="Complément..."
                onChange={onChangeDetails}
              />
            </>
          )}
        </div>
        <div>
          <button
            className="px-3 py-2 bg-gray-300 rounded-sm hover:bg-blue-500 hover:text-white"
            onClick={handleAddItem}>
            Ajouter
          </button>
        </div>
      </div>
      <div className={`${props.dispoType !== 'asso' ? 'w-full flex justify-center' : ''}`}>
        <div
          className={`relative overflow-x-auto shadow-md sm:rounded-lg my-3 ${
            props.dispoType !== 'asso' ? 'w-1/2' : ''
          }`}>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nom
                </th>
                {props.type === 'member' && props.dispoType === 'asso' && (
                  <>
                    <th scope="col" className="px-6 py-3">
                      Role
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Détails
                    </th>
                  </>
                )}
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr className="bg-white border-b  hover:bg-gray-50" key={index}>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      {item.name}
                    </th>
                    {props.type === 'member' && props.dispoType === 'asso' && (
                      <>
                        <td className="px-6 py-4">{convertRole(item.role)}</td>
                        <td className="px-6 py-4">{item.role_details}</td>
                      </>
                    )}
                    {item.role !== 'president' && (
                      <td className="px-6 py-4">
                        <button
                          className="text-red-600"
                          onClick={() => {
                            setRemoveItem(item);
                          }}>
                          Supprimer
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MultipleSelect;
