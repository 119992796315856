import './ButtonsList.css';

export default function ButtonsList({ steps, userDispo }) {
  return (
    <div className="ButtonsList">
      <ul>
        <div>
          {steps
            .sort((a, b) => a.order - b.order)
            .map((step, index) => (
              <button
                className={`px-2 py-1 text-white rounded-sm  ${
                  userDispo.step.name === step.step_id.name ? 'bg-blue-500 text-white' : 'bg-gray-300'
                }`}
                key={index}>
                {step.step_id.name}
              </button>
            ))}
        </div>
      </ul>
    </div>
  );
}
