import React, { useEffect } from 'react';
import Select from 'react-select';
import { useState } from 'react';
import { OrganizationServices } from '../../../../oldService/organizatonServices';
import { OrgaDirUsers1Services } from '../../../../oldService/organizationDirectusUsers1Services.js';
import HostOrganizationForm from '../../HostOrganizationForm';
import DispoStepService from '../../../../oldService/dispoStepService';
import ChoiceCard from '../../../ChoiceCard/ChoiceCard';
import SirenChoose from '../../../SirenChoose/SirenChoose';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../Step1/updateAction';
import updateSuccess from '../updateSuccess';
import { Controller, useForm } from 'react-hook-form';

const Step1 = ({ defaultValues, formRef, onSelect = null }) => {
  const { actions } = useStateMachine({ updateAction, updateSuccess });
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues
  });
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrga, setSelectedOrga] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [dispoStep, setDispoStep] = useState([]);
  const [origineFr, setOrigineFr] = useState(true);
  const [sirenData, setSirenData] = useState({});
  const [orgaChoice, setOrgaChoice] = useState(0);

  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);
  OrganizationServices.FormatGetAllOrganizations(organizations, setOrganizations);
  OrgaDirUsers1Services.FormatGetAllOrgaDirUsers1(contacts, setContacts);

  const [sortedOrga, setSortedOrga] = useState(
    organizations.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      else return 0;
    })
  );

  useEffect(() => {
    setSortedOrga(
      organizations.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        else return 0;
      })
    );
  }, [organizations]);

  const onSubmit = async () => {
    actions.updateSuccess({ step1: true });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    SelectOnClick();
  }, [selectedOrga]);

  React.useEffect(() => {
    const subscription = watch((value) => {
      setSelectedOrga(value.organization);
      actions.updateAction({ raw: value });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const optionsOrga = sortedOrga.map((item) => {
    return { value: item, label: item.name };
  });

  const SelectOnClick = () => {
    if (onSelect instanceof Function && selectedOrga) {
      onSelect(selectedOrga.value);
      actions.updateAction({ raw: { organization: selectedOrga } });
    }
  };

  return (
    <div>
      <div className="space-y-20">
        <h2 className="font-bold text-2xl pb-5 text-blue-700/90 text-center">Organisation d'accueil</h2>
        <div className="flex justify-center items-center space-x-20">
          <div>
            <button
              className={`shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white ${
                orgaChoice === 0 ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
              onClick={() => {
                setOrgaChoice(0);
              }}>
              Sélectionner une
              <br />
              organisation existante
            </button>
          </div>
          <p className="font-bold">ou</p>
          <div>
            <button
              className={`shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm px-3 py-2 hover:bg-blue-500 hover:text-white ${
                orgaChoice === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
              onClick={() => {
                setOrgaChoice(1);
              }}>
              Créer une
              <br />
              organisation d'accueil
            </button>
          </div>
        </div>
        <div className="px-5">
          {orgaChoice === 0 && (
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <p className="font-bold text-blue-700/90 pb-5 text-center">Sélectionner une organisation d'accueil</p>
              <div className="flex flex-col items-center justify-center">
                <div className="w-6/12">
                  <Controller
                    rules={{ required: true }}
                    options={optionsOrga}
                    isClearable={true}
                    name="organization"
                    control={control}
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <Select
                          value={optionsOrga.find((item) => {
                            return item.value.id === field.value.value?.id;
                          })}
                          options={optionsOrga}
                          placeholder="Sélectionner..."
                          {...field}
                        />
                      );
                    }}
                  />
                </div>
                {errors.organization?.type === 'required' && (
                  <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                )}
                {selectedOrga && (
                  <div className="flex flex-col items-center w-full">
                    <div className="w-6/12 space-y-5 flex justify-center pt-5">
                      <div className="flex flex-col">
                        <div className="flex">
                          <p>
                            <span className="font-semibold text-blue-500">Adresse:</span> {selectedOrga.value.adress},
                          </p>
                          <p>
                            {selectedOrga.value.postal_code} {selectedOrga.value.city},
                          </p>
                          <p>{selectedOrga.value.country}</p>
                        </div>
                        <p>
                          <span className="font-semibold text-blue-500">Numéro Siret:</span>{' '}
                          {selectedOrga.value.numero_siret_rna}
                        </p>
                        <p>
                          <span className="font-semibold text-blue-500">Public bénéficiaire:</span>{' '}
                          {selectedOrga.value.beneficiaries}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
          {orgaChoice === 1 && (
            <div>
              <p className="font-bold text-blue-700/90 pb-5 text-center">Créer une organisation d'accueil</p>
              <div className="flex justify-center">
                <div className="space-y-10">
                  <div>
                    <p>Mon organisation d'accueil se situe en France:</p>
                    <ChoiceCard data={origineFr} setData={setOrigineFr} />
                  </div>
                  {origineFr && <SirenChoose data={sirenData} setData={setSirenData} />}
                </div>
              </div>
              <div className="pt-10">
                <HostOrganizationForm
                  onCreated={(data) => {
                    setSortedOrga([...sortedOrga, data]);
                    setValue('organization', { value: data, label: data.name });
                    setOrgaChoice(0);
                  }}
                  sirenData={sirenData}
                  organizations={organizations}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step1;
