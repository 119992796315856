import { useState, useEffect, useContext } from 'react';
import AuthService from '../../../oldService/authService';
// import square from '../../../assets/images/black-square.jpg';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
// import ContentElem from '../../../oldComponents/ContentElem/ContentElem';
import NoteContent from '../../../oldComponents/NoteContent/NoteContent';
import DispoService from '../../../oldService/dispService';
import UserDispoService from '../../../oldService/userDispoService';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import ModalModifPlan from '../../../oldComponents/ModalModifPlan/ModalModifPlan';
import { AuthContext } from '../../../AuthProvider';
// import PeopleList from '../../../oldComponents/PeopleList/PeopleList';
import './EventDetails.css';
import NewHeaderPresDispo from '../../../components/NewHeaderPresDispo';
import NewListOfParticipant from '../../../components/NewListOfParticipant';
import ModalExportEvaluation from '../../../components/ModalExportEvaluation';
import ModalModifDateEvent from '../../../components/ModalModifDateEvent';

const EventDetails = () => {
  const token = AuthService.getCurrentToken();
  const [note, setNote] = useState('');
  const [blur, setBlur] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const { user } = useContext(AuthContext);

  const [event, setEvent] = useState([]);
  const [dispo, setDispo] = useState();
  const [userList, setUserList] = useState([]);
  const [dirUser, setDirUser] = useState([]);
  const [displayModalModifPlan, setDisplayModalModifPlan] = useState(false);
  const [userAlreadyInIt, setUserAlreadyInIt] = useState([]);
  const [displayModalExport, setDisplayModalExport] = useState(false);
  const [displayModifDateEvent, setDisplayModifDateEvent] = useState(false);

  const getDispo = async (id) => {
    const data = await DispoService.getOneDispo(id);
    setDispo(data.data.data[0]);
  };

  useEffect(() => {
    getDispo(id);
  }, [id]);

  let myUserDispo;
  let userDispoId;

  const [admin, setAdmin] = useState();

  const [inscription, setInscription] = useState();

  const handleDisplayModif = () => {
    setBlur(true);
    setDisplayModalModifPlan(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleDisplayModifDate = () => {
    setBlur(true);
    setDisplayModifDateEvent(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleDisplayModalExport = () => {
    setBlur(true);
    setDisplayModalExport(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleCloseModalOnEscape = () => {
    setBlur(false);
    setDisplayModalModifPlan(false);
    setDisplayModalExport(false);
    setDisplayModifDateEvent(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };

  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleCloseModalOnEscape();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);

  useEffect(() => {
    if (dispo?.responsibles) {
      const allInvolve = [];
      allInvolve.push(...userList, ...dispo.responsibles.map((resp) => resp.directus_users_id));
      setUserAlreadyInIt(allInvolve);
    }
  }, [userList, dispo]);
  if (token) {
    DispoService.FormatOneDispo(event, setEvent, id);
    DispDirUsersService.FormatGetDirectusUserDispo(admin, setAdmin, id, user.id);
    UserDispoService.FormatGetUserDispoDispo(inscription, setInscription, id, user.id);
    myUserDispo = UserDispoService.FormatOneUserDispoNote(id);
    userDispoId = UserDispoService.FormatOneUserDispoId(id);
    UserDispoService.FormatGetAllUser(userList, setUserList, id);
    UserDispoService.FormatUserEngaAll(dirUser, setDirUser);
  }

  useEffect(() => {
    setNote(myUserDispo);
  }, [myUserDispo]);

  return (
    <div className="EventDetails flex max-w-[100vw] min-h-screen">
      {dispo != undefined && (
        <>
          <div className="Commitments flex w-screen min-h-screen">
            <NavbarLeft user={user} sub={3} blur={blur} />
            <div className="flex flex-col h-full w-full">
              <NavbarTop user={user} urls={['home', 'events']} blur={blur} />
              <div className="p-10 space-y-8">
                <div className="space-y-14 py-4 px-5">
                  <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                    <NewHeaderPresDispo
                      dispo={dispo}
                      handleDisplayModalCreateEvent={handleDisplayModifDate}
                      handleDisplayModif={handleDisplayModif}
                      excludedUsers={userAlreadyInIt} //TODO
                      dispoId={id}
                      firstStep={null}
                      handleDisplayModalManageDoc={null}
                      docList={null}
                      handleDisplayModalExport={handleDisplayModalExport} //TODO
                      admin={admin}
                      inscription={inscription}
                      pending={false}
                      dispoType={'event'}
                      withIndividualStep={dispo.parent === null}
                      userDispo={userList.find((item) => item.id === user.id)}
                      event={dispo}
                    />
                  </div>
                  {displayModalModifPlan && (
                    <ModalModifPlan
                      id={id}
                      setDisplay={setDisplayModalModifPlan}
                      setBlur={setBlur}
                      data={dispo}
                      excludestudent={userAlreadyInIt}
                      admin={user.role.name === 'Administrator'}
                    />
                  )}
                  {displayModifDateEvent && (
                    <ModalModifDateEvent setDisplayModal={setDisplayModifDateEvent} setBlur={setBlur} event={dispo} />
                  )}
                  {displayModalExport && (
                    <ModalExportEvaluation
                      dispoChildren={dispo.id}
                      dispoName={dispo.name}
                      setDisplayModal={setDisplayModalExport}
                      setBlur={setBlur}
                      DispoType={'event'}
                      Members={userList}
                      Responsibles={dispo.responsibles}
                    />
                  )}
                  {(inscription || admin) && userList && (
                    <div
                      className={`p-5 grid grid-cols-2 bg-gray-50 rounded ${
                        blur === true ? 'blur-sm' : 'blur-none'
                      } gap-x-20`}>
                      <NewListOfParticipant
                        listOfParticipant={userList}
                        admin={admin}
                        excludedUsers={null}
                        dispoId={id}
                        firstStep={null}
                      />
                    </div>
                  )}
                  <div className={`p-5 bg-gray-50 rounded ${blur ? 'blur-sm' : 'blur-none'}`}>
                    <NoteContent note={note} setNote={setNote} id={userDispoId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventDetails;
