import http from '../http-common';

const URL = '/items/form';

class FormDataService {
  getAll() {
    return http().get(URL);
  }

  get(id) {
    const fields: Array<string> = [
      'id',
      'name',
      'date_created',
      'date_updated',
      'questions_id.questions_id.name',
      'questions_id.questions_id.id',
      'questions_id.questions_id.type',
      'questions_id.questions_id.questions_choice_id.questions_choice_id.name',
      'questions_id.questions_id.questions_parents.related_questions_id',
      'questions_id.questions_id.questions_choice_parents.questions_choice_id.name',
      'questions_id.questions_id.description',
      'questions_id.questions_id.required'
    ];
    return http().get(`${URL}/${id}?fields=${fields.join(',')}`);
  }

  create(data) {
    return http().post(URL, data);
  }

  update(id, data) {
    return http().put(`${URL}/${id}`, data);
  }

  delete(id) {
    return http().delete(`${URL}/${id}`);
  }
}

export default new FormDataService();
