import React, { useState } from 'react';
import FillingDocCard from '../FillingDocCard/FillingDocCard';
import { doc_description } from '../../data';
import FilesAssoService from '../../oldService/filesViesAssoService';
import UserDispoService from '../../oldService/userDispoService';
import FileService from '../../oldService/fileService';
import LoadingButton from '../LoadingButton/LoadingButton';
import moveToNextStep from '../../services/logicFunction/moveToNextStep';
import BasicErrorMessage from '../ErrorMessage/basic/BasicErrorMessage';

function FillingDoc(props) {
  const [statuts, setStatuts] = useState(null);
  const [proces, setProces] = useState(null);
  const [recepisse, setRecepisse] = useState(null);
  const [budget, setBudget] = useState(null);
  const [planning, setPlanning] = useState(null);
  const [error, setError] = useState(false);
  const [haveToAccept, setHaveToAccept] = useState(false);
  const [charter, setCharter] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const fileList = [
      { file: statuts, id: null },
      { file: proces, id: null },
      { file: recepisse, id: null },
      { file: budget, id: null },
      { file: planning, id: null }
    ];
    if (charter) {
      setLoading(true);
      try {
        const promises = fileList.map(async (obj) => {
          if (obj.file !== null) {
            const formData = new FormData();
            formData.append('title', obj.file.name);
            formData.append('file', obj.file);
            const result = await FileService.fileUpdate(formData);
            obj.id = result.data.data.id;
          }
        });
        await Promise.all(promises);
        const fileAsso = await FilesAssoService.PostFilesAsso(
          fileList[0].id,
          fileList[1].id,
          fileList[2].id,
          fileList[3].id,
          fileList[4].id,
          true,
          props.user.id
        );
        await UserDispoService.PatchUserDispoValidate(
          props.userDispo.other_id,
          fileAsso.data.data.id,
          props.dispo.step.find((item) => item.step_id.name === 'Administratif').step_id.tovalidate
        );
        if (props.dispo.step.find((item) => item.step_id.name === 'Administratif').step_id.tovalidate === false) {
          await moveToNextStep(
            props.dispo.id,
            props.dispo.step.find((item) => item.step_id.name === 'Administratif').step_id.id,
            props.userDispo.other_id,
            null,
            () => {
              setLoading(false);
              props.setChangeInPage((prev) => prev + 1);
            }
          );
        } else {
          setLoading(false);
          props.setChangeInPage((prev) => prev + 1);
        }
      } catch (err) {
        console.log(err);
        setError(true);
        setLoading(false);
      }
    } else {
      setHaveToAccept(true);
    }
  };

  return (
    <div className="FillingDoc px-10 py-5">
      <h2 className="font-medium text-xl py-5 text-blue-700">Dépôt des documents officiels</h2>
      <div className="grid px-20">
        <div className="p-10">
          <div className="space-y-4">
            <div
              className={`flex justify-center items-center bg-white py-10 px-5 ring-1 ring-black ring-opacity-5 shadow ${
                haveToAccept ? 'border border-2 border-red-500' : ''
              }`}>
              <p>
                <input
                  type="checkbox"
                  onChange={() => {
                    setHaveToAccept(false);
                    setCharter(!charter);
                  }}
                  value={charter}
                />
                <span className="text-red-600 pl-2">*</span>
                <span className="text-center">
                  Le Service Vie étudiante et l’UAE, ont élaboré ensemble une Charte qui rassemble les devoirs et
                  obligations qui s’appliquent à tous les étudiants responsables d’une association ou d’un club. Cette
                  charte est disponible dans les documents du dispositif, en haut de cette page. En cochant cette case,
                  vous confirmez avoir pris connaissance et approuvé la Charte des associations et clubs étudiants de
                  l’ESDES.
                </span>
              </p>
            </div>
            <ul className="grid grid-cols-2 gap-4">
              <FillingDocCard
                title={doc_description[0].name}
                description={doc_description[0].description}
                accept={'.pdf'}
                setFile={setStatuts}
                file={statuts}
              />
              <FillingDocCard
                title={doc_description[1].name}
                description={doc_description[1].description}
                accept={'.pdf'}
                setFile={setProces}
                file={proces}
              />
              <FillingDocCard
                title={doc_description[2].name}
                description={doc_description[2].description}
                accept={'.pdf'}
                setFile={setRecepisse}
                file={recepisse}
              />
              <FillingDocCard
                title={doc_description[3].name}
                description={doc_description[3].description}
                accept={'.pdf'}
                setFile={setBudget}
                file={budget}
              />
              <FillingDocCard
                title={doc_description[4].name}
                description={doc_description[4].description}
                accept={'.pdf'}
                setFile={setPlanning}
                file={planning}
              />
            </ul>
          </div>
        </div>
        {haveToAccept && (
          <div className="flex justify-center pb-5">
            <p className="text-red-500">Vous devez accepter les conditions.</p>
          </div>
        )}
        {error && <BasicErrorMessage error={error} />}
        {!props.admin && (
          <div className="flex justify-center">
            <LoadingButton loading={loading} handleSubmit={handleSubmit} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FillingDoc;
