import { useState } from 'react';
import File from '../../components/UI/Fields/File';
import './ReportContent.css';
import LoadingButton from '../LoadingButton/LoadingButton';
import FileService from '../../oldService/fileService';
import Files_vie_asso_files_service from '../../services/files_vie_asso_files.service';
import UserDispoService from '../../oldService/userDispoService';

export default function ReportContent({ childDispo, id_file_vie_asso, userDispoId, setChangeInPage }) {
  const [arrayAllFile, setArrayAllFile] = useState([{ title: '', file: null, index: 0 }]);
  const [lastToComplete, setLastToComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const handleChangeInFile = (index, type, value) => {
    const copyarrayAllFile = arrayAllFile;
    setLastToComplete(false);
    if (type === 'title') {
      copyarrayAllFile[index].title = `Bilan-${index}_${childDispo.name}_${value}`;
      setArrayAllFile([...copyarrayAllFile]);
    }
    if (type === 'file') {
      copyarrayAllFile[index].file = value;
      setArrayAllFile([...copyarrayAllFile]);
    }
  };

  const handleAddNewFile = () => {
    const objFile = { title: '', file: null, index: arrayAllFile.length };
    if (
      (arrayAllFile.length > 0 && arrayAllFile[arrayAllFile.length - 1].file === null) ||
      arrayAllFile[arrayAllFile.length - 1]?.title === '' ||
      arrayAllFile[arrayAllFile.length - 1]?.title === `Bilan-${arrayAllFile.length - 1}_${childDispo.name}_`
    ) {
      setLastToComplete(true);
      return;
    }
    setArrayAllFile([...arrayAllFile, objFile]);
  };
  const handleDelete = (index) => {
    const copyarrayAllFile = arrayAllFile;
    const regex = new RegExp(`Bilan-\\d+_${childDispo.name}_`, 'g');
    copyarrayAllFile.splice(index, 1);
    copyarrayAllFile.map((file, index) => {
      file.index = index;
      const userTitle = file.title.replace(regex, '');
      file.title = `Bilan-${index}_${childDispo.name}_${userTitle}`;
    });
    setArrayAllFile([...copyarrayAllFile]);
  };

  const handleSubmitAllFiles = async () => {
    if (
      arrayAllFile.length === 0 ||
      (arrayAllFile.length === 1 &&
        (arrayAllFile[0].file === null ||
          arrayAllFile[0]?.title === '' ||
          arrayAllFile[0]?.title === `Bilan-${0}_${childDispo.name}_`))
    ) {
      setErrorNumber(true);
    } else {
      setErrorNumber(false);
      setLoading(true);
      const promises = arrayAllFile.map(async (objFile) => {
        const formData = new FormData();
        formData.append('title', objFile.title);
        formData.append('file', objFile.file);
        const fileSubmit = await FileService.fileUpdate(formData);
        await Files_vie_asso_files_service.postNewDocument(id_file_vie_asso, fileSubmit.data.data.id);
      });

      await Promise.all(promises);
      await UserDispoService.PatchUserDispoValidation(userDispoId);
      setLoading(false);
      // window.location.reload();
      setChangeInPage((prev) => prev + 1);
    }
  };
  return (
    <div className="ReportContent space-y-5 px-10 py-5">
      <h2 className="font-medium text-xl py-5 text-blue-700">Dépôt du rapport</h2>
      <div className="space-y-5 text-center">
        <div className="grid grid-cols-2 gap-5">
          {arrayAllFile.map((file) => (
            <File
              file={file}
              handleChangeInFile={handleChangeInFile}
              key={file.index}
              totalLength={arrayAllFile.length}
              lastToComplete={lastToComplete}
              handleDelete={handleDelete}
              childDispoName={childDispo.name}
            />
          ))}
          <div className="flex justify-center items-center">
            <button
              type="button"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-6 py-3.5 text-center"
              onClick={handleAddNewFile}>
              Ajouter un nouveau fichier
            </button>
          </div>
        </div>
        {errorNumber && (
          <div className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
            <svg
              aria-hidden="true"
              className="flex-shrink-0 inline w-5 h-5 mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Danger</span>
            <div>
              <span className="font-medium">
                Il faut au moins un fichier pour soumettre l'étape, vérifier d'avoir ajouter au moins un fichier ainsi
                qu'un titre.
              </span>
            </div>
          </div>
        )}
        <LoadingButton
          btnText="Soumettre tous les fichiers"
          btnColor="bg-blue-600"
          btnTextColor="text-white"
          loading={loading}
          handleSubmit={handleSubmitAllFiles}
        />
      </div>
    </div>
  );
}
