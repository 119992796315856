import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'users';

const getOneUser = () => {
  return axios.get(
    API_URL +
      '/me?fields=avatar,id,first_name,last_name,phones,email,email2,group.group_id.name,role.name,group.group_id.parentgroup,group.group_id.parentgroup.name',
    { headers: authHeader() }
  );
};

const GetOneUserWithId = (id) => {
  return axios.get(
    API_URL +
      '?filter[id][_eq]=' +
      id +
      '&fields=avatar,id,first_name,last_name,phones,email,email2,group.group_id.name,role.name,group.group_id.parentgroup,group.group_id.parentgroup.name',
    { headers: authHeader() }
  );
};

const getAllUser = () => {
  return axios.get(
    API_URL +
      '?fields=avatar,id,first_name,last_name,phone,email,email2,group.group_id.name,group.group_id.id,group.group_id.parentgroup,group.group_id.parentgroup.name,role.name,last_access&limit=-1',
    { headers: authHeader() }
  );
};

const FormatUserList = (users, setUsers, admin = false) => {
  useEffect(() => {
    const user_list = [];

    const getUserList = async () => {
      const users = await (await getAllUser()).data.data;
      const addToArray = (array, item) => {
        array.push({
          search:
            item.last_name +
            ' ' +
            item.first_name +
            ' ' +
            item.group.slice(-1)[0]?.group_id.name +
            ' ' +
            item.email +
            ' ' +
            item.phone,
          id: item.id,
          name: item.last_name + ' ' + item.first_name,
          team: item.group.slice(-1),
          full_group: item.group.slice(-1)[0]?.group_id.name,
          email: item.email,
          email2: item.email2,
          lastAccess: new Date(item.last_access).toUTCString(),
          first_name: item.first_name,
          last_name: item.last_name,
          phone: item.phone
        });
      };
      users.map((item) => {
        if (admin === true) {
          if (item.role && item.role.name !== 'Contact') {
            addToArray(user_list, item);
            return item;
          }
        } else {
          if (item.role && item.role.name !== 'Contact' && item.role.name !== 'Administrator') {
            addToArray(user_list, item);
            return item;
          }
        }
      });
      setUsers(user_list);
    };
    getUserList();
  }, []);
  return users;
};

const FormatGetOneUserWithId = (user, setUser, id) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetOneUserWithId(id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setUser(list);
    };
    Makelist();
  }, []);
  return user;
};

const PatchUser = (id, data) => {
  return axios.patch(API_URL + '/' + id, data, { headers: authHeader() });
};

const PatchUserPassword = (id, password) => {
  return axios.patch(API_URL + '/' + id, { password: password }, { headers: authHeader() });
};

const getUser = (setUser, setIsLogged) => {
  axios
    .get(
      API_URL +
        '/me?fields=avatar,id,first_name,last_name,phones,email,email2,group.group_id.name,role.name,group.group_id.parentgroup,group.group_id.parentgroup.name,function',
      { headers: authHeader() }
    )
    .then((result) => {
      setUser(result.data.data);
      setIsLogged(true);
    })
    .catch(() => {
      setIsLogged(false);
    });
};

const getAllMailWithMore = (begin, end) => {
  const formatMail = begin + '+%@' + end;
  const encodeMail = encodeURIComponent(formatMail);
  return axios.get(API_URL + '?fields=email&filter={"_and":[{"email":{"_contains":"' + encodeMail + '"}}]}', {
    headers: authHeader()
  });
};
// , {"email":{"_contains":"@akanema.fr"}}
const UserService = {
  getOneUser,
  getAllUser,
  getUser,
  GetOneUserWithId,
  FormatUserList,
  PatchUser,
  PatchUserPassword,
  FormatGetOneUserWithId,
  getAllMailWithMore
};

export default UserService;
