import React, { useContext, useState, useEffect } from 'react';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import AssoContent from '../../../oldComponents/AssoContent/AssoContent';
import DispoService from '../../../oldService/dispService';
import UserDispoService from '../../../oldService/userDispoService';
import EventService from '../../../oldService/eventService';
import PendingService from '../../../oldService/pendingService';
import ModalModifPlan from '../../../oldComponents/ModalModifPlan/ModalModifPlan';
import TablePending from '../../../oldComponents/TablePending/TablePending';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import PipelineMultiple from '../../../oldComponents/PipelineMultiple/PipelineMultiple';
import PipelinePerso from '../../../oldComponents/PipelinePerso/PipelinePerso';
import DispDirFileService from '../../../oldService/dispDirFile';
import ModalAddDoc from '../../../oldComponents/ModalAddDoc/ModalAddDoc';
import EvalContent from '../../../oldComponents/EvalContent/EvalContent';
import { AuthContext } from '../../../AuthProvider';
import ModalExportEvaluation from '../../../components/ModalExportEvaluation';
import NewListOfParticipant from '../../../components/NewListOfParticipant';
import NewHeaderPresDispo from '../../../components/NewHeaderPresDispo';
import ModalManageDocument from '../../../components/ModalManageDocument';

import './UserAsso.css';
import Card from '../../../oldComponents/Card/Card';
import NewListOfEvent from '../../../components/NewListOfEvent';
import Modal from '../../../oldComponents/Modal/Modal';

const UserAsso = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { user } = useContext(AuthContext);
  const id = queryParams.get('id');

  const [blur, setBlur] = useState(false);
  const [displayModif, setDisplayModif] = useState(false);
  const [displayAddDoc, setDisplayAddDoc] = useState(false);
  const [userList, setUserList] = useState([]);
  const [dispo, setDispo] = useState();
  const [events, setEvents] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [admin, setAdmin] = useState();
  const [inscription, setInscription] = useState();
  const [pending, setPending] = useState();
  const [fileList, setFileList] = useState([]);
  const [dispoChildren, setDispoChildren] = useState([]);
  const [reload, setReload] = useState(0);
  const [firstStep, setFirstStep] = useState(null);
  const [userAlreadyInIt, setUserAlreadyInIt] = useState([]);
  const [userPresident, setUserPresident] = useState({});
  const [displayModalExport, setDisplayModalExport] = useState(false);
  const [changeInPage, setChangeInPage] = useState(0);
  const [displayModalCreateEvent, setDisplayModalCreateEvent] = useState(false);
  const [displayModalAllDocument, setDisplayModalAllDocument] = useState(false);

  const fetchStatusInThisDispo = async () => {
    const inscrip = await UserDispoService.getUserDispoDispo(id, user.id);
    if (inscrip.data.data.length !== 0) {
      setAdmin(false);
      setPending(false);
      setInscription(true);
      return;
    }
    const pendi = await PendingService.GetUserPending(id, user.id);
    if (pendi.data.data.length !== 0) {
      setPending(true);
      setAdmin(false);
      setInscription(false);
      return;
    }
    const adm = await DispDirUsersService.getDirectusUserDispo(id, user.id);
    if (adm.data.data.length !== 0) {
      setAdmin(true);
      setPending(false);
      setInscription(false);
      return;
    }
    setPending(false);
    setAdmin(false);
    setInscription(false);
  };
  const fetchAllParticipant = async (resolve, reject) => {
    try {
      const list = [];
      const participant = await UserDispoService.getAllUser(id);
      participant.data.data.map((item) => {
        list.push({
          id: item.user.id,
          name: item.user.first_name + ' ' + item.user.last_name,
          team: item.user.group.slice(-1)[0],
          email: item.user.email,
          phones: item.user.phones,
          other_id: item.id,
          step: item.step_id,
          validation: item.validation,
          files_asso_id: item.files_asso_id,
          punctual_files_id: item.punctual_files_id?.id,
          evaluation_id: item.evaluation_id,
          evaluation_id_2: item.evaluation_id_2,
          evaluation_finish: item.punctual_files_id?.evaluation_doc,
          finish: item.finish,
          last_name: item.user.last_name,
          date_created: item.date_created
        });
        return item;
      });
      setUserList(list);
      resolve();
    } catch (err) {
      reject();
      console.log(err);
    }
  };

  const fetchActualDispo = async () => {
    const dis = await DispoService.getOneDispo(id);
    setDispo(dis.data.data[0]);
  };

  const fetchEventOfDispo = async (resolve, reject) => {
    try {
      const evts = await EventService.getAllEventTemplate(id);
      setEvents(evts.data.data);
      resolve();
    } catch (err) {
      console.log(err);
      reject();
    }
  };

  const fetchPendingList = async (resolve, reject) => {
    try {
      const pdng = await PendingService.GetAllPending(id);
      setPendingList(pdng.data.data);
      resolve();
    } catch (err) {
      console.log(err);
      reject();
    }
  };

  const fetchDispoChild = async (resolve, reject) => {
    try {
      const dchld = await DispoService.GetAllDispoWithParent(id);
      setDispoChildren(dchld.data.data);
      resolve();
    } catch (err) {
      console.log(err);
      reject();
    }
  };

  useEffect(() => {
    if (admin === true) {
      DispDirFileService.FormatGetOneDispFileAdmin(setFileList, id);
    } else if (admin === false) {
      DispDirFileService.FormatGetOneDispFileNew(fileList, setFileList, id, user.id);
    }
  }, [reload, admin]);

  useEffect(() => {
    if (user.role.name === 'Administrator') {
      setAdmin(true);
      setInscription(false);
      setPending(false);
    } else {
      fetchStatusInThisDispo();
    }
  }, [changeInPage]);

  useEffect(() => {
    const promises = [];
    if (inscription === true) {
      console.log('inscription');
      const promise = new Promise((resolve, reject) => {
        fetchAllParticipant(resolve, reject);
      });
      const promise1 = new Promise((resolve, reject) => {
        fetchEventOfDispo(resolve, reject);
      });
      const promise2 = new Promise((resolve, reject) => {
        fetchDispoChild(resolve, reject);
      });
      promises.push(promise, promise1, promise2);
    } else if (admin === true) {
      console.log('admin');
      const promise = new Promise((resolve, reject) => {
        fetchAllParticipant(resolve, reject);
      });
      const promise1 = new Promise((resolve, reject) => {
        fetchEventOfDispo(resolve, reject);
      });
      const promise2 = new Promise((resolve, reject) => {
        fetchDispoChild(resolve, reject);
      });
      const promise4 = new Promise((resolve, reject) => {
        fetchPendingList(resolve, reject);
      });
      promises.push(promise, promise1, promise2, promise4);
    } else if (pending === true) {
      console.log('pending');
      fetchActualDispo();
      return;
    }
    if (admin === true || inscription === true) {
      Promise.all(promises).then(() => {
        fetchActualDispo();
      });
    }
    if (pending === false && admin === false && inscription === false) {
      fetchActualDispo();
    }
  }, [admin, inscription, pending, changeInPage]);

  const handleDisplayModif = () => {
    setDisplayModif(true);
    setBlur(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };

  useEffect(() => {
    if (dispo != undefined) {
      if (dispo.parent === null) {
        const list = [];
        dispo.step.map((item) => {
          list.push(item.order);
          return item;
        });
        const min = Math.min(...list);
        const firstStep = dispo.step.find((item) => item.order === min);
        setFirstStep(firstStep);
      } else {
        setFirstStep({ step_id: null });
      }
    }
  }, [dispo]);

  useEffect(() => {
    if (dispo?.responsibles) {
      const allInvolve = [];
      allInvolve.push(...userList, ...dispo.responsibles.map((resp) => resp.directus_users_id));
      setUserAlreadyInIt(allInvolve);
    }
    const president = dispo?.responsibles.find((user) => user.role === 'president');
    if (president) {
      setUserPresident(president.directus_users_id);
    }
  }, [userList, dispo]);

  const handleCloseModalOnEscape = () => {
    setBlur(false);
    setDisplayModif(false);
    setDisplayAddDoc(false);
    setDisplayModalExport(false);
    setDisplayModalAllDocument(false);
    setDisplayModalCreateEvent(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleCloseModalOnEscape();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  const makeResponsibleListForEvaluation = () => {
    const president = dispo.responsibles.find((user) => user.role === 'president');
    const list = dispo.responsibles.filter((user) => user !== president);
    return list;
  };
  const handleDisplayModalCreateEvent = () => {
    setBlur(true);
    setDisplayModalCreateEvent(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleDisplayModalManageDoc = () => {
    setBlur(true);
    setDisplayModalAllDocument(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  const handleDisplayModalExport = () => {
    setBlur(true);
    setDisplayModalExport(true);
    document.body.classList.add('ModalOpenNoScrollBody');
  };
  return (
    <div className="Asso flex max-w-[100vw] min-h-screen">
      {dispo != undefined && (
        <>
          <NavbarLeft user={user} sub={1} blur={blur} />
          <div className="flex flex-col h-full w-full page-Container">
            <NavbarTop user={user} blur={blur} urls={['home', 'plans']} />
            <div className="p-10 space-y-8">
              <div className="flex justify-between"></div>
              <div className="space-y-14 py-4 px-5">
                <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                  <NewHeaderPresDispo
                    dispo={dispo}
                    handleDisplayModalCreateEvent={handleDisplayModalCreateEvent}
                    handleDisplayModif={handleDisplayModif}
                    excludedUsers={userAlreadyInIt}
                    dispoId={id}
                    firstStep={firstStep}
                    handleDisplayModalManageDoc={handleDisplayModalManageDoc}
                    docList={fileList}
                    handleDisplayModalExport={handleDisplayModalExport}
                    admin={admin}
                    inscription={inscription}
                    pending={pending}
                    setChangeInPage={setChangeInPage}
                    dispoType={'asso'}
                    withIndividualStep={dispo.parent === null}
                    userDispo={userList.find((item) => item.id === user.id)}
                    dispoChildren={dispoChildren}
                  />
                </div>
                {(inscription || admin) && (
                  <div
                    className={`p-5 grid grid-cols-2 bg-gray-50 rounded ${
                      blur === true ? 'blur-sm' : 'blur-none'
                    } gap-x-8`}>
                    <NewListOfParticipant
                      listOfParticipant={userList}
                      admin={admin}
                      excludedUsers={userAlreadyInIt}
                      dispoId={id}
                      firstStep={firstStep}
                    />
                    <NewListOfEvent
                      listOfEvent={events}
                      admin={admin}
                      setDisplayModalCreate={setDisplayModalCreateEvent}
                    />
                  </div>
                )}
                {inscription && !admin && dispo.step.length !== 0 && (
                  <div className=" p-5 rounded">
                    <PipelinePerso
                      steps={dispo.step}
                      user={userList.find((item) => item.id === user.id)}
                      userDispo={userList.find((item) => item.id === user.id)}
                    />
                  </div>
                )}
                {displayModalAllDocument && (
                  <ModalManageDocument
                    docList={fileList}
                    setDisplayModalAllDocument={setDisplayModalAllDocument}
                    setBlur={setBlur}
                    setReload={setReload}
                    setDisplayAddDoc={setDisplayAddDoc}
                    admin={admin}
                  />
                )}
                {admin && dispo && (
                  <div className="space-y-5">
                    {dispo.step.length !== 0 && (
                      <div className={`p-5 rounded`}>
                        <div className="flex justify-end items-center gap-10"></div>
                        <PipelineMultiple
                          steps={dispo.step}
                          users={userList}
                          setBlur={setBlur}
                          blur={blur}
                          dispo={dispo}
                          setChangeInPage={setChangeInPage}
                        />
                      </div>
                    )}
                    {pendingList.length !== 0 && (
                      <div className={`p-5 bg-gray-50 rounded ${blur === true ? 'blur-sm' : 'blur-none'}`}>
                        <h2 className="font-bold text-xl pb-5 text-blue-700/90">Demandes en attente</h2>
                        <TablePending
                          data={pendingList}
                          setPendingList={setPendingList}
                          dispo={dispo}
                          setUserList={setUserList}
                          userList={userList}
                          stepList={dispo.step}
                          dispotype={'asso'}
                          setChangeInPage={setChangeInPage}
                        />
                      </div>
                    )}
                    {admin &&
                      dispo.evaluation_step &&
                      (dispo.evaluation_step === '1' || dispo.evaluation_step === '2') && (
                        <EvalContent
                          listBenevole={userList}
                          dispoId={id}
                          asso={true}
                          step={dispo.evaluation_step}
                          listResponsible={makeResponsibleListForEvaluation()}
                          president={user.id === userPresident.id}
                          file_vie_asso={dispo.files_vie_asso_id}
                          setChangeInPage={setChangeInPage}
                          blur={blur}
                        />
                      )}
                    {dispo.parent !== null && user.id === userPresident.id && (
                      <div className="bg-gray-50 p-5 rounded">
                        <h2 className="font-bold text-xl pb-5 text-blue-700/90">Dispositif parent</h2>
                        <div className="grid grid-cols-5">
                          <Card data={dispo.parent} template={dispo.parent.template} assoToAsso={true} />
                        </div>
                      </div>
                    )}
                    <div className="p-5">
                      <div className="space-y-5">
                        {displayModif && (
                          <ModalModifPlan
                            id={id}
                            setDisplay={setDisplayModif}
                            setBlur={setBlur}
                            data={dispo}
                            excludestudent={userAlreadyInIt}
                            dispoType={dispo.parent !== null ? 'asso' : ''}
                            admin={user.role.name === 'Administrator'}
                          />
                        )}
                        {displayAddDoc && (
                          <ModalAddDoc
                            userList={userList}
                            setBlur={setBlur}
                            setDisplayAddDoc={setDisplayAddDoc}
                            dispoId={id}
                            setReload={setReload}
                          />
                        )}
                        {displayModalCreateEvent && <Modal setBlur={setBlur} setDisplay={setDisplayModalCreateEvent} />}
                        {displayModalExport && (
                          <ModalExportEvaluation
                            dispoChildren={dispoChildren}
                            dispoName={dispo.name}
                            setDisplayModal={setDisplayModalExport}
                            setBlur={setBlur}
                            DispoType={dispo.parent === null ? 'DispoParent' : 'Asso'}
                            Members={userList}
                            Responsibles={dispo.responsibles}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {(admin || inscription) && (
                  <div className={`${blur ? 'blur-sm' : 'blur-none'}`}>
                    <AssoContent
                      dispoChildren={dispoChildren}
                      user={user}
                      admin={admin}
                      dispo={dispo}
                      userDispo={admin ? userList : userList.find((item) => item.id === user.id)}
                      events={events}
                      setChangeInPage={setChangeInPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserAsso;
