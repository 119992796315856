import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../AuthProvider';
import { Navigate, Outlet } from 'react-router-dom';
import UserService from '../../oldService/userService';

const AuthLayout = () => {
  const { isLogged, setUser, setIsLogged } = useContext(AuthContext);
  useEffect(() => {
    UserService.getUser(setUser, setIsLogged);
  }, [isLogged]);

  if (isLogged === undefined) {
    return <h1>LOADING...</h1>;
  }
  if (isLogged) {
    return <Outlet />;
  }
  if (!isLogged) {
    return <Navigate to={'/'} replace />;
  }
};

export default AuthLayout;
