import React, { useState } from 'react';

import './PptSoutenance.css';
import FillingDocCard from '../FillingDocCard/FillingDocCard';
import LoadingButton from '../LoadingButton/LoadingButton';
import { useContext } from 'react';
import { AuthContext } from '../../AuthProvider';
import FileService from '../../oldService/fileService';
import FilesAssoService from '../../oldService/filesViesAssoService';
import UserDispoService from '../../oldService/userDispoService';
import moveToNextStep from '../../services/logicFunction/moveToNextStep';
import BasicErrorMessage from '../ErrorMessage/basic/BasicErrorMessage';

function PptSoutenance({
  soutenance_number,
  childDispo,
  idFilesVieAsso,
  actualStep,
  userDispoId,
  parentDispoId,
  setChangeInPage
}) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const { user } = useContext(AuthContext);
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    const schoolYear =
      user.group.slice(-1)[0]?.group_id.parentgroup === null
        ? user.group.slice(-1)[0]?.group_id.name
        : user.group.slice(-1)[0]?.group_id.parentgroup.name;
    const fileName = `${childDispo.name}_${schoolYear}_${user.first_name}_${user.last_name}_Soutenance_${soutenance_number}`;
    formData.append('title', fileName);
    formData.append('file', file);
    try {
      const fileSubmit = await FileService.fileUpdate(formData);
      await FilesAssoService.patchFilesAssoSoutenance(idFilesVieAsso, fileSubmit.data.data.id, soutenance_number);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
    handleStepChange();
  };
  const handleStepChange = async () => {
    if (actualStep.tovalidate) {
      await UserDispoService.PatchUserDispoValidation(userDispoId);
      setLoading(false);
      // window.location.reload();
      setChangeInPage((prev) => prev + 1);
    } else if (!actualStep.toValidate) {
      await moveToNextStep(parentDispoId, actualStep.id, userDispoId, childDispo.id, () => {
        setLoading(false);
        // window.location.reload();
        setChangeInPage((prev) => prev + 1);
      });
    }
  };
  return (
    <div className="PptSoutenance space-y-5 px-10 py-5">
      <h2 className="font-medium text-xl py-5 text-blue-700">Dépôt de la soutenance {soutenance_number}</h2>
      <div className="flex flex-col items-center justify-center gap-5">
        <div className="w-1/2">
          <FillingDocCard title="PowerPoint Soutenance" accept=".pdf" setFile={setFile} file={file} />
        </div>
        <BasicErrorMessage error={error} />
        <LoadingButton loading={loading} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}

export default PptSoutenance;
