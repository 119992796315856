import React from 'react';
import TagList from '../TagList/TagList';
import DocList from '../DocList/DocList';
import DocListModif from '../DocListModif/DocListModif';
import square from '../../assets/images/black-square.jpg';
import PendingService from '../../oldService/pendingService';
import UserDispoService from '../../oldService/userDispoService';
import { API } from '../../oldService/authHeader';

import './ContentElem.css';

export default function ContentElem(props) {
  const image = props.image !== null ? API + 'assets/' + props.image : square;

  const onHandleChangeOne = () => {
    PendingService.PostPending(props.data.id, props.user.id).then(() => {
      if (props.dispotype === 'asso') {
        props.setChangeInPage((prev) => prev + 1);
      } else {
        window.location.reload();
      }
    });
  };

  const onHandleChangeOneEvent = () => {
    UserDispoService.PostUserDispo(props.data.id, props.user.id).then(() => {
      props.setInscription(2);
    });
  };

  const onHandleChangeRemoveEvent = () => {
    UserDispoService.deleteOneUserDispo(props.id).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="ContentElem">
      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          {props.event ? (
            <h2 className="font-bold text-xl pb-5 text-blue-700/90">Description de l'évènement</h2>
          ) : (
            <h2 className="font-bold text-xl pb-5 text-blue-700/90">Description du dispositif d'engagement</h2>
          )}
          <div className="flex px-10">
            <img className="img-content" src={image} alt="display element" />
            <p className="flex items-center pl-5 whitespace-pre-wrap">{props.data && props.data.description}</p>
          </div>
        </div>
        {props.admin && props.event !== true && (
          <div>
            <DocListModif
              data={props.docList}
              setBlur={props.setBlur}
              setDisplayAddDoc={props.setDisplayAddDoc}
              setReload={props.setReload}
            />
          </div>
        )}
        {props.event !== true && (
          <div className="flex flex-col justify-center items-center">
            {!props.inscription && !props.admin && !props.pending && (
              <div className="w-full flex justify-center items-center">
                <button
                  className="w-1/3 bg-gray-200 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                  onClick={onHandleChangeOne}>
                  Participer
                </button>
              </div>
            )}
            {(props.inscription === 1 || props.pending) && (
              <div className="w-full flex justify-center items-center">
                <button className="w-1/3 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 bg-blue-500 text-white">
                  En Attente de validation
                </button>
              </div>
            )}
            {props.inscription && props.doc === true && !props.admin && (
              <div className="flex justify-center items-center w-8/12">
                <DocList data={props.docList} filesPunSorted={props.filesPunSorted} />
              </div>
            )}
          </div>
        )}
        {props.event === true && (
          <div className="flex flex-col justify-center items-center">
            {!props.inscription && !props.event && (
              <div className="w-full flex justify-center items-center">
                <button
                  className="w-1/3 bg-gray-200 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                  onClick={onHandleChangeOneEvent}>
                  Participer
                </button>
              </div>
            )}
            {!props.inscription && props.event && (
              <div className="flex flex-col justify-center items-center space-y-4">
                <div className="flex space-x-14">
                  <p>
                    Date de début:{' '}
                    <span className="font-semibold">{new Date(props.data.startdate).toLocaleDateString()}</span>
                  </p>
                  <p>
                    Heure de début: <span className="font-semibold">{props.data.start_hourly}</span>
                  </p>
                </div>
                <div className="flex space-x-14">
                  <p>
                    Date de fin :{' '}
                    <span className="font-semibold">{new Date(props.data.enddate).toLocaleDateString()}</span>
                  </p>
                  <p>
                    Heure de fin: <span className="font-semibold">{props.data.end_hourly}</span>
                  </p>
                </div>
                <p>
                  Lieu: <span className="font-semibold">{props.data.location}</span>
                </p>
                <div className="w-full flex justify-center items-center pt-10">
                  <button
                    className="w-1/3 bg-gray-200 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                    onClick={onHandleChangeOneEvent}>
                    Participer
                  </button>
                </div>
              </div>
            )}
            {props.inscription === 1 && (
              <div className="w-full flex justify-center items-center">
                <button className="w-1/3 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 bg-blue-500 text-white">
                  En Attente de validation
                </button>
              </div>
            )}
            {props.inscription === 2 && props.doc === true && (
              <div className="flex justify-center items-center">
                <DocList data={props.docList} />
              </div>
            )}
            {(props.inscription === 2 || props.inscription) && props.event && (
              <div className="flex flex-col justify-center items-center space-y-4">
                <div className="flex space-x-14">
                  <p>
                    Date de début: <span className="font-semibold">{props.data.startdate}</span>
                  </p>
                  <p>
                    Heure de début: <span className="font-semibold">{props.data.start_hourly}</span>
                  </p>
                </div>
                <div className="flex space-x-14">
                  <p>
                    Date de fin : <span className="font-semibold">{props.data.enddate}</span>
                  </p>
                  <p>
                    Heure de fin: <span className="font-semibold">{props.data.end_hourly}</span>
                  </p>
                </div>
                <p>
                  Lieu: <span className="font-semibold">{props.data.location}</span>
                </p>
                <div className="w-full flex justify-center items-center pt-10">
                  <button
                    className="bg-gray-200 px-2 py-3 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-red-500 hover:text-white"
                    onClick={onHandleChangeRemoveEvent}>
                    Désinscription
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <TagList responsibleList={props.list} tagList={props.tag} />
    </div>
  );
}
