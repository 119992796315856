import React, { useState } from 'react';
import Select from 'react-select';
import UserService from '../../oldService/userService';

function StudentSelect({ excludedUsers = [], onSelect }) {
  const [studList, setStudList] = useState(null);
  UserService.FormatUserList(studList, setStudList);

  const [selectedOption, setSelectedOption] = useState(null);

  const options =
    studList &&
    studList
      .filter((s) => !excludedUsers || !excludedUsers.map((u) => u.id).includes(s.id))
      .map((item) => {
        return {
          value: item.id,
          label: `${item.name} / ${item.full_group} (${item.email})`
        };
      });

  return (
    <div className="MemberSelect">
      <Select
        id="select-member"
        isLoading={studList === null}
        placeholder={'Choisir nom...'}
        defaultValue={selectedOption}
        options={options}
        value={studList && options.find((item) => item.value === selectedOption)}
        onChange={(e) => {
          setSelectedOption(e.value);
          if (onSelect instanceof Function && e.value) {
            onSelect(e.value);
          }
        }}
      />
    </div>
  );
}

export default StudentSelect;
