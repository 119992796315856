import React from 'react';
import { Form } from '../../slices/form';
import { useForm } from 'react-hook-form';
import Textarea from '../UI/Fields/Textarea';
import RadioGroup from '../UI/Fields/RadioGroup';
import Input from '../UI/Fields/Input';

type Props = React.PropsWithChildren<{
  config: Form;
  onSubmit: (data: Array<any>) => void;
}>;

export default function GenericForm({ config, onSubmit }: Props) {
  const {
    register,
    unregister,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const isFieldVisible = (question) => {
    return question.questions_parents.every((p) => {
      return question.questions_choice_parents
        .map((c) => c.questions_choice_id.name)
        .includes(watch(p.related_questions_id));
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
            {config &&
              config.questions_id.map((item, index) => {
                if (!isFieldVisible(item.questions_id)) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    {true && (
                      <div className="space-y-2">
                        <h3 className="text-lg">
                          {item.questions_id.name}{' '}
                          {item.questions_id.required && <span className="text-red-500">*</span>}
                        </h3>
                        {item.questions_id.type === 'text' && (
                          <Textarea
                            register={register}
                            unregister={unregister}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'choice' && (
                          <RadioGroup
                            items={item.questions_id.questions_choice_id.map((itemQues) => {
                              return itemQues.questions_choice_id.name;
                            })}
                            register={register}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {['url', 'description'].includes(item.questions_id.type) && (
                          <Input
                            type={item.questions_id.type}
                            register={register}
                            unregister={unregister}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="flex justify-center pt-10">
            <button
              className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
              type="submit">
              <p>Soumettre</p>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
