import React, { useEffect } from 'react';
import { useState } from 'react';
import FormService from '../../../oldService/formService';
import { useForm } from 'react-hook-form';
import { ContactServices } from '../../../oldService/contactServices';

const ContactForm = ({ contact, onContactAddedOrUpdated }) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm();

  const [form, setForm] = useState([]);
  FormService.FormatGetForm(form, setForm, 'Contact');

  useEffect(() => {
    if (contact && form[0]) {
      reset({
        [form[0].questions_id[0].questions_id.id]: contact.first_name,
        [form[0].questions_id[1].questions_id.id]: contact.last_name,
        [form[0].questions_id[2].questions_id.id]: contact.function,
        [form[0].questions_id[3].questions_id.id]: contact.email,
        [form[0].questions_id[4].questions_id.id]: contact.phones
      });
    }
  }, [contact, form]);

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  const formatData = (data) => {
    const formattedData = [];
    if (form[0]) {
      form[0].questions_id.map((item, index) => {
        if (data[item.questions_id.id]) {
          formattedData.push({
            id: item.questions_id.id,
            index: index,
            data: data[item.questions_id.id]
          });
        }
      });
    }
    return formattedData;
  };

  const onSubmit = async (data) => {
    try {
      const formattedData = formatData(data);

      const response = await ContactServices.PatchContact(contact.id, {
        first_name: formattedData.find((item) => item.index === 0).data,
        last_name: formattedData.find((item) => item.index === 1).data,
        function: formattedData.find((item) => item.index === 2).data,
        email: formattedData.find((item) => item.index === 3).data,
        phones: formattedData.find((item) => item.index === 4).data
      });

      if (onContactAddedOrUpdated instanceof Function) {
        onContactAddedOrUpdated(response.data.data);
      }
    } catch (error) {
      if (error.response.data.errors[0].message === 'Field "email" has to be unique.') {
        setError(form[0].questions_id[3].questions_id.id, {
          type: 'email_unique',
          message: 'Un utilisateur avec cette adresse email existe déjà.'
        });
      }
    }
  };

  return (
    <div className="space-y-10">
      <div>
        <h2 className="font-bold text-xl text-blue-700/90">Saisir un nouveau contact</h2>
        <p className="text-gray-700">Ce formulaire vous sert à compléter les informations concernant le contact.</p>
      </div>
      <div>
        <form id="ContactForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-5">
            {form[0] &&
              form[0].questions_id.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="space-y-2">
                      <h3 className="text-lg">
                        {item.questions_id.name} <Obligatory />
                      </h3>
                      {item.questions_id.type === 'text' && (
                        <textarea
                          className="w-full p-2 h-28 border border-gray-300"
                          placeholder="À compléter"
                          {...register(item.questions_id.id, {
                            required: item.questions_id.required
                          })}
                        />
                      )}
                      {item.questions_id.type === 'description' && (
                        <div>
                          <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                          <input
                            type={item.questions_id.name === 'Email du contact' ? 'email' : 'text'}
                            className="w-full p-2 border border-gray-300"
                            placeholder="À compléter"
                            {...register(item.questions_id.id, {
                              required: item.questions_id.required
                            })}
                          />
                        </div>
                      )}
                      {errors[item.questions_id.id]?.type === 'required' && (
                        <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                      )}
                      {errors[item.questions_id.id] && (
                        <div className="mb-3 text-normal text-red-500">{errors[item.questions_id.id].message}</div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
