import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormService from '../../oldService/formService';
import RadioGroup from '../Fields/RadioGroup';
import AnswerFormService from '../../oldService/answerFormService';
import PdfServices, { PdfConvertData } from '../../oldService/pdfServices';
import DocumentsServices from '../../oldService/documentServices';
import { PunctualFilesServices } from '../../oldService/punctualFilesService';
import UserDispoService from '../../oldService/userDispoService';
import { findNextStep } from '../../oldService/steps/findNextStep';
import DispoStepService from '../../oldService/dispoStepService';
import { AuthContext } from '../../AuthProvider';
import './CommiProjectForm.css';
import Textarea from '../Fields/Textarea';
import Input from '../Fields/Input';
import { useContext } from 'react';

function CommiProjectForm({ dispo, userDispo }) {
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  const templateId = '1yOuq4D6TXqRjy2KIJBH_ZupQizUTqKSe_ljz7HNJsIA';

  const { user } = useContext(AuthContext);
  let groups = user.group.slice(-1)[0]?.group_id?.name;

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState([]);
  FormService.FormatGetForm(form, setForm, "Nouveau Projet d'Engagement");

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  let pdfName = dispo.name + '_' + groups + '-' + user.last_name + '-' + user.first_name + "Projet d'engagement";

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  const isFieldVisible = (values) => {
    return values.every((v) => Object.values(watch()).includes(v));
  };

  const formatData = (data) => {
    const formattedData = [];
    if (form[0]) {
      form[0].questions_id.map((item, index) => {
        if (data[item.questions_id.id]) {
          formattedData.push({
            id: item.questions_id.id,
            index: index,
            data: data[item.questions_id.id]
          });
        }
      });
    }
    return formattedData;
  };

  const onSubmit = async (data) => {
    const formattedData = formatData(data);

    setLoading(true);
    try {
      const resAns = await AnswerFormService.PostAnswerForm(formattedData, user.id, null, 'project');
      const resDoc = await DocumentsServices.PostDocument(PdfConvertData(formattedData, user, groups), 'project');
      await PdfServices.PostPdf(resDoc.data.data.id, templateId, pdfName);
      const resPun = await PunctualFilesServices.PostPunctualFiles(user.id, resAns.data.data.id, resDoc.data.data.id);
      await UserDispoService.PatchUserDispoPunctualFiles(userDispo.id, resPun.data.data.id);
      if (userDispo.step_id.tovalidate) {
        await UserDispoService.PatchUserDispoValidate(userDispo.id, null, true);
        window.location.reload();
      } else {
        await UserDispoService.PatchUserDispoStep(
          userDispo.id,
          findNextStep(dispoStep.find((item) => item.step_id === userDispo.step_id.id).order, dispoStep).step_id
        );
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="px-10 space-y-10">
      <div className="p-8 rounded">
        <div className="space-y-2">
          <h2 className="font-bold text-xl text-blue-700/90">Remplir mon projet d'engagement - Ma mission</h2>
          <div className="text-gray-700 space-y-4">
            <p>Vous allez pouvoir remplir votre projet d'engagement. Celui-ci vous permet de préparer votre mission.</p>
            <div>
              <p>Il sera évalué selon les critères suivants:</p>
              <p>- Forme du projet écrit: Clarté de la rédaction (2pts), Orthographe, grammaire et syntaxe (2pts)</p>
              <p>
                - Fond du projet écrit : Niveau de remplissage de la fiche (3 pts), Niveau d’identification des points à
                travailler pour mieux préparer sa mission (2,5pts), Qualité de l’auto-évaluation (2,5pts)
              </p>
              <p>
                - Pitch : dynamisme de la présentation (3pts), niveau d'information apporté (2pts), qualité de
                l'argumentation (3 pts)
              </p>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
            {form[0] &&
              form[0].questions_id.map((item, index) => {
                if (
                  item.questions_id.questions_choice_parents &&
                  !isFieldVisible(item.questions_id.questions_choice_parents.map((v) => v.questions_choice_id.name))
                ) {
                  return <></>;
                }
                return (
                  <div key={index}>
                    {true && (
                      <div className="space-y-2">
                        <h3 className="text-lg">
                          {item.questions_id.name} {item.questions_id.required && <Obligatory />}
                        </h3>
                        {item.questions_id.type === 'text' && (
                          <Textarea
                            register={register}
                            unregister={unregister}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'choice' && (
                          <RadioGroup
                            items={item.questions_id.questions_choice_id.map((itemQues) => {
                              return itemQues.questions_choice_id.name;
                            })}
                            index={index}
                            register={register}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'url' && (
                          <Input
                            type="url"
                            register={register}
                            unregister={unregister}
                            validationSchema={{ required: item.questions_id.required }}
                            id={item.questions_id.id}
                            name={item.questions_id.id}
                          />
                        )}
                        {item.questions_id.type === 'description' && (
                          <div>
                            <p>{item.questions_id.description}</p>
                            <Input
                              type="url"
                              register={register}
                              unregister={unregister}
                              validationSchema={{ required: item.questions_id.required }}
                              id={item.questions_id.id}
                              name={item.questions_id.id}
                            />
                          </div>
                        )}
                        {errors[item.questions_id.id]?.type === 'required' && (
                          <div className="mb-3 text-normal text-red-500">Ce champ est obligatoire.</div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="flex justify-center pt-10">
            {loading ? (
              <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
            ) : (
              <button
                className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
                type="submit">
                <p>Soumettre</p>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default CommiProjectForm;
