import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import FormService from '../../oldService/formService';
import RadioGroup from '../Fields/RadioGroup';
import { OrganizationServices } from '../../oldService/organizatonServices';
import Check from '../Check/Check';

const HostOrganizationForm = ({ setStep1Id, step1Id, sirenData, organizations, setStep2Display }) => {
  const { register, handleSubmit, reset } = useForm();
  const [alreadyExist, setAlreadyExist] = useState(false);

  const [form, setForm] = useState([]);
  FormService.FormatGetForm(form, setForm, "Organisation d'accueil");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sirenData.siret && form[0]) {
      reset({
        [form[0].questions_id[0].questions_id.id]: sirenData.uniteLegale.denominationUniteLegale,
        [form[0].questions_id[1].questions_id.id]: sirenData.siret,
        [form[0].questions_id[3].questions_id.id]:
          sirenData.adresseEtablissement.numeroVoieEtablissement +
          ' ' +
          sirenData.adresseEtablissement.typeVoieEtablissement +
          ' ' +
          sirenData.adresseEtablissement.libelleVoieEtablissement,
        [form[0].questions_id[4].questions_id.id]: sirenData.adresseEtablissement.codePostalEtablissement,
        [form[0].questions_id[5].questions_id.id]: sirenData.adresseEtablissement.libelleCommuneEtablissement,
        [form[0].questions_id[6].questions_id.id]: 'France'
      });
    }
  }, [sirenData]);

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  const onSubmit = async (data) => {
    const formattedData = formatData(data);
    let name = formattedData.find((item) => item.index === 0);
    let numero_siret_rna = formattedData.find((item) => item.index === 1)
      ? formattedData.find((item) => item.index === 1)
      : formattedData.find((item) => item.index === 2);
    let adress = formattedData.find((item) => item.index === 3);
    let postal_code = formattedData.find((item) => item.index === 4);
    let city = formattedData.find((item) => item.index === 5);
    let country = formattedData.find((item) => item.index === 6);
    let beneficiaries = formattedData.find((item) => item.index === 7);
    let other = formattedData.find((item) => item.index === 8);
    if (!organizations.find((orga) => orga.numero_siret_rna === numero_siret_rna.data)) {
      setAlreadyExist(false);
      setLoading(true);
      try {
        const res = await OrganizationServices.PostOrganization(
          name ? name.data : null,
          numero_siret_rna ? numero_siret_rna.data : null,
          adress ? adress.data : null,
          postal_code ? postal_code.data : null,
          city ? city.data : null,
          country ? country.data : null,
          beneficiaries !== 'Autres' && beneficiaries ? beneficiaries.data : other ? other.data : other
        );
        setStep1Id(res.data.data.id);
        setLoading(false);
        setStep2Display(2);
      } catch (err) {
        console.log(err);
      }
    } else {
      setAlreadyExist(true);
    }
  };

  const formatData = (data) => {
    const formattedData = [];
    if (form[0]) {
      form[0].questions_id.map((item, index) => {
        if (data[item.questions_id.id]) {
          formattedData.push({
            id: item.questions_id.id,
            index: index,
            data: data[item.questions_id.id]
          });
        }
      });
    }
    return formattedData;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-10">
          {form[0] &&
            form[0].questions_id.map((item, index) => {
              return (
                <div key={index}>
                  {true && (
                    <div className="space-y-2">
                      <h3 className="text-lg">
                        {item.questions_id.name} <Obligatory />
                      </h3>
                      {item.questions_id.type === 'text' && (
                        <textarea
                          className="w-full p-2 h-28 border border-gray-300"
                          placeholder="À compléter"
                          {...register(item.questions_id.id, { required: true })}
                        />
                      )}
                      {item.questions_id.type === 'choice' && (
                        <RadioGroup
                          items={item.questions_id.questions_choice_id.map((itemQues) => {
                            return itemQues.questions_choice_id.name;
                          })}
                          index={index}
                          register={register}
                          validationSchema={{ required: true }}
                          id={item.questions_id.id}
                          name={item.questions_id.id}
                        />
                      )}
                      {item.questions_id.type === 'url' && (
                        <input
                          type={'url'}
                          className="w-full p-2 border border-gray-300"
                          placeholder="https://"
                          {...register(item.questions_id.id, { required: true })}
                        />
                      )}
                      {item.questions_id.type === 'description' && (
                        <div>
                          <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                          <input
                            type={'text'}
                            className="w-full p-2 border border-gray-300"
                            placeholder="À compléter"
                            {...register(item.questions_id.id, { required: true })}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="flex justify-center pt-10">
          {loading ? (
            <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
          ) : (
            <div>
              {step1Id ? (
                <div className="text-blue-500">
                  <Check />
                </div>
              ) : (
                <button
                  className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
                  type="submit">
                  <p>Créer</p>
                </button>
              )}
            </div>
          )}
        </div>
        {alreadyExist && (
          <div className="flex justify-center pt-5">
            <p className="text-red-500 text-sm text-center">
              Le numéro SIRET/RNA est déjà enregistré,
              <br />
              veuillez sélectionner une organisation d'accueil existante ou essayer un autre numéro SIRET/RNA.
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default HostOrganizationForm;
