import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../../../oldService/authService';
import UserDispoService from '../../../oldService/userDispoService';
import DispoService from '../../../oldService/dispService';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import NoteContent from '../../../oldComponents/NoteContent/NoteContent';
import ContentElem from '../../../oldComponents/ContentElem/ContentElem';
import HomeCardList from '../../../oldComponents/HomeCardList/HomeCardList';
import EventService from '../../../oldService/eventService';
import DispDirFileService from '../../../oldService/dispDirFile';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthProvider';
import './CommitmentsDetails.css';

const CommitmentsDetails = () => {
  const [dispo, setDispo] = useState([]);
  const [note, setNote] = useState('');
  const token = AuthService.getCurrentToken();
  const { user } = useContext(AuthContext);
  const responsibleList = DispoService.FormatRespDispo();
  const tagList = DispoService.FormatTagDispo();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  let myUserDispo;
  let userDispoId;

  const [enga, setEnga] = useState();
  const [inscription, setInscription] = useState(enga ? 0 : 2);

  const [events, setEvents] = useState([]);

  const [fileList, setFileList] = useState([]);

  const getDispo = async (id) => {
    const data = await DispoService.getOneDispo(id);
    setDispo(data.data.data[0]);
  };

  if (token) {
    myUserDispo = UserDispoService.FormatOneUserDispoNote(id);
    userDispoId = UserDispoService.FormatOneUserDispoId(id);
    UserDispoService.FormatOneUserDispo(enga, setEnga, id);
    EventService.FormatGetAllEventTemplate(events, setEvents, id);
    DispDirFileService.FormatGetOneDispFile(fileList, setFileList, id);
  }

  useEffect(() => {
    getDispo(id);
  }, [id]);

  useEffect(() => {
    setNote(myUserDispo);
  }, [myUserDispo]);

  const lengthMore = events.filter((item) => item.status !== 'Archivé').length > 4 ? true : false;

  const navigate = useNavigate();

  if (!token) {
    navigate('/');
  } else {
    return (
      <div className={`UserDetails flex w-screen min-h-screen ${lengthMore && 'lengthMore5'}`}>
        <NavbarLeft data={user} sub={2} />
        <div className="flex flex-col h-full w-full">
          <NavbarTop urls={['home', 'commitments']} />
          <div className="p-10 space-y-8">
            <div>
              <h1 className="font-bold text-3xl text-blue-500">{dispo.name}</h1>
              <p className="text-sm pt-3">
                <span className="text-gray-500">Disponible jusqu'en: </span>
                <span>{dispo.enddate}</span>
              </p>
            </div>
            <div className="space-y-14 py-4 px-5">
              <div className="p-5 bg-gray-50 rounded">
                <ContentElem
                  image={dispo.picture}
                  list={responsibleList}
                  tag={tagList}
                  data={dispo}
                  inscription={inscription}
                  setInscription={setInscription}
                  doc={true}
                  docList={fileList}
                  asso={false}
                />
              </div>
              {inscription === 2 && (
                <div className="space-y-14">
                  <div className="p-5 bg-gray-50 rounded">
                    <NoteContent note={note} setNote={setNote} id={userDispoId} />
                  </div>
                  <div className="p-5 bg-gray-50 rounded">
                    <HomeCardList
                      role={user.role.name}
                      title={'Évènements du dispositif:'}
                      type={'events'}
                      data={events.filter((item) => item.status !== 'Archivé')}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CommitmentsDetails;
