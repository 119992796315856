import React, { useEffect, useState } from 'react';
import { QuestionEvaluation } from '../../../../../usualTypes/BasicTypes';

type ObjQuestion = {
  id_question: number;
  answer: string;
};
type Props = {
  question: QuestionEvaluation;
  onChange: (id: number, value: string) => void;
  oldEvals: ObjQuestion[];
};

const TextField = ({ question, onChange, oldEvals }: Props) => {
  const [defaultValue, setDefaultValue] = useState('');

  const getOldValue = () => {
    if (oldEvals) {
      const indexEvals = oldEvals.findIndex((obj) => obj.id_question === question.id);
      if (indexEvals === -1) return;
      setDefaultValue(oldEvals[indexEvals].answer);
    }
  };
  useEffect(() => {
    getOldValue();
  }, [oldEvals]);
  return (
    <div className="py-5 space-y-2">
      <label htmlFor="skills" className="font-semibold">
        {question.title}
        {question.mandatory && <span className="text-red-500">*</span>}
      </label>
      <textarea
        id="skills"
        className="text-input w-full p-2"
        placeholder={question.description}
        onChange={(e) => {
          onChange(question.id, e.target.value);
        }}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default TextField;
