import React, { useContext, useState } from 'react';
import NavbarTop from './oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from './oldComponents/NavbarLeft/NavbarLeft';
import HomeContent from './oldComponents/HomeContent/HomeContent';
import SignatureContactContent from './oldComponents/SignatureContactContent/SignatureContactContent';
import { AuthContext } from './AuthProvider';

import './App.css';

const App = () => {
  const { user } = useContext(AuthContext);

  const [blur, setBlur] = useState(false);

  return (
    <div className={`App flex max-w-[100vw] min-h-screen`}>
      <NavbarLeft sub={0} blur={blur} user={user} />
      <div className={`flex flex-col h-full w-full page-Container`}>
        <NavbarTop urls={['home']} blur={blur} user={user} />
        <div className="HomeComponent p-10 space-y-8">
          <h1 className={`font-bold text-3xl text-blue-500 ${blur ? 'blur-sm' : 'blur-none'}`}>ACCUEIL</h1>
          {user.role.name === 'Contact' ? (
            <SignatureContactContent blur={blur} setBlur={setBlur} />
          ) : (
            <div className="px-3 py-4 space-y-6 rounded-sm">
              <HomeContent />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
