import React from 'react';
import CommiProjectForm from '../CommiProjectForm/CommiProjectForm';
import ConventionForm from '../Forms/ConventionForm';
import SignatureForm from '../SignatureForm/SignatureForm';

const CommiProjectV2FormCompo = ({ userDispo, blur, setBlur, dispo }) => {
  return (
    <div>
      {userDispo.decline_reason && (
        <div role="alert" className="mb-5">
          <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">Motif du refus</div>
          <div className="border border-t-0 border-red-400 rounded-b px-4 py-3 text-red-700">
            <p>{userDispo.decline_reason}</p>
          </div>
        </div>
      )}
      {userDispo.step_id.name === "Projet d'engagement" && (
        <div>
          <h2 className="font-bold text-xl pb-5 text-blue-700/90">Projet d'engagement</h2>
          <CommiProjectForm dispo={dispo} userDispo={userDispo} />
        </div>
      )}
      {userDispo.step_id.name === 'Convention' && (
        <ConventionForm dispo={dispo} userDispo={userDispo} blur={blur} setBlur={setBlur} />
      )}
      {userDispo.step_id.name === 'Signature' && (
        <div className="flex justify-center items-center">
          <SignatureForm userDispo={userDispo} />
        </div>
      )}
    </div>
  );
};

export default CommiProjectV2FormCompo;
