import React, { useEffect, useState, useContext } from 'react';
import MailIcon from '../../../oldComponents/MailIcon/MailIcon';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import RatingCard from '../../../oldComponents/RatingCard/RatingCard';
import { evaluations_punctual, evaluations_asso_volunteer, evaluations_asso_president } from '../../../data';
import { useNavigate } from 'react-router-dom';
import DispoService from '../../../oldService/dispService';
import EvaluationServices from '../../../oldService/evaluationService';
import ChoiceCard from '../../../oldComponents/ChoiceCard/ChoiceCard';
import UserService from '../../../oldService/userService';
import UserDispoService from '../../../oldService/userDispoService';
import DispDirUsersService from '../../../oldService/dispDirUsersService';
import { PunctualFilesServices } from '../../../oldService/punctualFilesService';
import { OrgaDirUsers1Services } from '../../../oldService/organizationDirectusUsers1Services.js';

import './Rating.css';
import { AuthContext } from '../../../AuthProvider';
import PdfServices, { pdfConvertEvaluationPunctual } from '../../../oldService/pdfServices';
import DocumentsServices from '../../../oldService/documentServices';
import BasicErrorMessage from '../../../oldComponents/ErrorMessage/basic/BasicErrorMessage';
const Rating = () => {
  const { user } = useContext(AuthContext);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const dispoId = queryParams.get('dispoId');
  const userId = queryParams.get('user');
  const asso = queryParams.get('asso');
  const userDispoId = queryParams.get('userDispoId');
  const step = queryParams.get('step');
  const president = queryParams.get('president');
  const punctual = queryParams.get('punctual');
  const lastEvalIdAsso = queryParams.get('eval');

  const [theUser, setTheUser] = useState([]);
  UserService.FormatGetOneUserWithId(theUser, setTheUser, userId);

  const [dispo, setDispo] = useState([]);
  const [lastEval, setLastEval] = useState([]);
  const [orga, setOrga] = useState({});
  const [idUserDispo, setIdUserDispo] = useState([]);
  const [lastEvalId, setLastEvalId] = useState('');
  const [evals, setEvals] = useState([]);
  const [lastEvals, setLastEvals] = useState([]);
  const [skills, setSkills] = useState(null);
  const [comment, setComment] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const [requestError, setRequestError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (punctual) {
      const fetchData = async () => {
        const organisation = await OrgaDirUsers1Services.getOrganizationByUser(user.id);
        setOrga(organisation.data.data);
        const idUserDispo = await UserDispoService.getIdUserDispoByPunctualFiles(punctual);
        setIdUserDispo(idUserDispo.data.data[0]);
        const punctualFiles = await PunctualFilesServices.GetOnePunctualFiles(punctual);
        if (punctualFiles.data.data[0].evaluation !== null) {
          const lastEval = await EvaluationServices.GetOneEvaluation(punctualFiles.data.data[0].evaluation);
          setLastEval(lastEval.data.data);
        }
        setLastEvalId(punctualFiles.data.data[0].evaluation);
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (lastEval.length !== 0) {
      setComment(asso === 'true' ? lastEval[0].field1 : lastEval[0].field2);
      setLastEvals(lastEval[0].evals);
      setSkills(asso !== 'true' && lastEval[0].field1);
      setWelcome(asso !== 'true' && lastEval[0].welcome);
    }
  }, [lastEval]);

  if (punctual === undefined) {
    DispoService.FormatGetOneDispo(dispo, setDispo, dispoId);
  }
  if (lastEvalIdAsso) {
    EvaluationServices.FormatGetOneEvaluation(lastEval, setLastEval, lastEvalIdAsso);
  }

  const FindTotal = () => {
    let big = 0;
    evals.map((item) => {
      big += item === null ? 0 : item;
      return item;
    });
    if (asso === 'true') return (big / evals.length) * 2;
    else return big;
  };

  const OnClickSubmit = async () => {
    if (!theUser[0]) {
      console.log('Error, bad url');
      return 0;
    } else if (asso && asso === 'true') {
      if (lastEvalIdAsso !== null) {
        try {
          await EvaluationServices.DeleteEvaluation(lastEvalIdAsso);
        } catch (err) {
          console.log(err);
        }
      }
      try {
        const newEvaluation = await EvaluationServices.PostEvaluationAsso(
          evals,
          FindTotal(),
          comment,
          president ? 'asso-president' : 'asso-volunteer',
          userId,
          dispoId
        );
        if (step === '1') {
          if (president === 'true') {
            await DispDirUsersService.PatchDispDirUserEval(userDispoId, newEvaluation.data.data.id);
          } else {
            await UserDispoService.PatchUserDispoEval(userDispoId, newEvaluation.data.data.id);
          }
          navigate('/user/asso/details?id=' + id);
        } else if (step === '2') {
          if (president === 'true') {
            await DispDirUsersService.PatchDispDirUserEval2(userDispoId, newEvaluation.data.data.id);
          } else {
            await UserDispoService.PatchUserDispoEval2(userDispoId, newEvaluation.data.data.id);
          }
          navigate('/user/asso/details?id=' + id);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      if (lastEvalId !== null) {
        try {
          await EvaluationServices.DeleteEvaluation(lastEvalId);
        } catch (err) {
          console.log(err);
        }
      }
      (async () => {
        let Error = false;
        try {
          const monGroupe = theUser[0].group.slice(-1).find((group) => group.group_id.parentgroup !== null);
          let promo;
          if (monGroupe === undefined) {
            promo = theUser[0].group.slice(-1).find((group) => group.group_id.parentgroup === null)?.group_id.name;
          } else {
            promo = monGroupe?.group_id.parentgroup.name;
          }
          const document_name = `${idUserDispo.dispositif.name}_${promo !== undefined ? promo : ''} ${
            monGroupe !== undefined ? ',' + monGroupe.group_id.name : ''
          }_${theUser[0].last_name}-${theUser[0].first_name}_Evaluation`;
          setLoading(true);
          const evaluation = await EvaluationServices.PostEvaluation(
            evals,
            FindTotal(),
            skills,
            comment,
            welcome,
            'punctual',
            userId,
            dispoId
          );
          await PunctualFilesServices.PatchEvaluation(punctual, evaluation.data.data.id);
          const resDoc = await DocumentsServices.PostDocument(
            pdfConvertEvaluationPunctual(theUser[0], user, orga[0], FindTotal(), skills, welcome, comment, evals),
            'Evaluation'
          );
          await PdfServices.PostPdf(resDoc.data.data.id, '1xe0REcj5Y6PWQILEWLbwNmFiJGflV1UhBa2sti7k0zs', document_name);
          await PunctualFilesServices.PatchPunctualFilesEvaluation(punctual, resDoc.data.data.id);
          await UserDispoService.PatchUserDispoValidation(idUserDispo.id);
        } catch (err) {
          Error = true;
          setLoading(false);
          setRequestError(Error);
          console.log(err);
        }
        if (!Error) {
          setLoading(false);
          navigate('/home');
        }
      })();
    }
  };

  const OnClickSendEmail = () => {
    window.location = 'mailto:' + theUser[0].email;
  };

  return (
    <div className="Rating flex w-screen min-h-screen">
      <NavbarLeft user={user} />
      <div className="flex flex-col h-full w-full">
        {!asso ? <NavbarTop user={user} urls={['home']} /> : <NavbarTop user={user} urls={['home', 'plans']} />}
        <div className="bg-white px-10 py-10 space-y-8">
          <h1 className="font-bold text-3xl text-blue-500">Évaluation</h1>
          <div className="space-y-5 px-10 py-5 rounded-sm bg-gray-100 flex align-items justify-between">
            <div>
              <h2 className="font-medium pb-1 text-xl text-blue-700">
                {theUser[0] && theUser[0].first_name + ' ' + theUser[0].last_name}
              </h2>
              <p className="text-gray-600/90">
                {theUser[0] &&
                  theUser[0].group &&
                  theUser[0].group.slice(-1)[0] &&
                  theUser[0].group.slice(-1)[0].group_id.name}
              </p>
            </div>
            <div>
              <button className="flex hover:text-blue-500" onClick={OnClickSendEmail}>
                <MailIcon />
                Envoyer un mail
              </button>
            </div>
          </div>
          <div className="space-y-5 px-10 py-5 rounded-sm bg-gray-100">
            <div>
              <h2 className="font-medium pb-1 text-xl text-blue-700">{dispo && dispo.name}</h2>
            </div>
            <div className="px-10">
              {asso === 'true' &&
                president !== 'true' &&
                evaluations_asso_volunteer.map((item, index) => {
                  return (
                    <RatingCard
                      key={index}
                      title={item.title}
                      description={item.description}
                      evals={evals}
                      setEvals={setEvals}
                      index={index}
                      last={lastEvals}
                    />
                  );
                })}
              {asso === 'true' &&
                president === 'true' &&
                evaluations_asso_president.map((item, index) => {
                  return (
                    <RatingCard
                      key={index}
                      title={item.title}
                      description={item.description}
                      evals={evals}
                      setEvals={setEvals}
                      index={index}
                      last={lastEvals}
                    />
                  );
                })}
              {asso !== 'true' &&
                evaluations_punctual.map((item, index) => {
                  return (
                    <RatingCard
                      key={index}
                      title={item.title}
                      description={item.description}
                      evals={evals}
                      setEvals={setEvals}
                      index={index}
                      last={lastEvals}
                    />
                  );
                })}
              {!asso && (
                <div className="py-5 space-y-2">
                  <label htmlFor="skills">Compétences acquises par l'étudiant</label>
                  <textarea
                    id="skills"
                    className="text-input w-full p-2"
                    placeholder="Compétences..."
                    onChange={(e) => {
                      setSkills(e.target.value);
                    }}
                    defaultValue={skills}
                  />
                </div>
              )}
              <div className="py-5 space-y-2">
                <label htmlFor="comment">Observations</label>
                <textarea
                  id="comment"
                  className="text-input w-full p-2"
                  placeholder="Observations..."
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  defaultValue={comment}
                />
              </div>
              {!asso && (
                <div className="py-5 space-y-2">
                  <label htmlFor="comment">Souhaitez-vous accueillir un étudiant l'année prochaine ?</label>
                  <ChoiceCard data={welcome} setData={setWelcome} />
                </div>
              )}
              <BasicErrorMessage error={requestError} />
              <div className="flex justify-center">
                {loading ? (
                  <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
                ) : (
                  <button
                    className="bg-gray-300 px-3 py-2 rounded-sm hover:bg-blue-500 hover:text-white"
                    onClick={OnClickSubmit}>
                    Soumettre
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
