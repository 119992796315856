import React, { useEffect } from 'react';

type Props = React.PropsWithChildren<{
  id: string;
  name: string;
  register: (name: string, validationSchema: any) => object;
  unregister: (name: string) => void;
  validationSchema: any;
  type?: string;
}>;

export default function Input({ id, name, register, type = 'text', unregister, validationSchema }: Props) {
  useEffect(() => {
    register(name, validationSchema);
    return () => {
      unregister(name);
    };
  }, [register, unregister]);
  return (
    <input
      type={type}
      className="w-full p-2 border border-gray-300"
      placeholder={type === 'url' ? 'https://' : 'À compléter'}
      id={id}
      name={name}
      {...register(name, validationSchema)}
    />
  );
}
