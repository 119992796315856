import { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import AnswerFormService from '../../../oldService/answerFormService';
import updateStep1Action from './Step1/updateAction';
import updateStep2Action from './Step2/updateAction';
import updateStep3Action from './Step3/updateAction';

const LoadData = ({ userDispo, children }) => {
  const { actions } = useStateMachine({ updateStep1Action, updateStep2Action, updateStep3Action });
  const [organizationAnswer, setOrganizationAnswer] = useState();
  const [missionAnswer, setMissionAnswer] = useState();
  const [conventionAnswer, setConventionAnswer] = useState();

  AnswerFormService.FormatGetOneAnswerForm(
    missionAnswer,
    setMissionAnswer,
    userDispo.dispositif,
    userDispo.user.id,
    'mission'
  );
  AnswerFormService.FormatGetOneAnswerForm(
    conventionAnswer,
    setConventionAnswer,
    userDispo.dispositif,
    userDispo.user.id,
    'convention'
  );
  AnswerFormService.FormatGetOneAnswerForm(
    organizationAnswer,
    setOrganizationAnswer,
    userDispo.dispositif,
    userDispo.user.id,
    'organization'
  );

  useEffect(() => {
    if (organizationAnswer) {
      actions.updateStep1Action({
        raw: organizationAnswer.answer_raw,
        data: organizationAnswer.answer
      });
    }

    if (missionAnswer) {
      actions.updateStep2Action({ raw: missionAnswer.answer_raw, data: missionAnswer.answer });
    }

    if (conventionAnswer) {
      actions.updateStep3Action({
        raw: conventionAnswer.answer_raw,
        data: conventionAnswer.answer
      });
    }
  }, [organizationAnswer, missionAnswer, conventionAnswer]);

  return children({
    organizationAnswer: organizationAnswer?.answer_raw,
    missionAnswer: missionAnswer?.answer_raw,
    conventionAnswer: conventionAnswer?.answer_raw
  });
};

export default LoadData;
