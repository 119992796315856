export const findNextStep = (actualOrder, dispoStep) => {
  console.log('***START***');

  const list = [];

  dispoStep.map((item) => {
    if (item.order > actualOrder) list.push(item.order);
    return item;
  });
  const min = Math.min(...list);
  const nextStep = dispoStep.find((item) => item.order === min);
  console.log('next step is:');
  console.log(nextStep);
  return nextStep && nextStep;
};
