import React, { useEffect, useState } from 'react';
import UserDispoService from '../../oldService/userDispoService';
import RemoveIcon from '../RemoveIcon/RemoveIcon';
import WarningIcon from '../WarningIcon/WarningIcon';

function TableListPlans(props) {
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckAll = (statuts) => {
    if (statuts === true) {
      props.setList(
        props.data.map((item) => {
          return item;
        })
      );
    } else {
      props.setList([]);
    }
  };

  const handleAdd = (statuts, item) => {
    if (statuts === true) {
      props.setList([...props.list, item]);
    } else {
      props.setList(
        props.list.filter(
          (itemList) =>
            itemList.name !== item.name &&
            itemList.description !== item.description &&
            itemList.number_hours !== item.number_hours &&
            itemList.responsibles !== item.responsibles &&
            itemList.tag !== item.tag
        )
      );
    }
  };

  const handleRemoveItem = (item) => {
    UserDispoService.deleteOneUserDispo(item.other_id).then(() => {
      window.location.reload();
    });
  };

  useEffect(() => {
    props.list.length === props.data.length ? setCheckAll(true) : setCheckAll(false);
  }, [props.list, props.data]);

  return (
    <div className="TableListPlans">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 max-h-96 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckAll(e.target.checked);
                        }}
                        checked={checkAll}
                      />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Nom
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Groupe(s)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Dispositif(s)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    {props.admin && (
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Dernière Connexion
                      </th>
                    )}
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.data.map((item, index) => {
                    return (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              handleAdd(e.target.checked, item);
                            }}
                            checked={props.list[0] !== undefined && props.list.find((li) => li.id === item.id)}
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                          {item.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900">
                          <div className="flex justify-center items-center space-x-1">
                            <div className="flex space-x-1">
                              {item.team && <p>{item.team.group_id.name}</p>}
                              {item.team && item.team.group_id.parentgroup && (
                                <p>/ {item.team.group_id.parentgroup.name}</p>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900">
                          <div className="flex justify-center items-center space-x-1">
                            {props.planDetails
                              ? props.userDispo
                                  .filter((dispo) => dispo.user.id === item.other_id)
                                  .map((dispoItem, index, array) => {
                                    return (
                                      <div key={index} className="flex space-x-1">
                                        {index < 2 && (
                                          <div className="flex space-x-1">
                                            <p>
                                              {dispoItem.dispositif &&
                                                (dispoItem.dispositif.name.length > 15
                                                  ? dispoItem.dispositif.name.substring(0, 15) + '...'
                                                  : dispoItem.dispositif.name)}
                                            </p>
                                            {index < array.length - 1 ? <span>/</span> : ''}
                                          </div>
                                        )}
                                        {index === 1 && array.length > 2 && <span>...</span>}
                                      </div>
                                    );
                                  })
                              : props.userDispo
                                  .filter((dispo) => dispo.user.id === item.id)
                                  .map((dispoItem, index, array) => {
                                    return (
                                      <div key={index} className="flex space-x-1">
                                        {index < 2 && (
                                          <div className="flex space-x-1">
                                            <p>
                                              {dispoItem.dispositif &&
                                                (dispoItem.dispositif.name.length > 15
                                                  ? dispoItem.dispositif.name.substring(0, 15) + '..'
                                                  : dispoItem.dispositif.name)}
                                            </p>
                                            {index < array.length - 1 ? <span>/</span> : ''}
                                          </div>
                                        )}
                                        {index === 1 && array.length > 2 && <span>...</span>}
                                      </div>
                                    );
                                  })}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900 underline hover:no-underline">
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </td>
                        {props.admin && (
                          <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-900">
                            {item.lastAccess}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 flex justify-center text-sm text-gray-900">
                          {props.admin ? (
                            <button className="flex items-center bg-gray-200 py-1 px-1 space-x-1 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white">
                              <WarningIcon />
                              <span className="px-1">Réinitialiser MDP</span>
                            </button>
                          ) : (
                            <button
                              className="hover:text-red-500"
                              onClick={() => {
                                handleRemoveItem(item);
                              }}>
                              <RemoveIcon />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableListPlans;
