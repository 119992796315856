import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CardList from '../../../oldComponents/CardList/CardList';
import NavbarTop from '../../../oldComponents/NavbarTop/NavbarTop';
import Searchbar from '../../../oldComponents/Searchbar/Searchbar';
import NavbarLeft from '../../../oldComponents/NavbarLeft/NavbarLeft';
import UserDispoService from '../../../oldService/userDispoService';
import { AuthContext } from '../../../AuthProvider';

import './Events.css';
import DispoService from '../../../oldService/dispService';

const Events = () => {
  const [idEventIAmIn, setIdEventIAmIn] = useState([]);
  const [idDispoImEngaged, setIdDispoImEngaged] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [eventDisplay, setEventDisplay] = useState([]);
  const [allEvent, setAllEvents] = useState([]);
  const [filterCheck, setFilterCheck] = useState(0);
  const [allMyEvents, setAllMyEvents] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [filter, setFilter] = useState(0);
  const [archived, setArchived] = useState(false);

  const location = useLocation();
  const params = useParams();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    setFilter(parseInt(params.subFilter, 10));
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const IParticipate = await UserDispoService.getUserEvent(user.id);
      const allDispoUserIsIn = await UserDispoService.getUserEnga(user.id);
      const events = await DispoService.getAllEvent();
      setIdDispoImEngaged(allDispoUserIsIn.data.data.map((dispo) => dispo.dispositif.id));
      setIdEventIAmIn(IParticipate.data.data.map((event) => event.dispositif.id));
      setAllEvents(events.data.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const newFilteredEvents = searchArray.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchField);
    });
    setEventDisplay(newFilteredEvents);
  }, [searchField]);

  useEffect(() => {
    setFilterCheck(filter);
  }, [filter]);

  useEffect(() => {
    const eventToGet = allEvent.filter(
      (event) =>
        idDispoImEngaged.includes(event.parent) ||
        event?.responsibles.find((resp) => resp.directus_users_id === user.id) ||
        event?.status === 'Public'
    );
    setAllMyEvents(eventToGet);
  }, [allEvent]);

  useEffect(() => {
    if (filterCheck === 0) {
      setEventDisplay(allMyEvents.filter((event) => event.archived === archived));
      setSearchArray(allMyEvents);
    }
    if (filterCheck === 1) {
      const filterArray = allMyEvents.filter(
        (event) =>
          event?.responsibles.find((resp) => resp.directus_users_id !== user.id) &&
          idEventIAmIn.includes(event.id) &&
          event.archived === archived
      );
      setEventDisplay(filterArray);
      setSearchArray(filterArray);
    }
    if (filterCheck === 2) {
      const filterArray = allMyEvents.filter(
        (event) =>
          (idDispoImEngaged.includes(event.parent) || event.status === 'Public') &&
          !idEventIAmIn.includes(event.id) &&
          event.archived === archived
      );
      setEventDisplay(filterArray);
      setSearchArray(filterArray);
    }
    if (filterCheck === 3) {
      const filterArray = allMyEvents.filter(
        (event) => event?.responsibles.find((resp) => resp.directus_users_id === user.id) && event.archived === archived
      );
      setEventDisplay(filterArray);
      setSearchArray(filterArray);
    }
    if (filterCheck === 4) {
      const filterArray = allMyEvents.filter((event) => event?.status === 'Public' && event.archived === archived);
      setEventDisplay(filterArray);
      setSearchArray(filterArray);
    }
  }, [filterCheck, allMyEvents, archived]);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };
  return (
    <div className="Events">
      <div className="Commitments flex max-w-[100vw] min-h-screen">
        <NavbarLeft sub={3} user={user} />
        <div className="flex flex-col h-full w-full page-Container">
          <NavbarTop urls={['home', 'events']} user={user} />
          <div className="p-10 space-y-8">
            <h1 className="font-bold text-3xl text-blue-500">LISTE DES ÉVÈNEMENTS</h1>
            <div className="px-3 py-4 space-y-6 bg-gray-50 rounded">
              <div className="flex flex-col items-center space-y-6 px-5 xl:flex-row xl:space-y-0 xl:justify-between">
                <div className="w-1/3">
                  <Searchbar placeholder={'Rechercher par nom...'} onChangeHandler={onSearchChange} />
                </div>
                <div className="flex space-x-2 xl:space-x-1">
                  <button
                    className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${
                      archived ? 'bg-gray-300' : 'bg-blue-500 text-white'
                    }`}
                    onClick={() => {
                      // setFilterCheck(0);
                      setFilter(0);
                      setArchived(false);
                    }}>
                    Tous
                  </button>
                  {user.role.name === 'Administrator' && (
                    <button
                      className={`px-2 py-1 border rounded hover:bg-blue-500 hover:text-white ${
                        archived ? 'bg-blue-500 text-white' : 'bg-gray-300'
                      }`}
                      onClick={() => {
                        setArchived(true);
                      }}>
                      Archivé
                    </button>
                  )}
                </div>
              </div>
              <div className="relative">
                <div className="absolute right-5 -top-3 flex space-x-2">
                  <div className="flex items-center space-x-1">
                    <input
                      type="checkbox"
                      id="engaged"
                      onChange={() => {
                        setFilterCheck(1);
                      }}
                      checked={filterCheck === 1}
                    />
                    <label htmlFor="engaged">Auxquels je suis inscrit</label>
                  </div>
                  <div className="flex items-center space-x-1">
                    <input
                      type="checkbox"
                      id="respo"
                      onChange={() => {
                        setFilterCheck(2);
                      }}
                      checked={filterCheck === 2}
                    />
                    <label htmlFor="respo">Auxquels je peux participer</label>
                  </div>
                  <div className="flex items-center space-x-1">
                    <input
                      type="checkbox"
                      id="all"
                      onChange={() => {
                        setFilterCheck(3);
                      }}
                      checked={filterCheck === 3}
                    />
                    <label htmlFor="all">Que je gère</label>
                  </div>
                  <div className="flex items-center space-x-1">
                    <input
                      type="checkbox"
                      id="status"
                      onChange={() => {
                        setFilterCheck(4);
                      }}
                      checked={filterCheck === 4}
                    />
                    <label htmlFor="status">Public</label>
                  </div>
                </div>
              </div>
              <CardList data={eventDisplay} role={user && user.role.name} type={'events'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
