import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/tag';

const getAllTag = () => {
  return axios.get(API_URL + '?fields=id,label', { headers: authHeader() });
};

const FormatgetAllTag = (tagList, setTagList) => {
  useEffect(() => {
    const list = [];

    const MakeTagList = async () => {
      const data = await TagService.getAllTag();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setTagList(list);
    };
    MakeTagList();
  }, []);
  return tagList;
};

const TagService = {
  getAllTag,
  FormatgetAllTag
};

export default TagService;
