import React, { useState, useEffect } from 'react';
import { API } from '../../oldService/authHeader';
import DispDirUsersService from '../../oldService/dispDirUsersService';
import DispoService from '../../oldService/dispService';
import FilesAssoService from '../../oldService/filesViesAssoService';
import UserDispoService from '../../oldService/userDispoService';
import Files_vie_asso_files_service from '../../services/files_vie_asso_files.service';

import './ModalValidAdmin.css';
import LoadingButtonModalValidAdmin from '../LoadingButtonModalValidAdmin/LoadingButtonModalValidAdmin';
import FileService from '../../oldService/fileService';

function ModalValidAdmin(props) {
  const userStep = props.dispo.step.find((item) => item.step_id.id === props.user.step.id);
  const nextStepDispo = props.dispo.step.find((item) => item.order === userStep.order + 1);
  const [dispoUser, setDispoUser] = useState([]);
  const fetchDispoUser = async () => {
    const dispoUserRequest = await DispoService.GetDispoWithFilesAssoId(props.user.files_asso_id);
    setDispoUser(dispoUserRequest.data.data[0]);
  };
  const [dispoValid, setDispoValid] = useState([]);
  const fetchDispoValid = async () => {
    const dispoValidRequest = await DispoService.GetDispoValid(props.dispo.id, props.user.files_asso_id);
    setDispoValid(dispoValidRequest.data.data[0]);
  };
  const [fileAsso, setFileAsso] = useState([]);
  const fetchFileAsso = async () => {
    const fileAssoRequest = await FilesAssoService.GetOneFilesAsso(props.user.files_asso_id);
    setFileAsso(fileAssoRequest.data.data[0]);
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchDispoUser();
      await fetchDispoValid();
      await fetchFileAsso();
    };
    fetchData();
  }, []);

  const [userList, setUserList] = useState([]);
  if (props.dispoCreate) {
    UserDispoService.FormatGetAllUser(userList, setUserList, props.dispoCreate.id);
  }

  const [loading, setLoading] = useState(false);
  const handleDisplay = () => {
    props.setDisplay(false);
    props.setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };

  const ConvertRole = (str) => {
    let result;

    str === 'president'
      ? (result = 'Président-e')
      : str === 'vice-president'
      ? (result = 'Vice-Président-e')
      : str === 'treasurer'
      ? (result = 'Trésorier')
      : (result = 'Secrétaire');
    return result;
  };

  const handleAccept = () => {
    setLoading(true);
    const changeStep = () => {
      UserDispoService.PatchUserDispoStep(props.user.other_id, nextStepDispo.step_id.id).then(() => {
        setLoading(false);
        props.setChangeInPage((prev) => prev + 1);
        handleDisplay();
      });
    };
    if (userStep.step_id.name === 'Identité asso') {
      DispoService.PatchTemplateDispo(dispoValid.id, 292).then(() => {
        changeStep();
      });
    } else if (
      nextStepDispo &&
      nextStepDispo.step_id &&
      (nextStepDispo.step_id.name === 'Évaluations S1' || nextStepDispo.step_id.name === 'Évaluations S2')
    ) {
      DispoService.PatchDispoEvaluationStep(
        dispoUser.id,
        nextStepDispo.step_id.name === 'Évaluations S1' ? '1' : '2'
      ).then(() => {
        changeStep();
      });
    } else if (userStep.step_id.name === 'Bilan') {
      UserDispoService.PatchUserDispoFinish(props.user.other_id).then(() => {
        setLoading(false);
        props.setChangeInPage((prev) => prev + 1);
        handleDisplay();
      });
    } else {
      changeStep();
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    if (userStep.step_id.name === 'Administratif') {
      const documentTitle = ['statuts', 'proces_verbal', 'recepisse', 'budget', 'planning'];
      const promises = documentTitle.map(async (title) => {
        if (fileAsso[title] !== null) {
          await FileService.deleteFile(fileAsso[title]);
        }
      });
      await Promise.all(promises);
      await FilesAssoService.DeleteOneFilesAsso(fileAsso.id);
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, null);
    }
    if (userStep.step_id.name === 'Identité asso') {
      try {
        await DispoService.DeleteDispo(dispoValid.id);
        await UserDispoService.PatchUserDispoStep(props.user.other_id);
      } catch (err) {
        console.log(err);
      }
    }
    if (userStep.step_id.name === 'Membres bureau') {
      dispoValid.responsibles.map(async (item) => {
        await DispDirUsersService.DeleteDispDirUser(item.id);
        return item;
      });
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, props.user.files_asso_id);
    }
    if (userStep.step_id.name === 'Bénévoles') {
      userList.map(async (item) => {
        await UserDispoService.deleteOneUserDispo(item.other_id);
        return item;
      });
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, props.user.files_asso_id);
    }
    if (userStep.step_id.name === 'Soutenance S1') {
      await FileService.deleteFile(fileAsso.soutenance_1);
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, props.user.files_asso_id);
    }
    if (userStep.step_id.name === 'Soutenance S2') {
      await FileService.deleteFile(fileAsso.soutenance_2);
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, props.user.files_asso_id);
    }
    if (userStep.step_id.name === 'Bilan') {
      const promises = fileAsso.finalReport.map(async (report) => {
        await FileService.deleteFile(report.directus_files_id.id);
        await Files_vie_asso_files_service.deleteDocument(report.id);
      });
      await Promise.all(promises);
      await UserDispoService.PatchUserDispoValFiles(props.user.other_id, false, props.user.files_asso_id);
    }
    setLoading(false);
    props.setChangeInPage((prev) => prev + 1);
    handleDisplay();
  };
  const listFile = ['statuts', 'proces_verbal', 'recepisse', 'budget', 'planning'];

  const handleDowloadOne = (fileId, fileName) => {
    const url = API + 'assets/' + fileId + '?download';
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = props.user.name + '_' + fileName;
        a.click();
        URL.revokeObjectURL(url);
      });
  };
  return (
    <div className="ModalValidAdmin">
      <div
        id="authentication-modal"
        tabIndex="-1"
        className="backgoundModalColor overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
        aria-modal="true"
        role="dialog">
        <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
          <div className="relative bg-white rounded shadow">
            <button
              disabled={loading}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={handleDisplay}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="py-4 px-6">
              <h3 className="mb-4 text-xl font-medium text-gray-900">Validation de l'étape</h3>
              <div className="space-y-6">
                <p>
                  Demande de {props.user.name} pour l'étape "<span className="underline">{userStep.step_id.name}</span>
                  ".
                </p>
                {userStep.step_id.name === 'Administratif' && (
                  <div className="w-full">
                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                        {listFile.map((fileName, index) =>
                          fileAsso[fileName] === null ? (
                            <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                              <div className="flex w-0 flex-1 items-center">
                                <svg
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">
                                    L'étudiant n'a pas déposé de fichier pour {fileName}
                                  </span>
                                </div>
                              </div>
                            </li>
                          ) : (
                            <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                              <div className="flex w-0 flex-1 items-center">
                                <svg
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">{fileName}</span>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                                onClick={() => {
                                  handleDowloadOne(fileAsso[fileName], fileName);
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round">
                                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                                </svg>
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    </dd>
                  </div>
                )}
                {userStep.step_id.name === 'Identité asso' && (
                  <div className="flex flex-col justify-center">
                    <div className="flex justify-center w-full border-b-2">
                      <img src={API + 'assets/' + dispoValid?.picture} className="rounded-full w-32 py-2" />
                    </div>
                    <div className="w-full grid grid-cols-2 justify-items-center">
                      <div className="flex flex-col border-r-2 w-full border-b-2">
                        <p className="text-center font-bold pt-3">Nom</p>
                        <p className="text-center pb-3">{dispoValid?.name !== null ? dispoValid.name : 'Pas de nom'}</p>
                      </div>
                      <div className="flex flex-col w-full border-b-2">
                        <p className="text-center font-bold pt-3">Description courte</p>
                        <p className="text-center pb-3">
                          {dispoValid?.shortdescription !== null
                            ? dispoValid.shortdescription
                            : 'Pas de description courte'}
                        </p>
                      </div>
                    </div>
                    <div className="w-full grid grid-cols-2 justify-items-center">
                      <div className="flex flex-col border-r-2 border-b-2 w-full">
                        <p className="text-center font-bold pt-3">Temps d'engagement</p>
                        <p className="text-center pb-3">
                          {dispoValid?.nbrhour !== null ? dispoValid.nbrhour + 'h' : "Nombre d'heure non renseignée"}
                        </p>
                      </div>
                      <div className="flex flex-col w-full border-b-2">
                        <p className="text-center font-bold pt-3">Date de fin</p>
                        <p className="text-center pb-3">
                          {dispoValid?.enddate !== null ? dispoValid.enddate : 'Pas de date renseignée'}
                        </p>
                      </div>
                    </div>
                    <div className="w-full text-center">
                      <p className="text-center font-bold pt-3">Description</p>
                      <p className="text-center pb-3">
                        {dispoValid?.description !== null ? dispoValid.description : 'Pas de description'}
                      </p>
                    </div>
                  </div>
                )}
                {userStep.step_id.name === 'Membres bureau' && (
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th className="px-6 py-3">Nom</th>
                        <th className="px-6 py-3">Role</th>
                        <th className="px-6 py-3">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dispoValid?.responsibles &&
                        dispoValid.responsibles.map((item, index) => {
                          return (
                            <tr className="bg-white border-b" key={index}>
                              <td className="px-6 py-3">
                                <a className="hover:underline" href={'mailto:' + item.directus_users_id.email}>
                                  {item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name}
                                </a>
                              </td>
                              <td className="px-6 py-3">
                                <p>{ConvertRole(item.role)}</p>
                              </td>
                              <td className="px-6 py-3">
                                <p>{item.role_details}</p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
                {userStep.step_id.name === 'Bénévoles' && (
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th className="px-6 py-3">Nom</th>
                        <th className="px-6 py-3">Groupe</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map((item, index) => {
                        return (
                          <tr className="bg-white border-b" key={index}>
                            <td className="px-6 py-3">
                              <a className="hover:underline" href={'mailto:' + item.email}>
                                {item.name}
                              </a>
                            </td>
                            <td className="px-6 py-3">
                              <p>{item.team?.group_id?.name}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {userStep.step_id.name === 'Évaluations S1' && (
                  <div className="flex flex-col gap-2">
                    <span className="text-center">
                      Vous pouvez retrouver l'intégralité des évaluations sous le format CSV en cliquant sur le bouton
                      "Exporter des éléments" en haut de la page puis télécharger "Evaluations complétées par les
                      responsables associatifs"
                    </span>
                    <span className="italic text-center">
                      En cliquant sur le bouton "Valider" l'étudiant passera à l'étape suivante
                    </span>
                  </div>
                )}
                {userStep.step_id.name === 'Soutenance S1' && (
                  <div className="w-full">
                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                        <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <svg
                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">Soutenance S1</span>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => {
                              handleDowloadOne(fileAsso.soutenance_1, 'Soutenance_S1');
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round">
                              <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </div>
                )}
                {userStep.step_id.name === 'Évaluations S2' && (
                  <div className="flex flex-col gap-2">
                    <span className="text-center">
                      Vous pouvez retrouver l'intégralité des évaluations sous le format CSV en cliquant sur le bouton
                      "Exporter des éléments" en haut de la page puis télécharger "Evaluations complétées par les
                      responsables associatifs"
                    </span>
                    <span className="italic text-center">
                      En cliquant sur le bouton "Valider" l'étudiant passera à l'étape suivante
                    </span>
                  </div>
                )}
                {userStep.step_id.name === 'Soutenance S2' && (
                  <div className="w-full">
                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                        <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <svg
                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true">
                              <path
                                fillRule="evenodd"
                                d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">Soutenance S2</span>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => {
                              handleDowloadOne(fileAsso.soutenance_2, 'Soutenance_S1');
                            }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round">
                              <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </dd>
                  </div>
                )}
                {userStep.step_id.name === 'Bilan' && (
                  <div className="w-full">
                    <dd className="mt-1 text-sm leading-6 text-gray-700">
                      <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                        {fileAsso.finalReport &&
                          fileAsso.finalReport.map((report, index) => (
                            <li className="flex items-center gap-10 py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                              <div className="flex w-0 flex-1 items-center">
                                <svg
                                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true">
                                  <path
                                    fillRule="evenodd"
                                    d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                  <span className="truncate font-medium">{report.directus_files_id.title}</span>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                                onClick={() => {
                                  handleDowloadOne(report.directus_files_id.id, report.directus_files_id.title);
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round">
                                  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                                </svg>
                              </button>
                            </li>
                          ))}
                      </ul>
                    </dd>
                  </div>
                )}
                <LoadingButtonModalValidAdmin
                  loading={loading}
                  handleAccept={handleAccept}
                  handleDecline={handleDecline}
                  validateOnly={
                    userStep.step_id.name === 'Évaluations S1' || userStep.step_id.name === 'Évaluations S2'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalValidAdmin;
