import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/dispositif_directus_users';

const getDirectusUser = () => {
  return axios.get(API_URL + '?limit=-1', { headers: authHeader() });
};

const DeleteDispDirUser = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const getDirDispUser = (id) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      id +
      '&fields=id,directus_users_id.id,directus_users_id.first_name,directus_users_id.last_name,directus_users_id.phones,directus_users_id.group.group_id.name,directus_users_id.group.group_id.parentgroup.name,directus_users_id.email,evaluation_id.id,evaluation_id.total,evaluation_id_2.id,evaluation_id_2.total,role,role_details,dispositif_id.name,dispositif_id.id,date_created',
    { headers: authHeader() }
  );
};

const FormatGetDirDispUserFormated = (dirUser, setDirUser, id) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await getDirDispUser(id);
      data.data.data.map((item) => {
        list.push({
          id: item.directus_users_id.id,
          name: item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name,
          team: item.directus_users_id.group.slice(-1)[0],
          email: item.directus_users_id.email,
          other_id: item.id,
          evaluation_id: item.evaluation_id,
          evaluation_id_2: item.evaluation_id_2,
          role: item.role
        });
        return item;
      });
      setDirUser(list);
    };
    Makelist();
  }, []);
  return dirUser;
};

const PatchDispDirUserEval = (id, evalId) => {
  return axios.patch(API_URL + '/' + id, { evaluation_id: evalId }, { headers: authHeader() });
};

const PatchDispDirUserEval2 = (id, evalId) => {
  return axios.patch(API_URL + '/' + id, { evaluation_id_2: evalId }, { headers: authHeader() });
};

const getDirectusUserDispo = (dispoId, userId) => {
  return axios.get(API_URL + '?filter[dispositif_id][_eq]=' + dispoId + '&filter[directus_users_id][_eq]=' + userId, {
    headers: authHeader()
  });
};

const postDirectusUser = (dispId, userId, role, role_details = null) => {
  return axios.post(
    API_URL,
    {
      dispositif_id: dispId,
      directus_users_id: userId,
      role,
      role_details
    },
    { headers: authHeader() }
  );
};

const GetUserDispoDirectusWithoutDispo = (userId) => {
  return axios.get(API_URL + '?filter[directus_users_id][_eq]=' + userId + '&filter=[role][_eq]=president', {
    headers: authHeader()
  });
};

const FormatGetUserDispoDirectusWithoutDispoPresident = (dirUser, setDirUser, userId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetUserDispoDirectusWithoutDispo(userId);
      data.data.data.map((item) => {
        if (item.role === 'president') {
          list.push(item);
        }
        return item;
      });
      setDirUser(list);
    };
    Makelist();
  }, []);
  return dirUser;
};

const FormatAllDirectusUser = (dirUser, setDirUser) => {
  useEffect(() => {
    const list = [];

    const MakeDirUser = async () => {
      const data = await DispDirUsersService.getDirectusUser();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDirUser(list);
    };
    MakeDirUser();
  }, []);
  return dirUser;
};

const FormatDirectusUser = (user, setUser, id) => {
  useEffect(() => {
    const MakeUser = async () => {
      const data = await DispDirUsersService.getDirectusUser();
      const myUser = data.data.data.find((item) => item.directus_users_id === id).id;
      setUser(myUser);
      return myUser;
    };
    MakeUser();
  }, []);
  return user;
};

const FormatGetDirectusUserDispo = (dirList, setDirList, dispoId, userId) => {
  useEffect(() => {
    const MakeList = async () => {
      const data = await DispDirUsersService.getDirectusUserDispo(dispoId, userId);
      if (data.data.data[0]) setDirList(true);
      else setDirList(false);
    };
    MakeList();
  }, []);
  return dirList;
};

const FormatGetDirDispUser = (respo, setRespo, id) => {
  useEffect(() => {
    const list = [];

    const MakeUserDisp = async () => {
      const data = await DispDirUsersService.getDirDispUser(id);
      data.data.data.map((item) => {
        list.push({
          id: item.id,
          other_id: item.directus_users_id.id,
          name: item.directus_users_id.first_name + ' ' + item.directus_users_id.last_name,
          team: item.directus_users_id.group.slice(-1)[0],
          email: item.directus_users_id.email,
          role: item.role,
          role_details: item.role_details
        });
        return item;
      });
      setRespo(list);
    };
    MakeUserDisp();
  }, []);
  return respo;
};

const getAllEvalOfADispo = (dispoId) => {
  return axios.get(
    API_URL +
      '?filter[dispositif_id][_eq]=' +
      dispoId +
      '&filter[role][_neq]=president' +
      '&fields=dispositif_id.name,dispositif_id.id,dispositif_id.evaluation_step,evaluation_id.total,evaluation_id.date_created,evaluation_id.evals,evaluation_id.type,evaluation_id.user_created.first_name,evaluation_id.user_created.email,evaluation_id.user_created.last_name,evaluation_id.user_created.group.group_id.name,evaluation_id.user_created.group.group_id.parentgroup.name,evaluation_id_2.date_created,evaluation_id_2.total,evaluation_id_2.type,evaluation_id_2.evals,evaluation_id_2.user_created.first_name,evaluation_id_2.user_created.email,evaluation_id_2.user_created.last_name,evaluation_id_2.user_created.group.group_id.name,evaluation_id_2.user_created.group.group_id.parentgroup.name,evaluation_id.user.first_name,evaluation_id.user.last_name,evaluation_id.user.email,evaluation_id.user.phones,evaluation_id.user.group.group_id.name,evaluation_id.user.group.group_id.parentgroup.name,evaluation_id_2.user.first_name,evaluation_id_2.user.last_name,evaluation_id_2.user.email,evaluation_id_2.user.phones,evaluation_id_2.user.group.group_id.name,evaluation_id_2.user.group.group_id.parentgroup.name',
    { headers: authHeader() }
  );
};

const DispDirUsersService = {
  getDirectusUser,
  getDirDispUser,
  getDirectusUserDispo,
  postDirectusUser,
  FormatDirectusUser,
  FormatAllDirectusUser,
  FormatGetDirectusUserDispo,
  FormatGetDirDispUser,
  FormatGetUserDispoDirectusWithoutDispoPresident,
  DeleteDispDirUser,
  FormatGetDirDispUserFormated,
  PatchDispDirUserEval,
  PatchDispDirUserEval2,
  getAllEvalOfADispo
};

export default DispDirUsersService;
