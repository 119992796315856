import React, { useEffect } from 'react';

function Textarea({ id, name, register, unregister, validationSchema }) {
  useEffect(() => {
    register(name, validationSchema);
    return () => {
      unregister(name);
    };
  }, [register, unregister]);
  return (
    <textarea
      className="w-full p-2 h-28 border border-gray-300"
      placeholder="À compléter"
      id={id}
      name={name}
      {...register(name, validationSchema)}
    />
  );
}

export default Textarea;
