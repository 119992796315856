import React, { useEffect, useState } from 'react';
import UserService from '../../oldService/userService';

const ModalProposeEmailChange = ({ setDisplayModal, tryEmail, HandleAnswerChange }) => {
  const [consent, setConsent] = useState(false);
  const [alreadyUse, setAlreadyUse] = useState([]);
  const [beginMail, setBeginMail] = useState('');
  const [endMail, setEndMail] = useState('');
  const [addNumber, setAddNumber] = useState(0);
  const [proposeMail, setProposeMail] = useState('');
  const handleValidateAction = () => {
    HandleAnswerChange(3, proposeMail, 'ad6b0c99-34c1-4b88-9722-f5a734355bff');
    handleCloseModal();
  };
  useEffect(() => {
    const begin = tryEmail.split('@')[0];
    const end = tryEmail.split('@')[1];
    setBeginMail(begin);
    setEndMail(end);
    const fetchData = async () => {
      const listOfALreadyUse = [];
      const result = await UserService.getAllMailWithMore(begin, end);
      result.data.data
        .filter((email: { email: string }) => {
          const regex = new RegExp(`^${begin.replace('+', '\\+')}\\+\\d+@${end}$`);
          return regex.test(email.email);
        })
        .map((email: { email: string }) => {
          email.email.split('+').map((onePart) => {
            if (onePart.includes('@')) {
              listOfALreadyUse.push(parseInt(onePart.split('@')[0]));
            }
          });
        });
      setAlreadyUse(listOfALreadyUse);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const findFirst = (n: number) => {
      if (alreadyUse.includes(n)) return findFirst(n + 1);
      return n;
    };
    setAddNumber(findFirst(1));
  }, [alreadyUse]);

  useEffect(() => {
    setProposeMail(beginMail + '+' + addNumber + '@' + endMail);
  }, [beginMail, endMail, addNumber]);
  const handleCloseModal = () => {
    document.body.classList.remove('ModalOpenNoScrollBody');
    setDisplayModal(false);
  };
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backgoundModalColor">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-hide="popup-modal"
            onClick={handleCloseModal}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="flex items-center flex-col p-6 text-center">
            <svg
              className="mx-auto mb-4 text-gray-400 w-14 h-14"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <div className="mb-5 text-lg font-normal text-gray-500 space-y-2">
              <p>
                L'adresse mail <span className="font-semibold underline">{tryEmail}</span> que vous avez saisie est déjà
                utilisée par un autre utilisateur. Pour des raisons de sécurité et afin de garantir l'identité du
                signataire en signataire électronique, il n'est pas possible de partager une adresse email.
              </p>
              <p>
                Si vous souhaitez malgré tout permettre le partage d'une adresse, il existe une solution de
                contournement. Les principaux services de mail (Google et Microsoft) permettent d'utiliser des alias de
                mail en ajoutant +1, +2 etc... après l'adresse email.
              </p>
              <p>
                Attention, cette méthode ne fonctionne pas forcément avec tous les services, nous vous invitons à tester
                l'adresse en amont pour vérifier.
              </p>
              <p className="font-semibold underline">
                Si vous souhaitez l'appliquer ici, nous renseignerons l'adresse suivante pour ce contact :
                <span className="font-semibold underline">{proposeMail}</span>
              </p>
            </div>
            <div className="flex items-baseline">
              <input
                type="checkbox"
                name=""
                id="consent-choice"
                onChange={() => setConsent(!consent)}
                checked={consent}
              />
              <label htmlFor="consent-choice">
                Oui, j'ai bien compris et je veux modifier l'adresse par :{' '}
                <span className="font-semibold underline">{proposeMail}</span>
              </label>
            </div>
            <button
              data-modal-hide="popup-modal"
              type="button"
              disabled={!consent}
              className={`text-white ${consent ? 'bg-blue-500' : 'bg-gray-200'} hover:bg-gray-200 ${
                consent && 'hover:text-black'
              } font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}
              onClick={handleValidateAction}>
              Je tente le coup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalProposeEmailChange;
