import React, { useEffect } from 'react';
import LoadingButton from '../../../components/LoadingButton/LoadingButton';
const ConfirmationModal = ({ setDisplay, handleValidate, loading }) => {
  const handleCloseModal = () => {
    document.body.classList.remove('ModalOpenNoScrollBody');
    setDisplay(false);
  };
  useEffect(() => {
    const escapeClose = (e) => {
      if (e.keyCode === 27) {
        handleCloseModal();
      }
    };
    window.addEventListener('keydown', escapeClose);
    return () => window.removeEventListener('keydown', escapeClose);
  }, []);
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backgoundModalColor">
      <div className="relative w-full max-w-lg max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-hide="popup-modal"
            onClick={handleCloseModal}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <svg
              aria-hidden="true"
              className="mx-auto mb-4 text-gray-400 w-14 h-14"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500">
              En envoyant ce formulaire, la convention qui avait été générée précédemment sera supprimée et remplacée
              par une nouvelle avec les informations que vous venez de compléter.{'\n'} L'étudiant passera à l'étape
              suivante selon le paramétrage de ce dispositif: en attente de votre validation ou directement à l'étape
              suivante.
            </h3>
            <div className="flex w-full justify-center">
              <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                onClick={handleCloseModal}>
                Annuler et garder la convention précédente
              </button>
              <LoadingButton
                loading={loading}
                handleSubmit={handleValidate}
                btnText={'Oui, remplacer la convention'}
                btnColor={'bg-blue-500'}
                btnTextColor={'text-white'}
                roundeProp={'rounded-lg mx-2.5'}
                hoverProp={'hover:bg-gray-200 hover:text-black'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
