import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/files_vie_asso';

const PostFilesAsso = (statuts, proces, recepisse, budget, planning, pending, user) => {
  return axios.post(
    API_URL,
    {
      statuts: statuts,
      proces_verbal: proces,
      recepisse: recepisse,
      budget: budget,
      planning: planning,
      pending: pending,
      user_id: user
    },
    { headers: authHeader() }
  );
};

const GetOneFilesAsso = (id) => {
  return axios.get(
    API_URL +
      '?filter[id][_eq]=' +
      id +
      '&fields=id,statuts,proces_verbal,recepisse,budget,planning,soutenance_1,soutenance_2,finalReport.*.*',
    { headers: authHeader() }
  );
};

const DeleteOneFilesAsso = (filesAssoId) => {
  return axios.delete(API_URL + '/' + filesAssoId, { headers: authHeader() });
};

const FormatGetOneFilesAsso = (filesAsso, setFilesAsso, id) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await (await GetOneFilesAsso(id)).data.data;

      data.map((item) => {
        list.push(item);
        return item;
      });
      setFilesAsso(list[0]);
    };
    MakeList();
  }, []);
  return filesAsso;
};

const getSomeFilesAsso = (arrayId) => {
  let string = '';
  arrayId.map((id, index) => (string = string + `filter[_or][${index}][id][_eq]=` + id + '&'));
  return axios.get(
    API_URL +
      `?${string}&fields=id,date_created,statuts,proces_verbal,recepisse,bugdet,planning,soutenance_1,user_id.first_name,user_id.last_name,user_id.group.group_id.name`,
    { headers: authHeader() }
  );
};

const patchFilesAssoSoutenance = (idFilesVieAsso, fileId, soutenance_number) => {
  const objPatch = soutenance_number === 1 ? { soutenance_1: fileId } : { soutenance_2: fileId };
  return axios.patch(API_URL + '/' + idFilesVieAsso, objPatch, { headers: authHeader() });
};

const FilesAssoService = {
  PostFilesAsso,
  GetOneFilesAsso,
  DeleteOneFilesAsso,
  FormatGetOneFilesAsso,
  getSomeFilesAsso,
  patchFilesAssoSoutenance
};

export default FilesAssoService;
