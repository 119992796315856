import React from 'react';
type MyFile = {
  title: string;
  file: File;
  index: number;
};
type Props = {
  file: MyFile;
  handleChangeInFile: (index: number, type: string, value: File | string) => void;
  totalLength: number;
  lastToComplete: boolean;
  handleDelete: (index: number) => void;
  childDispoName: string;
};
const File = ({ file, handleChangeInFile, totalLength, lastToComplete, handleDelete, childDispoName }: Props) => {
  const regex = new RegExp(`Bilan-\\d+_${childDispoName}_`, 'g');
  return (
    <div
      className="FillingDocCard px-5 py-5 w-full shadow ring-1 ring-black ring-opacity-5 rounded-sm bg-white relative"
      key={file.index}>
      <button
        //   disabled={loading}
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
        onClick={() => handleDelete(file.index)}>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"></path>
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div
        className={`flex mb-8 w-2/3 ${
          totalLength - 1 === file.index && lastToComplete ? 'border-2 border-red-600 rounded-lg border-dashed' : ''
        }`}>
        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
          Bilan-{file.index}_{childDispoName}_
        </span>
        <input
          type="text"
          id="website-admin"
          className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-1/2 text-sm border-gray-300 p-2.5"
          placeholder="titre du document"
          onChange={(e) => handleChangeInFile(file.index, 'title', e.target.value)}
          value={file.title.replace(regex, '')}
        />
      </div>
      <div className="flex justify-center items-center w-full ">
        <label
          className={`flex flex-col justify-center items-center px-10 bg-gray-50 rounded-lg border-2 border-dashed cursor-pointer hover:bg-gray-100 ${
            totalLength - 1 === file.index && lastToComplete ? 'border-red-600' : 'border-gray-300'
          }`}>
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="mb-3 w-10 h-10 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
            </svg>
            <div>
              <p className="text-sm text-gray-500">
                <span className="font-semibold">Cliquer pour uploader</span> ou glisser et déposer
              </p>
              <p className="flex justify-center text-sm text-gray-500">images, pdf ou classeurs excel</p>
            </div>
            <div className="flex justify-center text-sm text-gray-500 pt-4 font-semibold">
              {file.file && <p>{file.file.name}</p>}
            </div>
          </div>
          <input
            onChange={(e) => handleChangeInFile(file.index, 'file', e.target.files[0])}
            accept=".pdf,image/*,.csv,.xlsx,.xls"
            id="dropzone-file"
            type="file"
            className="hidden"
          />
        </label>
      </div>
    </div>
  );
};

export default File;
