import React, { useState } from 'react';
import { StateMachineProvider, createStore } from 'little-state-machine';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import FormButtons from './FormButtons';
import LoadData from './LoadData';
import FormButtonsModify from './FormButtonsModify';

createStore(
  {
    step1: {},
    step2: {},
    step3: {},
    errors: {},
    success: {}
  },
  {
    persist: 'none'
  }
);

const ConventionForm = ({ dispo, userDispo, blur, setBlur, userModify = null, adminModify = false }) => {
  const [organization, setOrganization] = useState();
  const step1Ref = React.createRef();
  const step2Ref = React.createRef();
  const step3Ref = React.createRef();
  return (
    <StateMachineProvider>
      <LoadData userDispo={userDispo}>
        {({ organizationAnswer, missionAnswer, conventionAnswer }) => (
          <div>
            <div className="bg-gray-50 p-10 mb-10">
              <Step1
                defaultValues={organizationAnswer}
                formRef={step1Ref}
                onSelect={setOrganization}
                userDispo={userDispo}
              />
            </div>
            <div className="bg-gray-50 p-10 mb-10">
              <Step2
                defaultValues={missionAnswer}
                formRef={step2Ref}
                userDispo={userDispo}
                blur={blur}
                setBlur={setBlur}
              />
            </div>
            <div className="bg-gray-50 p-10 mb-10">
              <Step3
                defaultValues={conventionAnswer}
                formRef={step3Ref}
                userDispo={userDispo}
                organization={organization}
              />
            </div>
            <div className="flex justify-center">
              {adminModify ? (
                <FormButtonsModify
                  step1Ref={step1Ref}
                  step2Ref={step2Ref}
                  step3Ref={step3Ref}
                  userDispo={userDispo}
                  dispo={dispo}
                  userModify={userModify}
                />
              ) : (
                <FormButtons
                  step1Ref={step1Ref}
                  step2Ref={step2Ref}
                  step3Ref={step3Ref}
                  userDispo={userDispo}
                  dispo={dispo}
                />
              )}
            </div>
          </div>
        )}
      </LoadData>
    </StateMachineProvider>
  );
};

export default ConventionForm;
