import React, { useEffect, useState, useContext } from 'react';
import { useStateMachine } from 'little-state-machine';
import AnswerFormService from '../../../oldService/answerFormService';
import DocumentsServices from '../../../oldService/documentServices';
import PdfServices, { PdfConvertDataConventionV2, PdfConvertDataMissionV2 } from '../../../oldService/pdfServices';
import { PunctualFilesServices } from '../../../oldService/punctualFilesService';
import UserDispoService from '../../../oldService/userDispoService';
import { findNextStep } from '../../../oldService/steps/findNextStep';
import UserService from '../../../oldService/userService';
import DispoStepService from '../../../oldService/dispoStepService';
import { AuthContext } from '../../../AuthProvider';
import BasicErrorMessage from '../../ErrorMessage/basic/BasicErrorMessage';

const FormButtons = ({ step1Ref, step2Ref, step3Ref, userDispo, dispo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { state } = useStateMachine();
  const { user } = useContext(AuthContext);
  const templateId = '1wsOGepV2DFEgoZtX5qhNdA5H1CiTOh0SEEGuoEVFQUw';

  const templateIdFR = '1CNN_xdlwCgluoJG5gUX1ieocSSOqqKLLyxRyl_Rbhwo';
  const templateIdEN = '1pZD2mFR-F3fbpZeaTAdSrTIhlhk_cBpthqep13Tk2FQ';

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');

  useEffect(() => {
    if (state.success.step1 && state.success.step2 && state.success.step3) {
      submit();
    }
  }, [state.success]);

  const schoolYear = user.group.slice(-1)[0]
    ? user.group.slice(-1)[0]?.group_id?.parentgroup !== null
      ? user.group.slice(-1)[0]?.group_id?.parentgroup.name
      : ''
    : '';
  const groupName = user.group.slice(-1)[0] ? user.group.slice(-1)[0]?.group_id.name : '';
  const groups = schoolYear !== '' ? schoolYear + ',' + groupName : groupName;

  const [punctual, setPunctual] = useState([]);
  PunctualFilesServices.FormatGetOnePunctualFiles(punctual, setPunctual, userDispo.punctual_files_id);

  const [dispoStep, setDispoStep] = useState([]);
  DispoStepService.FormatGetAllDispoStepDispo(dispoStep, setDispoStep, id);

  const step1 = async ({ data, raw }) => {
    try {
      const answerForm = (await AnswerFormService.getOneAnswerForm(id, user.id, 'organization')).data.data;
      await PunctualFilesServices.PatchOrgaContactPunctualFiles(
        userDispo.punctual_files_id,
        raw.organization.value.id,
        null
      );

      if (answerForm[0]) {
        await AnswerFormService.DeleteAnserForm(answerForm[0].id);
      }
      return await AnswerFormService.PostAnswerForm(data, user.id, id, 'organization', raw);
    } catch (e) {
      console.error(e);
    }
  };
  const step2 = async ({ data, raw }) => {
    try {
      const answerForm = (await AnswerFormService.getOneAnswerForm(id, user.id, 'mission')).data.data;
      if (answerForm[0]) {
        await AnswerFormService.DeleteAnserForm(answerForm[0].id);
      }
      return await AnswerFormService.PostAnswerForm(data, user.id, id, 'mission', raw);
    } catch (e) {
      console.error(e);
    }
  };

  const step3 = async ({ data, raw }) => {
    try {
      const contact1Id = data.find((d) => d.index === 0.5);
      const contact2Id = data.find((d) => d.index === 1.5);
      const userPhone = data.find((d) => d.index === 7);

      if (contact1Id) {
        await PunctualFilesServices.PatchPunctualFilesContact(punctual[0].id, contact1Id.data);
      }

      if (contact2Id) {
        await PunctualFilesServices.PatchPunctualFilesContactEval(punctual[0].id, contact2Id.data);
      }

      if (userPhone) {
        await UserService.PatchUser(user.id, { phones: userPhone.data });
      }

      const answerForm = (await AnswerFormService.getOneAnswerForm(id, user.id, 'convention')).data.data;
      if (answerForm[0]) {
        await AnswerFormService.DeleteAnserForm(answerForm[0].id);
      }
      return await AnswerFormService.PostAnswerForm(data, user.id, id, 'convention', raw);
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async () => {
    let requestError = false;
    if (Object.keys(state.errors.step2).length === 0 && Object.keys(state.errors.step3).length === 0) {
      setIsLoading(true);

      await step1(state.step1);
      const step2Response = await step2(state.step2);
      const step3Response = await step3(state.step3, state.step2);

      // Save document and generate pdf for mission
      try {
        const pdfName = dispo.name + '_' + groups + '-' + user.last_name + '-' + user.first_name + '_Fiche_mission';
        const resDoc = await DocumentsServices.PostDocument(
          PdfConvertDataMissionV2(state.step2.data, state.step3.data, state.step1.raw, user, groups)
        );
        await PdfServices.PostPdf(resDoc.data.data.id, templateId, pdfName);
        await PunctualFilesServices.PacthPunctualFilesMission(
          punctual[0].id,
          step2Response.data.data.id,
          resDoc.data.data.id
        );
      } catch (err) {
        console.log(err);
        requestError = true;
      }

      // Save document and generate pdf for convention
      try {
        const pdfName = dispo.name + '_' + groups + '-' + user.last_name + '-' + user.first_name + '_Convention';
        const resDoc = await DocumentsServices.PostDocument(
          PdfConvertDataConventionV2(
            state.step3.data,
            state.step2.data,
            state.step1.raw,
            user,
            punctual[0],
            punctual[0].date_created.substr(0, 10),
            groups,
            state.step3.raw.french_mission
          ),
          'convention',
          null,
          state.step3.raw.french_mission
        );
        await PdfServices.PostPdf(
          resDoc.data.data.id,
          state.step3.raw.french_mission ? templateIdFR : templateIdEN,
          pdfName
        );
        await PunctualFilesServices.PatchPunctualFilesConvention(
          punctual[0].id,
          step3Response.data.data.id,
          resDoc.data.data.id
        );
      } catch (error) {
        console.log(error);
        requestError = true;
      }

      // Submit or validate step
      if (requestError) {
        setError(true);
        setIsLoading(false);
        return;
      }
      if (userDispo.step_id.tovalidate) {
        await UserDispoService.PatchUserDispoValidate(userDispo.id, null, true);
        window.location.reload();
      } else {
        await UserDispoService.PatchUserDispoStep(
          userDispo.id,
          findNextStep(dispoStep.find((item) => item.step_id === userDispo.step_id.id).order, dispoStep).step_id
        );
        window.location.reload();
      }
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    step1Ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    step2Ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    step3Ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const onSave = async () => {
    setIsLoading(true);
    await step1(state.step1);
    await step2(state.step2);
    await step3(state.step3);
    setIsLoading(false);
  };

  return (
    <div>
      <BasicErrorMessage error={error} />
      <div className="flex justify-center">
        {isLoading ? (
          <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
        ) : (
          <>
            <button
              className="flex justify-center items-center mx-5 px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
              onClick={async () => {
                await onSave();
              }}>
              <p>Sauvegarder</p>
            </button>
            <button
              className="flex justify-center items-center mx-5 px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
              onClick={async () => {
                await onSubmit();
              }}>
              <p>Soumettre</p>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default FormButtons;
