export const PROD = process.env.REACT_APP_PROD === 'true';

//PROD / PREPROD
export const API = process.env.REACT_APP_DIRECTUS_API_URL;

function authHeader() {
  const userStr = localStorage.getItem('token');
  let user = null;

  if (userStr) user = JSON.parse(userStr);

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return { Authorization: '' };
  }
}

export default authHeader;
