import React, { useState } from 'react';
import HelpIcon from '../HelpIcon/HelpIcon';

function DropDownHelp() {
  const [showOptions, setShowOptions] = useState(false);
  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="DropDownHelp">
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={handleClick}
            className="inline-flex justify-center items-center rounded-sm px-4 py-2 font-medium text-gray-600 hover:text-blue-500">
            <HelpIcon />
            <p>Besoin d'aide</p>
          </button>
        </div>
        {showOptions && (
          <div
            className="absolute right-0 mt-2 w-56 origin-top-right rounded bg-white shadow-lg border focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1">
            <div className="p-4" role="none">
              <div className="flex flex-col items-center">
                <p>Besoin d'aide ?</p>
                <p>
                  Contacter l'assistance{' '}
                  <a href="mailto:esdes.vieetudiante@univ-catholyon.fr" className="text-blue-500 underline">
                    ici
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropDownHelp;
