import React from 'react';
import PeopleListAddModal from '../../oldComponents/PeopleListAddModal';
import BigAnimateButton from '../BigAnimateButton';
import { useNavigate } from 'react-router-dom';

const RightPartHeaderAdmin = ({
  excludedUsers,
  dispoId,
  firstStep,
  handleDisplayModalCreateEvent,
  handleDisplayModif,
  handleDisplayModalExport,
  dispoChildren,
  dispoType,
  parentDispo,
  event
}) => {
  const navigate = useNavigate();
  const handleRedirectionDocumentPage = () => {
    dispoType === 'asso'
      ? navigate(`/user/asso/documents?id=${dispoId}`, { state: { childDispo: dispoChildren } })
      : navigate(`/user/punctual/documents?id=${dispoId}`);
  };
  const getAnchorLink = (targetDivId: string) => {
    const a = document.createElement('a');
    a.href = '#' + targetDivId;
    a.click();
  };
  return (
    <div className="flex flex-col px-10">
      <span className="text-xl font-bold leading-normal mt-0 mb-2 ">Actions rapides :</span>
      {dispoType === 'event' && (
        <BigAnimateButton
          handleOnClick={handleDisplayModalCreateEvent}
          btnText={`Modifier la date et l'heure : ${new Date(`${event.startdate}T${event.start_hourly}`).toLocaleString(
            undefined,
            {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            }
          )} --> ${new Date(`${event.enddate}T${event.end_hourly}`).toLocaleString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
        </BigAnimateButton>
      )}
      <div className="grid grid-cols-2 gap-4 justify-items-start items-center py-5">
        <PeopleListAddModal
          excludedUsers={excludedUsers}
          onAdded={() => {
            window.location.reload();
          }}
          dispoId={dispoId}
          firstStep={firstStep}
          AdminShortcut={true}
        />
        {dispoType !== 'event' && (
          <BigAnimateButton handleOnClick={handleDisplayModalCreateEvent} btnText="Créer un évènement">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
          </BigAnimateButton>
        )}
        <BigAnimateButton handleOnClick={handleDisplayModif} btnText=" Modifier le dispositif">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
          </svg>
        </BigAnimateButton>
        <BigAnimateButton handleOnClick={handleDisplayModalExport} btnText="Exporter des éléments">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
          </svg>
        </BigAnimateButton>
        {parentDispo && (
          <>
            <BigAnimateButton
              handleOnClick={() => handleRedirectionDocumentPage()}
              btnText="Voir les documents étudiant">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5" />
                <path d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z" />
              </svg>
            </BigAnimateButton>
            <BigAnimateButton handleOnClick={() => getAnchorLink('pipeline')} btnText="Voir l'avancement">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <line x1="18" y1="20" x2="18" y2="10"></line>
                <line x1="12" y1="20" x2="12" y2="4"></line>
                <line x1="6" y1="20" x2="6" y2="14"></line>
              </svg>
            </BigAnimateButton>
          </>
        )}
      </div>
    </div>
  );
};

export default RightPartHeaderAdmin;
