import React, { useState } from 'react';
import UserDispoService from '../../oldService/userDispoService';

import './NoteContent.css';

function NoteContent({ note, setNote, id }) {
  const [display, setDisplay] = useState(false);

  const handleSetNote = (e) => {
    setNote(e.target.value);
  };

  const patchNoteBtn = () => {
    setDisplay(false);
    UserDispoService.patchUserDispoNote(id, note);
  };

  return (
    <div className="NoteContent">
      <h2 className="font-bold text-xl text-blue-700/90 pb-5">Note à moi-même</h2>
      {!display ? (
        <div className="flex flex-col items-center justify-center px-10 space-y-5">
          <p className="text-gray-900 text-sm">
            {note ? note : 'Cette note reste personnelle, vous êtes la seule personne capable de la consulter.'}
          </p>
          <div>
            <button
              className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
              onClick={() => {
                setDisplay(true);
              }}>
              Ajouter une note
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-3">
          <div className="space-y-5 flex flex-col items-center justify-center">
            <textarea
              placeholder="Remplir le champ ci-dessous..."
              className="w-10/12 h-60 px-2 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5"
              defaultValue={note}
              onChange={handleSetNote}
            />
            <div className="flex items-center pl-10">
              <button
                className="bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:bg-blue-500 hover:text-white"
                onClick={patchNoteBtn}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoteContent;
