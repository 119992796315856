import axios from 'axios';
import authHeader from './authHeader';

//PROD / PREPROD
export const API_AKANEMA = process.env.REACT_APP_BACKEND_API_URL;

const ValidStep = (id, token) => {
  return axios.post(
    API_AKANEMA + '/api/user-dispositifs/' + id + '/validate-step',
    {
      token: token
    },
    { headers: authHeader() }
  );
};

const Sign = (documentId, punctualId, template) => {
  return axios.post(API_AKANEMA + '/api/documents/' + documentId + '/sign', {
    punctual_files: punctualId,
    template: template
  });
};

const AkanemaApiService = {
  ValidStep,
  Sign
};

export default AkanemaApiService;
