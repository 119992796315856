import axios from 'axios';
import authHeader, { API } from './authHeader';
import { useEffect } from 'react';

const API_URL = API + 'items/document';

const PostDocument = (data, type, userId, french) => {
  return axios.post(
    API_URL,
    {
      data: data,
      type: type,
      author: userId,
      french: french
    },
    { headers: authHeader() }
  );
};

const PatchSignatureStateDocument = (documentId, value) => {
  return axios.patch(API_URL + '/' + documentId, { signature_state: value }, { headers: authHeader() });
};

const PatchContactSignatureStateDocument = (documentId, value) => {
  return axios.patch(API_URL + '/' + documentId, { contact_signature_state: value }, { headers: authHeader() });
};

const PatchUserSignatureDocument = (documentId, iframeLink) => {
  return axios.patch(API_URL + '/' + documentId, { user_signature_link: iframeLink }, { headers: authHeader() });
};

const PatchContactSignatureDocument = (documentId, iframeLink) => {
  return axios.patch(API_URL + '/' + documentId, { contact_signature_link: iframeLink }, { headers: authHeader() });
};

const PatchAuthorDocument = (documentId, authorId) => {
  return axios.patch(API_URL + '/' + documentId, { author: authorId }, { headers: authHeader() });
};

const PatchTypeDocument = (documentId, type) => {
  return axios.patch(API_URL + '/' + documentId, { type: type }, { headers: authHeader() });
};

const GetAllDocuments = () => {
  return axios.get(API_URL + '?fields=attachment_urls,author.first_name,author.last_name,author.email', {
    headers: authHeader()
  });
};

const GetAllDocumentsUser = (userId) => {
  return axios.get(
    API_URL + '?fields=attachment_urls,author.first_name,author.last_name,author.email&filter[author][_eq]=' + userId,
    { headers: authHeader() }
  );
};

const FormatGetAllDocuments = (docList, setDocList) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await DocumentsServices.GetAllDocuments();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDocList(list);
    };
    MakeList();
  }, []);
  return docList;
};

const FormatGetAllDocumentsUser = (docList, setDocList, userId) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await DocumentsServices.GetAllDocumentsUser(userId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setDocList(list);
    };
    MakeList();
  }, []);
  return docList;
};

const PatchFrenchDocument = (id, french) => {
  return axios.patch(API_URL + '/' + id, {
    french: french
  });
};

const DeleteDocument = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const DocumentsServices = {
  PostDocument,
  PatchAuthorDocument,
  PatchTypeDocument,
  GetAllDocuments,
  GetAllDocumentsUser,
  FormatGetAllDocuments,
  FormatGetAllDocumentsUser,
  DeleteDocument,
  PatchUserSignatureDocument,
  PatchContactSignatureDocument,
  PatchSignatureStateDocument,
  PatchContactSignatureStateDocument,
  PatchFrenchDocument
};

export default DocumentsServices;
