import React, { useState } from 'react';
import UserDispoService from '../../oldService/userDispoService';
import { useNavigate } from 'react-router-dom';
import { UserDispo } from '../../usualTypes/BasicTypes';
import BasicErrorMessage from '../ErrorMessage/basic/BasicErrorMessage';
import LoadingButton from '../LoadingButton/LoadingButton';

const ModalAdminPunctualActionPipe = ({ setDisplayModal, student, dispo, setBlur }) => {
  const [userDispo, setUserDispo] = useState<UserDispo>();
  const [requestError, setRequestError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  UserDispoService.FormatGetUserDispoDispoList(userDispo, setUserDispo, dispo.id, student.id);
  const handleCloseModal = () => {
    setBlur(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
    setDisplayModal(false);
  };
  const handleGoToModify = async () => {
    document.body.classList.remove('ModalOpenNoScrollBody');
    if (student.step.name === 'Signature') {
      let error = false;
      setLoading(true);
      try {
        await UserDispoService.moveAStudentToPrevStep(userDispo?.id);
      } catch (err) {
        error = true;
        console.log(err);
      }
      if (!error) {
        setLoading(false);
        window.location.reload();
        handleCloseModal();
      } else {
        setLoading(false);
        console.log('erro');
        setRequestError(true);
      }
    } else {
      navigate('/admin/punctual/modifForm', { state: { userDispo: userDispo, dispo: dispo } });
    }
  };
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backgoundModalColor">
      {userDispo && (
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="popup-modal"
              onClick={handleCloseModal}>
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="flex items-center flex-col p-6 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-14 h-14"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              {student.step.name === 'Signature' ? (
                <span className="mb-5 text-lg font-normal text-gray-500">
                  L'étudiant <span className="font-semibold underline">{`${student.name}`}</span> n'a pas encore reçu sa
                  convention signé par les deux parties. Vous pouvez annuler la signature et remettre l'étudiant dans
                  l'étape précédente. <br />
                  <span className="text-red-700 uppercase">
                    cette action entrainera une nouvelle génération de signature qui sera décompté de votre quota{' '}
                  </span>
                </span>
              ) : (
                <span className="mb-5 text-lg font-normal text-gray-500">
                  L'étudiant <span className="font-semibold underline">{`${student.name}`}</span> n'a pas encore soumis
                  son formulaire de convention, vous pouvez si vous le souhaitez, modifier et soumettre cette étape à sa
                  place en cliquant sur le bouton modifier. Cette action récupèrera les éventuelles réponses qu'il à
                  déjà pu enregistrer.
                </span>
              )}
              <BasicErrorMessage error={requestError} />
              <LoadingButton
                loading={loading}
                handleSubmit={handleGoToModify}
                btnText={student.step.name === 'Signature' ? "Changer l'étudiant d'étape" : 'Modifier'}
                btnColor={'bg-blue-500'}
                btnTextColor={'text-white'}
                roundeProp={'rounded-lg'}
                hoverProp={'hover:bg-gray-200 hover:text-black'}
              />
              {/* <button
                data-modal-hide="popup-modal"
                type="button"
                className="text-white bg-blue-500 hover:bg-gray-200 hover:text-black font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={handleGoToModify}>
                {student.step.name === 'Signature' ? "Changer l'étudiant d'étape" : 'Modifier'}
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalAdminPunctualActionPipe;
