import axios from 'axios';
import { useEffect } from 'react';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/punctual_files';

const PostPunctualFiles = (userId, projectAnswerId, projectDocId) => {
  return axios.post(
    API_URL,
    {
      user: userId,
      project_answer: projectAnswerId,
      project_doc: projectDocId
    },
    { headers: authHeader() }
  );
};

const PatchOrgaContactPunctualFiles = (punctualId, orgaId, contactId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      organization_id: orgaId,
      contact: contactId
    },
    { headers: authHeader() }
  );
};

const PatchPunctualFilesContact = (punctualId, contactId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      contact: contactId
    },
    { headers: authHeader() }
  );
};

const PatchPunctualFilesContactEval = (punctualId, contactId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      contact_eval: contactId
    },
    { headers: authHeader() }
  );
};

const PatchOrgaContactListPunctualFiles = (punctualId, orgaId, contactListId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      organization_id: orgaId,
      contact_list: contactListId
    },
    { headers: authHeader() }
  );
};

const PacthPunctualFilesMission = (punctualId, answerId, docId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      mission_answer: answerId,
      mission_doc: docId
    },
    { headers: authHeader() }
  );
};

const PatchPunctualFilesConvention = (punctualId, answerId, docId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      convention_answer: answerId,
      convention_doc: docId
    },
    { headers: authHeader() }
  );
};

const GetOnePunctualFiles = (id) => {
  return axios.get(
    API_URL +
      '?filter[id][_eq]=' +
      id +
      '&fields=id,project_answer,project_doc.id,user,project_doc.attachment_urls,mission_doc.id,mission_answer,mission_doc.attachment_urls,organization_id.id,organization_id.name,organization_id.numero_siret_rna,organization_id.adress,organization_id.postal_code,organization_id.city,organization_id.country,organization_id.beneficiaries,contact.id,contact.first_name,contact.last_name,contact.email,contact.function,contact.phones,contact_list.directus_users_id.id,contact_list.directus_users_id.first_name,contact_list.directus_users_id.last_name,contact_list.directus_users_id.email,contact_list.directus_users_id.phones,contact_list.directus_users_id.function,contact_list.id,mission_answer.answer,mission_answer.id,date_created,convention_doc.id,convention_doc.attachment_urls,convention_answer,convention_doc.user_signature_link,convention_doc.contact_signature_link,convention_doc.signature_state,convention_doc.contact_signature_state,convention_doc.signed_file,convention_doc.french,convention_doc.data,evaluation_doc.attachment_urls,evaluation',
    { headers: authHeader() }
  );
};

const GetAllPunctualFilesContact = (id) => {
  return axios.get(
    API_URL +
      '?filter[contact][_eq]=' +
      id +
      '&fields=id,user.id,user.first_name,user.last_name,user.email,convention_doc.id,convention_doc.contact_signature_link,convention_doc.contact_signature_state,organization_id,convention_doc.signed,evaluation,evaluate',
    { headers: authHeader() }
  );
};

const GetAllPunctualFilesDoc = () => {
  return axios.get(
    API_URL +
      '?fields=id,user.first_name,user.last_name,project_doc.id,project_doc.attachment_urls,project_doc.name,mission_doc.id,mission_doc.attachment_urls,mission_doc.name,convention_doc.id,convention_doc.attachment_urls,convention_doc.name,convention_doc.signed_file,evaluation_doc.name,evaluation_doc.attachment_urls,evaluation_doc.id&limit=-1',
    { headers: authHeader() }
  );
};

const FormatGetAllPunctualFilesDoc = (punctuals, setPunctuals) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllPunctualFilesDoc();
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setPunctuals(list);
    };
    Makelist();
  }, []);
  return punctuals;
};

const FormatGetAllPunctualFilesContact = (punctuals, setPunctuals, contactId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllPunctualFilesContact(contactId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setPunctuals(list);
    };
    Makelist();
  }, []);
  return punctuals;
};

const GetAllPunctualFilesContactEvaluate = (id) => {
  return axios.get(
    API_URL +
      '?filter={"_and":[{"contact_eval":{"_eq":"' +
      id +
      '"}},{"evaluate":{"_eq":"true"}},{"convention_answer":{"dispositif":{"archived":{"_neq":"true"}}}}]}&fields=id,user.id,user.first_name,user.last_name,user.email,convention_doc.id,convention_doc.contact_signature_link,convention_doc.contact_signature_state,organization_id,convention_doc.signed,convention_answer.dispositif.archived,evaluation,evaluate,user.group.group_id.name,user.group.group_id.parentgroup.name,evaluation.id,evaluation.total',
    { headers: authHeader() }
  );
};

const FormatGetAllPunctualFilesContactEvaluate = (punctuals, setPunctuals, contactId) => {
  useEffect(() => {
    const list = [];

    const Makelist = async () => {
      const data = await GetAllPunctualFilesContactEvaluate(contactId);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setPunctuals(list);
    };
    Makelist();
  }, []);
  return punctuals;
};

const FormatGetOnePunctualFiles = (punctual, setPunctual, id) => {
  useEffect(() => {
    const list = [];

    const MakeList = async () => {
      const data = await PunctualFilesServices.GetOnePunctualFiles(id);
      data.data.data.map((item) => {
        list.push(item);
        return item;
      });
      setPunctual(list);
    };
    MakeList();
  }, []);
  return punctual;
};

const PatchEvaluate = (id, value) => {
  return axios.patch(API_URL + '/' + id, { evaluate: value }, { headers: authHeader() });
};

const PatchEvaluation = (punctualId, evalId) => {
  return axios.patch(API_URL + '/' + punctualId, { evaluation: evalId }, { headers: authHeader() });
};

const DeletePunctualFiles = (id) => {
  return axios.delete(API_URL + '/' + id, { headers: authHeader() });
};

const PatchPunctualFilesEvaluation = (punctualId, docId) => {
  return axios.patch(
    API_URL + '/' + punctualId,
    {
      evaluation_doc: docId
    },
    { headers: authHeader() }
  );
};
export const PunctualFilesServices = {
  PostPunctualFiles,
  PatchPunctualFilesContact,
  PatchPunctualFilesContactEval,
  PatchOrgaContactPunctualFiles,
  PatchOrgaContactListPunctualFiles,
  PacthPunctualFilesMission,
  PatchPunctualFilesConvention,
  GetOnePunctualFiles,
  FormatGetOnePunctualFiles,
  DeletePunctualFiles,
  GetAllPunctualFilesContact,
  FormatGetAllPunctualFilesContact,
  FormatGetAllPunctualFilesContactEvaluate,
  PatchEvaluate,
  PatchEvaluation,
  GetAllPunctualFilesDoc,
  FormatGetAllPunctualFilesDoc,
  PatchPunctualFilesEvaluation
};
