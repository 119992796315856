import React from 'react';
import StarIcon from '../StarIcon/StarIcon';
import { useNavigate } from 'react-router-dom';

function RatingTableList(props) {
  const navigate = useNavigate();
  const handleRedirect = (user) => {
    props.asso
      ? navigate('/evaluation?dispo=asso', {
          state: { userEvaluate: user, responsible: props.responsible, dispoId: props.dispoId, step: props.step }
        })
      : navigate('/evaluation?dispo=punctual', { state: { userEvaluate: user } });
  };
  return (
    <div className="RatingTableLis">
      <div className="flex flex-col max-h-96">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-sm">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Nom
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Groupe
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Note
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.data.map((person) => (
                    <tr key={person.id}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.team?.group_id?.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.evaluation_id === null || person.evaluation_id === undefined ? (
                          <span className="text-red-500">Non noté</span>
                        ) : (
                          <span>
                            <span className="text-blue-700">{person.evaluation_id.total}</span>
                            {props.asso ? '/20' : '/100'}
                          </span>
                        )}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                        <button
                          className="flex items-center w-1/3 space-x-1 font-bold bg-gray-200 px-3 py-2 rounded-sm shadow-sm ring-1 ring-black ring-opacity-5 hover:text-white hover:bg-blue-500"
                          onClick={() => handleRedirect(person)}>
                          <StarIcon />
                          <span>Noter</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingTableList;
