import React, { useState } from 'react';
import DispDirFileService from '../../oldService/dispDirFile';
import FillingDocCard from '../FillingDocCard/FillingDocCard';
import FileService from '../../oldService/fileService';

import './ModalAddDoc.css';
import Select from 'react-select';
import LoadingButton from '../LoadingButton/LoadingButton';

function ModalAddDoc({ userList = [], ...props }) {
  const [file, setFile] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const formData = new FormData();

  const handleDisplayClose = () => {
    setLoading(false);
    props.setBlur(false);
    props.setDisplayAddDoc(false);
    document.body.classList.remove('ModalOpenNoScrollBody');
  };

  const onSubmit = async () => {
    if (file) {
      setLoading(true);
      formData.append('title', file.name);
      formData.append('file', file);

      const result = await FileService.fileUpdate(formData);
      if (selectedOption) {
        await DispDirFileService.PostDispFile(props.dispoId, result.data.data.id, selectedOption);
        props.setReload((prev) => prev + 1);
        handleDisplayClose();
      } else {
        await DispDirFileService.PostDispFile(props.dispoId, result.data.data.id);
        props.setReload((prev) => prev + 1);
        handleDisplayClose();
      }
    }
  };

  const options = userList.map((item) => {
    return { value: item.id, label: item.name };
  });

  return (
    <div className="">
      <div
        id="authentication-modal"
        className="overflow-y-scroll overflow-x-hidden fixed z-50 w-full md:inset-0 flex justify-center padding-custom-modal backgoundModalColor">
        <div className="ModalAddDoc bg-white rounded shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={handleDisplayClose}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-6 px-6 space-y-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900">Ajouter un document</h3>
            <FillingDocCard description={'Description'} accept={'.pdf'} setFile={setFile} file={file} />
            {userList && (
              <div className="MemberSelect">
                <Select
                  id="select-member"
                  placeholder={'Choisir nom...'}
                  options={[{ value: '', label: 'Pour tous les participants' }, ...options]}
                  value={options.find((item) => item.value === selectedOption)}
                  onChange={async (e) => {
                    setSelectedOption(e.value);
                  }}
                />
              </div>
            )}
            <div className="flex justify-center">
              <LoadingButton handleSubmit={() => onSubmit()} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAddDoc;
