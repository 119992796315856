import React, { useEffect } from 'react';
import { useState } from 'react';
import { ContactServices } from '../../oldService/contactServices';
import FormService from '../../oldService/formService';
import { OrgaDirUsers1Services } from '../../oldService/organizationDirectusUsers1Services.js';
import ChooseProject from '../ChooseProject/ChooseProject';
import ModalProposeEmailChange from '../../components/ModalProposeEmailChange';

const ContactForm = ({
  setStep2Id,
  step1Id,
  setBlur,
  setDisplay,
  setContactList,
  contactList,
  convention,
  setEmailUsed,
  onContactAdded
}) => {
  const [answer, setAnswer] = useState([]);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [displayModalChangeEmail, setDisplayModalChangeEmail] = useState(false);

  const [form, setForm] = useState([]);
  FormService.FormatGetForm(form, setForm, 'Contact');

  const [loading, setLoading] = useState(false);

  const HandleClose = () => {
    setDisplay(false);
    setBlur(false);
  };

  const Obligatory = () => {
    return <span className="text-red-500">*</span>;
  };

  const HandleAnswerChange = (index, value, id) => {
    console.log(value);
    const list = [...answer];
    const existingItem = list.find((item) => item.index === index);
    if (existingItem) {
      existingItem.data = value;
    } else {
      list.push({ index: index, data: value, id: id });
    }
    setAnswer(list);
  };

  const [endProc, setEndProc] = useState(false);

  const HandleSubmit = () => {
    setError(false);
    setEmailError(false);
    setEmailUsed(false);

    answer.find((item) => item.index === 0) && answer.find((item) => item.index === 0).data !== ''
      ? answer.find((item) => item.index === 1) && answer.find((item) => item.index === 1).data !== ''
        ? answer.find((item) => item.index === 2) && answer.find((item) => item.index === 2).data !== ''
          ? answer.find((item) => item.index === 3) && answer.find((item) => item.index === 3).data !== ''
            ? answer.find((item) => item.index === 4) && answer.find((item) => item.index === 4).data !== ''
              ? answer.find((item) => item.index === 3).data.includes('.') &&
                answer.find((item) => item.index === 3).data.includes('@')
                ? setEndProc(true)
                : setEmailError(true)
              : setError(true)
            : setError(true)
          : setError(true)
        : setError(true)
      : setError(true);

    if (!error) {
      HandleAnswerChange(
        0,
        answer.find((item) => item.index === 0).data.replaceAll(' ', ''),
        answer.find((item) => item.index === 0).id
      );
      HandleAnswerChange(
        1,
        answer.find((item) => item.index === 1).data.replaceAll(' ', ''),
        answer.find((item) => item.index === 1).id
      );
      HandleAnswerChange(
        3,
        answer.find((item) => item.index === 3).data.replaceAll(' ', ''),
        answer.find((item) => item.index === 3).id
      );
    }
  };

  useEffect(() => {
    if (endProc === true) {
      let first_name = answer.find((item) => item.index === 0);
      let last_name = answer.find((item) => item.index === 1);
      let functionC = answer.find((item) => item.index === 2);
      let email = answer.find((item) => item.index === 3);
      let phones = answer.find((item) => item.index === 4);
      ContactServices.PostContact(
        first_name ? first_name.data : null,
        last_name ? last_name.data : null,
        functionC ? functionC.data : null,
        email ? email.data : null,
        phones ? phones.data : null
      )
        .then((res) => {
          console.log('1');
          OrgaDirUsers1Services.PostOrgaDirUsers1(step1Id, res.data.data.id)
            .then((res2) => {
              console.log('2');
              if (convention === true) {
                if (onContactAdded instanceof Function) {
                  setLoading(false);
                  setBlur(false);
                  setDisplay(false);
                  onContactAdded({
                    directus_users_id: {
                      addrcountry: res.data.data.addrcountry,
                      addrstreet: res.data.data.addrstreet,
                      addrzipcode: res.data.data.addrzipcode,
                      email: res.data.data.email,
                      first_name: res.data.data.first_name,
                      last_name: res.data.data.last_name,
                      function: res.data.data.function,
                      id: res.data.data.id,
                      phones: res.data.data.phones
                    },
                    id: res2.data.data.id,
                    organization_id: step1Id
                  });
                } else {
                  window.location.reload();
                }
              } else {
                setStep2Id(res.data.data.id);
                setLoading(false);
                setBlur(false);
                setDisplay(false);
                setContactList([
                  ...contactList,
                  {
                    addrcountry: res.data.data.addrcountry,
                    addrstreet: res.data.data.addrstreet,
                    addrzipcode: res.data.data.addrzipcode,
                    email: res.data.data.email,
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    function: res.data.data.function,
                    id: res.data.data.id,
                    phones: res.data.data.phones
                  }
                ]);
              }
            })
            .catch(() => {
              console.log('err2');
            });
        })
        .catch(() => {
          setEmailUsed(true);
          setDisplayModalChangeEmail(true);
          setEndProc(false);
          // setDisplay(false);
          // setBlur(false);
        });
    }
  }, [endProc]);

  return (
    <div className="space-y-10">
      {displayModalChangeEmail && (
        <ModalProposeEmailChange
          setDisplayModal={setDisplayModalChangeEmail}
          tryEmail={answer.find((item) => item.index === 3)?.data}
          HandleAnswerChange={HandleAnswerChange}
        />
      )}
      <div>
        <h2 className="font-bold text-xl text-blue-700/90">Saisir un nouveau contact</h2>
        <p className="text-gray-700">
          Ce formulaire vous sert à compléter les informations concernant le nouveau contact.
        </p>
      </div>
      <div>
        <div className="space-y-5">
          {form[0] &&
            form[0].questions_id.map((item, index) => {
              return (
                <div key={index}>
                  <div className="space-y-2">
                    <h3 className="text-lg">
                      {item.questions_id.name} <Obligatory />
                    </h3>
                    {item.questions_id.type === 'text' && (
                      <textarea
                        className="w-full p-2 h-28 border border-gray-300"
                        placeholder="À compléter"
                        onChange={(e) => {
                          HandleAnswerChange(index, e.target.value, item.questions_id.id);
                        }}
                      />
                    )}
                    {item.questions_id.type === 'choice' && (
                      <ChooseProject
                        list={item.questions_id.questions_choice_id.map((itemQues) => {
                          return itemQues.questions_choice_id.name;
                        })}
                        index={index}
                        setChoice={setAnswer}
                        choice={answer}
                        id={item.questions_id.id}
                      />
                    )}
                    {item.questions_id.type === 'url' && (
                      <input
                        type={'url'}
                        className="w-full p-2 border border-gray-300"
                        placeholder="https://"
                        onChange={(e) => {
                          HandleAnswerChange(index, e.target.value, item.questions_id.id);
                        }}
                      />
                    )}
                    {item.questions_id.type === 'description' && (
                      <div>
                        <p className="text-gray-500 text-sm pb-2">{item.questions_id.description}</p>
                        <input
                          type={'text'}
                          className="w-full p-2 border border-gray-300"
                          placeholder="À compléter"
                          onChange={(e) => {
                            HandleAnswerChange(index, e.target.value, item.questions_id.id);
                          }}
                          value={answer.find((item) => item.index === index)?.data}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="flex justify-center pt-5 -mb-5">
          {error && <p className="text-red-500 font-bold">Champ(s) requis manquant(s).</p>}
          {emailError && <p className="text-red-500 font-bold">Format de l'email non valide.</p>}
        </div>
        <div className="flex justify-center pt-10">
          {loading ? (
            <span className="inline-block w-8 h-8 border-4 border-t-blue-500 border-l-blue-500 border-r-blue-500 border-b-gray-50 rounded-full animate-spin"></span>
          ) : (
            <div className="flex space-x-10">
              <div>
                <button
                  className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-blue-500 hover:text-white"
                  onClick={HandleSubmit}>
                  <p>Créer</p>
                </button>
              </div>
              <div>
                <button
                  className="flex justify-center items-center px-3 py-2 rounded-sm bg-gray-200 hover:bg-red-00 hover:text-white"
                  onClick={HandleClose}>
                  <p>Annuler</p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
