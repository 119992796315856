import DispoStepService from '../../oldService/dispoStepService';
import UserDispoService from '../../oldService/userDispoService';
import DispoService from '../../oldService/dispService';
import { DispositifStep } from '../../usualTypes/BasicTypes';

type response = {
  data: { data: DispositifStep[] };
};

const moveToNextStep = async (
  dispoId: number,
  actualStepId: number,
  userDispoId: number,
  dispoCreateId: number,
  callback: () => void
) => {
  const allStep: response = await DispoStepService.GetAllDispoStepDispoWithOrder(dispoId);
  const actualStep = allStep.data.data.find((step: DispositifStep) => step.step_id.id === actualStepId);
  const nextStep = allStep.data.data.find((step: DispositifStep) => step.order === actualStep.order + 1);
  if (nextStep.step_id.name === 'Évaluations S1' || nextStep.step_id.name === 'Évaluations S2') {
    await DispoService.PatchDispoEvaluationStep(dispoCreateId, nextStep.step_id.name === 'Évaluations S1' ? '1' : '2');
  }
  await UserDispoService.PatchUserDispoStep(userDispoId, nextStep.step_id.id);
  await callback();
};

export default moveToNextStep;
