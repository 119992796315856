import React, { useState } from 'react';
import AuthService from '../../oldService/authService';

import './Dropdown.css';

function Dropdown(props) {
  const [showOptions, setShowOptions] = useState(false);
  const token = AuthService.getCurrentToken();
  const handleClick = () => {
    setShowOptions(!showOptions);
  };
  const logout = () => {
    AuthService.logout(token.refresh_token);
  };

  return (
    <div className="Dropdown">
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={handleClick}
            className="inline-flex justify-center items-center rounded-sm px-4 py-2 font-medium text-gray-600 hover:text-blue-500">
            <div className="relative h-8 w-8 bg-gray-600 rounded-full overflow-hidden mr-2">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 w-10 h-10 text-gray-400 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"></path>
              </svg>
            </div>
            {props.lastName} {props.firstName}
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {showOptions && (
          <div
            className="absolute z-50 right-0 mt-2 w-56 origin-top-right rounded bg-white shadow-lg border focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1">
            <div className="py-1" role="none">
              <a
                href="/account"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-500 hover:text-white"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0">
                Mon Compte
              </a>
              <button
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 font-semibold hover:bg-blue-500 hover:text-white"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-1"
                onClick={logout}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 pr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Se déconnecter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
