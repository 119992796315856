import React, { Dispatch, SetStateAction } from 'react';
import StarRating from './ChoiceType/starRating';
import CheckBox from './ChoiceType/checkBox';
import TextField from './ChoiceType/textField';
import BasicErrorMessage from '../../ErrorMessage/basic/BasicErrorMessage';
import LoadingButton from '../../LoadingButton/LoadingButton';
import { QuestionEvaluation } from '../../../usualTypes/BasicTypes';

type ObjQuestion = {
  id_question: number;
  answer: string;
};
type Props = {
  questionArray: QuestionEvaluation[];
  evaluationToSubmit: ObjQuestion[];
  setEvaluationToSubmit: Dispatch<SetStateAction<ObjQuestion[]>>;
  OnClickSubmit: () => Promise<void>;
  error: boolean;
  loading: boolean;
  errorLength: boolean;
};
const FormDisplay = ({
  questionArray,
  evaluationToSubmit,
  setEvaluationToSubmit,
  OnClickSubmit,
  error,
  loading,
  errorLength
}: Props) => {
  const onChangeQuestion = (id: number, value: string) => {
    const list = evaluationToSubmit;
    const indexAlreadyExist = list.findIndex((obj: ObjQuestion) => obj.id_question === id);
    if (indexAlreadyExist === -1) {
      const objAnswer = { id_question: id, answer: value };
      list.push(objAnswer);
    } else {
      list[indexAlreadyExist].answer = value;
    }
    setEvaluationToSubmit(list);
  };
  return (
    <div className="px-10">
      {questionArray.map((question: QuestionEvaluation, index: number) => (
        <div key={index}>
          {question.type === 'starRating' && (
            <StarRating question={question} onChange={onChangeQuestion} oldEvals={evaluationToSubmit} />
          )}
          {question.type === 'checkBox' && (
            <CheckBox question={question} onChange={onChangeQuestion} oldEvals={evaluationToSubmit} />
          )}
          {question.type === 'textField' && (
            <TextField question={question} onChange={onChangeQuestion} oldEvals={evaluationToSubmit} />
          )}
        </div>
      ))}
      <BasicErrorMessage error={error} />
      <BasicErrorMessage
        error={errorLength}
        message={"Assurez vous d'avoir rempli l'intégralité de l'évaluation avant de l'envoyer"}
      />
      <div className="flex justify-center">
        <LoadingButton loading={loading} handleSubmit={OnClickSubmit} btnText={'Enregistrer'} />
      </div>
    </div>
  );
};

export default FormDisplay;
