import React, { useEffect, useState } from 'react';
import PeopleListAddModal from '../../oldComponents/PeopleListAddModal';
import UserDispoService from '../../oldService/userDispoService';

const NewListOfParticipant = ({ listOfParticipant, admin, excludedUsers, firstStep, dispoId }) => {
  const [filteredUser, setFilteredUser] = useState([]);
  const [searchField, setSearchField] = useState('');

  const handleSearchField = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchField(value);
  };

  const handleFilterSearch = () => {
    const userList = [...listOfParticipant];
    const filter = userList.filter((user) => user.name.toLowerCase().includes(searchField));
    setFilteredUser(filter);
  };

  useEffect(() => {
    handleFilterSearch();
  }, [searchField, listOfParticipant]);

  const handleRemoveItem = (user) => {
    UserDispoService.deleteOneUserDispo(user.other_id).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="w-full max-h-[32rem] overflow-x-hidden overflow-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8">
      <div className="flex items-center justify-between mb-4">
        <h5 className="text-xl font-bold leading-none text-gray-900">Liste des participants</h5>
        <input
          className="Searchbar px-2 py-1 border rounded-sm"
          type="search"
          placeholder="Rechercher..."
          onChange={handleSearchField}
        />
      </div>
      <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200">
          {admin && (
            <PeopleListAddModal
              excludedUsers={excludedUsers}
              onAdded={() => {
                window.location.reload();
              }}
              dispoId={dispoId}
              firstStep={firstStep}
              newList={true}
            />
          )}
          {filteredUser.length === 0 && (
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 text-jaune-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">Aucun utilisateur à afficher</p>
                </div>
              </div>
            </li>
          )}
          {filteredUser.map((user, index) => (
            <li className="py-2" key={index}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0 text-jaune-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">{user.name}</p>
                  <span className="text-sm text-gray-500 truncate">
                    <div className="flex space-x-1">
                      {user.team && <p>{user.team.group_id.name}</p>}
                      {user.team && user.team.group_id.parentgroup && <p>/ {user.team.group_id.parentgroup.name}</p>}
                    </div>
                  </span>
                </div>
                <div className="flex gap-8">
                  <a className="text-saumon-300 hover:text-saumon-200" href={`mailto:${user.email}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                  </a>
                  {admin && (
                    <button
                      className="text-rose-200 hover:text-rose-100"
                      onClick={() => {
                        handleRemoveItem(user);
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="18" y1="8" x2="23" y2="13"></line>
                        <line x1="23" y1="8" x2="18" y2="13"></line>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewListOfParticipant;
