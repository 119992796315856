import React, { useState } from 'react';
import { SirenServices } from '../../oldService/sirenService';

const ReactChoose = ({ data, setData }) => {
  const [inputSiret, setInputSiret] = useState('');

  const SiretOnClick = () => {
    SirenServices.GetSiret(setData, inputSiret);
  };

  return (
    <div>
      <p>Remplissage automatique à l'aide du numéro SIRET</p>
      <div className="flex justify-center space-x-2">
        <input
          type="text"
          placeholder="Numéro Siret..."
          className="border rounded-sm px-2"
          onChange={(e) => {
            setInputSiret(e.target.value);
          }}
        />
        <button className="border rounded-sm px-2 py-1 hover:bg-blue-500 hover:text-white" onClick={SiretOnClick}>
          Ok
        </button>
      </div>
      {data.error && (
        <div className="text-center">
          <p className="text-red-500 text-sm">Numéro siret invalide.</p>
        </div>
      )}
    </div>
  );
};

export default ReactChoose;
