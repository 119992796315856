import React, { useState } from 'react';
import LoadingButton from '../LoadingButton/LoadingButton';
import DispoService from '../../oldService/dispService';

const ModalModifDateEvent = ({ setDisplayModal, setBlur, event }) => {
  const [startHour, setStartHour] = useState(event.start_hourly);
  const [startDate, setStartDate] = useState(event.startdate);
  const [endHour, setEndHour] = useState(event.end_hourly);
  const [endDate, setEndDate] = useState(event.enddate);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setBlur(false);
    setDisplayModal(false);
  };

  const handleValidateAction = () => {
    setLoading(true);
    DispoService.modifDateEvent(event.id, startDate, startHour, endDate, endHour).then(() => {
      window.location.reload();
    });
  };
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backgoundModalColor">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-hide="popup-modal"
            onClick={handleCloseModal}>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="flex items-center flex-col p-6 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto mb-4 text-gray-400 w-14 h-14"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <circle cx="12" cy="12" r="7"></circle>
              <polyline points="12 9 12 12 13.5 13.5"></polyline>
              <path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"></path>
            </svg>
            <div className="grid grid-cols-2 space-x-2">
              <div>
                <label htmlFor="start-date" className="block mb-2 text-sm font-medium text-gray-900">
                  Date de début
                </label>
                <input
                  type="date"
                  name="start-date"
                  id="start-date"
                  defaultValue={event.startdate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="start-time" className="block mb-2 text-sm font-medium text-gray-900">
                  Heure de début
                </label>
                <input
                  type="time"
                  name="start-time"
                  id="start-time"
                  defaultValue={event.start_hourly}
                  onChange={(e) => {
                    setStartHour(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-2">
              <div>
                <label htmlFor="start-date" className="block mb-2 text-sm font-medium text-gray-900">
                  Date de fin
                </label>
                <input
                  type="date"
                  name="start-date"
                  id="start-date"
                  defaultValue={event.enddate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="start-time" className="block mb-2 text-sm font-medium text-gray-900">
                  Heure de fin
                </label>
                <input
                  type="time"
                  name="start-time"
                  id="start-time"
                  defaultValue={event.end_hourly}
                  onChange={(e) => {
                    setEndHour(e.target.value);
                  }}
                />
              </div>
            </div>
            <span className="mb-5 text-lg font-normal text-gray-500"></span>
            <LoadingButton
              loading={loading}
              handleSubmit={handleValidateAction}
              btnText="Modifier"
              roundeProp="rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalModifDateEvent;
