import { useEffect } from 'react';
import axios from 'axios';
import authHeader from './authHeader';
import { API } from './authHeader';

const API_URL = API + 'items/organization';

const getOneOrganization = (id) => {
  return axios.get(API_URL + '?filter[id][_eq]=' + id, { headers: authHeader() });
};

const PostOrganization = (
  name,
  numero_siret_rna,
  adress,
  postal_code,
  city,
  country,
  beneficiaries,
  numero_rna = null
) => {
  return axios.post(
    API_URL,
    {
      name: name,
      numero_siret_rna: numero_siret_rna,
      numero_rna: numero_rna,
      adress: adress,
      postal_code: postal_code,
      city: city,
      country: country,
      beneficiaries: beneficiaries
    },
    { headers: authHeader() }
  );
};

const PatchOrganization = (
  id,
  name,
  numero_siret_rna,
  adress,
  postal_code,
  city,
  country,
  beneficiaries,
  numero_rna = null
) => {
  return axios.patch(
    API_URL + '/' + id,
    {
      name: name,
      numero_siret_rna: numero_siret_rna,
      numero_rna: numero_rna,
      adress: adress,
      postal_code: postal_code,
      city: city,
      country: country,
      beneficiaries: beneficiaries
    },
    { headers: authHeader() }
  );
};

const GetAllOrganizations = () => {
  return axios.get(API_URL + '?filter[archived][_eq]=false&limit=-1', { headers: authHeader() });
};

const FormatGetOneOrganization = (organization, setOrganization, id) => {
  useEffect(() => {
    const MakeList = async () => {
      const data = await getOneOrganization(id);
      setOrganization(data.data.data[0]);
    };
    MakeList();
  }, [id]);
  return organization;
};

const FormatGetAllOrganizations = (organizations, setOrganizations) => {
  useEffect(() => {
    let list = [];

    const MakeList = async () => {
      const data = await OrganizationServices.GetAllOrganizations();
      data.data.data.map((item) => {
        list.push({
          search:
            item.name +
            ' ' +
            item.numero_siret_rna +
            ' ' +
            item.adress +
            ' ' +
            item.postal_code +
            ' ' +
            item.city +
            ' ' +
            item.country +
            ' ' +
            item.beneficiaries,
          ...item
        });
        return item;
      });
      setOrganizations(list);
    };
    MakeList();
  }, []);
  return organizations;
};

export const OrganizationServices = {
  PatchOrganization,
  PostOrganization,
  GetAllOrganizations,
  FormatGetAllOrganizations,
  FormatGetOneOrganization
};
