import React from 'react';
import Card from '../Card/Card';
import CreationCard from '../CreationCard/CreationCard';
import Slider from 'react-slick';

import './HomeCardList.css';

function HomeCardList(props) {
  let user = props.user;

  const settings = {
    className: 'center',
    dots: true,
    centerPadding: '60px',
    slidesToShow: 5,
    swipeToSlide: true,
    slidesToScroll: 4
  };

  return (
    <div className="HomeCardList">
      {user && (
        <>
          <div className="flex justify-between items-center">
            <h2 className="font-bold text-xl pb-5 text-blue-700/90">{props.title}</h2>
            <a href={'/user/' + props.type + '/0'} className="text-sm underline hover:text-blue-500">
              Voir tout
            </a>
          </div>
          <div className="px-5 py-5">
            {props.data.length >= 5 ? (
              <Slider {...settings}>
                {props.data.map((item, index) => {
                  return (
                    <div key={index}>
                      <Card data={item} type={props.type} role={user.role.name} template={item.template} />
                    </div>
                  );
                })}
                {props.type === 'plans' && user.role.name === 'Administrator' && (
                  <div className="pt-16">
                    <CreationCard type={props.type} />
                  </div>
                )}
              </Slider>
            ) : (
              <div className="grid grid-cols-5">
                {props.data.map((item, index) => {
                  return (
                    <div key={index}>
                      <Card data={item} type={props.type} role={props.role} template={item.template} />
                    </div>
                  );
                })}
                {props.data.length < 5 && props.type === 'plans' && user.role.name === 'Administrator' && (
                  <CreationCard type={props.type} />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default HomeCardList;
